import { getIsSpeedy, getIsUnlimitedMode } from '../App'
import { UAParser } from 'ua-parser-js'

import { MAX_CHALLENGES } from '../constants/settings'
import { CharStatus } from './statuses'
import { unicodeSplit } from './words'
import { getGameStreak, getStoredBotStats, getStoredNumberOfGames, getTitle } from './localStorage'
import { getTranslation } from '../context/messages'
import { calculateCountdownTitle, getTotalTimePenalty, isSpeedyGameDone } from './speedy'
import { TranslationKey } from '../constants/strings'
import { getToday } from './dateutils'

const webShareApiDeviceTypes: string[] = ['mobile', 'smarttv', 'wearable']
const parser = new UAParser()
const browser = parser.getBrowser()
const device = parser.getDevice()

export const shareStatus = (
  guesses: string[],
  lost: boolean,
  isHardMode: boolean,
  isGridHardMode: boolean,
  isHiddenLetterMode: boolean,
  isHighContrastMode: boolean,
  handleShareToClipboard: () => void,
  handleShareFailure: () => void,
  statuses: Map<number, CharStatus[]>
) => {
  var textToShare = `${getTitle()} ${getIsUnlimitedMode() ? '' : getToday().toDateString()}\n`
  
  if(getIsSpeedy()){
    textToShare = textToShare + getTranslation(TranslationKey.SPEEDY_NUM_OF_GAMES) + ': ' + getStoredNumberOfGames() + '\n'
    textToShare = textToShare + getTranslation(TranslationKey.TIME_PENALTY) + ': ' + getTotalTimePenalty() + '\n'
    textToShare = textToShare + getTranslation(TranslationKey.TIME) + ': ' + calculateCountdownTitle() + '\n'
  } else {
    textToShare = textToShare + `\n${lost ? 'X' : guesses.length}/${MAX_CHALLENGES}`
    textToShare = textToShare + `\n${getTranslation(TranslationKey.HARD_MODE)}:  ${isHardMode ? '✔️' : '❌'}\n${getTranslation(TranslationKey.GRID_HARD_MODE)}: ${isGridHardMode ? '✔️' : '❌'}\n${getTranslation(TranslationKey.HIDDEN_LETTER_MODE)}: ${isHiddenLetterMode ? '✔️' : '❌'}\n\n`
  }

  textToShare = textToShare + generateEmojiGrid(guesses, getEmojiTiles(isHighContrastMode), statuses)

  const shareData = { text: textToShare }
  let shareSuccess = false
  try {
    if (attemptShare(shareData)) {
      navigator.share(shareData)
      shareSuccess = true
    }
  } catch (error) {
    shareSuccess = false
  }

  try {
    if (!shareSuccess) {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(textToShare)
          .then(handleShareToClipboard)
          .catch(handleShareFailure)
      } else {
        handleShareFailure()
      }
    }
  } catch (error) {
    handleShareFailure()
  }
}

export const generateSpeedyEmojiGrid = () => {
  var emoji = '\n'
  var counter = 0;
  const tiles = ['1️⃣','2️⃣','3️⃣','4️⃣','5️⃣','6️⃣']
  for(const result of getGameStreak()){
    if(result === -1){
      emoji = emoji + '🟥'
    } else {
      emoji = emoji + tiles[result - 1]
    }

    counter++

    if(counter % 5 === 0){//todo
      emoji = emoji + '\n'
    }
  }
  
  return emoji
}

export const generateEmojiGrid = (
  guesses: string[],
  tiles: string[], 
  statuses: Map<number, CharStatus[]>,
) => {
  if(isSpeedyGameDone()){
    return generateSpeedyEmojiGrid()
  }

  const playerStats = getStoredBotStats(true, guesses.length)

  return guesses
    .map((guess, index) => {
      const splitGuess = unicodeSplit(guess)

      if(playerStats[index][1] !== ''){
        splitGuess.push(' (')
        splitGuess.push(playerStats[index][1])//words left
        splitGuess.push(')')
      }
      
      return splitGuess
        .map((_, i) => {
          if(i < statuses.get(index)!.length){
            switch (statuses.get(index)![i]) {
              case 'correct':
                return tiles[0]
              case 'present':
                return tiles[1]
              case 'hidden':
                return tiles[2]
              case 'absent':
                return tiles[3]
              default:
                return tiles[4]
            }
          }else{
            return splitGuess[i]
          }
        })
        .join('')
    })
    .join('\n')
}

const attemptShare = (shareData: object) => {
  return (
    // Deliberately exclude Firefox Mobile, because its Web Share API isn't working correctly
    browser.name?.toUpperCase().indexOf('FIREFOX') === -1 &&
    webShareApiDeviceTypes.indexOf(device.type ?? '') !== -1 &&
    navigator.canShare &&
    navigator.canShare(shareData) &&
    navigator.share
  )
}

const getEmojiTiles = (isHighContrastMode: boolean) => {
  let tiles: string[] = []
  tiles.push(isHighContrastMode ? '🟧' : '🟩')//correct
  tiles.push(isHighContrastMode ? '🟦' : '🟨')//present
  tiles.push('⬛')//hidden
  tiles.push('⬜')//absent
  tiles.push('⬜')//not played
  tiles.push()
  return tiles
}
