import { getIsShowSpotleBot } from '../App'
import '../index.css'
import QuestionBlock from './QuestionBlock'

const Faq = () => {
  if(getIsShowSpotleBot()){
    return (
      <div className="mx-auto flex w-full grow flex-col px-1 pt-2 pb-8 sm:px-6 md:max-w-7xl lg:px-8 short:pb-2 short:pt-2">
        <div className="flex text-left text-gray-500 dark:text-gray-300">
          <span style={{ fontSize: '30px', fontWeight: 'bold' }}>
            FAQ
          </span>
        </div>
        <br />
        <QuestionBlock question="How good is the Spotle Bot?" text="Currently the Bot has a 96.81% win rate (447 failures in 14000 games). 
          More than half of a the losses are on Sundays, with 252 losses. Mondays have 22, Tuesdays 31, Wednesdays 30, Thursdays 34, 
          Fridays 31 and Saturdays 47."/>
        <QuestionBlock question="Is the Bot being improved?" text="Yes. The first iteration of the Bot would lose 583 games."/>
      </div>
    )
  }
  return (
    <div className="mx-auto flex w-full grow flex-col px-1 pt-2 pb-8 sm:px-6 md:max-w-7xl lg:px-8 short:pb-2 short:pt-2">
      <div className="flex text-left text-gray-500 dark:text-gray-300">
        <span style={{ fontSize: '30px', fontWeight: 'bold' }}>
          FAQ
        </span>
      </div>
      <br />
      <QuestionBlock question="How do I play Spotle?" text="Spotle can be played on any modern device that runs a supported browser.
          If you are reading this, you can play Spotle. If you have a physical keyboard, simply type the desired word and press Enter.
          You can also use the mouse to click on the keyboard present on the screen. If you are on a mobile device, use the keyboard
          on the screen. There's no need to select the square you want to add a letter to as that's done automatically."/>
      
    	<div id="ezoic-pub-ad-placeholder-112"> </div>

      <QuestionBlock question="Why is it called Spotle?" text="The name was for a very long time an unknown. While Spotle was the final choice, other names like Staircastle, Staircasle, Maskle
          were in contention. Spotle was the best name we could think of. We do apologise to Spotle.io (do check out their game as well),
          as we only found your game after launching ours."/>
    
      <QuestionBlock question="Why did we create Spotle?" text="@kennyhaller and 
          @michael.dicostanzo started hiding some of the letters for their tiktok videos. Like most things online, if you 
          do the same over and over, people will eventually lose interest to move to new shinier things, so the challenges 
          were a way to attract and retain people. Eventually Kenny came up with the idea to ask/challenge Sven to see if he could
          make those challenges more than sticky notes on a screen.
          "/>

			<div id="ezoic-pub-ad-placeholder-113"> </div>

      <QuestionBlock question="How hard is Spotle?" text="As per the Privacy Policy, we collect information on whether a game was lost
          or won. This is used to see how easy/hard the game is. Currently the rate fluctuates between 30 and 70%, depending on the 
          challenge. Spotle will usually range from 5 hidden letters, to a possible max of 17. Average sits around 8/9 hidden letters. Each 
          row wil always have at least 1 letter hidden but never all 5."/>

      <QuestionBlock question="Why do some masks look like figures?" text="While this sometimes happens by pure chance, other times it's 
          intentional. Currently, Spotle games are divided in 4 categories: Monday to Friday, Symmetrical Saturday, Staircase Sunday and 
          Special dates. On Monday to Friday the mask will be random (from a list of randomized numbers). Symmetrical Saturday uses that same
          list but makes sure the figure is symmetrical. On Sundays we use a special list to make sure the mask looks like a staircase. The 
          special days can be any important dates. More recently we had Christmas, Kenny's birthday, Taylor Swift's Birthday and a few more 
          are planned for the future.
          "/>

			<div id="ezoic-pub-ad-placeholder-114"> </div>

      <QuestionBlock question="Has Spotle gone international?" text="Yes. Currently Spotle can be played in English, Portuguese, French, Spanish, and German, but the code 
          is prepared for other languages to be easily added. We just need translations and a list of words. Feel free to use the Contact Us section 
          if you want to help."/>
    </div>
  )
}

export default Faq