import { MAX_CHALLENGES } from '../constants/settings'
import {
  GameStats,
  addGameStreakLoss,
  addGameStreakWin,
  loadStatsFromLocalStorage,
  saveStatsToLocalStorage,
} from './localStorage'

export enum Result {
  TIE = 'TIE',
  WIN = 'WIN',
  LOSS = 'LOSS',
  EMPTY = 'EMPTY'
}

const addStats = (
  gameStats: GameStats
) => {
  const stats = {...gameStats}
  stats.totalGames += 1

  stats.successRate = getSuccessRate(stats)

  saveStatsToLocalStorage(stats)
  return stats
}

// In stats array elements 0-5 are successes in 1-6 trys
export const addStatsForLostGame = (
  gameStats: GameStats
) => {
  // Count is number of incorrect guesses before end.
  addGameStreakLoss()

  const stats = { ...gameStats }

  // A fail situation
  stats.currentStreak = 0
  stats.gamesFailed += 1

  return addStats(stats)
}

export const addStatsForWonGame = (
  gameStats: GameStats,
  count: number
) => {
  // Count is number of incorrect guesses before end.
  addGameStreakWin(count)

  const stats = { ...gameStats }

  if (count > MAX_CHALLENGES) {
    if(stats.sixPlusWins !== undefined){
      stats.sixPlusWins += 1
    }else{
      stats.sixPlusWins = 1
    } 
  } else {
    stats.winDistribution[count - 1] += 1
  }
  
  stats.currentStreak += 1

  if (stats.bestStreak < stats.currentStreak) {
    stats.bestStreak = stats.currentStreak
  }
  
  return addStats(stats)
}

export const addTimeoutStatsForCompletedGame = (
  gameStats: GameStats
) => {
  const stats = { ...gameStats }

  if(stats.timeoutWins !== undefined){
    stats.timeoutWins += 1
  }else{
    stats.timeoutWins = 1
  } 
  stats.currentStreak += 1

  if (stats.bestStreak < stats.currentStreak) {
    stats.bestStreak = stats.currentStreak
  }

  return addStats(stats)
}

export const addTieStatsForCompletedGame = (
  gameStats: GameStats
) => {
  const stats = { ...gameStats }

  if(stats.ties !== undefined){
    stats.ties += 1
  }else{
    stats.ties = 1
  }

  return addStats(stats)
}

const defaultStats: GameStats = {
  winDistribution: Array.from(new Array(MAX_CHALLENGES), () => 0),
  gamesFailed: 0,
  currentStreak: 0,
  bestStreak: 0,
  totalGames: 0,
  successRate: 0,
  timeoutWins: 0,
  sixPlusWins: 0,
  ties: 0
}

export const loadStats = () => {
  return loadStatsFromLocalStorage() || defaultStats
}

const getSuccessRate = (gameStats: GameStats) => {
  const { totalGames, gamesFailed } = gameStats

  return Math.round(
    (100 * (totalGames - gamesFailed)) / Math.max(totalGames, 1)
  )
}
