import { TranslationKey } from '../../constants/strings'
import { getTranslation} from '../../context/messages'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const AboutUsModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title={TranslationKey.ABOUT_US} isOpen={isOpen} handleClose={handleClose}>
      <p className="mt-6 text-sm italic text-gray-500 dark:text-gray-300">
        {getTranslation(TranslationKey.EMAIL_US)}   {' '} 
        <a href="mailto:spotlegame@gmail.com" className="font-bold underline">email</a>
      </p>
      
    </BaseModal>
  )
}
