import { calculateCountdownTitle, getSpeedyGameCounter, isSpeedyGameDone } from '../../lib/speedy'
import { getIsScalizzi, getIsShowSpotleBot, getIsSpeedy, getIsTaytay, isCoSpotle } from '../../App'
import { getSpeedyStartTime, getStoredBotChallengeMode, getStoredBotMode, getStoredGameDate, getStoredIndex, getStoredOpponentLevel, getTitle, getWinsNeeded, setStoredIndex } from '../../lib/localStorage'
import {
  ChartBarIcon,
  CogIcon,
  FlagIcon,
  InformationCircleIcon,
  PlayIcon,
} from '@heroicons/react/outline'
import CountUpTime from '../../lib/countdown/CountUpTime'
import FadeIn from 'react-fade-in/lib/FadeIn'
import { getTayTaySongName, getTranslation } from '../../context/messages'
import { TranslationKey } from '../../constants/strings'
import { getCoSpotleGameCounter } from '../../lib/cospotle'
import { getIndex } from '../../lib/words'
import { TITLE_DELAY } from '../../constants/settings'
import { useEffect, useState } from 'react'

type Props = {
  setIsInfoModalOpen: (value: boolean) => void
  setIsLanguageModalOpen: (value: boolean) => void
  setIsGamesModalOpen: (value: boolean) => void
  setIsStatsModalOpen: (value: boolean) => void
  setIsSettingsModalOpen: (value: boolean) => void
}

function createValidPhases():number[] {
  const validPhases = []
  if(getIsSpeedy() && !isSpeedyGameDone()){
    validPhases.push(0)
  }

  if(isSpeedyGameDone()){
    validPhases.push(1)
  }

  if(!getIsScalizzi()){
    validPhases.push(2)
  }

  if(getIsScalizzi()){
    validPhases.push(3)
  }

  if(getStoredBotChallengeMode()){
    validPhases.push(4)
    validPhases.push(5)
  }

  if(isCoSpotle() && getStoredBotMode() && !getIsShowSpotleBot()){
    validPhases.push(6)
  }

  if(getIsSpeedy()){
    validPhases.push(7)
  }

  if(getIsTaytay()){
    validPhases.push(8)
  }

  return validPhases
}

function defaultTitle(title: string): JSX.Element{
  return (
    <FadeIn transitionDuration={TITLE_DELAY / 3}>  
      <p className="mr-8 text-xl font-bold dark:text-white text-center">
        {title}
      </p>
    </FadeIn>
  )
}

//save the time when we change title, use that to see how big, or small the delay should be to make sure 
//that a game change, like a key press, doesn't hold the title hostage

export const Navbar = ({
  setIsInfoModalOpen,
  setIsLanguageModalOpen,
  setIsGamesModalOpen,
  setIsStatsModalOpen,
  setIsSettingsModalOpen
}: Props) => {

  const [titleIndex, setTitleIndex] = useState(() => {
    setStoredIndex(0)
    return 0
  })
  
  //make this save the time of the last rotation so we can avoid issues when the page keeps getting reloaded
  useEffect(() => {
    const interval = setInterval(() => {
      setTitleIndex(getStoredIndex() + 1)
      setStoredIndex(getStoredIndex() + 1)
    }, TITLE_DELAY)
  
    return () => clearInterval(interval)
  }, [])

  const validPhases = createValidPhases()
  const index = titleIndex % validPhases.length

  return (
    <div className="navbar">
      <div className="navbar-content px-2 short:h-auto">
        <div className="right-icons">
          <InformationCircleIcon
            className="mr-3 h-6 w-6 cursor-pointer dark:stroke-white"
            onClick={() => setIsInfoModalOpen(true)}
          />
          <FlagIcon
            className="mr-2.5 h-6 w-6 cursor-pointer dark:stroke-white"
            onClick={() => setIsLanguageModalOpen(true)}
          />
          <PlayIcon
            className="h-6 w-6 cursor-pointer dark:stroke-white"
            onClick={() => setIsGamesModalOpen(true)}
          />
        </div>
        {validPhases[index] === 0 && (
          <FadeIn transitionDuration={TITLE_DELAY / 3}>
            <div className="mr-8 text-xl font-bold dark:text-white text-center">
              <CountUpTime startTime={getSpeedyStartTime()}/>
            </div>
          </FadeIn>
        )}
        {validPhases[index] === 1 && (
          defaultTitle(calculateCountdownTitle())
        )}
        {validPhases[index] === 2 && (
          defaultTitle(getTitle())
        )}
        {validPhases[index] === 3 && (
          defaultTitle('Time passed since Scalizzi last drove into a ditch')
        )}
        {validPhases[index] === 4 && (
          defaultTitle(getTranslation(TranslationKey.SPEEDY_NUM_OF_GAMES) +
          ': ' + getCoSpotleGameCounter())
        )}
        {validPhases[index] === 5 && (
          defaultTitle(getTranslation(TranslationKey.WINS_NEEDED) +
          ': ' + getWinsNeeded())
        )}
        {validPhases[index] === 6 && (
          defaultTitle(getTranslation(TranslationKey.LEVEL) +
          ': ' + getStoredOpponentLevel())
        )}
        {validPhases[index] === 7 && (
          defaultTitle(getTranslation(TranslationKey.SPEEDY_NUM_OF_GAMES) +
          ': ' + getSpeedyGameCounter())
        )}
        {validPhases[index] === 8 && (
          defaultTitle(getTayTaySongName(getIndex(getStoredGameDate())))
        )}
        <div className="right-icons">
          <ChartBarIcon
            className="mr-3 h-6 w-6 cursor-pointer dark:stroke-white"
            onClick={() => setIsStatsModalOpen(true)}
          />
          <CogIcon
            className="h-6 w-6 cursor-pointer dark:stroke-white"
            onClick={() => setIsSettingsModalOpen(true)}
          />
        </div>
      </div>
      <hr></hr>
    </div>
  )
}
