import { useEffect, useState } from "react";
import moment from "moment";
import { GameStats, getStoredBotMode, getStoredGameDate, getStoredGuessDate, getStoredMyTurn, getStoredParallelMode, getStoredTeamMode, setStoredGuessDate } from "./../localStorage";
import { ClockIcon } from "@heroicons/react/outline";
import { getLossMessage } from "../../context/messages";
import { REVEAL_TIME_MS } from "../../constants/settings";
import { addStatsForLostGame, addTimeoutStatsForCompletedGame } from "./../stats";

const zeroSecondsClock = () => {  
  return (<div
    className="flex items-center justify-center text-center"
    style={{
      fontSize: '15px',
      color: 'black',
      fontWeight: 'bold'
    }}
  >
    <p className="counter right-icons">
      <ClockIcon className="h-6 w-6" />
      <span>0s</span>
    </p>
  </div>)
}

export const CountdownSeconds = (props:{
  isGameLost: boolean,
  setIsGameLost: Function,
  isGameWon: boolean,
  setIsGameWon: Function,
  solution: string,
  showSuccessAlert: Function,
  stats: GameStats,
  guesses: string[],
  setStats: Function
} ) => {
  const [currentTime, setCurrentTime] = useState(Date.now());
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  if(getStoredParallelMode() || getStoredBotMode()){
    return (<div> </div>)
  }

  if(getStoredGuessDate() === undefined){
    return zeroSecondsClock()
  }

  const targetTime = getStoredGuessDate()!.getTime()

  var timeBetween = moment.duration(targetTime - currentTime);

  var seconds = timeBetween.seconds() + timeBetween.minutes() * 60
  if(seconds <= 0){
    if(seconds < 0){
      //here because sometimes the guessDate is not reset
      return zeroSecondsClock()
    }
    
    if(!(props.isGameLost || props.isGameWon)){
      if(getStoredMyTurn() || getStoredTeamMode()){
        props.setIsGameLost(true)
        props.setStats(addStatsForLostGame(props.stats))
      }else{
        props.setIsGameWon(true)
        props.showSuccessAlert(getLossMessage(getStoredGameDate(), props.solution), {
          persist: false,
          delayMs: REVEAL_TIME_MS * props.solution.length + 10,
        })
        props.setStats(addTimeoutStatsForCompletedGame(props.stats))
      }
    }
    
    setStoredGuessDate(undefined)
    seconds = 0
  }

  var colour = 'green'
  if(seconds < 5){
    colour = 'red'
  } else if(seconds < 15){
    colour = 'orange'
  } else if(seconds < 25){
    colour = 'gold'
  } else if(seconds < 35){
    colour = 'yellowgreen'
  }

  return (
    <div
      className="flex items-center justify-center text-center"
      style={{
        fontSize: '15px',
        color: colour,
        fontWeight: 'bold'
      }}
    >
      <p className="counter right-icons">
        <ClockIcon className="h-6 w-6" />
        <span>{seconds}s </span>
      </p>
    </div>
  );
};