import '../index.css'

import { TranslationKey } from '../constants/strings'
import { setTitle } from '../lib/localStorage'

const Imperialists = () => {
  setTitle(TranslationKey.SLAVA_UKRAINI)

  const prefersDarkMode = window.matchMedia(
    '(prefers-color-scheme: dark)'
  ).matches

  if (prefersDarkMode) {
    document.documentElement.classList.add('dark')
  } else {
    document.documentElement.classList.remove('dark')
  }

  return (
    <div>
      <div className="flex items-center justify-center text-center text-gray-500 dark:text-gray-300">
        <span style={{ fontSize: '50px', fontWeight: 'bold' }}>
          SLAVA UKRAINI
        </span>
      </div>
      <br />
      <div className="flex items-center justify-center text-center text-gray-500 dark:text-gray-300">
        <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
          Looks like you are accessing from R*ssia.
          <br />
          While the invasion of Ukraine continues, you will not be able to
          easily access Spotle. <br />
          Once your country stops this barbaric war, Spotle will reopen for you.
        </span>
      </div>
      <br />
      <div className="flex items-center justify-center text-center">
        <div
          style={{
            height: '480px',
            width: '280px',
            backgroundImage:
              'url(https://media3.giphy.com/media/v1.Y2lkPTc5MGI3NjExNTUwNjdhZTcxOTQzY2YwNzdmZjg2NGY3OTBhYTg1MDRmNWM4YzZjMiZlcD12MV9pbnRlcm5hbF9naWZzX2dpZklkJmN0PWc/4kxC8fLzzHiYitnA3k/giphy.gif)',
            display: 'block',
          }}
        />
      </div>
    </div>
  )
}

export default Imperialists
