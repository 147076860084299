import { getCounter, getStoredCustomSplitTime, getStoredNumberOfRows, getStoredStatuses, setStoredCharObj } from './localStorage'
import { getIndex, unicodeSplit } from './words'
import { RANDOM } from '../constants/random'
import { getIsHorsle, getIsLazy, getIsScalizzi, getIsSpeedy, getIsUnlimitedMode, isCustom, isImpossible, isSixLetter } from '../App'
import { MAX_CHALLENGES } from '../constants/settings'
import { addDays } from 'date-fns'
import { getHorsleTimeArray, getSpecialSplitTimeArray, getStairCaseSundaySplitTimeArray, getSymmetricalSaturdaySplitTimeArray } from './masks'

export type CharStatus = 'absent' | 'present' | 'correct' | 'hidden' | 'empty'

export const setKeyStatuses = (
  statuses: CharStatus[],
  guess: string,
  charObj: { [key: string]: CharStatus },
): { [key: string]: CharStatus } => {

  if(typeof statuses !== "undefined"){

    const array = unicodeSplit(guess)
    const map = new Map<string, number>();
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      
      if(typeof map.get(element) === "undefined"){
        map.set(element, index)
      }else{
        if((statuses[map.get(element)!] === 'absent')
        || (statuses[map.get(element)!] === 'hidden' && statuses[index] === 'present')
        || (statuses[map.get(element)!] === 'hidden' && statuses[index] === 'correct')
        || (statuses[map.get(element)!] === 'present' && statuses[index] === 'correct')){
          map.delete(element)
          map.set(element, index)
        }
      }
    }
    for (let [letter, i] of Array.from(map.entries())) {
      if(statuses[i] === 'hidden' && typeof charObj[letter] === "undefined"){
        charObj[letter] = 'hidden'      
      }
      else if (statuses[i] === 'absent' && (typeof charObj[letter] === "undefined" || charObj[letter] === "hidden")) {
        // make status absent
        charObj[letter] = 'absent'
      }
      else if (statuses[i] === 'correct') {
        //make status correct
        charObj[letter] = 'correct'
      }
      else if (statuses[i] === 'present' && charObj[letter] !== 'correct') {
        //make status present
        charObj[letter] = 'present'
      }
    }
  }

  setStoredCharObj(charObj)
  return charObj
}

export const getSpecialBackground = (today: Date): string => {
  //kenny
  if(today.getMonth() === 10 && today.getDate() === 17){
    return 'https://media1.giphy.com/media/epd82n6GN6vMzzRygj/giphy.gif'
  }
  
  //christmas
  if(today.getMonth() === 11 && today.getDate() === 25){
    return 'https://media3.giphy.com/media/eoi0x4UDYfwhC9ozX7/giphy.gif'
  } 

  return ""
}

function getNewIndex(indexes: number[], newIndex: number):number {
  if(newIndex < 0){
    newIndex = 4
  }

  if(!indexes.includes(newIndex)){
    return newIndex
  }

  return getNewIndex(indexes, newIndex - 1)
}

export const getImpossibleTimeArray = (
  today: Date   
): number[] => {
  var length = 5
  if(isSixLetter()) {
    length = 6
  }

  var indexes: number[] = []
  for(var i = 0; i < getStoredNumberOfRows(); i++){
    const randomIndex = +RANDOM[getIndex(today) % RANDOM.length].slice(i * length, i * length + length)
    indexes.push(getNewIndex(indexes, Math.floor(randomIndex/20000)))
  }

  const splitTime: number[] = [];

  for(var countRow = 0; countRow < MAX_CHALLENGES - 1; countRow++){
    for(var j = 0; j < length; j++){
      if(!indexes.includes(j)){
        splitTime.push(1) 
      }
      else{
        splitTime.push(0)
      }
    }
  }

  return splitTime
}

export const getSpeedyTimeArray = (
  today: Date
): number[] => {
    const timeArray = getHorsleTimeArray(today)
    var newTimeArray = []

    for(const time of timeArray){
      if(time === 1){
        newTimeArray.push(0)
      } else {
        newTimeArray.push(1)
      }
    }

    return newTimeArray
}

export const getSplitTimeArray = (
  today: Date   
): number[] => {
  // if(true){
  //   return [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
  // }

  //if(true){
  //  return getSplitTimeStarts(today)
  //}

  if(getIsSpeedy() && !getIsUnlimitedMode()){
    today = addDays(today, -1 * 10 * getCounter() * 7)
  }

  if(isCustom() && getStoredCustomSplitTime().length > 0){
    return getStoredCustomSplitTime()
  }

  if(getIsHorsle() || getIsScalizzi()){
    return getHorsleTimeArray(today)
  }

  if(getIsSpeedy() && !getIsLazy()){
    return getSpeedyTimeArray(today)
  }

  if(isImpossible()){
    return getImpossibleTimeArray(today)
  }

  var splitTimeArray = getSpecialSplitTimeArray(today)
  //unique days
  if(splitTimeArray.length !== 0){
    return splitTimeArray
  }

  const randomNumber = RANDOM[getIndex(today) % RANDOM.length]

  //saturday
  if(today.getDay() === 6){
    return getSymmetricalSaturdaySplitTimeArray(randomNumber)
  }
  //sunday
  else if(today.getDay() === 0){
    if(!isSixLetter()){
      return getStairCaseSundaySplitTimeArray(randomNumber, 5)
    }

    return getStairCaseSundaySplitTimeArray(randomNumber, 6)
  }
  
  //random
  var hiddenSpots = 0  
  var length = 5
  if(isSixLetter()){
    length = 6
  }
  const splitTime = Array.from(randomNumber, Number)

  for (let i = 0; i < splitTime.length; i++) {
    if(hiddenSpots >= (length - 1) || splitTime[i] < 7){
      splitTime[i] = 0
    }else{
      splitTime[i] = 1
      hiddenSpots++
    }

    if((i + 1) % length === 0){
      if(hiddenSpots === 0 ){
        splitTime[i - 2] = 1
        }
        hiddenSpots = 0;
      }
    }

  return splitTime
}

const getStartDoubleHiddenSpots = (index: number) => {
  var splitTime= [0,0,0,0,0]
  if(index < 4){
    splitTime[0] = 1
    splitTime[index + 1] = 1
  } else if(index < 7){
    splitTime[1] = 1
    splitTime[index - 2] = 1
  } else if(index < 9){
    splitTime[2] = 1
    splitTime[index - 4] = 1
  } else {
    splitTime = [0,0,0,1,1]
  }

  return splitTime
}

const getStartHiddenSpots = (today: Date): number[] => {
  const daysFromStart = getIndex(today) % 32 //(2^5)
  
  if(daysFromStart === 0) {
    return [0,0,0,0,0]
  }

  const splitTime = [1,1,1,1,1]

  if(daysFromStart >= 1 && daysFromStart <= 5) {
    for(let i = 0; i < 5; i++){
      if(i === daysFromStart - 1){
        splitTime[i] = 1
      }else{
        splitTime[i] = 0
      }
    }
  } else if(daysFromStart >= 6 && daysFromStart <= 15) {
    return getStartDoubleHiddenSpots(daysFromStart - 6)
  } else if(daysFromStart >= 16 && daysFromStart <= 25) {
    const temp = getStartDoubleHiddenSpots(daysFromStart - 16)
    for(let i = 0; i < 5; i++){
      if(temp[i] === 0){
        temp[i] = 1
      } else {
        temp[i] = 0
      }
    }

    return temp
  } else if(daysFromStart >= 26 && daysFromStart <= 30) {
    for(let i = 0; i < 5; i++){
      if(i === daysFromStart - 26){
        splitTime[i] = 0
      }else{
        splitTime[i] = 1
      }
    }
  }

  return splitTime
}

export const getSplitTimeStarts = (today: Date): number[] => {
  return [...getStartHiddenSpots(today), 
          0,0,0,0,0,
          0,0,0,0,0,
          0,0,0,0,0,
          0,0,0,0,0,
          0,0,0,0,0]
}

const populateAllHiddenBlocks = (
  statuses: Map<number, CharStatus[]>,
  splitTime: number[],
  solutionLength: number
  ): void => {
  for (let i = 0; i < splitTime.length - 1; i++) {
    if (typeof statuses.get(i) === 'undefined'){
      statuses.set(i, new Array(solutionLength))
    }
    for (let j = 0; j < solutionLength; j++) {
      if(typeof statuses.get(i)![j] === 'undefined' && splitTime[i * solutionLength + j] === 1){
        statuses.get(i)![j] = 'hidden'
      }
    }
  }
}

export const getEasyGuessStatuses = (
  today: Date,
  isGridHardMode: boolean,
  solutionLength: number,
  hiddenLetterMode: boolean
): Map<number, CharStatus[]> => {
  if(isCustom()){
    return getStoredStatuses()
  }

  if(!isGridHardMode){
    const splitTimeArray = getSplitTimeArray(today)
    const splitTime = splitTimeArray.concat(splitTimeArray.concat(splitTimeArray))
    const statuses = new Map<number, CharStatus[]>()
    populateAllHiddenBlocks(statuses, splitTime, solutionLength)

    return getGuessStatuses(0, '', [''], statuses, today, hiddenLetterMode)
  }

  return new Map<number, CharStatus[]>()
}

function getHiddenLetters(
  index: number,
  guesses: string[],
  statuses: Map<number, CharStatus[]>
  ): string[]{
  var hiddenLetters:string[] = []

  for(let idx = 0; idx < index; idx++){
    const guessStatuses:CharStatus[] = statuses.get(idx)!

    for(let innerIdx = 0; innerIdx < guesses[idx].length; innerIdx++){
      if(guessStatuses[innerIdx] === 'hidden'){
        hiddenLetters.push(guesses[idx][innerIdx])
      }
    }
  }

  return hiddenLetters
}

export const getGuessStatuses = (
  index: number,
  solution: string,
  guesses: string[],
  statuses: Map<number, CharStatus[]>,
  today: Date,
  hiddenLetterMode: boolean
): Map<number, CharStatus[]> => {
  const splitSolution = unicodeSplit(solution)
  const splitGuess = unicodeSplit(guesses[index])
  const solutionCharsTaken = splitSolution.map((_) => false)
  const splitTimeArray = getSplitTimeArray(today)
  const splitTime = splitTimeArray.concat(splitTimeArray.concat(splitTimeArray))

  if (typeof statuses.get(index) === 'undefined'){
    statuses.set(index, new Array(solution.length))
  }

  var hiddenLetters:string[] = []
  if(hiddenLetterMode){
    hiddenLetters = getHiddenLetters(index, guesses, statuses)
  }

  // handle all correct cases first
  splitGuess.forEach((letter, i) => {
    if (letter === splitSolution[i]) {
      statuses.get(index)![i] = 'correct'
      solutionCharsTaken[i] = true
      return
    }
  })

  splitGuess.forEach((letter, i) => {
    if (statuses.get(index)![i] === 'correct') return

    if (!splitSolution.includes(letter)) {
      // handles the absent case
      statuses.get(index)![i] = 'absent'
      return
    }

    // now we are left with "present"s
    const indexOfPresentChar = splitSolution.findIndex(
      (x, index) => x === letter && !solutionCharsTaken[index]
    )

    if (indexOfPresentChar > -1) {
      statuses.get(index)![i] = 'present'
      solutionCharsTaken[indexOfPresentChar] = true
    } else {
      statuses.get(index)![i] = 'absent'
    }
  })

  // hidden cases last to prevent present/correct issues
  splitGuess.forEach((letter, i) => {
    if (splitTime[index * solution.length + i] === 1 || hiddenLetters.includes(letter)) {
      statuses.get(index)![i] = 'hidden'
      return
    }
  })

  return statuses
}
