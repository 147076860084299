import { enUS } from 'date-fns/locale'

export const MAX_CHALLENGES = 6
export const ALERT_TIME_MS = 2000
export const LONG_ALERT_TIME_MS = 10000
export const REVEAL_TIME_MS = 350
export const WELCOME_INFO_MODAL_MS = 350
export const DISCOURAGE_INAPP_BROWSERS = true
export const ENABLE_MIGRATE_STATS = false
export const BLOWFISH_KEY = 'svenRules_kennySucks_goBernieS'
export const BLOWFISH_IV = 'spotttle'
export const DATE_LOCALE = enUS
export const TITLE_DELAY = 3000
export const APRIL_FOOLS_DATE = new Date(1991, 3, 1)
export const URL = 'https://spotlegame.uk/api'
// export const URL = 'http://localhost:1337'
// export const APRIL_FOOLS_DATE = new Date(1991, 2, 16) // testing