import { addDays } from "date-fns"
import { getStoredAprilFoolsSolutions, getStoredGameDate, getStoredRemainingSolutions, resetStoredAprilFoolsSolutions, setStoredAprilFoolsSolutions } from "./localStorage"
import { getIndex, getSolution } from "./words"
import { bot } from "../bot/SpotleBot"
import { CharStatus } from "./statuses"

export const getAprilFoolsFakeSolutions = (
  guesses: string[],
  isHardMode: boolean,
  isGridHardMode: boolean,
  isHiddenLetterMode: boolean,
  statuses: Map<number, CharStatus[]>,
  currentGuess?: string
  ) => {
  if(guesses.length === 0){
      resetStoredAprilFoolsSolutions()
  }

  const solution = getSolution(getIndex(getStoredGameDate()))
  const fakeSolution = getSolution(getIndex(addDays(getStoredGameDate(), 14)))
  var midSolution = getSolution(getIndex(addDays(getStoredGameDate(), 28)))
  var lastSolution = getSolution(getIndex(addDays(getStoredGameDate(), 42)))

  var tempGuesses = [...guesses, 'SCALI']
  if(currentGuess !== undefined && currentGuess !== ''){
    tempGuesses = [...guesses, currentGuess, 'SCALI']
  }

  if((guesses.length === 1 && getStoredAprilFoolsSolutions().length === 0 ) || 
    (guesses.length === 3 && getStoredAprilFoolsSolutions().length === 1)){
    bot(isHardMode, isGridHardMode, isHiddenLetterMode, getStoredGameDate(), 
    solution, tempGuesses, statuses)

    //TODO randomize list
    const remainingSolutions = getStoredRemainingSolutions(guesses.length)
    const indexSolutions = remainingSolutions.indexOf(solution.toLocaleLowerCase())
    if (indexSolutions !== -1) {
      remainingSolutions.splice(indexSolutions, 1)
    }
    
    if(remainingSolutions.length > 1){
      if(guesses.length === 1){
          setStoredAprilFoolsSolutions(remainingSolutions[0].toLocaleUpperCase())
      } else if(guesses.length === 3){
        for(const fakeSol of remainingSolutions){
          if(!(getStoredAprilFoolsSolutions().includes(fakeSol.toLocaleUpperCase()))){
              setStoredAprilFoolsSolutions(fakeSol.toLocaleUpperCase())
              break
          }
        }
      }
    }
  }

  if(getStoredAprilFoolsSolutions().length > 0){
    midSolution = getStoredAprilFoolsSolutions()[0]
  }
  if(getStoredAprilFoolsSolutions().length > 1){
    lastSolution = getStoredAprilFoolsSolutions()[1]
  }

  return [fakeSolution, midSolution, lastSolution]
}


export const checkFakeSolution = (
  size: number, fakeSolution: string, guesses: string[], currentGuess: string
) => {
  return guesses.length === size && fakeSolution !== currentGuess
}

export const fakeGuessUsed = (guesses: string[], fakeGuess: string) => {
  return guesses.includes(fakeGuess)
}