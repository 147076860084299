import '../index.css'

import { Route, Routes } from 'react-router-dom'

import Faq from '../faq/Faq'
import { isRussia } from '../lib/localStorage'

const RoutedFaq = () => {
  if (isRussia()) {
    return (
      <div>
        <Routes>
          <Route path="*" element={<div></div>} />
        </Routes>
      </div>
    )
  }

  return (
    <div>
      <Routes>
        <Route path="*" element={<Faq />} />
      </Routes>
    </div>
  )
}

export default RoutedFaq
