import { WORDS_SONGS_TAYTAY } from "../constants/wordlist";
import { MESSAGES, TranslationKey } from "../constants/strings";
import { getLanguageKey, getStoredGameDate, getStoredNumberOfRows, isRussia } from "../lib/localStorage";
import { getIsScalizzi } from "../App";
import { getIndex } from "../lib/words";

export const PATHS = ['/lazy', '/swift', '/speedy', '/speedy/lazy', '/cospotle', 
  '/cospotle/team', '/cospotle/bot', 'cospotle/parallel', '/cospotle/bot/challenge',]

export const PATHS_TRANSLATION = [TranslationKey.LAZY, TranslationKey.SWIFTIE_SPOTLE,
  TranslationKey.SPEEDY, TranslationKey.SPEEDY_LAZY, TranslationKey.COSPOTLE,
  TranslationKey.TEAM_COSPOTLE, TranslationKey.COSPOTLE_VS_BOT, TranslationKey.PARALLEL_COSPOTLE,
  TranslationKey.COSPOTLE_VS_BOT_CHALLENGE]

export function getRandomPath(): string{
  return PATHS[getIndex(getStoredGameDate()) % PATHS.length]
}

export function getRandomPathTranslation(): string{
  return getTranslation(PATHS_TRANSLATION[
      getIndex(getStoredGameDate()) % PATHS_TRANSLATION.length])
}

export const getPageTitle = (path: string, defaultTitle?: boolean) => {
  path = path.toLowerCase()
  
  if(path !== '/' && path.endsWith('/')){
    path = path.slice(0, -1)
  }

  var translationKey = TranslationKey.NAUGHTY_GAMER
  if (path === '/unlimited' || path === '/english/unlimited' ||
      path === '/portuguese/unlimited' || path === '/portugues/unlimited' ||
      path === '/french/unlimited' || path === '/francais/unlimited' ||
      path === '/spanish/unlimited' || path === '/espanol/unlimited' ||
      path === '/german/unlimited' || path === '/deutsch/unlimited') {
      translationKey = TranslationKey.SPOTLE_UNLIMITED
  } 
  else if (path === '/' || path === '/english' ||
      path === '/portuguese' || path === '/portugues' ||
      path === '/spanish' || path === '/espanol' ||
      path === '/french' || path === '/francais' ||
      path === '/german' || path === '/deutsch') {
      translationKey = TranslationKey.SPOTLE
  } 
  else if (path === '/six' || path === '/six/english') {
    translationKey = TranslationKey.SIX_SPOTLE
} 
  else if (path === '/tos') {
    translationKey = TranslationKey.TERMS_OF_SERVICE
  } 
  else if (isRussia() || path === '/r*ssia') {
    translationKey = TranslationKey.SLAVA_UKRAINI
  } 
  else if (path === '/privacypolicy') {
    translationKey = TranslationKey.PRIVACY_POLICY
  } 
  else if (path === '/cookiespolicy') {
    translationKey = TranslationKey.COOKIES_POLICY
  } 
  else if (path === '/dummies') {
    translationKey = TranslationKey.SPOTLE_FOR_DUMMIES
  } 
  else if (path === '/spotlebot') {
    translationKey = TranslationKey.SPOTLE_BOT
  } 
  else if (path.endsWith('/staircase')) {
    translationKey = TranslationKey.STAIRCASE
  } 
  else if(path.endsWith('/speedy/lazy/unlimited')){
    translationKey = TranslationKey.SPEEDY_LAZY_UNLIMITED
  } 
  else if(path.endsWith('/speedy/lazy')){
    translationKey = TranslationKey.SPEEDY_LAZY
  }
  else if (path.endsWith('/speedy/unlimited')){
    translationKey = TranslationKey.SPEEDY_UNLIMITED
  } 
  else if (path.endsWith('/speedy')){
    translationKey = TranslationKey.SPEEDY
  } 
  else if (path.endsWith('/lazy/unlimited')) {
    translationKey = TranslationKey.LAZY_UNLIMITED
  }  
  else if (path.endsWith('/lazy')) {
    translationKey = TranslationKey.LAZY
  }
  else if (path.endsWith('/swift/unlimited')) {
    translationKey = TranslationKey.SWIFTIE_SPOTLE_UNLIMITED
  } 
  else if (path.endsWith('/swift')) {
    translationKey = TranslationKey.SWIFTIE_SPOTLE
  } 
  else if (path.endsWith('/ginjanner')){
    translationKey = TranslationKey.GINJANNER
  }
  else if (path.endsWith('/ginjanner/unlimited')) {
    translationKey = TranslationKey.GINJANNER_UNLIMITED
  } 
  else if(path === '/scalizzi'){
    translationKey = TranslationKey.SCALIZZI
  } 
  else if(path.endsWith('/impossible')){
    if(getStoredNumberOfRows() === 1 || defaultTitle){
      translationKey = TranslationKey.IMPOSSIBLE_SPOTLE
    }
    else if(getStoredNumberOfRows() === 2){
      translationKey = TranslationKey.IMPOSSIBLE_SPOTLE2
    }
    else if(getStoredNumberOfRows() === 3){
      translationKey = TranslationKey.IMPOSSIBLE_SPOTLE3
    }
    else if(getStoredNumberOfRows() === 4){
      translationKey = TranslationKey.IMPOSSIBLE_SPOTLE4
    }
    else if(getStoredNumberOfRows() === 5){
      translationKey = TranslationKey.IMPOSSIBLE_SPOTLE5
    }
  }
  else if(path.includes('/cospotle/bot/parallel/challenge')){
    translationKey = TranslationKey.BOT_PARALLEL_COSPOTLE_CHALLENGE
  }
  else if(path.includes('/cospotle/bot/parallel')){
    translationKey = TranslationKey.BOT_PARALLEL_COSPOTLE
  }
  else if(path.includes('/cospotle/bot/challenge')){
    translationKey = TranslationKey.COSPOTLE_VS_BOT_CHALLENGE
  }
  else if(path.includes('/cospotle/bot')){
    translationKey = TranslationKey.COSPOTLE_VS_BOT
  }
  else if(path.includes('/cospotle/team')){
    translationKey = TranslationKey.TEAM_COSPOTLE
  }
  else if(path.includes('/cospotle/parallel')){
    translationKey = TranslationKey.PARALLEL_COSPOTLE
  }
  else if(path.includes('/cospotle')){
    translationKey = TranslationKey.COSPOTLE
  }
  else if(path.includes('/custom')){
    translationKey = TranslationKey.CUSTOM
  }

  return getTranslation(translationKey)
}

export function getTayTaySongName(index: number): string {
  return 'From: ' + WORDS_SONGS_TAYTAY[index % WORDS_SONGS_TAYTAY.length]
}

const getSpecialDateMessage = (
  today: Date
  ): string => {

  if(getIsScalizzi()){
    const scaliMessages = ['lol whatever', 'I DID NOT!!!', 'oh well', 'OH MY GOD!!!', 'oh shut up']
    return scaliMessages[Math.floor(Math.random() * scaliMessages.length)]
  }

  //kenny
  if(today.getMonth() === 10 && today.getDate() === 17){
    return "#happyBDayKennyHaller"
  }
  //spotle
  if(today.getMonth() === 10 && today.getDate() === 16){
    return "#happySpotleDay"
  }
  //tay tay
  if(today.getMonth() === 11 && today.getDate() === 13){
    return "#happySwiftDay"
  }
  //christmas
  if(today.getMonth() === 11 && today.getDate() === 25){
    return "Merry Christmas :)"
  }
  //valentine
  if(today.getMonth() === 1 && today.getDate() === 14){
    return "You matter :)"
  }
  //sav
  if(today.getMonth() === 2 && today.getDate() === 19){
    return "#happyBDayDailySav"
  }

  //april fools
  if(today.getMonth() === 3 && today.getDate() === 1){
    return "#happyAprilFools"
  }

  //new year
  if(today.getMonth() === 0 && today.getDate() === 1){
    return "#happyNewYear"
  }

  return ''
}

export function getWinMessage(today: Date): string {
  const specialMessage = getSpecialDateMessage(today)

  if(specialMessage.length > 0){
    return specialMessage
  }

  return MESSAGES[getLanguageKey()].WIN_MESSAGES[Math.floor(Math.random() * MESSAGES[getLanguageKey()].WIN_MESSAGES.length)]
}

export const getLossMessage = (today: Date, solution: string): string => {
  const specialMessage = getSpecialDateMessage(today)
  const lossMessage = getTranslationWithInfo(TranslationKey.CORRECT_WORD_MESSAGE, solution)

  if(specialMessage.length > 0){
    return specialMessage + ' - ' + lossMessage
  }

  return lossMessage
}

export function getTieMessage(today: Date): string {
  const specialMessage = getSpecialDateMessage(today)

  if(specialMessage.length > 0){
    return specialMessage
  }

  return MESSAGES[getLanguageKey()].TIE_MESSAGES[Math.floor(Math.random() * MESSAGES[getLanguageKey()].TIE_MESSAGES.length)]
}

export function getWrongSpotMessage(guess: string, position: number): string{
  return MESSAGES[getLanguageKey()].WRONG_SPOT_MESSAGE(guess, position)
}

export function getTranslationWithIndex(key: TranslationKey, index: number): string{
  return (MESSAGES[getLanguageKey()][key]).charAt(index)
}

export function getTranslationWithInfo(key: TranslationKey, info: string | number): string{
  return MESSAGES[getLanguageKey()][key](info)
}

export function getTranslation(key: TranslationKey): string{
  return MESSAGES[getLanguageKey()][key]
}