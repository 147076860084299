import { MAX_CHALLENGES } from '../../constants/settings'
import { isCoSpotle } from '../../App'
import { GameStats, getStoredBotMode, getStoredParallelMode, getStoredPlainCoSpotle } from '../../lib/localStorage'
import { Progress } from './Progress'
import { getIndex, getSolution } from '../../lib/words'

type Props = {
  gameStats: GameStats
  isGameWon: boolean
  isGameLost: boolean
  numberOfGuessesMade: number
  guesses: string[]
  today: Date
}

const isCurrentDayStatRow = (
  isGameWon: boolean,
  numberOfGuessesMade: number,
  index: number,
  today: Date,
  guesses: String[], 
  isTimeout: boolean,
  draw: boolean
) => {
  return !isTimeout 
    && !draw
    && isGameWon 
    && numberOfGuessesMade === index + 1 
    && guesses.length !== 0 
    && guesses[index] === getSolution(getIndex(today)
  )
}

export const Histogram = ({
  gameStats,
  isGameWon,
  isGameLost,
  numberOfGuessesMade,
  guesses,
  today,
}: Props) => {
  var winDistribution = gameStats.winDistribution
  const sixPlusIndex = numberOfGuessesMade > MAX_CHALLENGES ? numberOfGuessesMade - 1 : winDistribution.length + 1
  var maxValue = Math.max(...winDistribution, gameStats.gamesFailed, 1)
  if(isCoSpotle()){
    if(getStoredPlainCoSpotle()){
      winDistribution = [...winDistribution, gameStats.sixPlusWins]
    }
    if(!getStoredBotMode() && getStoredPlainCoSpotle()){
      winDistribution = [...winDistribution, gameStats.timeoutWins]
    }
    if(getStoredParallelMode()){
      winDistribution = [...winDistribution, gameStats.ties]
    }
  }

  maxValue = Math.max(...winDistribution, gameStats.gamesFailed, 1)

  winDistribution = gameStats.winDistribution

  const isDraw = isGameLost && isGameWon
  const isTimeout = !isDraw && isGameWon && (guesses.length === 0 || guesses[guesses.length - 1] !== getSolution(getIndex(today)))
  return (
    <div className="justify-left m-2 columns-1 text-sm dark:text-white">
      {winDistribution.map((value, i) => (
        <Progress
          key={i}
          index={String(i + 1)}
          isCurrentDayStatRow={isCurrentDayStatRow(
            isGameWon,
            numberOfGuessesMade,
            i,
            today, 
            guesses,
            isTimeout,
            isDraw
          )}
          size={90 * (value / maxValue)}
          label={String(value)}
        />
      ))}
      {(isCoSpotle()) && (
        <div>
          {getStoredPlainCoSpotle() && (
            <Progress
              key={sixPlusIndex}
              index={'6+'}
              isCurrentDayStatRow={isCurrentDayStatRow(
                isGameWon,
                numberOfGuessesMade,
                sixPlusIndex,
                today,
                guesses,
                isTimeout,
                isDraw
              )}
              size={90 * (gameStats.sixPlusWins / maxValue)}
              label={String(gameStats.sixPlusWins)}
            />
          )}
          {(!getStoredBotMode() && getStoredPlainCoSpotle()) && (
            <Progress
              key={999}
              index={'T'}
              isCurrentDayStatRow={isTimeout}
              size={90 * (gameStats.timeoutWins / maxValue)}
              label={String(gameStats.timeoutWins)}
            />
          )}
          {getStoredParallelMode() && (
            <Progress
              key={9999}
              index={'D'}
              isCurrentDayStatRow={isDraw}
              size={90 * (gameStats.ties / maxValue)}
              label={String(gameStats.ties)}
            />
          )}
        </div>
      )}
      <Progress
        key={1000}
        index={'X'}
        isCurrentDayStatRow={isGameLost && !isDraw}
        size={90 * (gameStats.gamesFailed / maxValue)}
        label={String(gameStats.gamesFailed)}
      />
    </div>
  )
}
