import { BaseModal } from './BaseModal'
import { GameStats, saveStatsToLocalStorage } from '../../lib/localStorage'
import { MAX_CHALLENGES } from '../../constants/settings'
import { BackspaceIcon } from '@heroicons/react/outline'
import { TranslationKey } from '../../constants/strings'
import { getTranslation } from '../../context/messages'

type Props = {
  isOpen: boolean
  handleClose: () => void
  setStats: (gameStats: GameStats) => void
  onEnter: () => void
}

export const ResetModal = ({
  isOpen,
  handleClose,
  setStats,
  onEnter,
}: Props) => {
  return (
    <BaseModal title={TranslationKey.RESET} isOpen={isOpen} handleClose={handleClose}>
      <p className="mt-1 text-xs text-gray-500 dark:text-gray-300">
        {getTranslation(TranslationKey.RESET_CONFIRM)}
      </p>
      <div>
          <hr className="mt-4 -mb-4 border-gray-500" />
          <div className="mt-5 items-center items-stretch justify-center text-center dark:text-white sm:mt-6">
            <button
              type="button"
              className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-base"
              onClick={() => {
                const defaultStats: GameStats = {
                  winDistribution: Array.from(new Array(MAX_CHALLENGES), () => 0),
                  gamesFailed: 0,
                  currentStreak: 0,
                  bestStreak: 0,
                  totalGames: 0,
                  successRate: 0,
                  timeoutWins: 0,
                  sixPlusWins: 0,
                  ties: 0
                }
                saveStatsToLocalStorage(defaultStats)
                setStats(defaultStats)
                onEnter()
                handleClose()
              }}>
              <BackspaceIcon className="mr-2 h-6 w-6 cursor-pointer dark:stroke-white" />
              {getTranslation(TranslationKey.RESET)}
            </button>
          </div>
        </div>
    </BaseModal>
  );
}
