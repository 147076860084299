import { getTranslation } from '../../../context/messages'
import { PlayIcon } from '@heroicons/react/outline'
import { TranslationKey } from '../../../constants/strings'
import { getLanguagePageUrl } from '../../../route/GameRoute'

type Props = {
  isUnlimitedMode: boolean
}

export const SwitchGame = ({isUnlimitedMode}: Props) => {
  if(!isUnlimitedMode){
    return (
      <div className="flex justify-between gap-4 py-3">
        <div className="mt-2 text-left text-gray-500 dark:text-gray-300">
          <p className="leading-none">{getTranslation(TranslationKey.UNLIMITED_MODE)}</p>
          <p className="mt-1 text-xs text-gray-500 dark:text-gray-300">
            {getTranslation(TranslationKey.UNLIMITED_MODE_DESCRIPTION)}
          </p>
        </div>
        <div>
          <button
            type="button"
            className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-base"
            onClick={event =>  window.location.href=getLanguagePageUrl('/unlimited')}
          >
          <PlayIcon className="mr-2 h-6 w-6 cursor-pointer dark:stroke-white" />
            {getTranslation(TranslationKey.PLAY)}
          </button>
        </div>
      </div>
    )
  }

  return (
    <div className="flex justify-between gap-4 py-3">
      <div className="mt-2 text-left text-gray-500 dark:text-gray-300">
        <p className="leading-none">{getTranslation(TranslationKey.DAILY_MODE)}</p>
        <p className="mt-1 text-xs text-gray-500 dark:text-gray-300">
          {getTranslation(TranslationKey.DAILY_MODE_DESCRIPTION)}
        </p>
      </div>
      <div>
        <button
          type="button"
          className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-base"
          onClick={event =>  window.location.href=getLanguagePageUrl('/')}
        >
        <PlayIcon className="mr-6 h-6 w-6 cursor-pointer dark:stroke-white" />
          {getTranslation(TranslationKey.PLAY)}
        </button>
      </div>
    </div>
  )
}
