import { GameStats, getBot, getCounter, getGameStreak, getLanguage, getStoredNumberOfGames, getStoredBotChallengeMode, getStoredBotMode, getStoredCoSpotleCustomCode, getStoredCoSpotleStatuses, getStoredCopiedMode, getStoredGameDate, getStoredGridHardMode, getStoredHardMode, getStoredHiddenLetterMode, getStoredMyTurn, getStoredOpponentLevel, getStoredParallelMode, getStoredStatuses, getStoredTeamMode, getStoredUsername, setBot, setCounter, setGameStreak, setStoredNumberOfGames, handleStoredCoSpotleCustomCode, setStoredCoSpotleStatuses, setStoredGameDate, setStoredGuessDate, setStoredMyTurn, setStoredOpponent, setStoredOpponentCountry, setStoredOpponentLevel, getWinsNeeded, setWinsNeeded, getStoredPlainCoSpotle } from "./localStorage"
import { isCoSpotle } from "../App"
import { getTranslation, getTranslationWithInfo } from "../context/messages"
import { getIndex, getNewGameDate, getSolution, getValidGuesses, getWords } from "./words"
import { bot } from "../bot/SpotleBot"
import { getCountryCode } from "./timezones"
import { Result, addTimeoutStatsForCompletedGame } from "./stats"
import { Language } from "../constants/language"
import { TranslationKey } from "../constants/strings"
import { MAX_CHALLENGES, URL } from "../constants/settings"
import { CharStatus, getGuessStatuses } from "./statuses"
import { BOTS_INFO, BOT_NAME } from "../constants/bots"

export interface Task {
    response: string
    opponent: string | undefined
    first: boolean | undefined
    guesses: string[] | undefined
    player1: string | undefined
    guessesPlayer1: string[] | undefined
    guessesPlayer2: string[] | undefined
    gameDate: string | undefined
    timestamp: number | undefined
    country: string
    customCode: string
  }

/*window.addEventListener('beforeunload', function (e) {
  if(getStoredOpponent() !== ''){
    addStatsForCompletedGame(loadStats(), MAX_CHALLENGES)
  }
  endGame(getStoredUsername())
});
*/
export const registerNewGame = async (
  username: string,
  setMyTurn: Function, 
  setOpponent: Function,
  onResetGame: Function,
  setIsCoSpotleGameModalOpen: Function,
  customUsername: string,
  showSuccessAlert: Function,
  softReset: boolean | undefined,
  attempt: number
  ) => {  

  if(getStoredBotMode()){
    setStoredGameDate(getNewGameDate(true))
    if(!softReset){
      const bot = getBotName()
      setBot(bot)
      setStoredOpponentLevel(BOTS_INFO[bot].LEVEL)
      setOpponent(BOTS_INFO[bot].BOT_NAME)
      setStoredOpponent(BOTS_INFO[bot].BOT_NAME)
      setStoredOpponentCountry(BOTS_INFO[bot].COUNTRY)
    }
    setIsCoSpotleGameModalOpen(false)
    setStoredMyTurn(Math.round(Math.random()) === 1)
    setMyTurn(getStoredMyTurn())
    setStoredGuessDate(new Date(Date.now() + 20000))

    return onResetGame(true)
  }

  var request = URL + '/newgame?name=' + username 
  + '&customname=' + customUsername
  + '&country=' + getCountryCode()

  if(getLanguage() !== Language.ENGLISH){
    request = request + '&language=' + getLanguage()
  }
  if(getStoredTeamMode()){
    request = request + '&gamemode=team'
  }
  if(getStoredHardMode()){
    request = request + '&hardmode=1'
  }
  if(getStoredGridHardMode()){
    request = request + '&gridhardmode=1'
  }
  if(getStoredHiddenLetterMode()){
    request = request + '&hiddenlettermode=1'
  }
  //or we have tried 15 times, or we just copied the URL hence we should just wait
  if(getStoredCoSpotleCustomCode() !== null && (attempt < 15 || getStoredCopiedMode())){
    request = request + '&customgame=' + getStoredCoSpotleCustomCode()
  }

  return await fetch(request)
    .then(function(response) {
      return response.json();
  })
    .then(function(myJson) {
  
    const data:Task = JSON.parse(JSON.stringify(myJson))[0]
    
    if(data.response === 'go'){
      const dateArray = data.gameDate!.split('/')
      const newDate = new Date(+dateArray[0], +dateArray[1] - 1, +dateArray[2])

      setStoredGameDate(newDate)
      setOpponent(data.opponent!)
      setStoredOpponent(data.opponent!)
      setStoredOpponentCountry(data.country!)
      setIsCoSpotleGameModalOpen(false)
      handleStoredCoSpotleCustomCode(data.customCode!)
      if(data.first === true || data.first === false){
        setMyTurn(data.first)
        setStoredMyTurn(data.first)
        setStoredGuessDate(new Date(Date.now() + 20000))
      }else{
        setStoredGuessDate(new Date(Date.now() + 25000))
      }

      return onResetGame(true)
    }

    if(data.response === 'wait'){
      (async () => {
        if(attempt % 16 >= 0 && attempt % 16 < 4){
          showSuccessAlert(getTranslation(TranslationKey.OPPONENT_0))
        } else if(attempt % 16 >= 4 && attempt % 16 < 8){
          showSuccessAlert(getTranslation(TranslationKey.OPPONENT_1))
        } else if(attempt % 16 >= 8 && attempt % 16 < 12){
          showSuccessAlert(getTranslation(TranslationKey.OPPONENT_2))
        } else {
          showSuccessAlert(getTranslation(TranslationKey.OPPONENT_3))
        }
        
        if(getStoredParallelMode()){
          await new Promise((f) => setTimeout(f, 300))
        }else{
          await new Promise((f) => setTimeout(f, 2000))
        }

        console.log(attempt)

        return registerNewGame(
          username, 
          setMyTurn, 
          setOpponent, 
          onResetGame, 
          setIsCoSpotleGameModalOpen,
          customUsername,
          showSuccessAlert,
          softReset,
          attempt + 1)
      })()
    }
  })
    .catch((error) => {
      console.log(error)
  });
}

const getBotName = (): BOT_NAME => {
  const bots = [BOT_NAME.CALEB, BOT_NAME.CAM, BOT_NAME.DINIS, BOT_NAME.DUARTE, BOT_NAME.FAY,
    BOT_NAME.FAY_FAY, BOT_NAME.KENNY, BOT_NAME.LILY, BOT_NAME.LUCAS, BOT_NAME.MICHAEL, 
    BOT_NAME.PATRICK, BOT_NAME.SAVANNAH, BOT_NAME.SCALI, BOT_NAME.SPOTLE, BOT_NAME.SPYFALL, 
    BOT_NAME.SVEN, BOT_NAME.TASH, BOT_NAME.TOBY, BOT_NAME.BRYN]

    var newBots = bots
    if(getStoredBotChallengeMode()){
      newBots = []

      for(const bot of bots){
        if(getStoredOpponentLevel() === BOTS_INFO[bot].LEVEL){
          newBots.push(bot)
        }
      }
    }

  return newBots[Math.floor(Math.random() * newBots.length)]
}

export const addGuess = async (username: string, guess: string, guessIndex: number) => {  
  const timestamp = Date.now()
  setStoredGuessDate(getTime(guessIndex, timestamp, false))

  if(getStoredBotMode()){
    return
  }
  
  return await fetch(URL + '/addguess?name=' + username + '&guess=' + guess + '&timestamp=' + timestamp)
    .then(function(response) {
      return response.json();
  })
    .catch((error) => {
      console.log(error)
  });
}

export const getGuess = async (
  username: string, 
  setCoSpotleGuesses: Function,
  setCoSpotleStatuses: Function,
  playerGuesses: string[],
  coSpotleGuesses: string[],
  setIsGameWon: Function,
  stats: GameStats,
  setStats: Function
  ) => {  
  if(getStoredBotMode()){
    var newGuesses: string[] = []
    var needsWord = true
    var statuses: Map<number, CharStatus[]>
    var guesses: string[] = []
    if(getStoredParallelMode()){
      guesses = coSpotleGuesses
      statuses = getStoredCoSpotleStatuses()
    } else {
      guesses = playerGuesses
      statuses = getStoredStatuses()
    }

    if(getStoredBotChallengeMode()){
      needsWord = false

      if(guesses.length === 0 && getBot().STARTER !== undefined && !getStoredParallelMode()){
        newGuesses = [getBot().STARTER]
      } else if(getBot().LEVEL === 1){
        newGuesses = [...guesses, getRandomWord(['PZAZZ', 'XYLYL', 'QAJAQ', 'ZIZIT', 'BOBBY', 'MOMMY', 'MUMMY',
        'MAMMY', 'DADDY', 'FUFFY', 'MAMMA', 'PIZZA', 'SASSY', 'DIZZY',
        'FIZZY', 'FLUFF', 'SAVVY', 'POPPY', 'TATTY', 'GRRRL'])]
      } else if(getBot().LEVEL === 2){
        newGuesses = [...guesses, getRandomWord(getValidGuesses()).toLocaleUpperCase()]
      } else if(getBot().LEVEL === 3){
        newGuesses = [...guesses, getRandomWord(getWords()).toLocaleUpperCase()]
      } else {
        needsWord = true
      }
    }

    if(needsWord){
      newGuesses = bot(
        getStoredHardMode(),
        getStoredGridHardMode(),
        getStoredHiddenLetterMode(),
        getStoredGameDate(),
        getSolution(getIndex(getStoredGameDate())),
        [...guesses, 'SCALI'],
        statuses
      )[0]
    }    
    
    //we just want the first guess from the bot, not all of them
    //setCoSpotleGuesses([...guesses, newGuesses[guesses.length]])
    populateCoSpotleGuesses(
      Date.now(), 
      setCoSpotleStatuses, 
      setCoSpotleGuesses, 
      [...guesses, newGuesses[guesses.length]]
    )
    
    return
  }

  return await fetch(URL + '/getguess?name=' + username)
    .then(function(response) {
      return response.json();
  })
    .then(function(myJson) {
  
    const data:Task = JSON.parse(JSON.stringify(myJson))[0]
    
    if(data.response === 'ok'){
      if (!getStoredParallelMode() && playerGuesses.length !== data.guesses!.length){
        return populateCoSpotleGuesses(
          data.timestamp!, 
          setCoSpotleStatuses, 
          setCoSpotleGuesses, 
          data.guesses!
        )
      } else if (getStoredParallelMode()){
        var newCoSpotleGuesses = []
        if(getStoredUsername() === data.player1!){
          newCoSpotleGuesses = data.guessesPlayer2!
        }else{
          newCoSpotleGuesses = data.guessesPlayer1!
        }

        if(coSpotleGuesses.length !== newCoSpotleGuesses.length){
          return populateCoSpotleGuesses(
            data.timestamp!, 
            setCoSpotleStatuses, 
            setCoSpotleGuesses, 
            newCoSpotleGuesses
          )
        }



        return
      }
    } else if (data.response === '404'){
      //the opponent closed the page
      setStats(addTimeoutStatsForCompletedGame(stats))
      endGame(username)

      //softReset
      setStoredOpponent('')

      return setIsGameWon(true)
    }
  })
    .catch((error) => {
      console.log(error)
  });
}

function getRandomWord(words: string[]): string{
  return words[Math.floor(Math.random() * words.length)]
}
function populateCoSpotleGuesses(
  timestamp: number, 
  setCoSpotleStatuses: Function, 
  setCoSpotleGuesses: Function,
  newGuesses: string[]
){
  const solution = getSolution(getIndex(getStoredGameDate()))
  setStoredGuessDate(getTime(newGuesses.length - 1, timestamp, true))

  const coSpotleStatuses = getStoredCoSpotleStatuses()
  if(solution === newGuesses[newGuesses.length - 1]){
    const correctArray: CharStatus[] = []
      for (var i = 0; i < solution.length; i++) {
        correctArray.push('correct')
      }
      coSpotleStatuses.set(newGuesses.length - 1, correctArray)
  } else {
    coSpotleStatuses.set(
      newGuesses.length - 1,
      getGuessStatuses(
        newGuesses.length - 1,
        solution,
        newGuesses,
        coSpotleStatuses,
        getStoredGameDate(),
        getStoredHiddenLetterMode()
      ).get(newGuesses.length - 1)!
    )
  }

  setCoSpotleStatuses(coSpotleStatuses)
  setStoredCoSpotleStatuses(coSpotleStatuses)
  return setCoSpotleGuesses(newGuesses)
} 

export const endGame = async (username: string) => {  
  if(isCoSpotle()){
    if(getStoredBotMode()){
      return
    }

    return await fetch(URL + '/endgame?name=' + username)
      .then(function(response) {
        return response.json();
    })
      .catch((error) => {
        console.log(error)
    });
  }
}

export function getFlagEmoji(countryCode: string) {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map(char =>  127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
}

function getTime(guessIndex: number, timestamp: number, myTurn: boolean): Date {
  var defaultTime = 30000
  if(!myTurn){
    defaultTime = 35000
  }

  if(guessIndex > MAX_CHALLENGES){
    return new Date(timestamp + defaultTime)
  }

  return new Date(timestamp + defaultTime + guessIndex * 10000)
}

export function isChallengeModeGameDone(): boolean {
  return getStoredBotChallengeMode() && getCounter() >= getStoredNumberOfGames()
}

export function getCoSpotleGameCounter(): string{
  var count = getCounter()
  if(!isChallengeModeGameDone()){
    count++
  }
  
  return count + '/' + getStoredNumberOfGames().toString()
}
  
export function resetChallengeModeGame(showSuccessAlert: Function, showErrorAlert: Function, newGame?: boolean): void {
  if(getCounter() > 0){
    //to make sure we don't show the wrong message for a new game
    if(wonStreak()){
      showSuccessAlert(getTranslationWithInfo(TranslationKey.BOT_CHALLENGE_WIN, getStoredOpponentLevel()))
      setStoredOpponentLevel(getStoredOpponentLevel() + 1)
    } else {
      showErrorAlert(getTranslation(TranslationKey.BOT_CHALLENGE_LOSS),
        {
          durationMs: 3000,
        })
      setStoredOpponentLevel(getStoredOpponentLevel() - 1)
    }
  }

  setCounter(0)
  setGameStreak([])
  setStoredNumberOfGames(getNumberOfGamesPerLevel())
  setWinsNeeded(winsNeededForNextLevel())

  if(newGame){
    setStoredOpponentLevel(1)
  }
}

function getNumberOfGamesPerLevel(): number {
  if(getStoredOpponentLevel() <= 3){
    return 3
  } else if(getStoredOpponentLevel() <= 5) {
    return 5
  } else if (getStoredOpponentLevel() <= 7) {
    return 7
  } else if (getStoredOpponentLevel() <= 9) {
    return 11
  }

  return 15
}

export function streakGamesWon(): number{
  var wins = 0;

  for(const result of getGameStreak()){
    if(result !== -1){
      wins++
    }
  }

  return wins
}

function wonStreak(): boolean{
  return streakGamesWon() / getStoredNumberOfGames() > 0.5
}

export function winsNeededForNextLevel(): number {
  var maxWins = getStoredNumberOfGames() / 2 + 0.5
  if(getStoredNumberOfGames() % 2 === 0){
    maxWins = getStoredNumberOfGames() / 2 + 1
  }

  maxWins = maxWins - streakGamesWon()

  return maxWins < 0 ? 0 : maxWins
}

export function winImpossible(): boolean {
  //counter starts at 0, UI is adding 1 game
  const remainingGames = getStoredNumberOfGames() - getCounter()

  return getWinsNeeded() > remainingGames
}

export function setCoSpotleWonLostTieStatus(playerGuesses: string[], coSpotleGuesses: string[], 
  setIsGameLost: Function, setIsGameWon: Function, solution: string, tries: number) :Result {
  if(getStoredParallelMode()){
    if(getStoredBotMode() && playerGuesses.length !== coSpotleGuesses.length && tries < 5){
        setTimeout(() => {
        }, 1000)

      return setCoSpotleWonLostTieStatus(playerGuesses, coSpotleGuesses, setIsGameLost, setIsGameWon, solution, tries + 1)
    }
    if(playerGuesses.includes(solution)){
      if(coSpotleGuesses.includes(solution)){
        setIsGameLost(true)
        setIsGameWon(true)

        return Result.TIE
      }
      setIsGameWon(true)

      return Result.WIN
    } else if(coSpotleGuesses.includes(solution)){
      setIsGameLost(true)
      
      return Result.LOSS
    } else if(playerGuesses.length === MAX_CHALLENGES && coSpotleGuesses.length === MAX_CHALLENGES){
      //both lost
      setIsGameLost(true)
      setIsGameWon(true)

      return Result.TIE
    }
  } else if(getStoredPlainCoSpotle()){
    if(coSpotleGuesses.includes(solution)){
      setIsGameLost(true)

      return Result.LOSS
    } else if(playerGuesses.includes(solution)){
      setIsGameWon(true)

      return Result.WIN
    }

    return Result.EMPTY
  } else if(getStoredTeamMode()){
    if(coSpotleGuesses.includes(solution) || playerGuesses.includes(solution)){
      setIsGameWon(true)

      return Result.WIN
    } else if(playerGuesses.length === MAX_CHALLENGES){
      setIsGameLost(true)

      return Result.LOSS
    }
    
    return Result.EMPTY
  }

  return Result.EMPTY
}
