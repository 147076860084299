import { createKey, getStoredGridHardMode, getStoredHardMode, getStoredHiddenLetterMode, setStoredAllGlobalGuesses, setStoredGlobalGuesses } from "../lib/localStorage";
import { URL } from "../constants/settings";

export const recordGuess = async (guess: string, guessIndex: number, gameDate: Date) => { 
    var localUrl = URL + '/addglobalguess?guess=' + guess + '&year=' + gameDate.getFullYear() 
    + '&month=' + gameDate.getMonth() + '&day=' + gameDate.getDate() + '&guessindex=' + guessIndex

    const key = createGlobalKey()
    if(key !== '') {
        localUrl = localUrl + "&key=" + key
    }

    return await fetch(localUrl)
        .then(function(response) {
            return response.json();
        })
        .catch((error) => {
            console.log(error)
        });
}

export const getGlobalGuesses = async (
    gameDate: Date,
    guessIndex: number
) => {  
    var localUrl = URL + '/getglobalguesses?year=' + gameDate.getFullYear() 
    + '&month=' + gameDate.getMonth() + '&day=' + gameDate.getDate() + '&guessindex=' + guessIndex
    
    const key = createGlobalKey()
    if(key !== '') {
        localUrl = localUrl + "&key=" + key
    }

    return await fetch(localUrl)
        .then(function(response) {
            return response.json();
        })
        .then(function(myJson) {
            setStoredGlobalGuesses(myJson, guessIndex)

        })
        .catch((error) => {
            console.log(error)
        });
}

export const getAllGlobalGuesses = async (
    gameDate: Date,
    guessIndex: number
) => {  
    var localUrl = URL + '/getallglobalguesses?year=' + gameDate.getFullYear() 
    + '&month=' + gameDate.getMonth() + '&day=' + gameDate.getDate() + '&guessindex=' + guessIndex
    
    const key = createPlainGlobalKey()
    if(key !== '') {
        localUrl = localUrl + "&key=" + key
    }

    return await fetch(localUrl)
        .then(function(response) {
            return response.json();
        })
        .then(function(myJson) {
            setStoredAllGlobalGuesses(myJson, guessIndex)

        })
        .catch((error) => {
            console.log(error)
        });
}

function createGlobalKey(): string {
    var key = createKey('')
    key = key.replace("unlimited", "")
    key = key.replace("bot", "")

    if(getStoredHardMode(true)){
        key = key + 'hardMode'
    }
    if(getStoredGridHardMode(true)){
        key = key + 'gridHardMode'
    }
    if(getStoredHiddenLetterMode(true)){
        key = key + 'hiddenLetterMode'
    }

    return key
}

function createPlainGlobalKey(): string {
    var key = createKey('')
    key = key.replace("unlimited", "")
    key = key.replace("bot", "")

    return key
}