import { MAX_CHALLENGES } from '../../constants/settings'
import { CompletedRow } from './CompletedRow'
import { CurrentRow } from './CurrentRow'
import { EmptyRow } from './EmptyRow'
import { CharStatus } from '@/lib/statuses'
import { Cell } from './Cell'
import { getIsShowSpotleBot, isCoSpotle } from '../../App'
import { getStoredPlainCoSpotle } from '../../lib/localStorage'

type Props = {
  guesses: string[]
  currentGuess: string
  isRevealing?: boolean
  currentRowClassName: string
  statuses: Map<number, CharStatus[]>
  maskedStatuses?: Map<number, CharStatus[]>
  botStats?: string[][]
  titles?: string[]
  solution: string
  onOpenSolutionsPage?: Function
  botGame?: boolean
}

export const Grid = ({
  guesses,
  currentGuess,
  isRevealing,
  currentRowClassName,
  statuses,
  maskedStatuses,
  botStats,
  titles,
  solution,
  onOpenSolutionsPage,
  botGame
}: Props) => {
  var empties =
    guesses.length < MAX_CHALLENGES - 1 && !getIsShowSpotleBot()
      ? Array.from(Array(MAX_CHALLENGES - 1 - guesses.length))
      : []

    return (
    <div id="#toc-container">
      {typeof titles !== 'undefined' &&
        (<div className="flex justify-center text-center">
          {titles.map((title, i) => (
            <Cell
              key={i + solution.length}//todo
              position={i + solution.length}//todo
              value={title}
            />
        ))}
        </div>)}
      
      {
      guesses.map((guess, i) => (
        <CompletedRow
          key={i}
          index={i}
          guess={guess}
          isRevealing={isRevealing && guesses.length - 1 === i}
          statuses={statuses!}
          maskedStatuses={maskedStatuses}
          botStats={botStats}
          length={guesses.length}
          onOpenSolutionsPage={onOpenSolutionsPage}
          botGame={botGame}
        />
      ))}
      {(!getIsShowSpotleBot() && (guesses.length < MAX_CHALLENGES || (isCoSpotle() && getStoredPlainCoSpotle()))) && (
        <CurrentRow 
          key={guesses.length}
          guess={currentGuess} 
          className={currentRowClassName} 
          index={guesses.length}
          statuses={statuses!} 
          botStats={botStats}
          solution={solution}
        />
      )}
      {empties.map((_, i) => (
        <EmptyRow 
          key={i + guesses.length + 1} 
          index={i + guesses.length + 1}
          statuses={statuses!} 
          botStats={botStats}
          solution={solution}
        />
      ))}
    </div>
  )
}
