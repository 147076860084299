import { BackspaceIcon, DesktopComputerIcon, PlayIcon, ShareIcon } from '@heroicons/react/outline'
import Countdown from 'react-countdown'
import tiktokIcon from './tiktok.svg'
import twitchIcon from './twitch.svg'
import youtubeIcon from './youtube.svg'
import instagramIcon from './instagram.svg'
import discordIcon from './discord.svg'

import {
  ENABLE_MIGRATE_STATS,
} from '../../constants/settings'
import { GameStats, getLanguage, getStoredBotChallengeMode } from '../../lib/localStorage'
import { shareStatus } from '../../lib/share'
import { Histogram } from '../stats/Histogram'
import { MigrationIntro } from '../stats/MigrationIntro'
import { StatBar } from '../stats/StatBar'
import { BaseModal } from './BaseModal'
import { CharStatus } from '../../lib/statuses'
import { getIsSpeedy, getIsUnlimitedMode, isCoSpotle, isCustom } from '../../App'
import { getRandomPath, getRandomPathTranslation, getTranslation } from '../../context/messages'
import { Language } from '../../constants/language'
import { calculateCountdownTitle, isSpeedyGameDone, resetSpeedyGame } from '../../lib/speedy'
import { SpeedyHistogram } from '../stats/SpeedyHistogram'
import { TranslationKey } from '../../constants/strings'
import { getLanguagePageUrl } from '../../route/GameRoute'
import { getToday } from '../../lib/dateutils'
import { addDays } from 'date-fns'


type Props = {
  isOpen: boolean
  handleClose: () => void
  guesses: string[]
  gameStats: GameStats
  isGameLost: boolean
  isGameWon: boolean
  handleShareToClipboard: () => void
  handleShareFailure: () => void
  handleMigrateStatsButton: () => void
  isHardMode: boolean
  isGridHardMode: boolean
  isHiddenLetterMode: boolean
  isDarkMode: boolean
  isHighContrastMode: boolean
  numberOfGuessesMade: number
  statuses: Map<number, CharStatus[]>
  today: Date
  onResetGame: (softReset?: boolean) => void
  setShowSpotleBot: (showSpotleBot: boolean) => void
  getIsShowSpotleBot: () => boolean
  setIsResetModalOpen: (modal: boolean) => void
  isButtonEnabled: boolean
  handleButtonDisable: () => void
}

export const StatsModal = ({
  isOpen,
  handleClose,
  guesses,
  gameStats,
  isGameLost,
  isGameWon,
  handleShareToClipboard,
  handleShareFailure,
  handleMigrateStatsButton,
  isHardMode,
  isGridHardMode,
  isHiddenLetterMode,
  isHighContrastMode,
  numberOfGuessesMade,
  statuses,
  today,
  onResetGame,
  setShowSpotleBot,
  getIsShowSpotleBot,
  setIsResetModalOpen,
  isButtonEnabled, 
  handleButtonDisable
}: Props) => {
  const showPlayAgainButton = getIsUnlimitedMode() && !getIsShowSpotleBot()

  return (
    <BaseModal
      title={TranslationKey.STATISTICS_TITLE}
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <StatBar gameStats={gameStats} />
      <hr className="mt-3 border-gray-500" />
      {isSpeedyGameDone() && (
        <div>
          <h4 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">
            {getTranslation(TranslationKey.TOP_FIVE)}
          </h4>
          <SpeedyHistogram
            gamePlayed={calculateCountdownTitle()}
          />
        </div>
      )}
      {!getIsSpeedy() && (
        <div>
          <h4 className="mt-2 text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">
            {getTranslation(TranslationKey.GUESS_DISTRIBUTION_TEXT)}
          </h4>
          <Histogram
            gameStats={gameStats}
            isGameWon={isGameWon}
            isGameLost={isGameLost}
            numberOfGuessesMade={numberOfGuessesMade}
            guesses={guesses}
            today={today}
          />
        </div>
      )}
      {(isGameLost || isGameWon) && (
        <div>
          <hr className="mt-3 border-gray-500" />
          <div className="mt-3 columns-2 items-center items-stretch justify-center text-center dark:text-white">
            {!getIsUnlimitedMode() && (
              <div className="inline-block w-full text-left">
                <div>
                  <h5>{getTranslation(TranslationKey.NEW_WORD_TEXT)}</h5>
                  <Countdown
                    className="text-lg font-medium text-gray-900 dark:text-gray-100"
                    date={addDays(getToday(), 1)}
                    daysInHours={true}
                  />
                </div>
              </div>
            )}
            <div>
              <button
                type="button"
                className="mt-1 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-base"
                onClick={() => {
                  shareStatus(
                    guesses,
                    isGameLost,
                    isHardMode,
                    isGridHardMode,
                    isHiddenLetterMode,
                    isHighContrastMode,
                    handleShareToClipboard,
                    handleShareFailure,
                    statuses
                  )
                }}
              >
                <ShareIcon className="mr-2 h-6 w-6 cursor-pointer dark:stroke-white" />
                {getTranslation(TranslationKey.SHARE_TEXT)}
              </button>
            </div>
          </div>
          <div className="items-center items-stretch justify-center text-center dark:text-white">
          {(!getIsShowSpotleBot() && !getIsSpeedy()) && (
            <div>
              <hr className="mt-3 border-gray-500" />
              <button
                type="button"
                className="mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-yellow-500 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:ring-offset-2 sm:text-base"
                onClick={() => {
                  window.location.href=getLanguagePageUrl(getRandomPath())
                }}
              >
                <PlayIcon className="mr-2 h-6 w-6 cursor-pointer dark:stroke-white" />
                  {getTranslation(TranslationKey.GAMEMODE_OF_THE_DAY) + getRandomPathTranslation()}
              </button>
              {!isCustom() && (
                <div>
                  <hr className="mt-3 border-gray-500" />
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-orange-500 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-400 focus:ring-offset-2 sm:text-base"
                    onClick={() => {
                      setShowSpotleBot(true)
                      handleClose()
                    }}
                  >
                    <DesktopComputerIcon className="mr-2 h-6 w-6 cursor-pointer dark:stroke-white" />
                    {getTranslation(TranslationKey.CHECK_SPOTLE_BOT)}
                  </button>
                </div>
              )}
              
            </div>
          )}
          {getIsShowSpotleBot() && (
            <div>
              <hr className="mt-3 border-gray-500" />
              <button
                type="button"
                className="mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-base"
                onClick={() => {
                  setShowSpotleBot(false)
                  handleClose()
                }}
              >
                <PlayIcon className="mr-2 h-6 w-6 cursor-pointer dark:stroke-white" />
                {getTranslation(TranslationKey.RETURN)}
              </button>
            </div>
          )}
          </div>
        </div>
      )}
      {(ENABLE_MIGRATE_STATS && (!getIsSpeedy() || isSpeedyGameDone())) && (
        <div>
          <hr className="mt-3 border-gray-500" />
          <MigrationIntro handleMigrateStatsButton={handleMigrateStatsButton} />
        </div>
      )}    
      {(showPlayAgainButton && !isCoSpotle()) && (
        <div>
          {(!getIsSpeedy() || isSpeedyGameDone()) && (
            <div>
              <hr className="mt-3 border-gray-500" />
              <div className="mt-3 items-center items-stretch justify-center text-center dark:text-white">
                <button
                  type="button"
                  className="mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-base"
                  onClick={() => {
                    setIsResetModalOpen(true)
                  }}>
                  <BackspaceIcon className="mr-2 h-6 w-6 cursor-pointer dark:stroke-white" />
                  {getTranslation(TranslationKey.RESET)}
                </button>
              </div>
            </div>
          )}
          <hr className="mt-3 border-gray-500" />
          <div className="mt-3 items-center items-stretch justify-center text-center dark:text-white">
            <button
              type="button"
              className="mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-base"
              onClick={() => {
                if(getIsSpeedy()){
                  resetSpeedyGame()
                }
                onResetGame()
              }}
            >
              <PlayIcon className="mr-2 h-6 w-6 cursor-pointer dark:stroke-white" />
              {getIsSpeedy() && !isSpeedyGameDone() ? getTranslation(TranslationKey.RESTART) : getTranslation(TranslationKey.PLAY_AGAIN)}
            </button>
          </div>
        </div>
      )}
      {(showPlayAgainButton && isCoSpotle()) && (
        <div>
          <div>
            <hr className="mt-3 border-gray-500" />
            <div className="mt-3 items-center items-stretch justify-center text-center dark:text-white">
              <button
                type="button"
                className="mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-base"
                onClick={() => {
                  setIsResetModalOpen(true)
                }}>
                <BackspaceIcon className="mr-2 h-6 w-6 cursor-pointer dark:stroke-white" />
                {getTranslation(TranslationKey.RESET)}
              </button>
            </div>
          </div>
          <hr className="mt-3 border-gray-500" />
          <div className="mt-3 columns-2 items-center items-stretch justify-center text-center dark:text-white">
            <div>
              <button
                type="button"
                disabled={!isButtonEnabled}
                className="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-left text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 disabled:border-gray-200
                disabled:bg-white disabled:text-gray-900 disabled:focus:outline-none disabled:dark:border-gray-600 disabled:dark:bg-gray-800 disabled:dark:text-gray-400 sm:text-sm"
                onClick={() => {
                  handleButtonDisable()
                  onResetGame(false)
                }}
              >
                <PlayIcon className="mr-2 h-6 w-6 cursor-pointer dark:stroke-white" />
                {getStoredBotChallengeMode() && (
                  <>{getTranslation(TranslationKey.RESTART)}</> 
                )}
                {!getStoredBotChallengeMode() && (
                  <>{getTranslation(TranslationKey.NEW_GAME)}</> 
                )}
              </button>
            </div>
            <div>
              <button
                type="button"
                disabled={!isButtonEnabled || (getStoredBotChallengeMode() && !isGameLost && !isGameWon)}
                className="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-left text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 disabled:border-gray-200
                disabled:bg-white disabled:text-gray-900 disabled:focus:outline-none disabled:dark:border-gray-600 disabled:dark:bg-gray-800 disabled:dark:text-gray-400 sm:text-sm"
                onClick={() => {
                  handleButtonDisable()
                  onResetGame(true)
                }}
              >
                <PlayIcon className="mr-2 h-6 w-6 cursor-pointer dark:stroke-white" />
                {getStoredBotChallengeMode() && (
                  <>{getTranslation(TranslationKey.NEXT)}</> 
                )}
                {!getStoredBotChallengeMode() && (
                  <>{getTranslation(TranslationKey.REMATCH)}</> 
                )}
              </button>
            </div>
          </div>
        </div>
      )}
      <div>
        <hr className="mt-3 border-gray-500" />
        {getLanguage() === Language.GERMAN && (
          <div className="mt-2 columns-3">
            <div className="columns-2 items-center items-stretch justify-center text-center">
              <p>
                <a href="https://www.twitch.tv/gicongames" target="_blank" rel="noopener noreferrer">
                  <img src={twitchIcon} alt="gicongamesTwitch"  />
                </a>{' '}
              </p>
              <p>
                <a href="https://discord.com/invite/uZFhRsw22u" target="_blank" rel="noopener noreferrer">
                  <img src={discordIcon} alt="gicongamesDC" />
                </a>{' '}
              </p>
            </div>
          </div>
        )}
        {getLanguage() !== Language.GERMAN && (
          <div className="mt-2 columns-5 items-center items-stretch justify-center text-center">
            <p>
              <a href="https://tiktok.com/@kennyhaller" target="_blank" rel="noopener noreferrer">
                <img src={tiktokIcon} alt="kennyhallerTikTok" />
              </a>{' '}
            </p>
            <p>
              <a href="https://twitch.tv/kennyhaller" target="_blank" rel="noopener noreferrer">
                <img src={twitchIcon} alt="kennyhallerTwitch"  />
              </a>{' '}
            </p>
            <p>
              <a href="https://www.instagram.com/kennyhalleryt" target="_blank" rel="noopener noreferrer">
                <img src={instagramIcon} alt="kennyhallerInstagram" />
              </a>{' '}
            </p>
            <p>
              <a href="https://www.youtube.com/channel/UCBfUrCE9TFejLTfQ7OPirpg" target="_blank" rel="noopener noreferrer">
                <img src={youtubeIcon} alt="kennyhallerYT" />
              </a>{' '}
            </p>
            <p>
              <a href="https://discord.gg/uvFg7ggW4B" target="_blank" rel="noopener noreferrer">
                <img src={discordIcon} alt="kennyhallerDC" />
              </a>{' '}
            </p>
          </div>
        )}
      </div>
    </BaseModal>
  )
}
