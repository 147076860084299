import '../index.css'

const QuestionBlock = (props:{ question: string, text: string } ) => {
  return (
    <div>
      <div className="flex text-left text-gray-500 dark:text-gray-300">
        <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
          {props.question}
        </span>
      </div>

      <div className="text-gray-500 dark:text-gray-300">
        <span style={{ fontSize: '15px' }}>
            {props.text}
        </span>
      </div>
      <br />
      <br />
    </div>
  )
}

export default QuestionBlock