import '../index.css'

import { getStoredDarkMode, setTitle } from '../lib/localStorage'
import { SwitchGame } from '../components/modals/settings/SwitchGame'
import { TranslationKey } from '../constants/strings'

const Policy = (props:{ title: TranslationKey, policy: string } ) => {
  setTitle(props.title)

  if (getStoredDarkMode()) {
    document.documentElement.classList.add('dark')
  } else {
    document.documentElement.classList.remove('dark')
  }

  return (
    <div>
      <div className="flex items-center justify-center text-center">
        <div className="transform rounded-lg bg-white text-left dark:bg-gray-800 sm:p-4">
          <SwitchGame isUnlimitedMode={false} />
          <SwitchGame isUnlimitedMode={true} />
        </div>
      </div>
      <div dangerouslySetInnerHTML={{ __html: props.policy }} />

      {props.title === TranslationKey.PRIVACY_POLICY && 
        <span id="ezoic-privacy-policy-embed"> </span>
      }
    </div>
  )
}

export default Policy