import { BaseModal } from '../BaseModal'
import { getTranslation, getTranslationWithInfo} from '../../../context/messages'
import { GameModeSwitchModal } from './GameModeSwitchModal'
import { TranslationKey } from '../../../constants/strings'

export enum MODES {
  NONE = 'None',
  DAILY = 'Daily',
  UNLIMITED = 'Unlimited',
  MULTIPLAYER = 'Multiplayer',
}

export enum LETTERS {
  FIVE_LETTER = 'Spotle',
  SIX_LETTER = '6potle',
  NONE = 'None'
}

type Props = {
  isOpen: boolean
  handleClose: () => void
  pageNumber: number
  handleIncPageNumber: () => void
  handleDecPageNumber: () => void
  pageType: string
  handleToDailyPageType: () => void
  handleToUnlimitedPageType: () => void
  handleToMultiplayerPageType: () => void
  handleBlankPageType: () => void
  letterMode: string
  handleToFiveLetterMode: () => void
  handleToSixLetterMode: () => void
  handleBlankLetterMode: () => void
}

function addSixLetterUrl(path: string, sixLetter: boolean): string {
  if(sixLetter && !path.includes('/six')){
    return '/six' + path
  }

  return path
}

export const GamesModal = ({
  isOpen,
  handleClose,
  pageNumber,
  handleIncPageNumber,
  handleDecPageNumber,
  pageType,
  handleToDailyPageType,
  handleToUnlimitedPageType,
  handleToMultiplayerPageType,
  handleBlankPageType,
  letterMode,
  handleToFiveLetterMode,
  handleToSixLetterMode,
  handleBlankLetterMode
}: Props) => {
  var daily = pageType === MODES.DAILY
  var unlimited = pageType === MODES.UNLIMITED
  var multiplayer = pageType === MODES.MULTIPLAYER
  var fiveLetterMode = letterMode === LETTERS.FIVE_LETTER
  var sixLetterMode = letterMode === LETTERS.SIX_LETTER
  
  var message = ''
  if(daily){
    message = getTranslationWithInfo(TranslationKey.CHOOSE_GAME_MODE, getTranslation(TranslationKey.DAILY_MODE))
  } else if (unlimited){
    message = getTranslationWithInfo(TranslationKey.CHOOSE_GAME_MODE, getTranslation(TranslationKey.UNLIMITED_MODE))
  } else if (multiplayer){
    message = getTranslationWithInfo(TranslationKey.CHOOSE_GAME_MODE, getTranslation(TranslationKey.MULTIPLAYER_MODE))
  }
  return (
    <BaseModal title={TranslationKey.GAME_MODES} isOpen={isOpen} handleClose={handleClose}>
      <p className="mt-1 text-xs text-gray-500 dark:text-gray-300">
        {message}
      </p> 
      <br></br> 
      {pageNumber === 0 && (
        <>
          <button
            type="button"
            className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-base"
            onClick={event => {
              handleToFiveLetterMode()
              handleIncPageNumber()
            }}
          >
            {getTranslation(TranslationKey.FIVE_LETTER)}
          </button>
          <button
            type="button"
            className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-base"
            onClick={event => {
              handleToSixLetterMode()
              handleIncPageNumber()
            }}
          >
            {getTranslation(TranslationKey.SIX_LETTER)}
        </button>
        </>
      )}
      {pageNumber === 1 && (
        <div key={0} className="mt-2 flex flex-col divide-y">
          {!daily && (
            <button
            type="button"
            className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-base"
            onClick={event => {
                handleToDailyPageType()
                handleIncPageNumber()
              }}
            >
              {getTranslation(TranslationKey.DAILY_MODES)}
            </button>
          )}
          {!unlimited && (
            <button
              type="button"
              className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-base"
              onClick={event => {
                handleToUnlimitedPageType()
                handleIncPageNumber()
              }}
            >
              {getTranslation(TranslationKey.UNLIMITED_MODES)}
            </button>
          )}
          {!multiplayer && (
            <button
            type="button"
            className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-base"
            onClick={event => {
              handleToMultiplayerPageType()
              handleIncPageNumber()
            }}
          >
            {getTranslation(TranslationKey.MULTIPLAYER_MODE)}
          </button>
          )}
        </div>
      )}
      {(pageNumber === 2 && daily) && (
        <>
          <GameModeSwitchModal index={0} pageNumber={pageNumber - 2} location={addSixLetterUrl('/', sixLetterMode)} description={TranslationKey.DAILY_MODE_DESCRIPTION}/>
          <GameModeSwitchModal index={1} pageNumber={pageNumber - 2} location={addSixLetterUrl('/ginjanner', sixLetterMode)} description={TranslationKey.GINJANNER_DESCRIPTION}/>
          <GameModeSwitchModal index={2} pageNumber={pageNumber - 2} location={addSixLetterUrl('/lazy', sixLetterMode)} description={TranslationKey.LAZY_MODE_DESCRIPTION}/>
          <GameModeSwitchModal index={3} pageNumber={pageNumber - 2} location={addSixLetterUrl('/speedy/lazy', sixLetterMode)} description={TranslationKey.SPEEDY_LAZY_MODE_DESCRIPTION}/>
          <GameModeSwitchModal index={4} pageNumber={pageNumber - 2} location={addSixLetterUrl('/speedy', sixLetterMode)} description={TranslationKey.SPEEDY_MODE_DESCRIPTION}/>
          {fiveLetterMode && (
            <GameModeSwitchModal index={5} pageNumber={pageNumber - 2} location={'/swift'} description={TranslationKey.SWIFTIE_MODE_DESCRIPTION}/>
          )}
          

        </>
      )}
      {(pageNumber === 2 && unlimited) && (
        <>
          <GameModeSwitchModal index={0} pageNumber={pageNumber - 2} location={addSixLetterUrl('/unlimited', sixLetterMode)} description={TranslationKey.UNLIMITED_MODE_DESCRIPTION}/> 
          <GameModeSwitchModal index={1} pageNumber={pageNumber - 2} location={addSixLetterUrl('/ginjanner/unlimited', sixLetterMode)} description={TranslationKey.GINJANNER_UNLIMITED_DESCRIPTION}/> 
          <GameModeSwitchModal index={2} pageNumber={pageNumber - 2} location={addSixLetterUrl('/lazy/unlimited', sixLetterMode)} description={TranslationKey.LAZY_MODE_DESCRIPTION}/>
          <GameModeSwitchModal index={3} pageNumber={pageNumber - 2} location={addSixLetterUrl('/speedy/lazy/unlimited', sixLetterMode)} description={TranslationKey.SPEEDY_LAZY_MODE_DESCRIPTION}/>
          <GameModeSwitchModal index={4} pageNumber={pageNumber - 2} location={addSixLetterUrl('/speedy/unlimited', sixLetterMode)} description={TranslationKey.SPEEDY_MODE_DESCRIPTION}/>
          <GameModeSwitchModal index={5} pageNumber={pageNumber - 2} location={addSixLetterUrl('/impossible', sixLetterMode)} description={TranslationKey.IMPOSSIBLE_MODE_DESCRIPTION}/>
          <GameModeSwitchModal index={6} pageNumber={pageNumber - 2} location={addSixLetterUrl('/staircase', sixLetterMode)} description={TranslationKey.STAIRCASE_MODE_DESCRIPTION}/>
          
          {fiveLetterMode && (
            <>
              <GameModeSwitchModal index={7} pageNumber={pageNumber - 2} location={'/swift/unlimited'} description={TranslationKey.SWIFTIE_UNLIMITED_MODE_DESCRIPTION}/>
              <GameModeSwitchModal index={8} pageNumber={pageNumber - 2} location={'/custom'} description={TranslationKey.CUSTOM_DESCRIPTION}/>
              <GameModeSwitchModal index={9} pageNumber={pageNumber - 2} location={'/dummies'} description={TranslationKey.DUMMIES_MODE_DESCRIPTION}/>
            </>
          )}
        </>
      )}
      {(pageNumber === 2 && multiplayer) && (
        <div>
          <p className="text-center text-md font-bold text-red-500">
            CoSpotle is still in alpha. Bugs are expected.
          </p>
          <p className="mt-2 text-center text-xs italic font-bold underline text-red-500">
            <a href="https://discord.gg/GyesQwYa">
              Found a bug? Join our discord and report it.
            </a>
          </p>
          <GameModeSwitchModal index={0} pageNumber={pageNumber - 2} location={addSixLetterUrl('/cospotle', sixLetterMode)} 
            description={TranslationKey.COSPOTLE_MODE_DESCRIPTION}/>
          <GameModeSwitchModal index={1} pageNumber={pageNumber - 2} location={addSixLetterUrl('/cospotle/team', sixLetterMode)} 
            description={TranslationKey.COSPOTLE_TEAM_MODE_DESCRIPTION}/>
          <GameModeSwitchModal index={2} pageNumber={pageNumber - 2} location={addSixLetterUrl('/cospotle/parallel', sixLetterMode)} 
            description={TranslationKey.COSPOTLE_PARALLEL_MODE_DESCRIPTION}/> 
          <GameModeSwitchModal index={3} pageNumber={pageNumber - 2} location={addSixLetterUrl('/cospotle/bot', sixLetterMode)} 
            description={TranslationKey.COSPOTLE_BOT_MODE_DESCRIPTION}/> 
          <GameModeSwitchModal index={4} pageNumber={pageNumber - 2} location={addSixLetterUrl('/cospotle/bot/challenge', sixLetterMode)} 
            description={TranslationKey.COSPOTLE_BOT_CHALLENGE_MODE_DESCRIPTION}/>                   
          <GameModeSwitchModal index={5} pageNumber={pageNumber - 2} location={addSixLetterUrl('/cospotle/bot/parallel', sixLetterMode)} 
            description={TranslationKey.COSPOTLE_BOT_PARALLEL_MODE_DESCRIPTION}/> 
          <GameModeSwitchModal index={6} pageNumber={pageNumber - 2} location={addSixLetterUrl('/cospotle/bot/parallel/challenge', sixLetterMode)} 
            description={TranslationKey.COSPOTLE_BOT_PARALLEL_CHALLENGE_MODE_DESCRIPTION}/>
        </div>
      )}
      <br></br>
      <div className="navbar-content px-2 short:h-auto">
        <div className="right-icons">
        {(pageNumber > 0) && (
          <button
            type="button"
            className="w-full inline-flex items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-base"
            onClick={event => {
                if(pageNumber === 2){
                  handleBlankPageType()
                } else if (pageNumber === 1){
                  handleBlankLetterMode()
                }
                handleDecPageNumber()
              }
            }
          >
            {getTranslation(TranslationKey.PREVIOUS)}
          </button>
        )}
        </div>
        <div className="right-icons">
        {((pageNumber - 2 < 2 && unlimited) || (pageNumber - 2 < 1 && (daily || multiplayer))) && (
          <button
            type="button"
            className="w-full inline-flex items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-base"
            onClick={event => {
              handleIncPageNumber()
            }}
          >
            {getTranslation(TranslationKey.NEXT)}
          </button>
        )}
        </div>
      </div>
    </BaseModal>
  );
}
