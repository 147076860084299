import { DuplicateIcon, PlayIcon } from '@heroicons/react/outline'
import { BaseModal } from '../BaseModal'
import { getTranslation } from '../../../context/messages'
import { SettingsToggle } from '../settings/SettingsToggle'
import { getStoredBotMode, getStoredCustomUsername, getStoredGridHardMode, getStoredHardMode, getStoredHiddenLetterMode, getTitle, handleStoredCoSpotleCustomCode, setStoredCopiedMode } from '../../../lib/localStorage'
import { useState } from 'react'
import { copyTextToClipboard } from '../../../lib/clipboard'
import { TranslationKey } from '../../../constants/strings'

type Props = {
  isOpen: boolean
  handleClose: () => void
  onResetGame: (softReset?: boolean) => void
  isHardMode: boolean
  handleHardMode: Function
  isGridHardMode: boolean
  handleGridHardMode: Function
  isHiddenLetterMode: boolean
  handleHiddenLetterMode: Function
  handleCustomUsername: Function
  path: string
  showSuccessAlert: Function
  showErrorAlert: Function
}

export const CoSpotleGameModal = ({
  isOpen,
  handleClose,
  onResetGame,
  isHardMode,
  handleHardMode,
  isGridHardMode,
  handleGridHardMode,
  isHiddenLetterMode,
  handleHiddenLetterMode,
  handleCustomUsername,
  path,
  showSuccessAlert,
  showErrorAlert
}: Props) => {
  const [isCopyButtonEnabled, setIsCopyButtonEnabled] = useState(true)
  
  setStoredCopiedMode(false)
  const copyEmigrationCodeToClipboard = () => {
    const username = getStoredCustomUsername()
    if(username === '' ||
      username === undefined ||
      username.length > 20){
      showErrorAlert(getTranslation(TranslationKey.INVALID_USERNAME))
      return
    }

    var customCode = ''
    if(getStoredHardMode()){
      customCode = customCode + '1&'
    }else{
      customCode = customCode + '0&'
    }

    if(getStoredGridHardMode()){
      customCode = customCode + '1&'
    }else{
      customCode = customCode + '0&'
    }

    if(getStoredHiddenLetterMode()){
      customCode = customCode + '1&'
    }else{
      customCode = customCode + '0&'
    }

    const customGame = Math.floor(100000000000 + Math.random() * 900000000000).toString()
    const url = window.location.origin + path + '/'
    
    copyTextToClipboard(url + customCode + customGame)
    setIsCopyButtonEnabled(false)
    setStoredCopiedMode(true)
    handleStoredCoSpotleCustomCode(customGame)

    showSuccessAlert(getTranslation(TranslationKey.GAME_COPIED_MESSAGE))
  }

  return (
    <BaseModal
      titleString={getTitle()!}
      isOpen={isOpen}
      handleClose={handleClose}
    >
      {!getStoredBotMode() && (
        <div>
        <div>
          <div className="flex justify-between gap-20 py-3">
            <div className="mt-3 text-left text-gray-500 dark:text-gray-300">
              <p className="leading-none">{getTranslation(TranslationKey.USERNAME)}</p>
            </div>
            <textarea
                onChange={(e) => handleCustomUsername(e.target.value)}
                id="username"
                rows={1}
                minLength={1}
                maxLength={20}
                className="textareaFixed block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                defaultValue={getStoredCustomUsername()}
              >
            </textarea>
          </div>
          {false && (
            <>
              <SettingsToggle
                settingName={TranslationKey.HARD_MODE}
                flag={isHardMode}
                handleFlag={handleHardMode}
                description={TranslationKey.HARD_MODE_DESCRIPTION}
              />
              <SettingsToggle
                settingName={TranslationKey.GRID_HARD_MODE}
                flag={isGridHardMode}
                handleFlag={handleGridHardMode}
                description={TranslationKey.GRID_HARD_MODE_DESCRIPTION}
              />
              <SettingsToggle
                settingName={TranslationKey.HIDDEN_LETTER_MODE}
                flag={isHiddenLetterMode}
                handleFlag={handleHiddenLetterMode}
                description={TranslationKey.HIDDEN_LETTER_MODE_DESCRIPTION}
              />         
            </>
          )}
        </div>
        <hr className="mt-2 border-gray-500" />
        <div className="mt-5 columns-2 items-center items-stretch justify-center text-center dark:text-white sm:mt-6">
          <div>
            <button
              disabled={true}
              onClick={
                () => {
                  copyEmigrationCodeToClipboard()
                  onResetGame(true)
                }
              }
              type="button"
              className="w-full inline-flex items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-left text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 disabled:border-gray-200
                disabled:bg-white disabled:text-gray-900 disabled:focus:outline-none disabled:dark:border-gray-600 disabled:dark:bg-gray-800 disabled:dark:text-gray-400 sm:text-sm"
            >
              {isCopyButtonEnabled && (
                <DuplicateIcon className="mr-2 h-6 w-6 cursor-pointer dark:stroke-white" />
              )}
              {getTranslation(TranslationKey.INVITE)}
            </button>
          </div>
          <div>
            <button
              type="button"
              disabled={!isCopyButtonEnabled}
              className="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-left text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 disabled:border-gray-200
              disabled:bg-white disabled:text-gray-900 disabled:focus:outline-none disabled:dark:border-gray-600 disabled:dark:bg-gray-800 disabled:dark:text-gray-400 sm:text-sm"
              onClick={() => {
                const username = getStoredCustomUsername()
                if(username === '' ||
                  username === undefined ||
                  username.length > 20){
                  showErrorAlert(getTranslation(TranslationKey.INVALID_USERNAME))
                  return
                }
                setIsCopyButtonEnabled(false)
                onResetGame()
              }}
            >
              <PlayIcon className="h-6 w-6 cursor-pointer dark:stroke-white" />
              {getTranslation(TranslationKey.NEW_GAME)}
            </button>
          </div>
        </div>
        </div>
      )}
      {getStoredBotMode() && (
        <div>
          <div className="mt-3 text-center text-gray-500 dark:text-gray-300">
            <p className="leading-none">{getTitle()}</p>
          </div>
          <br></br>
          <div>
          {false && (
            <div>
              <hr className="mt-2 border-gray-500" />
              <SettingsToggle
                settingName={TranslationKey.HARD_MODE}
                flag={isHardMode}
                handleFlag={handleHardMode}
                description={TranslationKey.HARD_MODE_DESCRIPTION}
              />
              <hr className="mt-2 border-gray-500" />
              <SettingsToggle
                settingName={TranslationKey.GRID_HARD_MODE}
                flag={isGridHardMode}
                handleFlag={handleGridHardMode}
                description={TranslationKey.GRID_HARD_MODE_DESCRIPTION}
              />
              <hr className="mt-2 border-gray-500" />
              <SettingsToggle
                settingName={TranslationKey.HIDDEN_LETTER_MODE}
                flag={isHiddenLetterMode}
                handleFlag={handleHiddenLetterMode}
                description={TranslationKey.HIDDEN_LETTER_MODE_DESCRIPTION}
              />         
            </div>
          )}
          </div>
          <hr className="mt-2 border-gray-500" />
          <div className="mt-5 items-center items-stretch justify-center text-center dark:text-white sm:mt-6">
            <div>
              <button
                type="button"
                disabled={!isCopyButtonEnabled}
                className="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-left text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 disabled:border-gray-200
                disabled:bg-white disabled:text-gray-900 disabled:focus:outline-none disabled:dark:border-gray-600 disabled:dark:bg-gray-800 disabled:dark:text-gray-400 sm:text-sm"
                onClick={() => {
                  setIsCopyButtonEnabled(false)
                  onResetGame()
                }}
              >
                <PlayIcon className="h-6 w-6 cursor-pointer dark:stroke-white" />
                  {getTranslation(TranslationKey.NEW_GAME)}
              </button>
            </div>
          </div>
        </div>
      )}
    </BaseModal>
  )
}
