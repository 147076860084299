import { DuplicateIcon } from '@heroicons/react/outline'
import { BaseModal } from './BaseModal'
import { getTranslation } from '../../context/messages'
import { getStoredCustomSolution, getTitle, setStoredStatuses } from '../../lib/localStorage'
import { useEffect, useState } from 'react'
import { copyTextToClipboard } from '../../lib/clipboard'
import { TranslationKey } from '../../constants/strings'
import { localeAwareUpperCase } from '../../lib/words'
import { CustomGrid } from '../grid/custom/CustomGrid'
import { MAX_CHALLENGES } from '../../constants/settings'
import { encrypt } from '../../lib/encryption'

type Props = {
  isOpen: boolean
  handleClose: () => void
  handleCustomSolution: Function
  path: string
  showSuccessAlert: Function
}

export const CustomGameModal = ({
  isOpen,
  handleClose,
  handleCustomSolution,
  path,
  showSuccessAlert,
}: Props) => {
  const [isCopyButtonEnabled, setIsCopyButtonEnabled] = useState(true)
  const [postContent, setPostContent] = useState('');
  const [firstPage, setFirstPage] = useState(true);
  const [statuses, setStatuses] = useState(new Map())

  useEffect(() => {
    handleCustomSolution(postContent)
  }, [postContent, handleCustomSolution])

  const copyToClipboard = () => {
    var stringStatuses = ''
    for(var i = 0; i < MAX_CHALLENGES; i++){
      for(var j = 0; j < statuses.get(i)!.length; j++){
        if(statuses.get(i)![j] === 'empty'){
          stringStatuses = stringStatuses + '0'
        }else{
          stringStatuses = stringStatuses + '1'
        }
      }
    }



    copyTextToClipboard(window.location.origin + path + '/&/' + encrypt(getStoredCustomSolution()) + '&' + parseInt(stringStatuses, 2))
    setIsCopyButtonEnabled(false)
    setFirstPage(false)
    setStoredStatuses(statuses)
  }

  const randomiseStatuses = () => {
    const size = getStoredCustomSolution().length

    var localStatuses = new Map()
  
    for(var i = 0; i < MAX_CHALLENGES; i++){
      if(typeof localStatuses.get(i) === "undefined"){
        localStatuses.set(i, [])
        for(var k = 0; k < size; k++){
          localStatuses.get(i)![k] = 'empty'
        }
      }

      for(var j = 0; j < size; j++){
        const random = Math.random() * 10

        if(random > 7){
          localStatuses.get(i)![j] = 'hidden'
        } else {
          localStatuses.get(i)![j] = 'empty'
        }
      }
    }

    setStatuses(localStatuses)
  }

  return (
    <BaseModal
      titleString={getTitle()!}
      isOpen={isOpen}
      handleClose={handleClose}
    > 
      {firstPage && (
        <div>
          <div>
            <div className="flex justify-between gap-5 py-3">
              <div className="my-2 text-left text-gray-500 dark:text-gray-300">
                <p className="leading-none">{getTranslation(TranslationKey.CUSTOM_DESCRIPTION_SIMPLE)}</p>
              </div>
              <textarea
                onChange={(e) => 
                  setPostContent(localeAwareUpperCase(e.target.value.replace(/[^a-z]/gi, '')))
                }
                value={postContent}
                id="solution"
                rows={1}
                minLength={1}
                maxLength={15}
                autoCapitalize='characters'
                className="textareaFixed block rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
              />
            </div>
          </div>
          <hr className="mt-2 border-gray-500" />
          <div className="mt-5 items-center items-stretch justify-center text-center dark:text-white sm:mt-6">
            <div>
              <button
                disabled={!isCopyButtonEnabled}
                onClick={
                  () => {
                    if(postContent.length > 0 && postContent.length < 16){
                      setFirstPage(false)
                    }
                  }
                }
                type="button"
                className="w-full inline-flex items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-left text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 disabled:border-gray-200
                  disabled:bg-white disabled:text-gray-900 disabled:focus:outline-none disabled:dark:border-gray-600 disabled:dark:bg-gray-800 disabled:dark:text-gray-400 sm:text-sm"
              >
                {isCopyButtonEnabled && (
                  <DuplicateIcon className="mr-2 h-6 w-6 cursor-pointer dark:stroke-white" />
                )}
                {getTranslation(TranslationKey.NEXT)}
              </button>
            </div>
          </div>
        </div>
      )}
      {!firstPage && (
        <div>
          <div className="mb-4 text-gray-500 dark:text-gray-300">
            <p className="leading-none">{getTranslation(TranslationKey.DRAW_MASK)}</p>
          </div>
          <CustomGrid solution={getStoredCustomSolution()} statuses={statuses}/>
          <div className="mt-5 columns-2 items-center items-stretch justify-center text-center dark:text-white sm:mt-6">
            <div>
              <button
                disabled={!isCopyButtonEnabled}
                onClick={
                  () => {
                    copyToClipboard()
                    showSuccessAlert(getTranslation(TranslationKey.GAME_COPIED_MESSAGE))
                    handleClose()
                  }
                }
                type="button"
                className="w-full inline-flex items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-left text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 disabled:border-gray-200
                  disabled:bg-white disabled:text-gray-900 disabled:focus:outline-none disabled:dark:border-gray-600 disabled:dark:bg-gray-800 disabled:dark:text-gray-400 sm:text-sm"
              >
                {isCopyButtonEnabled && (
                  <DuplicateIcon className="mr-2 h-6 w-6 cursor-pointer dark:stroke-white" />
                )}
                {getTranslation(TranslationKey.INVITE)}
              </button>
            </div>
            <div>
              <button
                disabled={!isCopyButtonEnabled}
                onClick={
                  () => {
                    randomiseStatuses()
                  }
                }
                type="button"
                className="w-full inline-flex items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-left text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 disabled:border-gray-200
                  disabled:bg-white disabled:text-gray-900 disabled:focus:outline-none disabled:dark:border-gray-600 disabled:dark:bg-gray-800 disabled:dark:text-gray-400 sm:text-sm"
              >
                {isCopyButtonEnabled && (
                  <DuplicateIcon className="mr-2 h-6 w-6 cursor-pointer dark:stroke-white" />
                )}
                {getTranslation(TranslationKey.RANDOM_MASK)}
              </button>
            </div>
          </div>
        </div>
      )}
    </BaseModal>
  )
}
