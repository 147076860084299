import { CharStatus } from '../../../lib/statuses'
import { CustomCell } from './CustomCell'

type Props = {
  index: number
  statuses: Map<number, CharStatus[]>
  size: number
}

export const CustomEmptyRow = ({index, statuses, size }: Props) => {
  const emptyCells = Array.from(Array(size))

  var rowStatuses: CharStatus[] = []
  if(typeof statuses.get(index) !== "undefined"){
    rowStatuses = statuses.get(index)!
  }else{
    for(var i = 0; i < size; i++){
      rowStatuses[i] = 'empty'
    }
    statuses.set(index, rowStatuses)
  }

  return (
    <div className="mb-1 flex justify-center">
      {emptyCells.map((_, i) => (
        <CustomCell 
          key={i} 
          position={i}
          rowIndex={index}
          statuses={statuses}
        />
      ))}
    </div>
  )
}
