
import { TranslationKey } from '../../constants/strings'
import { getTranslation } from '../../context/messages'
import { GameStats } from '../../lib/localStorage'

type Props = {
  gameStats: GameStats
}

const StatItem = ({
  label,
  value,
}: {
  label: string
  value: string | number
}) => {
  return (
    <div className="m-1 w-1/4 items-center justify-center dark:text-white">
      <div className="text-3xl font-bold">{value}</div>
      <div className="text-xs">{label}</div>
    </div>
  )
}

export const StatBar = ({ gameStats }: Props) => {
  return (
    <div className="my-2 flex justify-center">
      <StatItem label={getTranslation(TranslationKey.TOTAL_TRIES_TEXT)} value={gameStats.totalGames} />
      <StatItem label={getTranslation(TranslationKey.SUCCESS_RATE_TEXT)} value={`${gameStats.successRate}%`} />
      <StatItem label={getTranslation(TranslationKey.CURRENT_STREAK_TEXT)} value={gameStats.currentStreak} />
      <StatItem label={getTranslation(TranslationKey.BEST_STREAK_TEXT)} value={gameStats.bestStreak} />
    </div>
  )
}
