import { recordGaEvent } from "../components/alerts/Analytics"
import { getStoredDarkMode } from "../lib/localStorage"
import { getTranslation } from "../context/messages"
import { ArrowCircleLeftIcon, ArrowCircleRightIcon } from "@heroicons/react/outline"
import { TranslationKey } from "../constants/strings"
import { BaseModal } from "../components/modals/BaseModal"

const SolutionsModal = (props: {
    solutions: string[]
    solutionsPageNumber: number
    handleIncSolutionsPageNumber: () => void
    handleDecSolutionsPageNumber: () => void
    onClose: () => void
    isOpen: boolean
  }) => {
    const pageNumber = props.solutionsPageNumber
    recordGaEvent('solutions_' + (pageNumber))
  
    if (getStoredDarkMode()) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }

    const min = pageNumber * 10
    const max = (pageNumber + 1) * 10

    const pageSolutions: string[] = []

    for(var i = min; i < max && i < props.solutions.length; i++){
      pageSolutions.push(props.solutions.at(i)!)
    }
  
    return (
      <BaseModal title={TranslationKey.SOLUTIONS_REMAINING} isOpen={props.isOpen} handleClose={props.onClose}>
        <br></br>
        {pageSolutions.map((word, i) => (          
          <p className="text-center text-md font-bold dark:text-white" key={i}>
            {(min + i + 1).toString() + ': ' + word.toUpperCase()}
          </p>
        ))}
        <br></br>
      
        <div className="flex justify-center pb-6 short:pb-2">
          {pageNumber > 0 && (
            <button
              type="button"
              className="mt-2 mr-2 flex items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-base"
              onClick={event => {
                  props.handleDecSolutionsPageNumber()
              }}
              >
              <ArrowCircleLeftIcon className="mr-2 h-6 w-6 cursor-pointer dark:stroke-white" />
              {getTranslation(TranslationKey.PREVIOUS)}
            </button>
          )}
          {props.solutions.length > max && (
            <button
              type="button"
              className="mt-2 mr-2 flex items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-base"
              onClick={event => {
                props.handleIncSolutionsPageNumber()
              }}
            >
              <ArrowCircleRightIcon className="mr-2 h-6 w-6 cursor-pointer dark:stroke-white" />
              {getTranslation(TranslationKey.NEXT)}
            </button>
          )}
        </div>
      </BaseModal>
    )
  }
  
  export default SolutionsModal