import { MAX_CHALLENGES } from '../../../constants/settings'
import { CharStatus } from '../../../lib/statuses'
import { CustomEmptyRow } from './CustomEmptyRow'

type Props = {
  solution: string
  statuses: Map<number, CharStatus[]>
}

export const CustomGrid = ({
  solution,
  statuses
}: Props) => {
  const empties = Array.from(Array(MAX_CHALLENGES))

    return (
    <>      
      {empties.map((_, i) => (
        <CustomEmptyRow 
          key={i} 
          index={i}
          statuses = {statuses!} 
          size={solution.length}
        />
      ))}
    </>
  )
}
