import { getResultsTimestamps } from '../../lib/speedy'
import { Progress } from './Progress'

type Props = {
  gamePlayed: string
}

const isCurrentGameStatRow = (
  gamePlayed: string,
  pastGame: string
) => {
  return gamePlayed === pastGame
}

export const SpeedyHistogram = ({
  gamePlayed,
}: Props) => {
  const winDistribution = getResultsTimestamps()

  return (
    <div className="justify-left m-2 columns-1 text-sm dark:text-white">
      {winDistribution.map((value, i) => (
        <Progress
          key={i}
          index={String(i + 1)}
          isCurrentDayStatRow={isCurrentGameStatRow(
            gamePlayed,
            value
          )}
          size={90}
          label={value}
        />
      ))}
    </div>
  )
}
