import { TranslationKey } from '../../constants/strings'
import { BaseModal } from './BaseModal'
import { GameModeSwitchModal } from './settings/GameModeSwitchModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const NewGameModeModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title={TranslationKey.NEW_GAME_MODE} isOpen={isOpen} handleClose={handleClose}>
      <GameModeSwitchModal index={0} pageNumber={0} location={'/lazy'} description={TranslationKey.LAZY_MODE_DESCRIPTION}/>
      <GameModeSwitchModal index={1} pageNumber={0} location={'/speedy'} description={TranslationKey.SPEEDY_MODE_DESCRIPTION}/>
      <GameModeSwitchModal index={2} pageNumber={0} location={'/speedy/lazy'} description={TranslationKey.SPEEDY_LAZY_MODE_DESCRIPTION}/>
    </BaseModal>
  )
}
