import {
  BookOpenIcon,
  ChatAltIcon,
  KeyIcon,
  StopIcon,
} from '@heroicons/react/outline'
import { getDefinitionText } from './Dictionary';

type Props = {
  setIsContactInfoModalOnly: (value: boolean) => void
  showDefinition: boolean
  solution: string
}

export const Footbar = ({
  setIsContactInfoModalOnly, 
  showDefinition,
  solution,
}: Props) => {
  return (
    <div className="navbar">
      <hr></hr>
      <div className="navbar-content px-2 short:h-auto">
        <div className="columns-2">
          <KeyIcon
            className="h-6 cursor-pointer dark:stroke-white"
            onClick={(event) => (window.location.href = '/privacyPolicy')}
          />
          <BookOpenIcon
            className="h-6 cursor-pointer dark:stroke-white"
            onClick={(event) => (window.location.href = '/tos')}
          />
        </div>
        <div className="h-10 px-2">
          {showDefinition && (
            <p className="text-xs italic dark:text-white text-center">{getDefinitionText(solution)}</p>
          )}
        </div>
        <div className="columns-2">
          <StopIcon
            className="h-6 cursor-pointer dark:stroke-white"
            onClick={(event) => (window.location.href = '/cookiesPolicy')}
          />
          <ChatAltIcon
            className="h-6 cursor-pointer dark:stroke-white"
            onClick={() => setIsContactInfoModalOnly(true)}
          />
        </div>
      </div>
    </div>
  )
}
