import '../index.css'

import { getLanguage, getLanguageText } from '../lib/localStorage'
import { Language } from '../constants/language'
import App from '../App'

export function getLanguagePageUrl(path: string): string {
  const langNeutral = ['/dummies', '/swift', '/swift/unlimited', '/scalizzi']
  if(langNeutral.includes(path)){
    return path
  }

  if(getLanguage() === Language.ENGLISH){
    return path
  }

  return '/' + getLanguageText() + path
}

export function getLanguageFromPath(path: string): Language {
  if(path.startsWith("/portuguese") || path.startsWith("/portugues")){
    return Language.PORTUGUESE
  } else if(path.startsWith("/spanish") || path.startsWith("/espanol")){
    return Language.SPANISH
  } else if(path.startsWith("/french") || path.startsWith("/francais")){
    return Language.FRENCH
  } else if(path.startsWith("/german") || path.startsWith("/deutsch")){
    return Language.GERMAN
  }
  
  return Language.ENGLISH
}

type Props = {
  path: string
}

function GameRoute({
  path
}: Props) {
  const horsle = path.includes("dummies")
  const staircase = path.includes("staircase")
  const lazy = path.includes("lazy")
  const taytay = path.includes("swift")
  const speedy = path.includes("speedy")
  const scalizzi = path.includes("scalizzi")
  const impossible = path.includes("impossible")
  const coSpotle = path.includes("cospotle")
  const custom = path.includes("custom")
  const ginjanner = path.includes("ginjanner")
  const sixLetter = path.includes("six")

  const isUnlimitedMode = path.includes("unlimited") || 
    horsle || staircase || scalizzi || impossible || coSpotle

  return <App
          language={getLanguageFromPath(path)}
          isUnlimitedMode={isUnlimitedMode}
          horsle={horsle}
          staircase={staircase}
          lazy={lazy}
          taytay={taytay}
          speedy={speedy}
          scalizzi={scalizzi}
          impossible={impossible}
          coSpotle={coSpotle}
          custom={custom}
          ginjanner={ginjanner}
          sixLetter={sixLetter}
          path={path}
        />
}

export default GameRoute
