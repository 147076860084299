import { RANDOM } from "../constants/random"
import { getIndex } from "./words"
import { isSixLetter } from "../App"
import { MAX_CHALLENGES } from "../constants/settings"

export const getSpecialSplitTimeArray = (
    today: Date   
  ): number[] => {
    if(isSixLetter()) {
      return []
    }
  
    //valentines day
    if(today.getMonth() === 1 && today.getDate() === 14){
      return [0,1,0,1,0,
              1,0,1,0,1,
              1,0,0,0,1,
              0,1,0,1,0,
              0,0,1,0,0,
              0,0,0,0,0]
    }
    //halloween
    if(today.getMonth() === 9 && today.getDate() === 31){
      return [1,0,1,0,1,
              0,1,0,1,0,
              0,0,1,0,0,
              1,0,0,0,1,
              0,1,1,1,0,
              0,0,0,0,0]
    }
  
    //christmas
    if(today.getMonth() === 11 && today.getDate() === 25){
      return [0,0,1,0,0,
              0,1,0,1,0,
              0,0,1,0,0,
              0,0,1,0,0,
              0,1,1,1,0,
              0,0,0,0,0]
    }
  
    //kenny
    if(today.getMonth() === 10 && today.getDate() === 17){
      return [0,1,0,0,1,
              0,1,0,1,0,
              0,1,1,0,0,
              0,1,0,1,0,
              0,1,0,0,1,
              0,0,0,0,0]
    }
  
    //tay tay
    if(today.getMonth() === 11 && today.getDate() === 13){
      return [1,1,1,0,0,
              0,1,0,0,1,
              0,1,0,1,0,
              0,1,0,0,1,
              0,0,0,1,0,
              0,0,0,0,0]
    }
  
    //spotle
    if(today.getMonth() === 10 && today.getDate() === 16){
      return [0,1,1,1,1,
              1,0,0,0,0,
              0,1,1,1,0,
              0,0,0,0,1,
              1,1,1,1,0,
              0,0,0,0,0]
    }
  
    //savs
    if(today.getMonth() === 2 && today.getDate() === 19){
      return [0,1,1,1,0,
              1,0,0,0,0,
              0,1,1,1,0,
              0,0,0,0,1,
              0,1,1,1,0,
              0,0,0,0,0]
    }
  
    //april fools
    if(today.getMonth() === 3 && today.getDate() === 1){
      return [0,1,1,1,0,
              0,1,0,0,0,
              0,1,1,0,0,
              0,1,0,0,0,
              0,1,0,0,0,
              0,0,0,0,0]
    }
  
    //new year
    if(today.getMonth() === 0 && today.getDate() === 1){
      return [1,0,0,0,1,
              0,1,0,1,0,
              0,0,1,0,0,
              0,0,1,0,0,
              0,0,1,0,0,
              0,0,0,0,0]
    }
  
    return []
}

function extendMatrix(splitTimeMatrix: number[][], reverse: boolean, bottomTop: boolean): number[] {
    var array: number[] = []

    if(bottomTop){
        if(reverse) {
            for(let i = splitTimeMatrix.length - 1; i >= 0; i--){
                for(let j = splitTimeMatrix[0].length - 1; j >= 0 ; j--){
                    array.push(splitTimeMatrix[i][j])
                }
            }
        } else {
            for(let i = splitTimeMatrix.length - 1; i >= 0; i--){
                for(let j = 0; j < splitTimeMatrix[0].length; j++){
                    array.push(splitTimeMatrix[i][j])
                }
            }
        }   
    } else {
        if(reverse) {
            for(let i = 0; i < splitTimeMatrix.length; i++){
                for(let j = splitTimeMatrix[0].length - 1; j >= 0 ; j--){
                    array.push(splitTimeMatrix[i][j])
                }
            }
            
        } else {
            for(let i = 0; i < splitTimeMatrix.length; i++){
                for(let j = 0; j < splitTimeMatrix[0].length; j++){
                    array.push(splitTimeMatrix[i][j])
                }
            }
        }
    }

    addVisible(array, splitTimeMatrix[0].length)

    return array
}

function createArrowMask(length: number): number[][] {
    var addHidden = length - 1
    var currentHidden = 0
    const splitTimeMatrix: number[][] = []
    while(splitTimeMatrix.length < MAX_CHALLENGES - 1){
        splitTimeMatrix.push([])
    }

    for(let i = 0; i < MAX_CHALLENGES - 1; i++){
        for(let j = 0; j < length; j++){
            if(currentHidden < addHidden){
                splitTimeMatrix[i].push(1)
                currentHidden = currentHidden + 1
            } else if(j === length - 1 && currentHidden === 0 && i !== MAX_CHALLENGES - 1){
                splitTimeMatrix[i].push(1)
            } else{
                splitTimeMatrix[i].push(0)
            }
        }
        addHidden = addHidden - 1
        currentHidden = 0
    }

    return splitTimeMatrix
}

function addHidden(array: number[], amount: number){
  while(amount > 0){
    array.push(1)
    amount = amount - 1
  }

  return array
}

function addVisible(array: number[], amount: number){
  while(amount > 0){
    array.push(0)
    amount = amount - 1
  }

  return array
}

function slideArray(row: number[], right: boolean, amount: number) {
  for(let i = 0; i < amount; i++) {
    if(right){
      //moves first to back
      row.push(row.shift()!);
    } else {
      row.unshift(row.pop()!);
    }
  }
}

function createRowStaircase(length: number, maxVisible: number, startIndex: number, right: boolean): number[] {
  const maxHidden = length - maxVisible

  const firstRow: number[] = []
  for(let i = 0; i < maxVisible; i++){
    addVisible(firstRow, 1)
    addHidden(firstRow, 1)
  }
  addHidden(firstRow, maxHidden - maxVisible) //we also added one hidden one per each visible
  firstRow.length = length //reset column size
  slideArray(firstRow, right, startIndex)
  
  var staircase = firstRow.slice()

  for(let i = 1; i < MAX_CHALLENGES; i++) {
    slideArray(firstRow, right, 1)
    staircase = staircase.concat(firstRow)
  }

  staircase.length = length * MAX_CHALLENGES

  return staircase
}

function createMixedStaircase(length: number, maxVisible: number, startIndex: number, right: boolean): number[] {
  const maxHidden = length - maxVisible

  var firstRow: number[] = []
  addHidden(firstRow, maxHidden)
  addVisible(firstRow, maxVisible)
  
  var secondRow: number[] = []
  addVisible(secondRow, maxHidden - 1)
  if(maxHidden === 2){
    addHidden(secondRow, maxHidden + 1)
  } else {
    addHidden(secondRow, maxHidden - 1)
  }
  addVisible(secondRow, length - secondRow.length)

  firstRow.length = length //reset column size
  secondRow.length = length //reset column size
  slideArray(firstRow, right, startIndex)
  slideArray(secondRow, right, startIndex)

  if(right) {
    firstRow = firstRow.reverse()
    secondRow = secondRow.reverse()
  }
  
  var staircase = firstRow.slice()
  staircase = staircase.concat(secondRow)
  
  for(let i = 1; i < MAX_CHALLENGES; i++) {
    slideArray(firstRow, right, 3)
    slideArray(secondRow, right, 3)
    staircase = staircase.concat(firstRow)
    staircase = staircase.concat(secondRow)
  }

  staircase.length = length * MAX_CHALLENGES

  return staircase
}

function createUnifiedStaircase(length: number, maxVisible: number, startIndex: number, right: boolean): number[] {
  const maxHidden = length - maxVisible

  const firstRow: number[] = []
  addVisible(firstRow, maxVisible)
  addHidden(firstRow, maxHidden)
  slideArray(firstRow, right, startIndex)

  var staircase = firstRow.slice()

  for(let i = 1; i < MAX_CHALLENGES; i++) {
    slideArray(firstRow, right, 1)
    staircase = staircase.concat(firstRow)
  }

  staircase.length = length * MAX_CHALLENGES

  return staircase
}

function getUnifiedStaircases(length: number, visible: number): number[][] {
  const staircases = []

  for(let i = 0; i < length; i++){
    staircases.push(createUnifiedStaircase(length, visible, i, true))
    staircases.push(createUnifiedStaircase(length, visible, i, false))
  }

  return staircases
}

function getRowStaircases(length: number, visible: number): number[][] {
  const staircases = []

  for(let i = 0; i < length; i++){
    staircases.push(createRowStaircase(length, visible, i, true))
    staircases.push(createRowStaircase(length, visible, i, false))
  }

  return staircases
}

function getMixedStaircases(length: number, visible: number): number[][] {
  const staircases = []

  for(let i = 0; i < length; i++){
    staircases.push(createMixedStaircase(length, visible, i, true))
    staircases.push(createMixedStaircase(length, visible, i, false))
  }

  return staircases
}

export const getStairCaseSundaySplitTimeArray = (
    randomNumberString: string,
    length: number 
  ): number[] => {
    //values set in the order the if statements are
    const arrowArray = createArrowMask(length)
    const reverseArrowArray = createArrowMask(length)
    const randomNumber = +(randomNumberString.substring(0, 5))
    const arrows: number[][] = [
        extendMatrix(arrowArray, false, false),
        extendMatrix(reverseArrowArray, true, false),
        extendMatrix(arrowArray, false, true),
        extendMatrix(reverseArrowArray, true, true),
    ]

    const unifiedTwo = getUnifiedStaircases(length, 2)
    const unifiedThree = getUnifiedStaircases(length, 3)
    
    const rowTwo = getRowStaircases(length, 2)
    const rowThree = getRowStaircases(length, 3)

    var mixedTwo: number[][] = getMixedStaircases(length, 2)
    if(length === 6){
      mixedTwo = getMixedStaircases(length, 4)
    }
    const mixedThree: number[][] = getMixedStaircases(length, 3)

    var masks: number[][] = []
    masks = masks.concat(arrows, unifiedTwo, unifiedThree, arrows, mixedTwo, mixedThree, arrows, rowTwo, rowThree, arrows)
    //masks = masks.concat(arrows, unifiedTwo, unifiedThree, mixedTwo, mixedThree, arrows)
    //masks = arrows
    //masks = masks.concat(mixedThree)
  
    return masks[randomNumber % masks.length]
}
  
export const getSymmetricalSaturdaySplitTimeArray = (
    randomNumber: string
  ): number[] => {
    if(isSixLetter()){
      return getSixLetterSymmetricalSaturdaySplitTimeArray(randomNumber)
    }
  
    var hiddenSpots = 0
    const splitTime = Array.from(randomNumber, Number)
   
    for (let i = 0; i < splitTime.length; i++) {
       if(i % 5 === 0){
        if(hiddenSpots >= 4 || splitTime[i] < 7){
          splitTime[i] = 0
          splitTime[i + 4] = 0
        }else{
          splitTime[i] = 1
          splitTime[i + 4] = 1
          hiddenSpots++
          hiddenSpots++
        }
      }
      else if(i % 5 === 1){
        if(hiddenSpots >= 4 || splitTime[i] < 7){
          splitTime[i] = 0
          splitTime[i + 2] = 0
        }else{
          splitTime[i] = 1
          splitTime[i + 2] = 1
          hiddenSpots++
          hiddenSpots++
        }
      }
      else if(i % 5 === 2){
        if(hiddenSpots >= 4 || splitTime[i] < 7){
          splitTime[i] = 0
        }else{
          splitTime[i] = 1
          hiddenSpots++
        }
      }else if(i % 5 === 4){
        if(hiddenSpots === 0 ){
          splitTime[i - 2] = 1
        }
        hiddenSpots = 0
      }
    }
  
    return splitTime
}
  
const getSixLetterSymmetricalSaturdaySplitTimeArray = (
    randomNumber: string
  ): number[] => {
    var hiddenSpots = 0
    const splitTime = Array.from(randomNumber, Number)
    var noHidden = 0
  
    for (let i = 0; i < splitTime.length; i++) {
       if(i % 6 === 0){
        if(hiddenSpots >= 4 || splitTime[i] < 7){
          splitTime[i] = 0
          splitTime[i + 5] = 0
        }else{
          splitTime[i] = 1
          splitTime[i + 5] = 1
          hiddenSpots++
          hiddenSpots++
        }
      }
      else if(i % 6 === 1){
        if(hiddenSpots >= 4 || splitTime[i] < 7){
          splitTime[i] = 0
          splitTime[i + 3] = 0
        }else{
          splitTime[i] = 1
          splitTime[i + 3] = 1
          hiddenSpots++
          hiddenSpots++
        }
      }
      else if(i % 6 === 2){
        if(hiddenSpots >= 4 || splitTime[i] < 7){
          splitTime[i] = 0
          splitTime[i + 1] = 0
        }else{
          splitTime[i] = 1
          splitTime[i + 1] = 1
          hiddenSpots++
          hiddenSpots++
        }
      }else if(i % 6 === 5){
        if(hiddenSpots === 0 ){
          if(noHidden === 0){
            splitTime[i - 2] = 1
            splitTime[i - 3] = 1
            noHidden = 1
          } else if(noHidden === 1){
            splitTime[i - 1] = 1
            splitTime[i - 4] = 1
            noHidden = 2
          } else {
            splitTime[i - 5] = 1
            splitTime[i] = 1
            noHidden = 0
          }
        }
        hiddenSpots = 0
      }
    }
  
    return splitTime
}
  
export const getHorsleTimeArray = (
    today: Date   
): number[] => {
    const randomNumber = RANDOM[getIndex(today) % RANDOM.length]
  
    const result = [];
    for (let i = 0; i < randomNumber.length; i += 5) {
      result.push(randomNumber.slice(i, i + 5));
    }
  
    const splitTime: number[] = [];
  
    var countRow = 0;
    for(var row of result){
      var rowUnhiddenIndex = Math.floor((+row)/20000) + 5 * countRow
  
      for(var i = 5 * countRow; i < 5 * (countRow + 1); i++){
  
        if(i !== rowUnhiddenIndex){
          splitTime.push(1) 
        }
        else{
          splitTime.push(0)
        }
      }
      countRow += 1
    }
  
    return splitTime
}
  