import { getTranslation } from '../../../context/messages'
import {  getIsLazy, getIsSpeedy, getIsUnlimitedMode, isGinJanner, isImpossible} from '../../../App'
import { BaseModal } from '../BaseModal'
import { SettingsToggle } from './SettingsToggle'
import { getLanguage } from '../../../lib/localStorage'
import { Language } from '../../../constants/language'
import { PlayIcon } from '@heroicons/react/outline'
import { resetSpeedyGame } from '../../../lib/speedy'
import { TranslationKey } from '../../../constants/strings'

type Props = {
  isOpen: boolean
  handleClose: () => void
  isHardMode: boolean
  handleHardMode: Function
  isGridHardMode: boolean
  handleGridHardMode: Function
  isHiddenLetterMode: boolean
  handleHiddenLetterMode: Function
  getIsShowSpotleBot: Function
  isBotAssistedMode: boolean
  handleBotAssistedMode: Function
  isRandomMode: boolean
  handleRandomMode: Function
  isSpyfallMode: boolean
  handleSpyfallMode: Function
  getNumberOfGames: Function
  handleIncNumberOfGames: Function
  handleDecNumberOfGames: Function
  getNumberOfRows: Function
  handleIncNumberOfRows: Function
  handleDecNumberOfRows: Function
  onResetGame: (softReset?: boolean) => void
}
export const GameSettingsModal = ({
  isOpen,
  handleClose,
  isHardMode,
  handleHardMode,
  isGridHardMode,
  handleGridHardMode,
  isHiddenLetterMode,
  handleHiddenLetterMode,
  getIsShowSpotleBot,
  isBotAssistedMode,
  handleBotAssistedMode,
  isRandomMode,
  handleRandomMode,
  isSpyfallMode,
  handleSpyfallMode,
  getNumberOfGames,
  handleIncNumberOfGames,
  handleDecNumberOfGames,
  getNumberOfRows,
  handleIncNumberOfRows,
  handleDecNumberOfRows,
  onResetGame,
}: Props) => {
  return (
    <BaseModal title={TranslationKey.SETTINGS} isOpen={isOpen} handleClose={handleClose}>
      {!getIsSpeedy() && (
        <p className="mt-1 text-xs text-gray-500 dark:text-gray-300">
          {getTranslation(TranslationKey.GAME_SETTINGS_DISCLAIMER)}
        </p> 
      )} 
      <div className="mt-2 flex flex-col divide-y">
        {!getIsShowSpotleBot() && (
          <>
            {!isImpossible() && (
              <>
                {!isGinJanner() && (
                  <SettingsToggle
                    settingName={TranslationKey.HARD_MODE}
                    flag={isHardMode}
                    handleFlag={handleHardMode}
                    description={TranslationKey.HARD_MODE_DESCRIPTION}
                  />
                )}
                <SettingsToggle
                  settingName={TranslationKey.GRID_HARD_MODE}
                  flag={isGridHardMode}
                  handleFlag={handleGridHardMode}
                  description={TranslationKey.GRID_HARD_MODE_DESCRIPTION}
                />
                <SettingsToggle
                  settingName={TranslationKey.HIDDEN_LETTER_MODE}
                  flag={isHiddenLetterMode}
                  handleFlag={handleHiddenLetterMode}
                  description={TranslationKey.HIDDEN_LETTER_MODE_DESCRIPTION}
                />
              </>
            )}
            {(!getIsSpeedy() && getIsLazy() && getIsUnlimitedMode()) && (
              <>
                <SettingsToggle
                  settingName={TranslationKey.BOT_ASSISTED_MODE}
                  flag={isBotAssistedMode}
                  handleFlag={handleBotAssistedMode}
                  description={TranslationKey.BOT_ASSISTED_MODE_DESCRIPTION}
                />
                <SettingsToggle
                  settingName={TranslationKey.RANDOM_MODE}
                  flag={isRandomMode}
                  handleFlag={handleRandomMode}
                  description={TranslationKey.RANDOM_MODE_DESCRIPTION}
                />
                {getLanguage() === Language.ENGLISH && (
                  <>
                    <SettingsToggle
                      settingName={TranslationKey.SPYFALL_MODE}
                      flag={isSpyfallMode}
                      handleFlag={handleSpyfallMode}
                      description={TranslationKey.SPYFALL_DESCRIPTION}
                    />
                  </>
                )}
              </>
            )}
            {(getIsSpeedy() && getIsUnlimitedMode()) && (
              <>
              <div className="flex gap-4 py-3">
                <div className="mt-2 text-left text-gray-500 dark:text-gray-300">
                  <p className="leading-none">{getTranslation(TranslationKey.SPEEDY_NUM_OF_GAMES)}</p>
                  {getTranslation(TranslationKey.SPEEDY_NUM_OF_GAMES_DESCRIPTION) && (
                    <p className="mt-1 text-xs text-gray-500 dark:text-gray-300">
                      {getTranslation(TranslationKey.SPEEDY_NUM_OF_GAMES_DESCRIPTION)}
                    </p>
                  )}
                </div>
                <div className="columns-3 ml-19">
                  <button
                    type="button"
                    className="inline-flex items-center w-full rounded-md border border-transparent bg-green-600 px-2 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-lg"
                    onClick={event => {
                      handleDecNumberOfGames()
                    }}
                  >
                    {'-'}
                  </button>
                  <p className="py-2 mt-1 text-center text-xl text-gray-500 dark:text-gray-300">
                    {getNumberOfGames()}
                  </p>
                  <button
                    type="button"
                    className="inline-flex items-center w-full rounded-md border border-transparent bg-green-600 px-2 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-lg"
                    onClick={event => {
                      handleIncNumberOfGames()
                    }}
                  >
                    {'+'}
                  </button>
                </div>
              </div>
              <div className="mt-5 items-center items-stretch justify-center text-center dark:text-white sm:mt-6">
                <button
                  type="button"
                  className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-base"
                  onClick={() => {
                      resetSpeedyGame()
                      onResetGame()
                      handleClose()
                  }}
                >
                  <PlayIcon className="mr-2 h-6 w-6 cursor-pointer dark:stroke-white" />
                  {getTranslation(TranslationKey.RESTART)}
                </button>
              </div>
            </>
            )}
            {isImpossible() && (
              <>
              <div className="flex gap-4 py-3">
                <div className="mt-2 text-left text-gray-500 dark:text-gray-300">
                  <p className="leading-none">{getTranslation(TranslationKey.NUM_OF_ROWS)}</p>
                  {getTranslation(TranslationKey.NUM_OF_ROWS_DESCRIPTION) && (
                    <p className="mt-1 text-xs text-gray-500 dark:text-gray-300">
                      {getTranslation(TranslationKey.NUM_OF_ROWS_DESCRIPTION)}
                    </p>
                  )}
                </div>
                <div className="columns-3 ml-19">
                  <button
                    type="button"
                    className="inline-flex items-center w-full rounded-md border border-transparent bg-green-600 px-2 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-lg"
                    onClick={event => {
                      handleDecNumberOfRows()
                    }}
                  >
                    {'-'}
                  </button>
                  <p className="py-2 mt-1 text-center text-xl text-gray-500 dark:text-gray-300">
                    {getNumberOfRows()}
                  </p>
                  <button
                    type="button"
                    className="inline-flex items-center w-full rounded-md border border-transparent bg-green-600 px-2 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-lg"
                    onClick={event => {
                      handleIncNumberOfRows()
                    }}
                  >
                    {'+'}
                  </button>
                </div>
              </div>
            </>
            )}
          </>
        )}
      </div>
    </BaseModal>
  )
}