import '../index.css'

import { SwitchGame } from '../components/modals/settings/SwitchGame'
import { TranslationKey } from '../constants/strings'
import { getStoredDarkMode, setTitle } from '../lib/localStorage'

const NaughtyApp = () => {
  setTitle(TranslationKey.NAUGHTY_GAMER)

  if (getStoredDarkMode()) {
    document.documentElement.classList.add('dark')
  } else {
    document.documentElement.classList.remove('dark')
  }

  return (
    <div>
      <div
        className="flex items-center justify-center text-center"
        style={{
          fontSize: '35px',
          color: 'green',
        }}
      >
        <h3>YOU NAUGHTY GAMER!!!</h3>
      </div>
      <div
        className="flex items-center justify-center text-center"
        style={{
          fontSize: '35px',
          color: 'green',
        }}
      >
        <h3>WHAT ARE YOU DOING HERE???</h3>
      </div>
      <div className="flex items-center justify-center text-center">
        <div
          style={{
            height: '450px',
            width: '450px',
            backgroundImage:
              'url(https://media2.giphy.com/media/Rw9Y7jGKkZem54A4zJ/giphy.gif)',
            display: 'block',
          }}
        />
      </div>
      <div className="flex items-center justify-center text-center">
        <div className="inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all dark:bg-gray-800 sm:p-1">
          <SwitchGame isUnlimitedMode={false} />
          <SwitchGame isUnlimitedMode={true} />
        </div>
      </div>
    </div>
  )
}

export default NaughtyApp
