import { unicodeSplit } from '../../lib/words'
import { Cell } from './Cell'
import { CharStatus } from '../../lib/statuses'
import { MAX_CHALLENGES } from '../../constants/settings'

type Props = {
  index: number
  guess: string
  isRevealing?: boolean
  statuses: Map<number, CharStatus[]>
  maskedStatuses?: Map<number, CharStatus[]>
  botStats?: string[][]
  length: number
  onOpenSolutionsPage?: Function,
  botGame?: boolean
}

export const CompletedRow = ({
    index, 
    guess, 
    isRevealing, 
    statuses, 
    maskedStatuses, 
    botStats, 
    length,
    onOpenSolutionsPage,
    botGame
  }: Props) => {
  if(length - index > MAX_CHALLENGES){
    return <></>
  }
  const splitGuess = unicodeSplit(guess)
  if(typeof botStats !== 'undefined'){
    botStats[index].map((letter, _) => (
      splitGuess.push(letter)
    ))
  }

  var rowStatuses = new Array(guess.length)
  if(typeof statuses.get(index) !== "undefined"){
    rowStatuses = statuses.get(index)!
  }

  var rowMaskedStatuses = new Array(guess.length)
  if(typeof maskedStatuses !== 'undefined' && typeof maskedStatuses!.get(index) !== 'undefined'){
    rowMaskedStatuses = maskedStatuses!.get(index)!
  }

  return (
    <div className="mb-1 flex justify-center">
      {splitGuess.map((letter, i) => (
        <Cell
          key={i}
          index={index}
          value={letter}
          status={rowStatuses[i]}
          position={i}
          isRevealing={isRevealing}
          isCompleted
          maskedStatus={rowMaskedStatuses[i]}
          onOpenSolutionsPage={onOpenSolutionsPage}
          botGame={botGame}
        />
      ))}
      
    </div>
  )
}
