import { getTranslation } from '../../../context/messages'
import { TranslationKey } from '../../../constants/strings'
import { FlagIcon, PlayIcon, CogIcon, VideoCameraIcon } from '@heroicons/react/outline'

type Props = {
  label: TranslationKey
  message: TranslationKey
  setIsModalOpen: Function
  handleClosePrev: Function
  index: number
}

export const SwitchModal = ({label, message, setIsModalOpen, handleClosePrev, index}: Props) => {
  return (
    <div className="flex justify-between gap-4 py-3">
      <div className="mt-2 text-left text-gray-500 dark:text-gray-300">
      <p className="leading-none">{getTranslation(label)}</p>
        <p className="mt-1 text-xs text-gray-500 dark:text-gray-300">
          {getTranslation(message)}
        </p>
      </div>
      <div>
        <button
          type="button"
          className="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green-600 px-5 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-base"
          onClick={event => {
              handleClosePrev()
              
              return setTimeout(() => {
                setIsModalOpen(true)
              }, 300)
            }
          }
        >
          {index === 0 && (
            <CogIcon className="h-6 w-6 cursor-pointer dark:stroke-white" />
          )}
          {index === 1 && (
            <VideoCameraIcon className="h-6 w-6 cursor-pointer dark:stroke-white" />
          )}
          {index === 2 && (
            <PlayIcon className="h-6 w-6 cursor-pointer dark:stroke-white" />
          )}
          {index === 3 && (
            <FlagIcon className="h-6 w-6 cursor-pointer dark:stroke-white" />
          )}
        </button>
      </div>
    </div>
  )
}
