import { useEffect, useState } from "react";
import moment from "moment";


export const CountUpMonths = (props:{ startDate: Date} ) => {
  const [currentTime, setCurrentTime] = useState(Date.now());
  const targetTime = props.startDate.getTime()

  const timeBetween = moment.duration(currentTime - targetTime);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className="flex items-center justify-center text-center"
      style={{
        fontSize: '20px',
        color: 'green',
      }}
    >
      <p className="counter">
        <span>{timeBetween.years()}yr </span>
        <span>{timeBetween.months()}m </span>
        <span>{timeBetween.days()}d </span>
        <span>{timeBetween.hours()}h </span>
        <span>{timeBetween.minutes()}min </span>
        <span>{timeBetween.seconds()}s </span>
      </p>
    </div>
  );
};