import './index.css'

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import { AlertProvider } from './context/AlertContext'
import RoutedApp from './route/RoutedApp'
import RoutedFaq from './route/RoutedFaq'

ReactDOM.render(
  <React.StrictMode>
    <AlertProvider>
      <BrowserRouter>
        <RoutedApp />
      </BrowserRouter>
    </AlertProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

ReactDOM.render(
  <React.StrictMode>
    <AlertProvider>
      <BrowserRouter>
        <RoutedFaq />
      </BrowserRouter>
    </AlertProvider>
  </React.StrictMode>,
  document.getElementById('faq')
)
