export const RANDOM = ['144908230067563784477721288420',
'637959571045161040725170474347',
'782402136294154951326482036205',
'898396399351545615651990871848',
'602066114238119710070479890967',
'285607996046170880283175345874',
'429787260791810607674746439419',
'844283406140389948318651610549',
'284241304880167049910148025442',
'814645883168361733866124130193',
'668042229470290232152688497808',
'157096069695173669306393952807',
'339582926287794971935942297033',
'873748672045968166077130957800',
'472079731089933044227038968269',
'321265315011690736270071823979',
'342249450269418918253167842004',
'916494745689057907950577568021',
'750774930747373182721514769482',
'604070565880444910086166731623',
'563244744469447832021724964530',
'461466299460642024259395114285',
'835270718565345235190247533012',
'219859875283770611146690773636',
'621846603863596224992158176060',
'176975602823736360369445468634',
'274798518900418243283333132159',
'217127016967961740349025355034',
'944761362723963451072476984861',
'583987590812083105980041996348',
'166534507046613825715993682622',
'180927899964792170009791563817',
'646520357374082530647196480571',
'149460309204791071104412861009',
'180401884859851816393517896239',
'768179226817080265905095184171',
'813992580611295906801723911537',
'696150013933459003998178986922',
'649179768391760311632385984852',
'510362364086135956104931037687',
'495634616435034316264531878220',
'786800501484096238673779135264',
'804918595422163846053734415359',
'945758564221323769973390353657',
'677386073380984273617640298332',
'707285362215176177737182084384',
'140190576571490767114984198438',
'619614797817789763297951460170',
'169839525046044387695306287312',
'800440484356883665339653046246',
'276609784893676413595253073850',
'288906943663235699675665743158',
'202694618364333737627637649363',
'471917375704782581105112949873',
'996355037262575802697333273566',
'105018363644329617571697751650',
'608363687817994874604176972257',
'505644113451330329840317182352',
'703089013855455743146032856552',
'791259202261060458347738366382',
'305545081132853406532723832062',
'952987940779782940759315742696',
'722716177205352465267365241995',
'228815349255738320891543917849',
'419090678580449444349245877804',
'175158680081551006986327895960',
'998113005741555500819946150797',
'338644916019471220396855850748',
'936188945347130037204197149729',
'272981707240223448029118548490',
'573600362888042571971196886569',
'865002785378660147114867197615',
'522340404782636466541665267736',
'107439954085834932090721159482',
'930665732034983842715970173680',
'866648611389274397470162090126',
'529293627780763801557032136309',
'963486261928088807321985435085',
'953985042267546203203209389284',
'659345744594288575366967269472',
'963158038150972902743849665244',
'902295028146987844992617968425',
'633305646878029722832357716089',
'817227013115470666828391863625',
'406805068588870909908778040543',
'990144376018655250453131018758',
'758087589455276574612948871983',
'972734103585846075897919053751',
'433343297163763603456659840032',
'724684067145232673475999676760',
'564396159587224615603531247367',
'405282952450819907154885362462',
'292261541662448891993276997086',
'419460825269112591009705712764',
'176242711379995891335447969153',
'106840441887962036862421949236',
'486934672820176398101264792673',
'918468853630338457314538996537',
'588673575255221121669261169015',
'530606311564447675134674035874',
'380497661908785205992881871032',
'281489347118345004835305842237',
'433284841697787154590136688602',
'591915090010366003184876026911',
'517650543962216832001778882934',
'375340304824854691515973119793',
'203592422259184270801605775022',
'873575743633650091021305166824',
'719892228298291563656425438782',
'382212906103233880536901247427',
'708598678475928658849391775013',
'648401584394975182151822139407',
'706851742264270407132407131581',
'148747275517658438566458863178',
'933314514530650635478230098718',
'366965714643143925842258531969',
'726657476342355735577798161112',
'409363582857922404846334736978',
'446722392055617000941612290433',
'943365758978873563453876669293',
'749258943175273037973787115738',
'117750303910084774980714646827',
'957568592443713898212213993491',
'401563641584622660942482655584',
'790584662010046784866458951711',
'160000237900452180185114914192',
'695449956493248280375138735728',
'291281897550288541323246855148',
'541042180990620821116287199086',
'611103569677888003107274434611',
'531776417967211555684203350727',
'796449544297523567221136255895',
'217198806517649884685059859694',
'398284843939226749674656351447',
'349986885965469479466298498381',
'660803053708751246854851999799',
'560189661348038839123554710745',
'524375973520184744344629080890',
'106048483377799450129812674954',
'175470529805725200514161196443',
'597317807680225528833347389550',
'622669390029022963776649940088',
'203673350705130604497542752078',
'992464622106694235934686761760',
'178174644118269424234796649899',
'212422769587640124361920787647',
'296214350321750823673981292326',
'127990443791656177563290286032',
'159247151350356417833152222602',
'344341650186881963702459424961',
'675625692226260057388905381864',
'959381173278942442381377985807',
'954663190006280811662982310347',
'970947335064222852398244379339',
'480936977633265667253105519763',
'323819261634085533152463350153',
'883281100030891208995527435718',
'504189833649388972536211817677',
'772553365064296845063869315209',
'700028525023938391894560866109',
'167455094308798551918762258527',
'380189788844552250321002367992',
'617453696987494667867797195777',
'577591511902251155154997762019',
'536317569515678239341901761485',
'540433489551292335639279329402',
'157225405969879855160643684735',
'221369087178647099713062299350',
'481011847057476656013185474907',
'786355804034987804624948146470',
'540954443125396318289549485977',
'314884220770481351114853283418',
'724272872331531101518171573677',
'431654538025829986889665826870',
'998032470769277898091894078918',
'799458107272515103257638192917',
'887628632182983298166609093334',
'468523811210337388525274621339',
'430030910727943050546421912086',
'561059725287524778910961353562',
'480581502951981678822603313743',
'681041134756670544918867577317',
'802602949084070343121365023717',
'161139619163489665018330735041',
'483319670000851924433018250503',
'208166263831421491283254133837',
'958406353908931650165849293610',
'878928162716139194043992427210',
'691709773596862717127141492881',
'272040479805954152749209877150',
'308215984085548815444093558103',
'317443507792993842896272881209',
'645047822334335397233075341623',
'482422748895386231430467217508',
'586569985719661942182372348873',
'181786068502866659963008610459',
'928068331431879910807178550435',
'469023060116433696561987899186',
'607741144140421685201673878101',
'524915489731062456420097228391',
'325998613881562246105229955534',
'681851178614715081416716924324',
'232037543613119420210449193634',
'342577996102516771018475831854',
'530913890128269878176094941366',
'474745355191561353033373757523',
'469960004924280834280564890317',
'520716075862236193353950641164',
'705549189899361183569631257402',
'943073581440586808618024445396',
'390480707917769180705605972301',
'753909934528878262804638364474',
'724029057214278995175506939108',
'867785143964184870268121482554',
'285226610057924287495530546099',
'835019045341649132204982725418',
'333827459706918946491038575301',
'496443809346366913617333857506',
'895790300003018477549572327606',
'446966423155523441640860181340',
'387162681258472780754486847713',
'295365935310735906752237867103',
'980997702578739437431445558207',
'507847484151126598365648295915',
'561029484688924070949616541242',
'268128353403826048629802475472',
'137916830992375857347673914344',
'513132141711451814134080016640',
'463398288334373288526234637019',
'443219424325927745907756424041',
'819286804805958022006982564873',
'412432513659412310973446870775',
'137633237903421766215024897086',
'826252448921758227789599215754',
'959243258340622553286843273500',
'838734005480672040088957923792',
'205911025232897816118289624094',
'923615672118667364783742244707',
'658459259930730488928667433695',
'866524590367115393752255670024',
'599203366241253787971748882975',
'111931134479064126939534679170',
'814056013278010224454117948433',
'409706199260022386772158687871',
'602480452704595721611666751952',
'475478450023370559569121243923',
'449252435574352464926976081440',
'118564449385486274822233563044',
'986619127587232655489294796572',
'466918724217214430624671474849',
'334590950649498815690291922739',
'437260717634019102953236856990',
'397474597999022021037633387528',
'812521984021524514676036828247',
'482379421760744483546427734415',
'806768079868569662470560349695',
'589913480079660849796465474839',
'279291409401696638354480028901',
'665377781573766956064943458752',
'901144314827584370516293555683',
'749999561246239642143852917834',
'986928192433155598794967637367',
'838956453643832104918416250839',
'949105527159777762568728492063',
'849318385309588830003514442350',
'443260434950584685131568950122',
'168008060800638944151170442535',
'649836406115476206997715682745',
'275989952843760320007295735184',
'366568093491827372013645676403',
'697022526552885037958064634627',
'250386697726231913597159519073',
'226257459121529464271420373259',
'512799733100459935216515776614',
'767813048478120345726279277543',
'600689931363565505831913837597',
'189857622830569293013883687723',
'539682506414197824794590574345',
'292805143449923386494150229373',
'631859134401277446914982166354',
'638675492437456253644619410264',
'162732035716267472862573093031',
'477970141599012972646109722700',
'992876831331386838776606832020',
'348477939410923866018393490866',
'846527430981879581310742434598',
'559057998282387070374980035763',
'484400751787319129655222547471',
'510868138910765419712547020220',
'416400271392214126645366670343',
'472615441103245688619195115067',
'505789535301091401982534371376',
'999974232061846932003630989549',
'483016970227929872168112077928',
'893340128656016445133880279435',
'787860004570517242124918110993',
'929969840452880856557590310194',
'237561518247049023788286514355',
'213623012686128048619430473958',
'857088030723983275730435099774',
'882603332173568260537182829420',
'173938367608740666054474350911',
'716288839761561964020233653844',
'232635282990397562142791558689',
'154854320946314875124672181235',
'624378885944068231060719653884',
'363381596092830140504954466844',
'684253016878029517777369817812',
'761920145445653280932276918208',
'450965637731518721037502898429',
'116707924170057206247404230139',
'501547307693355673352614678219',
'356639229107591240509119873700',
'278932642365961942311186083419',
'758291737537145361499565026895',
'217359892605313756209026612066',
'573173247458496046627860271758',
'105665959038360749840988120544',
'794004878309749304771719092272',
'303453737699224668554300954785',
'502728459290784378560562745410',
'452213907480811937033233788477',
'507616332511766474327765570850',
'620539708859180016603093491106',
'932175920834982705912650615668',
'546033304352196152441727735981',
'354858010490427131167523012926',
'572285545227747384699666112385',
'503371465943912224461651648966',
'932785708429968243501292421095',
'143847316421212777528515275455',
'887281773498821197610109867941',
'651280636295842915962091394220',
'864883515772716750728493180576',
'991490210811360725978058361507',
'223626543526830083646499145838',
'652117155483016194354184752257',
'278776219201130440520460661071',
'875823344240789350253022958881',
'246135070335931353652287267795',
'240541359864426029885402794617',
'136007943852255842733654733098',
'496767524242214743459137583621',
'426463401291335209327487652394',
'399994020923779810617906135821',
'120244980200173055719194576420',
'764278465918562494921587749617',
'569062771288882938435598179227',
'798947818779348989844472839456',
'506094558349018240537672390520',
'203700703856112476781845238048',
'840537717938099506709981451785',
'498030306528893115639747534032',
'800103446947298113128891647767',
'464246216389677413455433915655',
'734913730294021237215957160629',
'752636634955654523688099643627',
'431615480621145813330460523610',
'743744111070913671354150580504',
'739831223202679777108069378652',
'291911056726762740453280141538',
'191873785976341833170967054600',
'134807736391879318584616768926',
'765132418062196907888041170692',
'220401686196970283315550450545',
'388643758569550531917986326186',
'922699386495613927425403398907',
'399701160923786654964987568030',
'334922416799616137258687645497',
'438764927333735325732414944022',
'219781122926236679343764610040',
'931829059115623566447893212280',
'252781703990677149598118924588',
'723215653143795144096075079446',
'892060970756121854744607522017',
'636067482832817183168724529265',
'235618187064295446619345378705',
'387654444110779828244249395108',
'502538252905112530596888482664',
'733094269658620521081186192529',
'629868950432717792085129556406',
'365130333679518948880462927828',
'842084535233870436955486092302',
'390059470576343801013865430040',
'796544815867379467402354114092',
'186538654221563116445939655491',
'748680852851927400639566238270',
'132505875621069984039145832896',
'117128790471844955995691728939',
'782901031003822947807397710116',
'422117175118340686398234579840',
'254437626559863981367712780882',
'155839760066486376010752184022',
'216623453356511327726429435874',
'372942686968137792608680616439',
'909775489223652564393402793147',
'562426854167122207491015847613',
'414790451944391953044720176617',
'662403701705944020078116585881',
'624629691493625085071173493336',
'288737027687358653160208434204',
'875347470405040944201223845894',
'116102058489436084328844274147',
'881260377874554401866535934707',
'434695899698784838027748961332',
'535865830109811012728676486896',
'639202916419147507095709831771',
'489604268199199692529876525342',
'444287803783041562605449331620',
'120414048276499390732818852523',
'942227749923884535830218923477',
'964327705706056405321367264082',
'721751945971262138998295214329',
'943022016618287628704670819061',
'303639999734430955519287453618',
'583749233535763012241884758137',
'934774125022124768681097824419',
'601720219788431050812422231675',
'294259889912277839778529415532',
'764599154718533684612445420574',
'431129527234657720871992342739',
'429337592319458379659098132504',
'124546142737025202602750447822',
'926697450259639085411432331917',
'875822616408269271520008116941',
'540451772208755914100743353353',
'200331727647989517976282532933',
'987519546679057440840803786148',
'339927336148260506130702938647',
'869919732613015278995345522112',
'143091233171230385738547295952',
'775097024339644376821120379808',
'530120885366048642308985016578',
'273024798434215273076658590188',
'117066366496266690409334835734',
'525329810292672106846964099222',
'845897632949093571306775643206',
'342264663923847764110517994562',
'345944938452345470354178812976',
'300473734925941492513459684487',
'662580862485880258402390136785',
'278335293426344148158990844054',
'301872762195823644185356912584',
'726466397896089386169556890230',
'268920811501860926278173525974',
'865330023422349597075772166773',
'814549937214989234032152914462',
'126353039613571767823617412969',
'530876780481072078354385392932',
'466948794204421831457553516626',
'499880617392178736836804461482',
'487209519163955754781709698983',
'742849019729758034829349323941',
'863647649446898802186750217530',
'713334525429492240029596127464',
'600314047913770213263288311691',
'393126046027168352371483831053',
'833960564626414918572462729263',
'265168479713629814886158837259',
'997378283236299654436122487409',
'546357950604689914222682766883',
'240581834682755132425500784268',
'418618392604928216383802539705',
'775263597333171599945397832042',
'400557566196457901735876975434',
'471103881698714974160470965524',
'515789058209846540377160129724',
'958114380368939500730996012305',
'482135815409797596017442819122',
'291877650905456567666609195613',
'272063581763049422535757270206',
'342423255476054641042084728189',
'476950381759430403065201893928',
'791234276510498710266693662966',
'605078517141831072031841267277',
'412606950827738258140425144019',
'210017411853587689818552848378',
'118915428885298043560393262127',
'873002118367057575681150532839',
'920871095102645568826849075627',
'925306311220237846221180817949',
'583222860121415986198835248153',
'125122948108392718187865959464',
'655530602821388386924452869710',
'521733428605985381179534620542',
'185125535543166489110450599523',
'525316800154099262210642570795',
'422036414781619088424348116733',
'605653050526196916200580913760',
'785172844513235176458972010136',
'501964791307551433259375996330',
'775768875337958090189388647127',
'115684941223497351578440021547',
'785549943035643843273237397570',
'526720411473143132021228171326',
'588501206612832708792086878820',
'664848769909421886483657358580',
'289261271708227148787584887250',
'120778395293819568572514165870',
'249686924064073588364161159205',
'489231684872557637831167489218',
'122494667588157986469833338638',
'976910101316268389617814892781',
'908441742883334117025205944350',
'898804698503838447691256218947',
'804126538902926731415548833346',
'333984141268790148639020337499',
'223611445188073341659682798144',
'109002028237046169894448084449',
'120940479825189520794314239880',
'304656380148346396534470696661',
'919269529822725219325690359178',
'161514746789128539241151168223',
'934854058310687172274751543977',
'222247533718441654936188052943',
'554724089511439953117859490358',
'360882023810353553746706833322',
'976032303203311620634150042871',
'923101290214942601728825857303',
'675510162155138453885641453787',
'148801216990525711554934415255',
'635599657791718293325427123985',
'782557723296444011260803537355',
'255465036808851942659541684740',
'935826547517362849032071351931',
'839377926697189770999680363403',
'740809555440431202547538663970',
'858411490080922880118265727245',
'830048053095066988884764275360',
'374451399062735905069497094983',
'451252154854313242524560854686',
'353808018809710582628505117946',
'188180226897422160073917979729',
'468437089729044245013510151250',
'857747403418565838563230485133',
'316994691463152313215056450135',
'946861445428825386498653145282',
'948478009758450610563119365652',
'450865400394727111722534518097',
'472772429790966525316620275247',
'546634678595271660505679628935',
'372746550375276438624269828240',
'396930097857545940938768941880',
'215198405518625181754650243224',
'336841562331281209871023161534',
'495261905711976069215675095026',
'536025488212384121788869875576',
'151173510798287090201478421139',
'397973032775516525838428365139',
'977290113351011888669704427850',
'971190073988562693045405999879',
'129073091741074583221806128792',
'860151116575317874543059289698',
'434925717910557769469009176452',
'331695855804319256612491012593',
'489273904963479489536565815795',
'666568444831629299167853352311',
'765462121373525938953452242859',
'503542551316894472678545871462',
'448535107346547351271173580077',
'188615082983346502057748287584',
'149409725886772962007843717550',
'314728350818264516006180429892',
'512840653581854122683020198148',
'484108194189739308487903623702',
'462669418367737692633911999418',
'988697305405359032624755321665',
'547428015861210615265772999183',
'905604460225570096655002239477',
'695391639624078461469378449286',
'328474244990989709816067619879',
'748295384879699192393136256076',
'880436319200145209571864248299',
'703631640122675561694278335202',
'787555419023121407988092287774',
'227580847966215360657150936984',
'137710662435371294876298915554',
'954694970290883136742776855623',
'368718042959165512532832970498',
'380276570173957015379193766627',
'676107790113582607191651526802',
'756764482249246893698240239704',
'899216239793358463969861973330',
'906596216656274292932311634963',
'585000807283919892661353941571',
'322803989100629435866511339385',
'136941614112691355543504364113',
'699378314593164135994711562271',
'776209399916044377401536441275',
'414621863147812423610809831408',
'518412291254563909864909746213',
'467599740337142536189609034014',
'920706947416994375009061395267',
'567392893479135454525965231461',
'801971048603342264280479899758',
'199696238220119344339492329106',
'434438578749163925367338435276',
'317290256274258051379317365993',
'539918912462673227755885254139',
'244885387687633153398132633639',
'512677612757574989120494249743',
'752630344458933821191005566040',
'654813462201357932251067079331',
'919046160285570479106020532301',
'288860752317054859219618127087',
'578267494072739697532060531867',
'502180483750951624293635173744',
'300526152109162297461467634856',
'925515051786567455718492786044',
'492998785137580033784402373095',
'506902841367562533813101487118',
'468683130097479399318297157112',
'607160662064410069367552870264',
'808465239734359361780664984802',
'204023801327999661490256768914',
'684377250582974946967157444631',
'602427112716278511710555193988',
'896662056518113044799808542802',
'386457412961780122097645051960',
'429802098751580240473517767697',
'829759132448390973230222781137',
'989658422619476859089545659838',
'423194984554417031215395246040',
'828275640186045659728848768865',
'392465587967196787014998556993',
'973286907850645187268917399333',
'229779555358495316904289086125',
'845155299600463659190123964247',
'254722223860885735347715578431',
'442845663193387421824214040846',
'738654816833730163717584466316',
'838918384629972802793907743981',
'466642519698087226336904240947',
'564249194808040069790562457136',
'783387763982851889713165623838',
'113070876674268666993646285920',
'514411853058590356805290275977',
'855228767250316048934095464406',
'423387051880978981393122862208',
'758106269880999308543838633154',
'481463034361052539380985636024',
'931653164331390195016364620642',
'321494424081561044784751431424',
'648682566826398013511695030050',
'979588032497040482384560129230',
'811907109950091077020294623324',
'101216663245825405010653967797',
'164801715721695907260804372793',
'303174210784429533078968282254',
'265803882958555153082856159255',
'842966479634796334453333254079',
'711268522026740638520526676034',
'865564540300841653550813198597',
'685586849506757632116603182690',
'638747066506323779666250920638',
'535751654806894272376563189227',
'235215681577176404097543058547',
'302502876028740715684129197335',
'377768106224849182241792437291',
'582066913074584638656182359325',
'813731203448958360776919294873',
'637511315436412515367356930763',
'881747946916150707954065122644',
'963315948855774321030081694125',
'613393696261030855625743481798',
'362056974351761363604709824350',
'735440497059259697873372337230',
'167030589643520642604120371680',
'404904589951175478826541218035',
'856146763384060525585570068420',
'343629515264635214112546629094',
'137097415954641645665623791470',
'240009341522028403658101291715',
'522018187484846087603299370956',
'833125538066291168650020830939',
'906394594654410899603006296006',
'893330506620726406812180866791',
'921690513098882598458175396462',
'544019338741795898984818680915',
'603966929053647634769530459398',
'545404638642143325769759521143',
'953885974020137864802318554231',
'881106721529781422730633435357',
'812253878210061958934849543312',
'887946770977363793516717819657',
'929918888447452161566724038646',
'209343056445222758812309555678',
'146721790870136737945123740282',
'298627242560197019940624689681',
'140557643103542591627302195928',
'358390126843453105474297784528',
'351957211964771428125587321296',
'838211687972239595932906024621',
'406850056310773749833281769801',
'635610313052154059018383626776',
'459396893876811316966401089679',
'509434283550639974040431190459',
'848842716364916985077107970155',
'276900454191916855392913170737',
'196879300620191398590888554396',
'320261913898769824454644180534',
'828081291921819238891771023997',
'203640146363080650827693574232',
'260329449153373577429813448411',
'375080544933972413444237338864',
'839884864350916979822147259391',
'928767723813231878687408844071',
'464461976427739294814578891590',
'310032886780335279834484146719',
'788001342939578357849686321988',
'813428636284517926145697523918',
'276684281489365916445603830748',
'702489552102638464494799737352',
'380757907175336797714291860175',
'191862281671421483579361115891',
'817523351708035145724179383762',
'821472844879581481835179930580',
'879674816330538233929960254905',
'188184092652040134577002070629',
'453887843559227520966775994107',
'966076918753037102729093532991',
'849574687499384405539571130402',
'320516178084671015910725614980',
'646132685804758022946018269437',
'208431577471520917831645558691',
'350250297238834096037823782151',
'348185493580718235022939325965',
'998092940273458283750529821572',
'256593557379750538506180094651',
'729138689478782861710911190425',
'354750617571115349616238095139',
'140693274524093682123991036304',
'993191293360216391893209445497',
'918803060570640930911700992835',
'157567093621135582656906952467',
'191399833649125438831064761444',
'221593183406146432914933582635',
'473398767671491882494548432972',
'693667071706862289590753578168',
'923968876588437491709243280594',
'317670858022750435707584982922',
'658121298027410400947922289596',
'222642020813231675867112266363',
'228130943452371009351261997277',
'183236489040748268755202841527',
'302448158351517698162592517429',
'555325789833389393567191977320',
'359716967833099490330804767424',
'585139644470988801270242887161',
'542866643959113438446909542601',
'867872999757731565430299446832',
'698970437739142389346612423052',
'610102269344233099931989066329',
'385659028048337194400618068401',
'933963401515085048761851250036',
'735341380307453308474118368280',
'376399641995545470398508155320',
'659276105398098846190954289272',
'425438053629969506941609748007',
'199367818149142044001368859900',
'906442356172211268058546516943',
'468655673057686362287141399321',
'628237902910657435437777427030',
'991968049599322453447036070369',
'293315520690054499012910548139',
'842370922852139751977626127522',
'817825118468051043955310716010',
'474426577397091379425857943682',
'546646554219949638438949571584',
'981743716252250154161612594056',
'426470742291241894403376592684',
'568841407020757708731392040394',
'907642778812128072131215159522',
'791668265248218012891709965423',
'784154103635473611565988545296',
'754559524856063813368483535648',
'496449131350467325246842544959',
'371153702907549505837382451774',
'747599352954661360520348732033',
'743753019237898292204486160052',
'336897986303258775622144986868',
'127458241557793831426881787253',
'417306758942597365330666144745',
'293228575517737115052357069706',
'561150603398479304038933547299',
'757585693328896344429668163414',
'216169433146441137662945940094',
'757308070809614723122876925844',
'129863355657312374724755296545',
'388416432336367770780765471106',
'988175551482086410299652342695',
'641399255533910972132603957397',
'678741451140671267837118870775',
'589502177691183002320302734342',
'533445048043588120483914035480',
'696245539326553498570916647838',
'974468312069925810583706142074',
'112269794211878940709879093109',
'432256929748790033676314220517',
'826356817761218792895290920638',
'466600360077935974652746034632',
'216345509829321576014010194170',
'845357219909877002362539182697',
'521655595467381744358380965711',
'990171158148334584823468183284',
'735742107426460711129851168134',
'138432760742339173718089728022',
'930950483502934104888488792954',
'536670308293268676656178944497',
'329879255034940019651159927237',
'464827149478938207988146395056',
'740184521003999780508058022368',
'894790228387968234000315819470',
'864951515672853175847618057531',
'600126332103060534832057599468',
'782083067964991942790770866720',
'227037440418428402826936887927',
'745135105948624682643447340773',
'575054352352137720840247126546',
'987529150284326216933010445905',
'495780665208519186255098886263',
'979139525077171332011484082977',
'570723376553376272567224574217',
'522729632896345591635463391782',
'279025206995399583447923458957',
'379408030344663068990386384431',
'969193974525655854602683798752',
'357012978149425652116573128989',
'370263662432960989809574399490',
'107525745850315795596754829543',
'543653671028883728727413392109',
'910645328035567322550210253075',
'859949345545539073217452260068',
'618701297829791401593439949281',
'759184006970277459414494635232',
'848893768430184259738522853806',
'947875321103515546127656140602',
'140987165743120617136804788213',
'942497823712656178865906777629',
'912223132531647534706255044195',
'394897564821478996152057670331',
'161515801401836281541021424890',
'862545882170816554805679193710',
'858283995364786883771329086052',
'889378762999599311973380423774',
'682004777993596974520097888199',
'597726739338087579089983569392',
'213251693709784012013424044399',
'278909689730324717320504610613',
'666178948846535075071815038757',
'679350137030958096573276932323',
'898324938720042057040051862362',
'248452279972047802303076142261',
'553525280042554491726885657785',
'595441232767965082580029649581',
'480038880742067265688299280605',
'603113939148261191543915222126',
'473623326011111985176579694638',
'762511915633359026614216328564',
'709641478983486628384530979382',
'463277469664715925802640136387',
'846241790634254479539272488363',
'901047706730534766898988933087',
'969503240095630154491787752469',
'883289122924789024114776246047',
'108402069677563464876018195721',
'958340415369932470973714664929',
'437345216446561730879134318362',
'577555374246190957325208334755',
'549913042781829667602610236963',
'630382827737998594170944029152',
'429684066449047755094423274575',
'709944389475493620550410231564',
'885440107804632725184584946241',
'473550961249328302386739044581',
'991743027973923380584848850436',
'822412128730042723253149131983',
'424102819036730169413593140758',
'200712969058274766359080267400',
'199181829104049608989433125185',
'960102829130877659989382548427',
'998272076068955214881572145408',
'627261118625666710442942680926',
'496895004278679259558676916318',
'987893785809878128490892767334',
'739063564408250295033188781801',
'494837448528552573650033753175',
'569756146585968424392720645964',
'380745222864038960987665485878',
'156914877275168842691513820306',
'680158849392526416009292415167',
'461198917878053386741689356989',
'608933607440976821449139318618',
'825452472450752245902002929273',
'262062280830577853240426131073',
'738193806766151663054841744483',
'896366353617029719196662017118',
'886137352123734691763435566844',
'889290985756681654078811695362',
'819847403738373346287119813312',
'850736359896864765143378387300',
'951786728198911016507932051853',
'233932474865332136787339472550',
'412572180610945181302449268382',
'266109712856786988719510052679',
'145777351404046356433111655365',
'585175306871482036069962843890',
'387195802269429325242532421168',
'298862009105116444599203879511',
'670348253256038425786303368938',
'959561975486747356147801898184',
'657807138790091594451761547011',
'751623850930396270161598589800',
'651072652024777709900054259371',
'217976435204538769273418761501',
'508603627335398309156289313228',
'383511479947011238096867349669',
'498142147942125381954447862483',
'476567384746792357129191654481',
'423383652819951180495688535543',
'642753188694455758788404370422',
'530771843694687301016809986340',
'890779941143113804628734463103',
'707791279264752785809186172485',
'270822006334090992932064044527',
'462052694210393672096692681404',
'652032219219018237589895140763',
'429946088416649876918077120233',
'281186545697825279862974291352',
'875549493310376156340654698636',
'187190269042511735564025135612',
'121418532824343858704121391415',
'837336336199046722971354355682',
'398890076521486255148259968057',
'335729103474497312198779617533',
'444846177274398624692747046431',
'327691815283196098575071464198',
'134783607840272238970124275954',
'970302909247915313504628960353',
'352778676168041624325410755692',
'118930675712365256728115992702',
'931837874569596976880078374279',
'876423229231681884375687380118',
'443407220264931984502861060420',
'602182886358328381963869846217',
'752252440157396240031067581166',
'757794735043649559117038765698',
'372844171998059136012621116131',
'312279145995298334090589132214',
'887578966829641474361598051235',
'486694768270950335525554177027',
'779619709953525340318249270195',
'933829074842355879868310860132',
'484550778390430950811164665568',
'851826953821643823848172545662',
'532915141822927619626612155919',
'337691183299693054906061023112',
'521068654132464646901307362374',
'340213140140135325273021380056',
'445015468931254766781546685059',
'807971346815155809381541380103',
'153387095385862637642999870956',
'687828937181099262846043396096',
'175335709705336903390475970656',
'180746700807579453805372579676',
'951547142050813807498116160387',
'794621420729418909567180099852',
'814217729810950998180396180169',
'888390047067912802226333975696',
'387204715639887995510924751021',
'655219751095763180832890990228',
'232397687703942216060994924147',
'227615671347550527200054526121',
'779005883056522584244137044387',
'419241371876690060515063034811',
'761781234743968372663351712490',
'468707992522695351219244341371',
'653810684686870843073222583041',
'143730526910041892491727030782',
'675049731697490362112279256459',
'917774230849779642455296681753',
'475574076696359526367575927801',
'347229871390756692456098591074',
'504384243904317887214821013303',
'455284763347545463415308264065',
'289571091712828000851658519157',
'574693065447970428230429294359',
'867814469682186635840333255844',
'660351803276378452483858218628',
'958427405791916245008441637345',
'759544068895597823837705223568',
'253254106056166024259466214794',
'383092883699639578027952152095',
'849079680975968648489088779914',
'358055622874985323700929959170',
'430463036146458765512382627321',
'169408771496459499505461098908',
'692364592203674983756795642838',
'642120037581790015104652361203',
'806286873695422003234922612925',
'440931169781548424087425125705',
'797073374113039540766142457420',
'832849525678814122180096688705',
'164729928728456577596783890126',
'753868317661929019252984345521',
'719008389491939848288267329363',
'287186180258349049582430948051',
'639599712028410884431621154692',
'531319255943956817589716419955',
'118704738526344851063424584727',
'965535295117230339799762057509',
'496931080471774706872749368661',
'264647509555186414219115987828',
'142601817825888226206043221589',
'305629580376738803155701131028',
'354111959697719572222440795818',
'183752171662010390723492055967',
'804004667936384838971649466446',
'110283183317145010513218110638',
'621801306618613788791897342031',
'761648682025082876298672459891',
'874343331463857711008036417194',
'363698126034873684769493923706',
'854235453596453786637023056346',
'771485508462179338298849122339',
'299588254874565213685711752768',
'961522564522844841842119612369',
'790243410813491900730199176270',
'642939106525726956101490046000',
'847085312011279415130393494602',
'855643450523859152764910387526',
'321064487795790229553099175524',
'190985904608852481343379226845',
'432949520725059756463790442497',
'878430454735711774213064258407',
'852737847082467265006483644935',
'224687936527232822278768815459',
'318764552859375048779338453248',
'515899064508935323994514764273',
'710551387214852802713005899833',
'747701870659454410555522892721',
'177952722530116619619189033597',
'374000065137260465410170496798',
'980800172031470183553899559326',
'119150369002391421083224540169',
'287719182151542077482599278184',
'397389137259563644186536656902',
'594209926092181749639374531545',
'390830347312360998715654537352',
'773747432001846099537199676218',
'878190925021050953438409822981',
'612258807149099265482005152915',
'111952333632812845786963641266',
'199782086064271310561644758219',
'925103299502920606649780242435',
'150126400168978205287769622261',
'318231819617632483409081653118',
'603532046392154002414441895835',
'389061404896323151313350417352',
'431540334885313201506931930652',
'887747752278861818908313950397',
'342980978450957078983512594265',
'880427157839617419532489024561',
'524288462328837037248335994565',
'195676066088632587809275380337',
'449642197940313921129511987959',
'141424261264493600658745961877',
'518212741518887199543818321937',
'920565022257469460956229324507',
'906773229559610961353817162352',
'573861680451751255202671537151',
'893232388306875722085907484944',
'782082744683869998301055440978',
'909944585879097431023468817017',
'719629233073385192414642681288',
'369040811554211979297389059080',
'398986655374261039950267194868',
'153560535916332566981110555992',
'373642447774567078301075949421',
'433786755449914075647369992462',
'365194489110363991513374483065',
'201922079932211621151644168981',
'573776313250242013322095041706',
'535603663936624365349533221423',
'439206638735587970543363444143',
'223941678368671136651119638556',
'779869799603027666453109760449',
'765808354697593414929144680845',
'267171517478517671382849078395',
'377031668216794091376333094503',
'468363953763872687822917044499',
'626545765520229387192479520269',
'356277195716975681070393343903',
'123643860909287327137185745076',
'649458497705730319154534190184',
'957798821374721143932050053679',
'140159209335350126218131621809',
'485939642573248438695457772084',
'722611951891473285572801277889',
'648945627625897317925125397770',
'646953314282831387193941750640',
'448287422466984158110575128139',
'322504898123827292591353533444',
'931941633462864644292685017540',
'407327573745175138427179733621',
'973366548049037206403771089544',
'348262484146067725168987083899',
'918541587720137646027414848639',
'377059616243692513311706857137',
'898157105792484025684803268913',
'680787155348360622809571538519',
'182179737151089438476944283000',
'258282530080436892463678371645',
'730580792202885742967836462760',
'958701809479954456710816293130',
'661086665775580743952152989493',
'196615141575448136619766560100',
'336512366598677071752567062588',
'198594718446859574476096193777',
'720634752826498894170152975728',
'750827651220012662423265652683',
'702990333299475676431225532380',
'845853810206547903365372335401',
'875650530702757458114513429350',
'963622785927021895675980764073',
'798441570374122168615151876025',
'292524541251638417248155623465',
'932607881047994222435064237636',
'964509948608364007022727445915',
'934980321936923213338004915389',
'321480410788379181871936916482',
'995792907566458591954224255578',
'490478251902699820117577248898',
'592681819573657583402183566624',
'329192325402792772362102731670',
'992011886707223844110396911851',
'746899184359038524326792343487',
'690246450087515989697478721081',
'993139376528183456392271219042',
'488574546656034893180359078055',
'773902312036112629892933295594',
'943125411333517665262320270089',
'452340594058117627437459746882',
'405178648995238926077153275455',
'792608189739323376422785111320',
'151884956441935509275669412026',
'491049470892540142977603049353',
'407862495942140063732360219561',
'951803203598459099692988224599',
'698767066309011417916238357770',
'838111277464834123626632686552',
'789742579726123463576482628632',
'677232647581693801872596087897',
'112873404299939386330278740432',
'504401507096583830275161796114',
'298895528803344255153559349852',
'296917224389915121453511037919',
'169198364200158754479263327654',
'242487400059374200454697722612',
'924395923164561105868728762802',
'887827556301234598014473142569',
'513296924631095536009133958318',
'613646119961419177334624517909',
'766220084729342567027870520433',
'782664861343191076670082666246',
'486552393678942633077989260079',
'264739705493663874278143186480',
'271977585348797401737418356309',
'971079486331669207127296663837',
'168779944337517063715465753699',
'719178388572426592553380270072',
'352614749375094090386833951830',
'825529843578870259768355248594',
'348184639142144647267576040134',
'948978706948357768454907018734',
'973189973357079996710432322256',
'951986720053291044487323326121',
'531369409445413039098366087937',
'160835879743042748374401855254',
'903598069329022564736523337920',
'696398160308561867912922748558',
'577448356970570638203705491785',
'899361353155323817523570525007',
'600991871426570253352461222181',
'140056440455676354397989462278',
'687428005981030665223229048913',
'572065822507844269086551750051',
'470733157232984972496488321766',
'924323423291348746329726463674',
'133935755117415120565963644421',
'772922246839533129439591645805',
'724529633776318378552151240555',
'315931925703068057321830839619',
'610947084229155833136728586594',
'358801997481591968407988064688',
'133877532664428380217981414751',
'917701919235154625657782714170',
'144297035059647296294585549512',
'573731101747158725136783065482',
'448333516280119869212763588689',
'953423850395064022486113693078',
'334702720433386529246477612081',
'447037554746314769787049262874',
'383586281238149732135857691298',
'740799916088530282085762282523',
'285445832793565490357214519696',
'847628134088662495867942296183',
'568728921830543857428293611713',
'787183344035421589170554094344',
'367747041744945004174947057714',
'370797681124519341993757689707',
'271890586787178472241977334532',
'950352814539444072334744414190',
'791810453518971161309844130328',
'943899066566218098428877391548',
'534193364020931686026184251034',
'461470506186753229403932139545',
'788702046286518641835435054829',
'123612667720883479231214396810',
'770367737054883415645817246072',
'813595068459918357769199074465',
'491951869951547085688020452332',
'231658488348740963469622351696',
'209613446461026373179576519311',
'580965733328576936820690190197',
'445259449467156175011660046866',
'228457188443977543957047837821',
'334161423859012546492146248684',
'113074164106912640849099617324',
'191066348756646254519612015278',
'516630262653110373410250625235',
'102128705842219641285417612693',
'819455690531393321570557417890',
'121562187984211588254865235548',
'468800545824310447556708339253',
'588221643962127627335697670007',
'902390166234344578370172075407',
'649190222673472435716367215912',
'488457223371358488329889832101',
'645264209062832300603601476063',
'659931767932515796975957629904',
'498923400880342894949988468305',
'598771450033562647866436077265',
'260411547947276493718915370361',
'663676695160657772670591566921',
'255743520969263918465786531782',
'936703639958053795383457491451',
'828897498935950248051731290221',
'858743201852982052889846946867',
'157141345387988929437025661539',
'985861387024972863197345569939',
'645092166333118078856851267497',
'896095731253198598838604170044',
'631018347894929086966085736177',
'184479179823383223008545184625',
'176476087599881642493413424200',
'887412061744210868256503087539',
'759665706607065403419843651334',
'623048395468234406063069966214',
'595881808053050146090401587289',
'709370203224810714395774452070',
'860515507197835345484825515266',
'979204433858380599072127392671',
'834603087169570684264476963354',
'983921287428813808092066015012',
'503275382281567101979585690369',
'729829726868892016674635021431',
'505752342800598414767080062702',
'127038130914161390124078551221',
'166182507439940880697164234314',
'291671029877692822221658447643',
'636010068609974473920145294163',
'646346529351741480574276784007',
'812230379423826284452084466480',
'685309310093675680057662299809',
'284258168832641634611609619986',
'756991731608388490423927911886',
'787753857333119286190612397008',
'582591444647234277002704119005',
'988149297164568801699516113452',
'953659882445146159386209367631',
'701799934195567558405873565232',
'887724385585452182040127069901',
'876178100703075867230905035588',
'171118529229171652317157956381',
'362926415499372390212703888207',
'880587603195968586443196930431',
'629153197307736789531387226072',
'489820859839344764689043891047',
'775218689095766167787212665276',
'127481483807282360110683049783',
'408742775407249792194375394031',
'665556529003586232919705326472',
'791949321511683974546312314383',
'952369684113567112531641014125',
'923473132827085078352526778418',
'517701860643464353669943123239',
'117300867578429323998830335622',
'120059651043169476867740535467',
'293982954338012568237240987539',
'596666751422327832048064926246',
'580104841698834826391300525248',
'541796788793736265908643912931',
'706824617733456281360426825700',
'922455475765055237654090664920',
'566727612778667685163961587936',
'666928562479245328127358344949',
'886772847568625019833175935947',
'707961104298886838603817451351',
'944735083751892858004037665139',
'787780117717261194374329113683',
'639002776826837976545557954053',
'918848691278768008522951614444',
'643337978012180811682932992485',
'618796495107112886047630730227',
'201558504120586285452968847603',
'559755759300539295362348398849',
'239670266439031587888575748702',
'521465746487189693007514543977',
'307817474227039554867555461869',
'906896773136269437888632844552',
'807690133099771890833839585712',
'809989434253347760901439058464',
'869127549582062453063853972852',
'795176684351745934059534324554',
'183464261280374142457489974633',
'772270399854192945744002084393',
'223203899091456631588504796569',
'723074216278164140843205020903',
'404160095992633259791937178884',
'805556574264113112450054910844',
'480960502426585898239267927208',
'725977864112928267757535915945',
'937479450753945569198759032083',
'650566457695893541353848567614',
'251352082808039005701042637225',
'441095449302710145999329799342',
'735655339411399957634039915242',
'586652327018447263583852883070',
'378416157204163830355940986169',
'388049306634597742168246650885',
'844111565403861739198580848519',
'634171198024494599881671167096',
'807873910577793044848765032948',
'966203681926433082302086611099',
'807525148332554462828801589715',
'433439272171170779608422290600',
'639481764475955271387061371797',
'615720695667715565475711391999',
'471858734817822651777702966523',
'242209229101413344227945732796',
'100150260424048581680866680922',
'880075189900420584159702080653',
'174155337843532936627565427621',
'518653330258717115683424379632',
'909280529993556423708170553936',
'846551327094875351505779697528',
'958051135410920022716531845430',
'647364609339588963244548925803',
'569226994712662967388596734379',
'621425590903914122506202384636',
'430740532202059283818801952599',
'199984077086530223035667267140',
'910334240542946899563865969371',
'868573024374024923091417157078',
'874062418986843430047119025475',
'461666166213222509117227319013',
'656485863371846094701261078631',
'876270708555466496572294499498',
'118898217858434121438327234814',
'511245290875491051225008312874',
'441621854051664850742872364365',
'136025296637836990444237977978',
'363862709096122157302759842986',
'665868366361380675843775012304',
'485042818853376674890364421969',
'847676147764230894104551763168',
'737747652648080560252133739596',
'623281493766030217462630287471',
'881516347682684476227000278238',
'591168458918573084382302385261',
'641469075356611568053543575172',
'299797724262470961812449629992',
'254262448017223558913154765794',
'767958579619551818737070921564',
'565448493264838507912310183977',
'800940193618695177100064487807',
'923721264453395240631586152352',
'602761794485274703523398497569',
'395453802483023130792212699064',
'931734814084673429372686437296',
'349387520373588988856050250597',
'191499483933938331618243032692',
'926400849485488675310104043073',
'912552877050728535561758156889',
'913597692599980293824572967192',
'871628035025516135798137887124',
'660874229079871621982023344916',
'570413811144997657753639240219',
'201123712754979071721023067412',
'631414788305073533571274273364',
'937765880132972190606195149031',
'193329893365826856912258992870',
'308955607798299930084062124928',
'399193687815774780446858852927',
'630308800524710753327155537353',
'164211683694588765306320942856',
'467704855062170640757900369873',
'622199721201621595101302793105',
'187036461665447222560670396603',
'884939006070696403228329910421',
'500120122284095023912017835325',
'925433488783410722264870841018',
'375612004955482481991336184877',
'530754684360467575941029576011',
'177328586952234327253525194754',
'923306971305286235587093295797',
'729026612738391294857297927113',
'576460865768280317750024576001',
'339453849359558503993965995711',
'751357211917866081607984618804',
'547261320268325569256721985529',
'529474837611511115721069567102',
'672922646202438658585363253915',
'619802205298743712769113847475',
'508360840420481297277318549825',
'506127597498323129370475690037',
'392676280536962928716252974090',
'634711799719071214504471076770',
'246617001379722089130742323948',
'135234462789149163738279467589',
'517637970468642936773895099338',
'108434168391716590164788813302',
'772827070423499530868376923177',
'991044376920991029757323496395',
'353558682847349267340617959498',
'261980834407976536362503970957',
'758915774805940314718847849322',
'428300540104768690950503889525',
'919419471718139068416213189148',
'833965987171556267705739066094',
'103793675979778336247431821945',
'179077174896592922237826879738',
'285475065399675233727546783230',
'881180747567229140017106495664',
'469910127496853971318420583258',
'794138128467154169838508837113',
'849126705716511655862905996083',
'698881025878125770017534032754',
'118771680144971629934016160670',
'596287592384845643235660940189',
'689854218052553456772642540827',
'673137430336858111483360492711',
'910807922921337701161290621183',
'856596975257881415805720545985',
'815582667106133414249406174337',
'111633913316773374325186639705',
'820771785842998408737408979120',
'113210573758720772313216161065',
'351764443210078111208589396703',
'268699980860081164829443356421',
'633505060929899724232698834070',
'342520290368091023205845114286',
'945312428439865246646507455173',
'485918472593040567219045316562',
'911041444216746646701792391108',
'337614001618662695391797874916',
'231053156553960819615163883568',
'903962017914548997170287379881',
'781810654381969308237001539334',
'963959253335476921230934638381',
'474767630336721105995284759327',
'111090691767831658433551787446',
'302008721464448558642362891994',
'128527036852977991482698314507',
'199508373654042771333226269130',
'105740007928080863805613648515',
'948309256623747973468719817007',
'596786836431636659907800215222',
'106203623215275884565468345216',
'592673574125588262116006724045',
'400377856051511278702755887996',
'321268832800431579302459589177',
'108160562425845146066319134826',
'811501412526139999601852783716',
'314371768603947702053008585682',
'910766223621491492752825925114',
'793097902877385858437946839579',
'746426232881024932999167011326',
'710073246395573083161060460998',
'658492615140987738154814018877',
'582077858303479691124393228967',
'835550232783973757710626184583',
'454665340608684423852958931527',
'791005334961884338727896229088',
'284937414264997509066724832072',
'818566308578949379145406753376',
'952920557681781626031083876862',
'196764881455412446834824440159',
'610222926714451648182702163416',
'607483287990215820631595329224',
'658334295524892656276357692757',
'718205746117027596459006718302',
'674684438061311363164515825811',
'988825958807366289030395384021',
'624048683313196178919940689182',
'231601734482251587756334538593',
'509884499386772464626558695183',
'931690240293976905323278348968',
'552444779346412451109436225324',
'675838066609564820902365875263',
'464456644600195938086057365676',
'631258334147912143083075668562',
'473221484159226403591981096558',
'236988952396146582212354611564',
'693821266901611088390090934568',
'236345603330558478119766176333',
'216819805855358309651932343805',
'381817679044992175418473378659',
'438420079009619204400735643643',
'640532665886496720449881783352',
'596604848480540811205867961366',
'871840068097536820325976885100',
'810969082679311510770284067441',
'214634660689919512813419156890',
'252949511929585190952177028026',
'582245611732551101518504481765',
'197018669320358625918552967780',
'358220668477137246251409232138',
'148092336690790982665358658723',
'860568671179784595980725748923',
'831196131585462898103552452138',
'347179029700130301014271771994',
'246559398692161543097092681321',
'527391701319953013054244813704',
'116014967533280503891583819073',
'152523072762132285389350977245',
'634081763896324627302502223318',
'216422000766229299465758251384',
'467134118674676254503486025390',
'729462315991474827463285387001',
'942907576090250388333863258006',
'204902707903398626299403997213',
'806210827266495027454610630783',
'231225243326376746298644695479',
'726840053716219860601055943614',
'627692006660236033307037999729',
'570051633952375505578649830395',
'943025089161495418467569386070',
'557073903567760164668868019334',
'619082813247377155310518561397',
'290279325167883405402218392733',
'623630595251298320121186534736',
'676354904984941062674222928084',
'181149492108047447482787275388',
'129758277187934523048260962297',
'502698814386832177529804542542',
'672154316649326318846883575025',
'690891227400952867428909834702',
'186179254678247093576668327468',
'320330000074977192125596742353',
'199407436485915098366874659396',
'580622342838479970105660053567',
'174296902188712220922719263616',
'577583049794124760996740833827',
'966003751835453647748464547339',
'186400133739651210879684486597',
'973497392570036450932077775933',
'208147852742948329003275227465',
'698806218167348281686184320541',
'169646602443855357174801345832',
'392602981641263002865700741867',
'605656761398098658244843217453',
'648527712009423097901300214817',
'753874286046760359883181030258',
'224462330606867492321765915313',
'815213018237435441299795223220',
'521178583479593855670388138036',
'877504879163937934657735831013',
'360532328337054638194577765822',
'839444072029882581671256873684',
'341972814075936242183037511918',
'860599539948142810740024947008',
'152525888457944992950703595683',
'578918894951122623721780275770',
'280385473734842289436894323389',
'955094066364730914790821093865',
'406292839711651442506241673728',
'558902515414921723808697414215',
'327272777020028150934773498015',
'999583628184538570991913449197',
'891975245181474286548058460533',
'189799287300873188918779526818',
'233072255268155838028091769452',
'537678245368490532495407055912',
'396478445004040301494686063093',
'871074621411010972590608624577',
'319416784661184137618103256065',
'530390636847820678887602292274',
'208803479101462495686561680900',
'746979713158313575477188752566',
'568062741598736956019663134585',
'293400782416036858049305557710',
'314977441939134052497539739192',
'674999079885598955826587725926',
'240198583559158030199259172211',
'708593811568239792059311623404',
'639439899458928151795097565876',
'952766042564459602698568613763',
'967106974586991724489883059765',
'198390708894472230393795321394',
'145182627732455710631968185192',
'290637155662631801390051818712',
'364857025552366118017809593520',
'269058100820866687529058154311',
'977605916048613440463205347421',
'323872799134613809088461343658',
'497487583371696579843046011156',
'465085850209287222758681310959',
'452091656442980044600734165986',
'296579620367189378857537327141',
'805667482174390242629969077588',
'942765524115386613768306213036',
'899349443920320905356698070146',
'357824702555567343332013710450',
'725976199716074297695308245368',
'823239805618528029246220941368',
'571804633333835860893262091604',
'687073822468690571063745547215',
'898317767273116272025339278440',
'254468213676458312051345329469',
'331545778611057745888445926884',
'751646818869293317608405336058',
'139930589608795200887057496809',
'158694634696098506373586956738',
'579125448982290338856840778285',
'317377680891089004922665466553',
'150935563612580628891920130884',
'589224157057427033898555938826',
'400224124180890516878530212891',
'402337798265428160881482630540',
'720723715871319859892587161481',
'644330090501514285560353159024',
'845137781837898927325953389763',
'976033983326567054026663921205',
'925677835092398562775799889162',
'160078804167093123247397013712',
'885557511200653689850451917517',
'603566150646039946552985114049',
'980851308249971687880466213108',
'373913896352997228239895999851',
'504443553910779394760044597468',
'806803688738122376318222364921',
'927604086474071433380408179528',
'756908824075111704457184837659',
'336532315335498137381710614751',
'379406323770921555665314967455',
'466127670541655945594514166576',
'295757141388639626831555343472',
'859444064439520691267550068855',
'418490475961352041936002057936',
'565244275111398799567229019194',
'625264501092921943889842214138',
'890293342842645730056358691765',
'233792952940879242252972541887',
'383025340299977087825301456861',
'671802048860727375047177840144',
'780303682415859885773975455150',
'977484278538875304213969143002',
'268730567186931002160179966705',
'668185782217841792274507856917',
'458376122681491189318090381774',
'302442032221132911927126535086',
'551758608224881182420056673150',
'253075025996095764954786610788',
'356584383949746015865510056946',
'297654712439438137616937092070',
'209645160454040224764836017606',
'780683962097153965844922420428',
'386094898395123562375979335581',
'827516267365360215957579778415',
'775227500381141354205806915017',
'963911849666848107371600048705',
'246640604106160669849182042249',
'990425250976785947904454380873',
'159132961224648892169907028940',
'936683796129798246128530246388',
'111784681640096719456262216420',
'800694224911888138256922627825',
'108760993907246589655599610524',
'770537009149898219630311781819',
'940307788897548107293522779493',
'139077688856220217192762470254',
'692272304660654701901805991116',
'206790408026623332325893210935',
'384075206981689498452728629589',
'172170498608793459472210575821',
'606636301406327045365962445626',
'963280362583672392679396330637',
'136248033227181987656786895516',
'189192841353459942052021995490',
'774564957528057724799286945955',
'234682657153745347101242961522',
'582110660059099136032186996527',
'308883106521018863642572591465',
'740310696143470208697521922326',
'708374425174564894771985940699',
'355855058088443798314046810238',
'839598950271481301373360226471',
'209119240758858675516056117546',
'399648762374199592663710355587',
'638104062925243209369040056002',
'998701140418227545649988643954',
'619491327391741347397600247982',
'116486466442052692633228317427',
'297911804597950195217786126436',
'710336898720831310267783912413',
'591578416443637442493725028786',
'434196564502098384605898166665',
'386374171553727295722832758753',
'496384453299099707876559311618',
'508290024609745781856814815451',
'936458335985134995600363971706',
'251792717838054684981823222504',
'194359286242699067578517788602',
'409678438482594520374976788604',
'213018580568326542336530971583',
'606301471427630885306370887320',
'869842904463979843303859661321',
'377915641761985833216748769322',
'936220204759347254293138042381',
'342477785579636316263579735553',
'109287578461652550000815866830',
'373845157045223906881583248169',
'797861169162026468590989628128',
'996521165483345010896681352772',
'956089618791772763573503335082',
'997826928634780166520569070208',
'665416107531181334494354322934',
'852437880029280818310129655032',
'967632328232156353947715271703',
'526850475216353031857405283832',
'312835678446832435188371094741',
'138462823046722647522528228273',
'807109767528466175740021483342',
'827536207809491755683392476278',
'993248629546955410591874972969',
'216571726047899131133228859516',
'532681672261793651547130199892',
'993212612233753022000283173380',
'950120717397927892254833862283',
'542351428139622279733992710692',
'136395567941833468502526450267',
'229480628888947469818258280667',
'416819493005384186508077784148',
'387611780526525527936967976000',
'941817066765221759644435238375',
'589771402480478860099631319559',
'229678715524695539959772283925',
'559611097060218915966128060753',
'370441542817363089854564578799',
'795313956692570204794205668824',
'845694373419714727498035521399',
'820670520719175029619454912198',
'950849418594690496655948027216',
'176977516846328557241176310336',
'944206601183427034719450382078',
'817362865495526682659388772740',
'512230191702928777379288435364',
'168426571729328938182678598123',
'630039454347146354867229860422',
'872402149632030269413218356247',
'295680638074264184015054096217',
'568191442322693006869601660317',
'452598995140435552430260519001',
'568830935722154913972765985130',
'960140584473450826925839115724',
'518848419845414997236607775418',
'635435552162820060692436752823',
'940598742529169038071086669280',
'659757294908519308284811621717',
'933965948871778037954271547474',
'471729482069411266539039966312',
'640290747980327366221412769710',
'782831378384054446030247778362',
'898252865107230592263843888038',
'870786613763069121847518424459',
'884843020040677067413389576964',
'968943780348339685921565966707',
'658498552380494122404049656591',
'101233045778073599097615369941',
'205982796286669247474633345492',
'695547908166564170637865077099',
'196214190416332735532712642215',
'773988311503316637603185270929',
'160454181460582930783079076023',
'277567893861849989088825190562',
'110204983892961928892790035353',
'522005550080112835533587397896',
'534751210396355258805881493626',
'557695278946488098060000099482',
'863666424467280139417363869245',
'731595217394630363036034344592',
'324092509837357162274450434586',
'680308399799582745820738663855',
'931056729148767914541239482673',
'825160570149756487770677075546',
'364553401338253667560394561844',
'266540447555387216310338836147',
'284354369298895484404659362196',
'833400695643841541765512532530',
'643494630216739222347240771354',
'216212725761672900047333069436',
'955552886920016638212939224396',
'293848732096719853819018415378',
'526496986384294834878925258276',
'556813088301651492455963112215',
'402315839006535510777030911392',
'760644720259821778993786128222',
'469772450522947829673238069631',
'984208360004731114502809795795',
'283744081575223132129004262629',
'259347018384071616493831951240',
'529967041786950286702605714383',
'487167173065092082453683918985',
'517222655237216699555217673103',
'231735495430616849285707511461',
'689694574705544193251527862664',
'437085818059769291566767993991',
'714009866480271724178266075496',
'758214835076274657697243569982',
'456979362345044482849744974266',
'912837993724635202078923044265',
'940801676668920781279559724616',
'273176745051722012712333346927',
'246177920961261879031980860115',
'515342195340819017401947581137',
'823734679943871959988519975439',
'858747715206124661475326760766',
'644187464106594168153355396525',
'519098280812936409880634247352',
'727887108196274778022932687896',
'485061329082226157666956934611',
'646422000582523783580610455508',
'917881480131599126345206435367',
'309534074571385869286336658576',
'763330057665515171359889235095',
'763437883671123636668815670292',
'981093857456069197487546494705',
'688297055947536370702247976382',
'648340352575021743299568685865',
'687657771763824409195882427651',
'541590699247119408833123739820',
'333262901878330633585673665894',
'727118312955475298549674192610',
'264077175510799406961644959838',
'138466898843082674946775280651',
'364644106467386836800544514662',
'743420424670711623665753414446',
'987308099541027539643923033406',
'501667144644779718421422146742',
'755765632508971716123020316748',
'557188588343472548492496252146',
'776171722508630577728176229431',
'195779990832735329044662587352',
'522206999623449441437754612360',
'260920472893164983558412317416',
'153428098836613956125731147245',
'387201028118881436317509825121',
'936803847022425287642992940272',
'303330916554212501125853828866',
'870211320252224129297775948953',
'340248915859684110177664616214',
'910079540632357552408548343887',
'534530816940954173631846892914',
'140075564395292133314424882038',
'772915117504652133365081639241',
'879789436718928174359781657488',
'224290432736516845061261832846',
'778014071187560888803627960011',
'369682455890924692256849529281',
'678147820373440056863374954687',
'598460275818134272528423430673',
'344979464862074925553687049239',
'720371301511362807621506851673',
'498988063685787973515158690408',
'715561136806415880813205325295',
'769449015590258067963544171688',
'261495465978267407504510322120',
'204516921629792959402961413134',
'773213302541093211661023217607',
'900335616784064426187968860855',
'512387719910238840233508196280',
'188717324554040890481266660187',
'824753135906149274235895556017',
'444221115421796230576451311097',
'472746214781282488095624418605',
'476620008199889257581349377115',
'843723595516086775316865772035',
'729661192350926515881590145374',
'148195644826780931120054782597',
'947498212733674434780474078631',
'959329861208971325901347471558',
'934039267882438769143882065250',
'808692371912830899576687949186',
'661320079680499736237828516877',
'166018074065529474496088827335',
'863015035707596461680090912432',
'318965237403742575547550450612',
'659921999145279085873471671341',
'407683496427463882449501293172',
'244685137475336606282107172755',
'728741439839429975012483550093',
'469832009399024634995245555891',
'224325110245036638617585426449',
'416843076967878826667029753019',
'629045053492433785601768154507',
'941059996577671304002347568467',
'581061116420261182561233495314',
'261025898813279086806201049153',
'947311067088176712348575352261',
'766609903136570737209168921447',
'457953951679195435598593220479',
'565264663720053702164711561571',
'156754565042649915207287423796',
'463262364406283825963987266839',
'668451476669652997037731245151',
'419979460592738981935276321583',
'354245388326968185725664774558',
'594033700877261062778668738936',
'307819584419132721272553981814',
'984310299466141822397046345663',
'937594533815511517506002182348',
'116263874117623404442385595460',
'132347121986965836381554595170',
'865498610704843659115978228961',
'725072206553230806594217851209',
'709895557147980668733368266747',
'459288489084468292054645056684',
'815681825130351991329900239573',
'654360405117699101309582754657',
'568161605901593778519987877278',
'228884749043268337338110118813',
'612244692501981303298290914620',
'450697105101520568542035764536',
'133272355573880272261131061621',
'592133106178286036185088964208',
'211553958302545116456122982393',
'923293802784720316742046381289',
'821239506679611686704412539126',
'709909938419877316736752536355',
'757844330056255426190108286765',
'372330618048287418234332637832',
'247780540015561922942144872237',
'128481717070327334349733496332',
'311290297994866921278508684551',
'809766911361250002426805898307',
'287099891000072418086076595861',
'472488482892665867012381749599',
'691960316113041249007181278869',
'974177441633145942378447635274',
'423369499369277667245384071263',
'403785665738474653296979676871',
'249297463143736725981657496286',
'485893743211433741839327315632',
'394574352962659961634215735365',
'762984282318735689315182156460',
'911708932173948580493010684973',
'781011517608569103060341180960',
'187586601890163075328059843214',
'130945323107196864769047759416',
'872466291122989731537489746011',
'358874405611577581671154119391',
'469665255490680575071771059643',
'596674847825247639622816577849',
'905079655877389145638863748390',
'527086779145429720267077169161',
'511134431692393835744661389498',
'702137141515167651000826846521',
'362262752691676183835241637373',
'782025304424964887575256629021',
'916766751930841857889365710000',
'218283102968720963894682482362',
'468064035208980857748942823650',
'522863758502624016725566973845',
'371406685599855771261857161214',
'323048238673367827155783310381',
'862677195960740086699446070621',
'627190566431119929305477011776',
'527106677427065867685394421481',
'145690558620166815997043356060',
'772261432191234851001682334477',
'723294369576042426168394913161',
'102171162459752598030555365432',
'407631985598182218362592117250',
'437638133225390240286787361041',
'883856254049660641366738651535',
'752012053276979710999451141474',
'617392182377364878524227294145',
'691620208797223722625912427409',
'755999564774995467946749356174',
'556503524088390205168664387629',
'600682482042747114458679715153',
'534891328261422286303797599763',
'126947320500987094914775668212',
'209189124724558957057695460665',
'881442528998516295411572136174',
'452253388308961735566138956248',
'623181246594913269472969370947',
'160142152914045664620247161670',
'213137388592894062650630444666',
'984501902871835776894726797497',
'825523894129362803710886898698',
'297649689591719882124320896314',
'287193590895086033595787999523',
'386801758443260170791416462095',
'756116870420858961489940756292',
'848810599719611238319954734137',
'252018291086434621112326410667',
'454543864763165934914187451946',
'100529145948038418257162632991',
'480455521678176003287866457024',
'844756709961359215519311538797',
'981407930556423394826061149244',
'967822152193425508670705257649',
'368127079824341418016101888921',
'176320998845146793047587659629',
'198640968742886472355109410262',
'673186279687128127457334465665',
'806216172293648807876037378471',
'366671080590013051160678240695',
'862367807511184552476347855479',
'351852387669868837338443291791',
'764019203650051703908636910083',
'418766903242062712214338775560',
'420631417427865923610522055763',
'973305982247125516783398846533',
'485777017885887235776694379819',
'515089828590161770531749571546',
'799938437206152770422796273069',
'649701702488727798246374088538',
'619540388136616826204140256174',
'889732850235038350971816187957',
'110422034088296565100205873034',
'904956957720830948734612380031',
'154076401901830569060783737212',
'565194814935043423922291841832',
'441979471316988708941816595052',
'847552320088511269156436539621',
'964814341355093150335155374786',
'917825405888863815303849638837',
'554491400709395095352236549070',
'510998892590719284848892480938',
'321349237096688173755960225620',
'587918684905289071204871723294',
'612282070598062331799605859026',
'312335504617524707614281552255',
'833342670391696152148272568580',
'740374537960314121466984670411',
'250717792560336172330105346198',
'139646037005224636359628646797',
'293758687570781791107325444273',
'431346884562791737134871946936',
'239743404537244450533838763512',
'650196519010832208099652621985',
'492744666917545239236550057773',
'671784762208897037157116365694',
'789935725351781314915495625932',
'544360704895956952305901469751',
'165603529294467538354559792898',
'760814230238628045913524437262',
'521058425900671532208943534202',
'734396884596429243563716696727',
'147979618355367454825553885110',
'625256518863190909653825145575',
'833519228831766558337380023026',
'338821122357873493649760915442',
'896073823015160648245372954870',
'533202412294420211933397097060',
'445697239181399010846604764091',
'274197475957971992417975650324',
'799337618041083823574179284683',
'412167841447938871815122966191',
'662610853545326351528774839100',
'413533871802123475257470335379',
'306104265967549717617943853902',
'747770370108533563875481044702',
'500297746812873778132126779177',
'707617588164253525624358313873',
'173692784418584890770700717183',
'339602315756256801635071178126',
'474813728010798142023187460508',
'105417884793459349416119529902',
'863691796777461509589492166416',
'127996484334915076027789427190',
'486120673979924000746375215285',
'349405036695112002942198163872',
'577667291210337762939481651516',
'161156935748724473662744272527',
'590576162213871382333599961748',
'718976679880637134046229236581',
'743482359029623588258113632014',
'587460730454848877798110787864',
'115331796971450477659952332813',
'379989646374446842404598183358',
'546915146498099685154486880789',
'842795163219097159932119018145',
'158306231416888834866874258145',
'365059446215153380933719185442',
'968776355783811827990032768121',
'217154955238614215359783296578',
'372083462524111087364477390553',
'850432629054314319573071695703',
'885050066871115773367532137470',
'265196837656540286770925339299',
'792684922531412507315932119387',
'280556776414720760008187347785',
'102694219352681747111564872330',
'620187445759247373365775776009',
'645478479154111780301691695504',
'221432517626253888688744683581',
'717031147193279447734744130818',
'758278911517837513402087543185',
'666739504752418337945958722088',
'108312885535687448286366181860',
'988677168027451490015725095042',
'445413777804292476006172266581',
'862092450911328527018105699850',
'662409694796996552842397317959',
'587131221883428820635190294762',
'984004888252075197868845933893',
'278526432949289801423795320178',
'201152247961537267182233371110',
'550576315427532851837181072992',
'342039689499013925286753892530',
'784835261143387381220110190292',
'590515666924933647516627322607',
'123020198155386022882996028647',
'662717318576023564100957230589',
'589740536286657023884792520853',
'715258659164076002961228953116',
'132570889983774269134925059433',
'848348175750688009834528469414',
'240762931383363934725490844396',
'987019740043776552465849183501',
'745954123145988970349040587685',
'628972840056281277150341314699',
'185903529356687129575314428260',
'430441589279929173191109638545',
'151602100610217944917931919374',
'994580987600586077986683069228',
'285386708755477992529830398460',
'876529860048733689891519386001',
'969460756707141857862057785254',
'572781644828449595854905275856',
'283046402648087932870433925812',
'809932863319356019553924737947',
'932060811472183999677147541780',
'774841648014167965741739387400',
'437698291597519662260869078979',
'493774069108776799145913723349',
'864516929113273203323680458291',
'179080002885368611320770621705',
'918584840396456799569531218313',
'838562814197964462657877371122',
'118888263200850437136833921277',
'344668650138823349076845212179',
'675380059436355770168341668374',
'629943640913895689866863431241',
'508933426737392120157807252358',
'788965818271043811107469512423',
'950446535169042628002678651499',
'427422342575280561703758379483',
'991171939957685370983541456754',
'202123524722876124952017360923',
'296660383511351300088056463419',
'278250360290143832920781334433',
'204873644016521652121748041551',
'869551931585738773865273181739',
'897386562600638750641113471596',
'129338873924317980550692446440',
'924401242978541526732873650653',
'112813925455874765759799235724',
'239572068759316635139320740195',
'797298869555819016428113742054',
'230178799467668623008934333129',
'864111199365570211069550566116',
'212870819748256229622677768929',
'216602086601561234905760094568',
'401033345144242991155058418521',
'809415043402842718159323163628',
'379480222379787610137394649895',
'569459821087271477539559854683',
'570279695798828980052371735971',
'732896234109983166988359648820',
'442849983217824197358850262363',
'663336908197929340246246147404',
'578934721063416811839947585017',
'625868266358936981602283561028',
'381307062472022239951289415035',
'266222380554167962341933656960',
'994812048741650007740071184063',
'336022257652521081164668252624',
'848968707514942754468594784866',
'177478421461561012928417038284',
'696878856856542148874392686511',
'405886921508251312256084650383',
'464719205170763738880021423293',
'505619257687580475614582953123',
'434366442811650762492121373626',
'688261448477914145924569167740',
'618762551027635882030049317177',
'759883906919347598331887538729',
'188982331278138767562047777260',
'686121120707470310193645315480',
'892995148878064217725037094643',
'763371768580411713978318037516',
'137160273403489836797104179464',
'434030884712563101561887735808',
'498854583294278946771739183195',
'255891392889357212905044775441',
'936408830131690014305163313137',
'789537697876554492605550615578',
'436384136720368461443447719723',
'434878022558762462480021597110',
'904977206816897055434522053445',
'664446323608791497724252779583',
'725151729899767634796958376135',
'909959390727349099599115955518',
'597718687932014414965117062601',
'539974439116721181679002138789',
'210398350094755264036220155277',
'503429295627059799195396075642',
'169200410899777497880026355857',
'858743125973525167951394276262',
'918221033654278205338435331692',
'599368150045595203868137149747',
'591547673537463725482407923224',
'638018396542122968025483354425',
'239321898490112865296764137820',
'888781071250093011112521575865',
'725333457585311626609907013913',
'452639680917066630276218880199',
'249243497042443827994229121869',
'901823223859936933244761575104',
'201862046983810314680882836937',
'293772628969685655100511543797',
'740590076016519000768325258098',
'879087499223960447585429865942',
'358133761473123932039863977127',
'379844033112057669757675476332',
'327203248208746005133808123598',
'960956845610154400526769483558',
'740955425905462069238501721239',
'778846033720284813918425168722',
'647240582139067689776806910744',
'851799985497957863576370628620',
'560992414269215639481147361274',
'562029026222029453124379042089',
'198174876376039343886108383469',
'592218031694951365403317847234',
'121791962484787247470068152639',
'406956260822568505117073782100',
'940622882748231853994543726104',
'297605245979142668816699648800',
'629764610548411865732795665832',
'299638178096031804130598133593',
'517136181537716824304205443165',
'232244050877276104246226898832',
'631879582048579215725796580917',
'784290402105679672874468447693',
'384979914337620085146081595616',
'670428202757832560329220641172',
'968418961684120297215205875548',
'798048085691230597431102051523',
'578983095666070908541534275036',
'204765841310562007338802746266',
'100048904022775115012582928286',
'968713891535157548594186845366',
'655904773252719522244758879348',
'969543650003941095807972927347',
'803384278783141285348990661483',
'658229216525869626033081486553',
'916357431364676466660521871020',
'186426216104954840368183432289',
'502081802615333161832504417797',
'954141739079921724913998946627',
'548618771442746582488164037768',
'524123840998443836476619238099',
'367732699652044173469838141055',
'709081040490092912235358125994',
'120604037262493670409660076052',
'411174829842927947981538086585',
'311205169350027580098889820254',
'531859218014175592642887934440',
'442758212615982089614750436690',
'765904866960048411219924185265',
'765773176127746867972106636183',
'988606441748118255918634062969',
'121964541162698480960335371642',
'550370776128073039153042744412',
'462930391084882449392280275951',
'817299084588230353628365040358',
'851119660885033989803482679512',
'126817137111197504258683891696',
'689614342183331997369948019495',
'539381419989959726149980065463',
'533300202960868058538896554193',
'568367701043826486889973782946',
'940003370821046514941034147432',
'634366696229911014719560792829',
'124920011428861508615783753640',
'886886622583740095774616063178',
'152884308310288202888128877425',
'106204545084695513142108966559',
'509537579617818527729744166487',
'103845572388126204293765777871',
'984086020617766108133139265001',
'908311951484269491710254121027',
'486462668897795997241191035272',
'532430748039439724922291314638',
'816926394520255904033291924076',
'360628629374773440687027787472',
'534144629509855401358067330964',
'907705471962155515610591485028',
'683194139583016916778290115933',
'740772612209170583982475225778',
'398510343310299725027990127436',
'398257160401381102738287545865',
'655730470234829876449039289539',
'306840740534412978555881593407',
'185107661583165908501526770573',
'938012345044587829893495631862',
'380797577132398020810810898844',
'984256017267586144299934528497',
'814448796837956700445306932693',
'175908146144839621225415670519',
'818050715198586330281635290966',
'709248579222389856750702752995',
'268657105551753560218439649491',
'773337846721421444237180062905',
'424807481742092652797861158427',
'767312611403494155007167497918',
'861414956736628963961184662824',
'959859459222169774356319030734',
'330338339824636550970077350524',
'744168942213311755259432194464',
'847853020310574816003949417625',
'428585748231759494744042189507',
'478792874990578190835726981037',
'429844580828127055561037350306',
'478884853519284989413937681885',
'535940928072649560553897618639',
'480938746115169821883602899092',
'795671599683030112911628973878',
'658929535908829770127178477023',
'341034751709775933012046632690',
'945615773687180788780220613366',
'205676245824751927211304039889',
'166771004582947335547359426822',
'550496754761374365834074148323',
'138039909197990136899902348907',
'514617422648242236435042244127',
'569691984400433467331406278629',
'281857034373273726652467761196',
'858893590524129499452940955752',
'284703895127284042196206862012',
'254447464847240883524287656775',
'717397284535875369188809438223',
'623524662491917886662003096837',
'134466871005925610477506941476',
'173899098246476977335404093083',
'853269069408380060712301080346',
'546980719679745184481012642659',
'368555202626564166237182514067',
'782212283451236238312565393297',
'785537340724037970351291757782',
'250633040264834520017000190421',
'728868771492573524529039534216',
'433557853715420613373327859654',
'578660210586887619474559981655',
'514909653116840800392188324920',
'320488432239283695592209554256',
'980564510319693627007402345252',
'713571345976519053012116189966',
'838696232459536892230346091251',
'296724647748327653482735097605',
'453986767819822990698003825488',
'863940310818298173320891096013',
'116952089275375458656663880166',
'712414908132933938586471036017',
'336843653977112532415839547024',
'189542167409929454019957486978',
'330438424748067140410808826538',
'955606149099231917703259778004',
'678209602094634824611816390275',
'161292137123254299464354229799',
'792111856504223323434582694053',
'767171787285014127153865919660',
'812945730607480588694573211684',
'477888054592896269948332369639',
'281495091132960780623279267470',
'822710928824130820192633180712',
'653261822237498240998590192641',
'534841969765793044763408472944',
'812166835797759856746576672121',
'526351922562216711706587592897',
'766090591145692697218546188251',
'561427256705060998514762865073',
'999886656237465000854851433357',
'464729383841013617878004816982',
'572372997699766016091290059461',
'329018643894574650973155149628',
'629216491826024759721567526083',
'631102935979663729341760159511',
'239119893710442781845719222586',
'626842283552766181774526621466',
'506397216415281114096943372887',
'576075578939272894300662111176',
'204875407535349274731637813225',
'332870938941394234257263459268',
'333029099504353240086150583609',
'928587481819990716118617762593',
'100938799926544657449615854693',
'400390124219381327732269296886',
'816070100973964608961185242011',
'634990277788699617842332884536',
'650075999049876456384890336400',
'327609704241369015156057573776',
'911989802232813479558773465510',
'273686174795365867017823930695',
'925439280716843191820354094595',
'542992518917426084147480137309',
'745967940305027966026807192279',
'719815249882040455247400555442',
'243868395079863400897702549033',
'333123563765753502379189357176',
'329840964739111259540700759608',
'255040415513862980421904019011',
'977698009546126877330562137243',
'878816506939574695978987584896',
'593529509122334310788289211247',
'253397854221563961615531159547',
'482093357489868606308222488423',
'379385577823236806740103226473',
'665095873867242719792819410304',
'317567120274384427395329258591',
'575924927049836639297530586134',
'674687998893023509613145334349',
'199999785763069519673095129847',
'878641327950088250025415818988',
'469079433038660073529174938043',
'709022665692335384951713673778',
'956269094408533874880800995638',
'521448217720848513507359187007',
'980611269292060618677647240608',
'917761916518370504171944553279',
'327186541148790777939779766929',
'317383425149191859602241922776',
'861579656610232954865938859935',
'233904567151181099707511593716',
'288215985590865436534948611798',
'233705728793041685019455839755',
'220043289421468755298313952538',
'640326933204290908777417387054',
'124276426362938688584601019763',
'172006430904916603560838792949',
'377722399048525421064594585490',
'219715448249335124837482534328',
'482849784233464506046815519950',
'322781118870547540376504252118',
'928856659876965848517856867552',
'324361909321645090734559877046',
'113571656701614528749454927621',
'890063760358342933316781818136',
'607187537627874932605000965429',
'579343912740395839851356048390',
'948328507427676438577110698959',
'781660306170128463703065283564',
'583611556182416680670773860898',
'858234618082258886354093787230',
'326222786362857068800225380614',
'201987034137762837310798363097',
'376528488877052810798656448737',
'709473663387091391798605683192',
'598644020525020476634435536426',
'720198522384738443131332119088',
'963457116745199992644749991200',
'652871849136716944103435852956',
'520497230242515624601480241233',
'105533929659423385185821855077',
'426768797035598757269140090090',
'363441443337489326722509459035',
'796235920359013839091832697554',
'996620388350585227835360351172',
'829440448254424805277373336886',
'131397760923728418389089231178',
'489420808436568226034687499939',
'118946854334749831953246346408',
'197850550302643043351754958611',
'942986794326681112604111340754',
'886514084149980819641359211879',
'198068012550182285591429810914',
'208831786923958048817812532846',
'744335966086468614854850299002',
'124690126667971432029170649711',
'876508854365192639345582515789',
'956677453933915037533290415044',
'773782950776933030476635522723',
'190017158380589892089201293310',
'718969595953251969206043221446',
'409592441650178020548694469491',
'611765451533164536540877181523',
'108153974990368398325231966911',
'952336708212712733441945457152',
'360928333027730895368872973324',
'942207465426781786037059771023',
'210009445716123972950373936853',
'215845703478247767802806864213',
'245232404809840276472261834352',
'494283289683716173061507447191',
'670194198909892208546761194735',
'795279425827695100889565323542',
'331796830788732968467302349976',
'472293712582421943218425116340',
'865530383592773823408319076203',
'407856000914444272576371549928',
'328867957071730417846441257719',
'592116097942324479107181763970',
'294993334502061416719707937264',
'743725091619372122444608533637',
'208698652180566948681161799567',
'303331658842312589857447540212',
'370369913349194970881351090728',
'841735796286731212724986614641',
'687215044537611552828018712227',
'719804411356017323827827857921',
'510795102851830189116874523045',
'584167770103575789476563069995',
'150617046654031565123326484587',
'346261158660660942243700815970',
'575363187086772180054567449775',
'728186059259868718187994911714',
'672870400398681660664708650618',
'334509482409594945711621999870',
'569640838046666766589618932964',
'751946709268124710534149054948',
'652255658710535393584813043722',
'330242027317321343634553135124',
'959034239969114037973789069257',
'872005073270321771312941274555',
'216332917214841350715380333044',
'376998504738714012544285527760',
'416552670690282151893644140244',
'728589945077417844619784615979',
'980382757956765554909117427154',
'275327401446078754657953145963',
'393178710799593826544063443073',
'278090063325852371204596414699',
'217007516497172047309311026294',
'419040301873638200704549193502',
'844679974986595889883021571850',
'424414513295345345694595650087',
'643900011252082132815211680226',
'769126116383113751461554786546',
'544787946649852016362034377055',
'384191113601437125225906515583',
'487804574599431946902082445524',
'321090638036568458376787053503',
'998599185795534567553321465055',
'259679605572665289045672151451',
'327898922945135451874604167888',
'704200522190656400802778723164',
'994545321814880313809578228946',
'981970882154782025094207193266',
'813496261397418298666236789585',
'491512176344377426803279743516',
'882455934099968518968444589351',
'235540675635056455055177839645',
'264652327068276771707223528537',
'813621806065269067179235185119',
'756246799826111416341141979510',
'370366896982858398909620520602',
'290409842821563926638825547668',
'412606874661045218587458786688',
'672387484474056523159036457539',
'708507244820240155484358356904',
'303746996289426052897748322595',
'147849780867791510485299724968',
'410347727448771534774370794478',
'685922713450719173288231915953',
'671107209460054092241277952737',
'845732232383714445402239912326',
'484604794418861346611653671362',
'693646878058977652208252217190',
'502484720606168777841320750183',
'360161938252940355209990211188',
'454968286882593505646708491940',
'841738748321078336672000718423',
'189002827678444575993687914471',
'603769825126968134004756268785',
'443349022527697178946488241696',
'225830572051723982835682936722',
'473660940158199871198259444029',
'218447783175230939841855495404',
'156713954641612903134623411823',
'665741880144287673917933019228',
'127464754333022332105356666258',
'148946868907411379792626033300',
'342785389292134205202433095330',
'319261778430938235484749245600',
'971218065292348633331773182131',
'866764054052988029760664879917',
'706395805867733850936586040892',
'624959461004998987443091972041',
'298505481586667652896901567477',
'249542004453324945240557352675',
'252878810419346027468717170253',
'778277775710136127836832130952',
'464222216784065018665791389387',
'395673396953543238089108687176',
'453524642363626066956841784543',
'292824051369284959417008420724',
'882821991594177493678486577925',
'989115315604834651135246959262',
'801768340820896068792704794181',
'737349713104925154212780759946',
'418061686505659826718639333627',
'579859723196886645099913642398',
'548509285693161452845064036680',
'297998966354544374776345551456',
'577875886404359457461051122051',
'661718249285860383024509778106',
'367869511271158611166798243410',
'666155210400054408806569698287',
'195560144814154332311144842115',
'725103286492410477016858173173',
'967208301200956583455168650699',
'347015140423472393874865784950',
'714883597476499369987698489456',
'241307533901370550068131639472',
'331284730606636378654219716184',
'413475424046091989850013898809',
'254062841231448126656517979856',
'615512005727490211145370397827',
'223256053828433241725212155823',
'716853929457561659725129063322',
'579794606197667290036751827454',
'982029902931395486180951832986',
'905693673895366682596817911332',
'866574914513653141226732754974',
'886361336009162522226264657723',
'341821395916129186179649091322',
'942369291841326845211039776485',
'294500758651198188629146490467',
'364391081072972962454622797161',
'916883424229921732799963916956',
'418224215806465786968381058441',
'656305195286925352728633665689',
'600402441972349273000003462702',
'694598546458420008068383760411',
'475792227374614534735115941270',
'682108693109914177268458968773',
'821533543638971379858555838211',
'524822329008467638214778014025',
'134185827143596071277652847802',
'610563577045527227028031651860',
'382489416889346695091975416487',
'923689187216047798889310554420',
'384749708139310981426047862985',
'860979761434081054076522159449',
'484335782011492338194111381396',
'853454220772529133581437981368',
'106397867249718342842946461202',
'109878872865115268235255246476',
'260011723065038236035109820754',
'163567563819420905847098029368',
'534129310567384405676529627061',
'771493032020413444548333825476',
'498936631733067116297762626403',
'556347628074186486499607123348',
'655512735935059877048232561497',
'724920615260145069399802173249',
'839361387623755227761847421280',
'682558822965355427862719959509',
'914357077858746882960615788665',
'286158198124193065038191698447',
'922557705237051733768233416244',
'716446209681166612021698970298',
'618698217738577507798478317710',
'876525078185421048869966343682',
'733527879827788775618271236664',
'925975097428538069276301626228',
'695085303112330187636571533554',
'215933396459842128427441085395',
'115980493208923869562016670900',
'705718716650223560283969691274',
'885191622468741535484932475306',
'679230137292562125651247246817',
'493108562706579006509917145510',
'597511853358472811714434476979',
'489663223880915080972841360136',
'270978388327870681916615421432',
'884951868507597582500631350619',
'745836188433863935253719775169',
'454082800584129353420236574509',
'868459596114020173708236016576',
'392035398891783981757541169036',
'921549380800088076832424460620',
'790602070608240503064916670576',
'801036741952711524966586529047',
'513264041565220120391223587481',
'315461399897387288606603717570',
'388198067271586361731592179081',
'722555431407522812034532413773',
'796187627919315506527235650587',
'364829145971193341525488984324',
'520658431694794067845548729911',
'627871495535731455122606058268',
'589317946269552057048886151757',
'402598699315497912471772238948',
'703013746221163874460252669035',
'940119904658862762021744870195',
'969788593730712659223056210822',
'253097421929571967156582039128',
'903436462785053627464891564027',
'703569295518853883811490878566',
'673273422778161197239665954824',
'414694504750487034747619153495',
'832539408530990134552158716920',
'928657935098712692095138841494',
'765915485024074181822760070806',
'844124348233099387357160687503',
'298156821610296888444909426223',
'992625086592598652065687968462',
'665589702173021566614451075675',
'129466129368490019679118339821',
'639731252157346571011818125773',
'569363566952269715141600670525',
'502091407966140827430545344419',
'378629163857053284711573637094',
'508752634380272580435020759449',
'650816661984682137148198037266',
'705097738251275996461926216586',
'131118930279561889720858337670',
'379047534452594139387485661898',
'388556051027780394911003284562',
'871203299131514715646419089475',
'135229098342069037334311644811',
'790303507939787385870576585207',
'156558504202784297474688329510',
'464214200108673522218148633412',
'752326839230477370205923971716',
'709850756202149256590265211323',
'729397256102953641328648530409',
'452549248540544240066898548938',
'495600224818983561214283995342',
'235071372307995890351774533467',
'483979127174084328675815977874',
'101828716433636386806723240591',
'200421738003315349539795814721',
'159684895688988502012038379680',
'964382006627377097253987927814',
'962163256055420739329134296929',
'986726819444353044678590719745',
'409978279809189984105329638361',
'203923485208446194451993760682',
'474110459657018834875929517360',
'813598738269043154753508199769',
'226029078475949359274564079778',
'579391628181265836856317553567',
'368221288027257265902270133827',
'729892567372561958660890096434',
'719004921824393488497634025926',
'917315788556515728928579847307',
'209949317274643445185654697153',
'299486861035842758808522872631',
'890706461702854505540413412668',
'777188502250037686691960950553',
'560259450389272860833420482120',
'945892183048276897286042590299',
'739079399302585613496689223611',
'887431424586649621954672564476',
'407906185526866905017272240119',
'640486860964028909289915177868',
'527440198827236620818396085176',
'815315157140384234806982283882',
'159163430548938135591251912239',
'481363708375280833778981836094',
'670632267179195834909853510696',
'971005379819825695015518991816',
'789347369965498599056683218641',
'123513747249262757456765871523',
'562225429569411710776874653282',
'110869542189341268933789460904',
'857038647097721460502549932253',
'378864355178028327994472987548',
'755839952097937286391714555457',
'972172263769735465379156248521',
'381230762728389103451594843732',
'336463475066454651752738610254',
'798820322935239333716519590180',
'478640983564623550649191338957',
'643024047018216533422729070377',
'472869529476046263550515490273',
'191786306379131984429313490382',
'495660656474510559926371419929',
'783249741068995025628120947953',
'965466749338619800273319217916',
'661013775918885693399610797122',
'425250445493550729234733310717',
'306013791318313046058694678116',
'375195418370137316060502349673',
'467023973970873449305070276707',
'381641577537067831489398921136',
'696938663167393095381171732152',
'602369477199113656382027833713',
'880974016559654379526234182975',
'896630522389117195928250875064',
'144888874769189925184060753984',
'898244502847745624554492631068',
'693421118239523477933546488032',
'213766386733236516376470346668',
'729726052717336764855478657317',
'949237739325716076483226534240',
'473353371997837606315132745897',
'647541060939644382914691021883',
'429961691434980308057868769952',
'535316776379552359683635995132',
'742229418534948736438004957873',
'201938517293415320203390082647',
'324256809103166020589409912142',
'533935223113031098966734260211',
'391608689172052144375224632353',
'914734921994314705136911074748',
'988107834014045061318654080101',
'561488274090421297882480963505',
'218325342407295383230122116928',
'582658223761169309433095654125',
'273865680696911647672541027946',
'624559108491439303162692628670',
'591937803232279157408957153725',
'301764427854764043969026038530',
'397314888676470958186867565970',
'824114671537638671579485919505',
'639515848220512048493594215463',
'241588805030492814614590431330',
'819334407742370505134048937448',
'378339882651076993234762838273',
'507440393545933833002061437344',
'398131925870754763776652151788',
'378940443577273971071728137387',
'285832003214263287497473857262',
'357315410937866972755203238730',
'103322228383483317441462973465',
'103191378591374881329837449843',
'777006740805290474933039719258',
'532929677557076149927339850734',
'198593233860745529611308058634',
'569013738013511205792147668814',
'649318608108621857248263733530',
'810412666262081993722415613664',
'399818755790540635434849739403',
'521115399015231425661954699961',
'507486288792019004811176031943',
'504925628365577986241234647654',
'693988281395198400744804188906',
'607294779355529569702084111589',
'482926891590092850604715419405',
'732532855995263565809253529239',
'720452042859472542703032175633',
'729399856989998873722318050143',
'829105710382143259287339820131',
'995361585836360291796677499751',
'307791688785389294971574983105',
'140583631555589592833694217078',
'619080405601913755371733319376',
'508032959665076042419662851493',
'280776319469661673042975863430',
'988799613829983373253324652966',
'952330580961699132930834160740',
'656335250972351486454472574789',
'391380118012654312029232781026',
'527124464194075529648219457294',
'904211549793047202891301354170',
'657200444094863110171953883213',
'404079910663891132221632813582',
'437580304137346882361159549764',
'161373071689120110801180387714',
'960839355179468007373357811049',
'340228579561541554884345463969',
'877720813327535706167092152641',
'538770937033776804039237075264',
'216655382039443579354468844939',
'829181221009976341627537383306',
'856877492728274333809368323808',
'991572308418523016167893395544',
'732410617132796467523678910403',
'849616476300370704293337426829',
'793637731632117793328642785899',
'542772488381667397321117371202',
'886065298315972227361614288736',
'348850696920325245247179712923',
'563025755449620046786855432470',
'639323074875276509375404639718',
'657783325658629638099010479128',
'578679074494866494518146349314',
'560652345035258637280878364234',
'987113651820475784091452157059',
'666996534339858342585636464637',
'235811149461767502630754716201',
'797803943213937910415242674881',
'697172831559763429185387561516',
'104897674627740350436918749006',
'184646656621044327299237722177',
'312162723823178873489363869856',
'521341911569929477570254470415',
'279409254631974639561342010440',
'505111721519261889976700947847',
'436604849473018556375362272702',
'727534542154866399735015189731',
'460403170795074788790047354846',
'411300075066131536565005810308',
'510609319388084152977905457313',
'241850263226248580842033189454',
'147031803860110878333387239939',
'465252031081714968248924653907',
'348976208904368200877269535510',
'271954644044950320837132096088',
'306643746354181069425976591999',
'505191607518371355717799337847',
'127766248929625006090334539792',
'161346051337028007735055514034',
'610102359776587511962832878479',
'123503678370314261933255817285',
'360169718815522397558478552328',
'850185808109116875480515098597',
'851105582446675733852807825466',
'521280642149244472254928722266',
'884080971682944217797545985852',
'312056060860653845939895944190',
'435464256903299901364361428724',
'817586598343619957156785745347',
'186916343205053433382952513771',
'472257309595754026979288299470',
'823602127212255943678158843770',
'741504932585745231506531427417',
'489028011753195114909537473460',
'721970095953054571173445827579',
'620620482557892202666354360076',
'465375396822343705959200840108',
'923597079934911283965314184114',
'622812794398578854761270263311',
'209867996045311694793889452696',
'401047917554772313730126731909',
'933525923723225784032478144145',
'609942848873425970391469348153',
'645543961887675360439488113476',
'295124349115168222231308987978',
'228444708910747701746751359704',
'601842817284091939698618976433',
'347631398425681027409000138096',
'146723062130276139032830516643',
'172468608129166007890743288065',
'646388693731414639926652622432',
'215681265786722792365158839984',
'135479344553194975141851556042',
'381340085452223110829705130905',
'733091539047759379467075144386',
'776105601810023256957488413220',
'921440542785024572626853444396',
'666268746302466631143555976715',
'848093227563682523345696885981',
'363778081192135607049135722155',
'204563888306492690536433148027',
'462391242479065792907132230510',
'903920495950379433169740320569',
'991351861943077036506151375524',
'204610458452124844726827211821',
'197166542300771483517469639236',
'987362493125725182096335685136',
'276222793636387188880108080798',
'584310277787250011242027339146',
'363759101945445945259179625290',
'186992862945365415338013015250',
'190284749849042450985908270521',
'279783939865617400198933183974',
'461481816055690248057827614473',
'651829649175840997986509472734',
'536845640218737660081978972288',
'418312018527270793703593173489',
'235052202694129031487538836467',
'928203710809845164145339439378',
'398797933496459463017818949540',
'447362796053196081265163186150',
'377022426653871449185552167865',
'161426589035030654782999996865',
'718773087648088719194851165592',
'396480780189679759531414676260',
'771612541898036155816424030876',
'607992436644533661864060046395',
'339688864748946599057555787293',
'834663941820159529726653072762',
'733398547416420641636985968330',
'775895504048359318200950960309',
'539122823391978081503759831501',
'263214081594374661788153075015',
'187211607057273612088423778922',
'247168083166223828212072675319',
'359137529461379303479011632641',
'626093989786938987102363062268',
'244614085630038459008162862036',
'533367590146572118982028648814',
'593836881781454552136481517892',
'849688302022555613575655018565',
'497740811643061990548601752377',
'436431195244354169362178067916',
'791924461612752256028101211725',
'821282077120224012352518688520',
'305643135520915680089942446231',
'732402586529447850758382754664',
'797913806673244634520095920916',
'380730709946948842018409242993',
'698944296022686755930563474032',
'526738719428526583214822179991',
'382149860854792327378723440931',
'388212855619234278593442632125',
'960821978587519475296763679817',
'782325238455474185957618177384',
'132382671756074545396739789021',
'911621546100998819301923958533',
'368196836898947445202852032400',
'177206283245882926148324991421',
'945240076319410858403468430697',
'415806231442465410283098622022',
'572214901090441496816646132517',
'858809412167989663799759643956',
'742929994616356005726739088335',
'451779772933274315217586268339',
'464971398598783222996377825120',
'836325903560943730986364996779',
'145750832091743181082618590639',
'186491331818889716514926497545',
'938778069619510452557092384944',
'391503233862026440505874132365',
'867671859482135720460230794088',
'983119420013019534403870767238',
'783200227504530544732813556483',
'442191483441988403981756099884',
'621607838831133892060609986057',
'997524704531627098162688733578',
'120835760854567455284411312623',
'275312552481579588273785658677',
'782922173554310144352256848643',
'753258490055521427102593523068',
'120239616236344340044711034004',
'319790659616257758294845969069',
'239183665237219078286459669532',
'583587942663127651631246484700',
'576519730686290035176173793058',
'799096937853494683028919869735',
'942597713647790356337540788158',
'580081639611483030772573588315',
'341087646863937856020714150122',
'147827164228890303698037343676',
'626080886927279518286614368999',
'851869153215631024681511271837',
'966953175272332376678270053990',
'500842090452918251322762043399',
'535562058009663013761953422882',
'671230863067254498764269831083',
'464440162379675547029635388004',
'559792785903638929405363762319',
'258529162769162401438522690497',
'149510926740359237738239440330',
'903696259830512782351030059843',
'539344409472638044739303972584',
'482989100902797728820931543748',
'201109428162065792431032678013',
'370910000726068875829493096301',
'395921640506823953956955432287',
'380774634811586448784976953328',
'472494117946222973486005615023',
'955497993535880523479360979290',
'286811438164464808869572447914',
'629654803140270544838039224738',
'898695054662873027309342925310',
'350420041441451487394513258626',
'485577059097258271243882956635',
'829050537148818787895072091288',
'519883189717837728520064741091',
'376754650698267867271719681664',
'284411470628032357673880956301',
'323308078550115958219596164087',
'156937057456074682418680753927',
'478040696539080694515424527480',
'350106776371566467817704522232',
'216499227964822841080782161762',
'525459245931915704161898113704',
'286244712576374648209823642243',
'666269821059728120500136486037',
'134968664339262558531884122612',
'421345338762856077124523093240',
'736285948931876154735412356170',
'208133970835991224354784077912',
'977458761620413817476672774420',
'998246081731153569897122594488',
'173469002271811638498518538642',
'857603555842999267852047922416',
'404692082300695414272024945639',
'134705707349956233096424234117',
'238893934586727104899206462593',
'202324144824147973654505017442',
'753810883086982068232597588751',
'966301887166188911151565072574',
'195453544691051715251432761020',
'609237528519165400994134380608',
'960356733023752567926461548843',
'199597760174365887804742291775',
'397141674995953974674947087140',
'391291002418494102015575522659',
'427868486984050313793808580540',
'658367424451643227881907245778',
'543103117381378232276931619934',
'242693848455622878271144064511',
'869596683725024202583606947536',
'874565278754441990959926464469',
'212392606305585940418278145428',
'922130041775359297222200276403',
'725003528237388948988643961992',
'400163072303871689089113434068',
'634333579333292179572816745080',
'117226485981217544000382680026',
'720286304722438319330048988961',
'229475717092549834119168072428',
'970705826431144050676316695913',
'357406889092933424203609136684',
'761918293958532931046738212844',
'660802364809472196345341444627',
'987923186324538702199981999224',
'475548948388958152273770175258',
'142687947697990935790493011314',
'859449655059170510593163868602',
'274620503027282540748411869943',
'793167788204698068144605130764',
'860456033525036581669892858002',
'473954068535826042777903871629',
'173969084937298018751354744320',
'624009304062735629885142264254',
'715841703122425300317347613841',
'414905180045819392113310945401',
'290831098836062197456935630751',
'850128840002687189667055783103',
'177511986133093238541449416036',
'308914232589581398920300523267',
'160762614553352968345323545626',
'872118961236790601037729597754',
'576030488653971170115388029504',
'169181048034672393262809558612',
'502713216080036815246482027288',
'859972831499145685550473246463',
'159191044464024038392503916974',
'718096901417689382333397151170',
'639205015371780821595970062135',
'693896883052150782454213237712',
'778771430226277439290923313189',
'559986510258397262505941876817',
'710562131742894473281029687564',
'878917137450176783093116699934',
'766141094160579219489782561867',
'328799535560081370217912044942',
'447138253858378655137608646749',
'789020402748536936327628820775',
'947830307101236457505674448701',
'571944431528490190545164085388',
'925945918638884802437249232999',
'301451846080488960895365551398',
'228644003864319833700259624989',
'743377098302954933648334724495',
'521589895719164694005904573408',
'107034037526450165729698818405',
'548232196110720621143434360464',
'635906865772310483892912911842',
'153319326819263073653518630174',
'940409178865361581252130210549',
'855055957055579456543468338965',
'100220332934339106785029656778',
'903497341437595842666967468463',
'212253414934696079014874029873',
'640354179398460893907739753931',
'975172893450355263433193694502',
'122876052549640473819783350192',
'310853405625554892076212469125',
'894457881565328454933510474285',
'727088096905732328105392346865',
'319094786014236824749863767914',
'409081380510142729183121076991',
'850834976946222844182431539436',
'861129007847022858078876478620',
'827379695453289487226854330074',
'825059824492689580768900312500',
'231099193978315918273195649502',
'237466457659540624310904739661',
'815330547342946220676859398603',
'630078778020748930002076294334',
'368488056296248717667160840851',
'724253519033330035050008749360',
'946234437915282277246969038167',
'219884082491023175854019240245',
'288960810222972417925322318239',
'267142545539590593396335997324',
'344789928161192039513802358271',
'319747403253030094554619958535',
'590970197481466424839660896543',
'775311786685383026676333646102',
'414616642800550993952739338529',
'362047019666512253152029862005',
'915797770488562752048171986299',
'171052972609460986742124620321',
'309948897899032286371079457186',
'661422468286321995354812659684',
'624391158973113668745025161764',
'793380742754638842637982135320',
'347924400032179520511864368756',
'194589136996529077946316975063',
'982304841640774865592457894695',
'965887063019593138983823591206',
'778478073405225073435407234053',
'352447365676468232137600183645',
'612956989992038353681324918790',
'773176398247315984623817920987',
'910805154449545984109126333443',
'264829935007631920758530179370',
'893112530300224698979806219650',
'348589742351443720150057576141',
'705421171231394124184843329125',
'455774481372815634237962377849',
'146334728734485022573051349206',
'244562205950134979791902393610',
'601566697908357642355095831946',
'318645851177591930231291624550',
'857265680674335662989929664194',
'814444711320056905621406047272',
'108868802174634706705244370563',
'977136195155899605842133365428',
'662061017056196681188915297976',
'584190124016215992025523018355',
'463968213150230550874058346839',
'366647518000729530299382676651',
'113327838136345132640760469322',
'810584654715398690408495799350',
'201122445923178665881965250011',
'387795643819248824284005764557',
'349116794515566847536172914208',
'127496117504438046677610033584',
'311418693677564009218365977532',
'150713537887332033691334183306',
'899653029462338775857791441994',
'513980735626849704134594399316',
'151638137009364802006873046885',
'998664697294436057078219222168',
'473385545029179688582944637148',
'291591825329984452331544854917',
'184933755392470488652308950971',
'220935794883627889598995816108',
'886056198218920106609225829304',
'904546316358089241353878711755',
'814166679441939607040303974859',
'135201946222618742837233396279',
'916365165115797823548731498350',
'202964079189127821514817050163',
'472500537845083762563602761796',
'247210802877095613859393932205',
'259845537920798560431344814803',
'368573813448243319586396510769',
'314011547596750337676383687044',
'912921765389970143121428137071',
'620106429072224102071814699493',
'932316278479436362923183128292',
'320849086095631497535749237348',
'974901802374132108816945315483',
'372878032587324702840464785274',
'591434165545741702270291271874',
'397521119296577731675734446940',
'614385478509766343576952134565',
'137585450042493646985665489242',
'231263804191148231915521024423',
'823814891489670508017006095103',
'952594257199039559043692385725',
'118390536222921216721321548231',
'286781934606310446170652995722',
'138270652210631303706681884025',
'239878700397396794942176891823',
'492661232241062340857435925975',
'259588318125021783489521913933',
'833860864552246049820703715501',
'367983281692274710166517424409',
'314235226603726700898718058090',
'330617489044455396251521893092',
'766558237885366362935067774667',
'274239176624253322308250355984',
'245091548978626534078884818292',
'857206426482292762772029239573',
'990298628901927493350178327365',
'363603399593773558739474667944',
'420299421163619464815531723907',
'937292349000232844632929320298',
'755901840378530642565953496376',
'830334426285146996016772077647',
'921716337363946400611009991961',
'304495480798316905032161970423',
'805142392027534315294992765865',
'329025589883144432654818579929',
'637585350362161720269760724334',
'396478237679081548316570092490',
'485864291878435418280878417991',
'926855237562088676285643598145',
'450385844925121433076485329441',
'265605994639194987113812890661',
'630443623537767092634574442851',
'547665259404845933845735376473',
'650948287298749422077393279762',
'311576857908082211136805188515',
'834876002543069379031670048987',
'666065705438494646657019223822',
'542580321405497199804635353517',
'101196371803545193727264690644',
'105177720779525432474581848667',
'708245026864384856995657087483',
'735811379215090059773973178769',
'333086435828819116229805293147',
'390943343365116089337675839039',
'386072038003794036803347072296',
'535618469136150763062846654958',
'751756536744071027366868183706',
'663301717849683002733737812749',
'525337264520242756101736322984',
'245903545433885058998819596543',
'480754333103433251059776346965',
'534157469823012144395700696827',
'177850241700732704839283783914',
'500770057281454839459058889549',
'667587788730523939550377979559',
'202738532978887601822455193374',
'336589894094742879641929667955',
'705616811043387976951912952410',
'848221552090760526511884080069',
'618685989811919059056481382204',
'204959869149115130370176576213',
'162025452897425457355697530420',
'332221559175293216350245030192',
'760284321725934212139083257440',
'511790180160154304803620088336',
'227853180510745246559038014027',
'226968386967862143083670619986',
'499759929370013876176783569010',
'302565318532839456017505339974',
'697793124001066957174855176996',
'631990333292180248505299584746',
'673351483780910450955593661854',
'883402568565381875843648943424',
'554185270547484378157778657339',
'323866326812393171051703171387',
'827203327951976187843098989465',
'674421141692084116941782933357',
'496496926900397785920182611773',
'628995395650035225942532247907',
'692523183541173499240381765573',
'962688298873441350216220787264',
'671357611469391863427169998447',
'468506222854058372999288524116',
'316347558000334955269490239918',
'317802323649147649138258375839',
'455634983309729024924431391821',
'836927066159917830411664792174',
'936324690771475067102895172264',
'572812359827127124129897992227',
'208728563424087471508526953833',
'943600253866652328338613889744',
'257793185556658390795093951751',
'297602139427040342923550515820',
'180867912873866767647447261449',
'211561976679845766361711660449',
'356183807026881127079382253651',
'276930407526581564117301630824',
'502833942996327517140392430218',
'875105799569011998436921987179',
'193576322957424119183426236839',
'704391138980887249100711570587',
'741768495550797258363577348757',
'779272741218980025470327322895',
'557549487826740234220172574942',
'542427852688927543368029742288',
'511266509693457359518282110356',
'883239479446243633357184446250',
'737944346355475183235928783972',
'714535291282165786139249749012',
'790165377520443225441579458073',
'751002602001198647669713641226',
'193807187526169312287660671485',
'852125600012826405194782592987',
'121005187967890032352365821995',
'325544102479020793414596260634',
'522064451622112164892724316995',
'361405649944497295291249853994',
'658529089540231090279238468124',
'370233297005866690539686379742',
'843694476915668335199576836618',
'316523636890427377513625425310',
'347325739286478224386646348804',
'771448605719887291939989667452',
'116359437087092449131188742198',
'486062281805648829538237434956',
'134177209302999026555077040096',
'840330582863136826097380491650',
'187742250821826258405615627272',
'874875521886584112038871192184',
'925847866415594373360598747611',
'705890388271162449741549611909',
'414381956237218214645270089247',
'923214235491622282341139888720',
'118006864661817545987113413349',
'160839009689331452282160133497',
'552896733973950435318226550028',
'714550102171570333497054411677',
'647334467522297947787351527061',
'711258404731528394012726289850',
'470578718646375024135534575272',
'343558564170245800469992129738',
'636853148015294612408926885688',
'335425737324654250259077788198',
'947286355247374546279757527068',
'651256046212035321116884281302',
'890713573071513040838381226403',
'534734521989121920979359292643',
'262876512743512080166406478579',
'381832752437112472946202117802',
'582383708604822548571123963029',
'495186698403768636925879581747',
'250191421400286972554455934633',
'425480464228257831146321488044',
'549251344107185102327377851699',
'588347131754436554081534077180',
'848400021320827380704006268136',
'765639246075386388566641736887',
'203116047789950095584826442080',
'284043627070021342283833040871',
'401100427747527963319816151571',
'939045801111492342671471466003',
'300335627604627171883416238257',
'643797766254341992401433130380',
'704659774310412209643852039515',
'824905077460773709733324891004',
'971827057667862253758103353778',
'551153466357554976096589911320',
'522556313951236051378333113589',
'624381973821063362328135379991',
'739366480003241070323631158617',
'257988490455026829842600132977',
'286692129356415307941785436702',
'499708294528066804184605611961',
'502099770052545987749494380225',
'304200759423212358450008546519',
'322776315382266805881640960683',
'518140692444236499828836751687',
'600808984480389255188297952254',
'716401329502536496200194647394',
'817182875801462631456663065352',
'792409759160264016216818479704',
'592967821798366182389297790544',
'607709304351432152871038216356',
'773765010080376117669086582032',
'754041975053444131672742412584',
'153069609133293619103313356287',
'995711439694898480963527595872',
'566131307107261170926937315993',
'298718020909585297921638846433',
'966852086302096198667189221684',
'808778702777733904876126478225',
'878402371423690323369176380297',
'628559148592098553908709184551',
'927257253331436652217079335228',
'815170554394970139934182392106',
'627160112501156873793459721887',
'843487053064084789436183282105',
'539207601111250695079145970125',
'513666648871399330419947729446',
'406434865180996723063816624091',
'299014238117672727109272691479',
'904671612409876401782633244985',
'340143345210216256992129033315',
'215598493930990410655380111982',
'621216461060829381017059445890',
'968478227732067435344582259185',
'890064122495393657643850392421',
'839827581721718297342811162140',
'565597973178319948364928947325',
'718393380363964402993262143008',
'651146988052895439216529994389',
'998746522010036224335554039030',
'315644947135497732482551437840',
'229837633699780561524017036063',
'957515870705838768617878369150',
'658807230110921007834398777529',
'489106178671065741603434282985',
'209440900455794478392833655624',
'291366588206052649823578292199',
'962532381196522539674985051122',
'895812692785155716828764622819',
'663263475456887639517230044113',
'194118628115929978479785496071',
'152042532993361308232361850893',
'321905291017010284152180258607',
'983882320906977184839235263830',
'341347342969578107581684390025',
'253970186046759836384656534848',
'100577801571143277607709669585',
'772147962675387673638979373440',
'807094315154716267799911258875',
'549287281691631702499734171383',
'116350792706868809451035084809',
'865195852804696198318563893867',
'666994295229448613376591458206',
'145217718311332055500405336747',
'929537947779986069267838850387',
'149071781117187219326959098012',
'970808584227097344617422897339',
'764395866964119372402836436836',
'247394613837168524533296873700',
'335730110038853895745124786559',
'249946778410893776996282475319',
'281055370341366526919669464523',
'209500309126693037398425061467',
'306577239207154426723713885175',
'204667794329617295097889512502',
'196288854867844448978826323257',
'835671535977782626486215982482',
'690706003917751142792182912822',
'703074817168027737714384435480',
'204762313573341749412459830733',
'894046803194130244222851014496',
'995377311838489278882722513124',
'154615346864254362983161565844',
'419861915944791771786994547177',
'972525053941848346586506183897',
'833578713966854319980525083813',
'894236204949325688070340883385',
'915424163087272532716261972194',
'137162325232421315206172749448',
'415999636779779177746658210541',
'693536584870641903027730792068',
'907139792993723905440438876212',
'957499129928479822328337911883',
'867917382917324117765548249345',
'659930910970917612000479411460',
'897762881624273366869612967278',
'138720577147674966661889965478',
'866243180652142491680174356637',
'930458670725056565160901352016',
'722943891809437891903361123423',
'553334545122386428241089639018',
'183238273018749233508424298341',
'172346270293325073010932572139',
'366595440194963851334444063665',
'901141760840213788315622746236',
'220119021036551387190351953933',
'567087456241522962824088558645',
'879274439963496889129167713719',
'734413719161078919677813370132',
'513161501443590076074419590068',
'641671887262857809736516650475',
'922265137312217765238274329381',
'150955371036675869802600218656',
'971549429507780680252102095699',
'778431036138491571139702322242',
'641952261484434470832312314555',
'618064052263378628190963539017',
'722036458223196010444750098014',
'865450852902898033656434740343',
'500509507940170900522696143855',
'279699996413935052708471269128',
'793653881832475872815463976924',
'758375074791693968368037217552',
'754909192056090177161865277866',
'861966450690637646550703947472',
'264815455366420693884176343064',
'884264096736350019276278716000',
'995874811563616716774278614726',
'740878228102035214584486791630',
'487331809758666472075109663727',
'646889738355427560054888276908',
'701464106726688291464677256053',
'124932096564980202659370754906',
'495635680367227200935989554065',
'840919513711815175839580187811',
'730215757616190320947707071698',
'401658291445875645737134056022',
'231024385635038812279957974407',
'417841563645895048788821831679',
'957826546803764655491957258926',
'631379934552348740724328539157',
'329034447083373104941527151177',
'510764704810313597370094872768',
'441194844469788868167627150232',
'225307500966670726153285943850',
'951687281358474236799701192986',
'857564880551172154939279335282',
'843388800322319514856413827703',
'865492123732923705519387075850',
'155548811595312735777856143390',
'404022250864429556582430252517',
'406554696835352684524124610943',
'747580269592393547120228658294',
'178364578676544674771937186200',
'736363701383252752588984016360',
'694641662892922065964400453828',
'619545392907254747436713356958',
'816647994462955615097698555257',
'172862563024495520393461036979',
'796536490537556667863209754355',
'670226706393910474885573080023',
'984908408714045378986061383474',
'419639440155252699867878988043',
'384293079899370046853858391010',
'859213991346033181949398861579',
'760815972759560327586934722703',
'451064810666751066378767616659',
'574252141055415203096250981591',
'586724027293566963415228881152',
'421761704916833612065664288463',
'413475083230969409105505217545',
'992288639951781577664076116892',
'275686410034951204121230473604',
'897434390945186895461615445697',
'328873910508762172648778137627',
'872922854370530231943782520604',
'576493603259939250728731872590',
'696636237466772968426356293002',
'980334933019657184770030177942',
'778067691464293772396377351643',
'422365675509834118557338046668',
'964798122829989291917728266689',
'483920213420640649581723469904',
'538756693889638126049969915137',
'184893184067089027935601946951',
'475142629795165376568145281717',
'788404823034955895312370061441',
'846504557971694252541470435543',
'950310233529613687410372547950',
'523775557913222964077311785154',
'337394262505893608742463764557',
'607286150697714949130585426204',
'130006748614365707500958224153',
'724668599884892197348218828046',
'694970292963664946990098999229',
'637522849591068378681714983777',
'374413912238634561186543460780',
'284678735776980050324192417325',
'474464164561176574728143973020',
'742033822724383018971342051728',
'862468225854950882271636821299',
'100534170675515216641228748599',
'693142640648842137907367136782',
'543938678525147044054448613006',
'143772579794548024608270590256',
'219008498493995739252744316613',
'349446154963718833236373763275',
'234018343180710561118765863135',
'292077070232442506346225212533',
'232245286978416084467757647116',
'760677121054592270576792630394',
'135824267977354830666160654306',
'854726658272576540701539799920',
'532258738568189885616373117375',
'922933090651259627159305939868',
'550641255325054419175720137793',
'649887783079614035324956676035',
'104224884871346682020521857858',
'144950356587691174954792014215',
'760980505685796127208831828676',
'559608336134048994211054473121',
'557155816750365421344532061184',
'125296953617137335677260845276',
'940747691432275433773168294390',
'364581250799835742244911576714',
'839489231536220239109604362220',
'513694362248812352672384041437',
'448030890686972618088134025381',
'748326084075191933646670178984',
'881011032544567633810526051202',
'367853521442583973104811947755',
'707827570753579190342166838039',
'820730501141148410621655787424',
'885792282371731518971751666454',
'767458066523450683450818024603',
'242683314709624091461367288685',
'926171135880533344490831194941',
'980474174721122859496941917417',
'296964245771451446344130325778',
'639809141389582604606705983861',
'612044971906598945182597022236',
'748094056151615408889555698518',
'249349123907436709694038763641',
'103078914191511448867482736481',
'872444480643490459053830286154',
'661006039537259257898523312484',
'861562318899875359337224151115',
'385204630997290213146509691191',
'350482796463264401689625555120',
'671791738608495143646700820814',
'673614674747816686640704986984',
'206711917403412571037788963658',
'291929588048091469833269572233',
'895302102906722386128584590040',
'418454764332210943817790999779',
'360497847433636443709266219798',
'539316860628264509430821485589',
'946353587667210591392858378936',
'142391187841060567454066731167',
'563040547752717015624659354657',
'139227520610770864538407377454',
'591037110702339561072782816534',
'605026762977059877961706690000',
'399734751404083313829149646300',
'363882952988978691904630892203',
'506484112395131484431665967957',
'980752179405936213829068844144',
'613483725863922132192846712117',
'911758833658616949424892419633',
'149942281004868659711323667246',
'364761993990063100672147585796',
'126914077726554925360595237328',
'214940469237583214755996135830',
'533119485250751310440534047395',
'968401801304333227208343441294',
'533243262786127796133526689799',
'239654656294312590204387789796',
'462100972118761076837515622126',
'783727738490069553019565397135',
'938473308392749281770480761205',
'349803891602495569991399476760',
'473924085426088257736137192194',
'478909417480560252765088285135',
'595985057835057868182839188431',
'472468758605315359972518361576',
'933596655814890521339658323726',
'293661381808389077042309442414',
'948518478434998971439667912486',
'312539792733082301700417025084',
'486838037422380454431466222623',
'549474218813246872477180490925',
'511048572493041317845327765973',
'887846862141346231128660220478',
'622692262310510699355517573840',
'379862488930532193856359675383',
'996493007158161121533333042249',
'361627234011521635229657469010',
'674397129542289290618523892837',
'911453860537827234216212281360',
'724877822703739028512407725299',
'974519259616974268900164528578',
'126178187717128928494301233947',
'358445382666512265409013180271',
'381771999827754648213912111197',
'962652611423460461728299328389',
'272419754343821217738496616870',
'474416713971145070907684614408',
'370640528460528533728269620312',
'622306707924652563750859232094',
'909484151202271356607451382054',
'500928315707797918045660659664',
'825999658970019659853233091226',
'993928563825116933805520444010',
'425874457897184905798735072393',
'507020471125791984431590465730',
'713155668735224450257897076213',
'172554571549358263122405286583',
'859571464384443421950379274681',
'136611087509937977190182892598',
'158069941960681213150251577779',
'569339872140751658439867511996',
'944427815516139964059618153469',
'309588491006836704190489911310',
'893221379696077326355534284161',
'570472486675763636157400684327',
'633563173364947997085319991450',
'879555736304011044867824626412',
'172939756963867690617576817269',
'956586689600456310319211414153',
'804060441970272273583396595271',
'699053119302025056706727312912',
'340718435901373096031053636693',
'894689863572451299661928821134',
'347667122671996241154977058415',
'584288671780681100880099532862',
'918416658672448880978238647666',
'936955728289467510388090399657',
'107056435294278649302649999907',
'179652645890699859473839511596',
'393788316231758718810804651120',
'386597580592754863646906125592',
'793373739079639904328266162391',
'841050921000340072009408413136',
'989403635275820053113721952867',
'877879811528910280112566066835',
'896816127289042427930193734132',
'842264357516450207642478093467',
'539195653600687996168785493599',
'123719448219975421122507765460',
'806985264879315019270244251899',
'790243469489412443854425560544',
'620086676643817827497656560531',
'971778642282913736868477123504',
'564982451096279092173840167363',
'760402524107479692808687773725',
'578506799098936877126110857051',
'150437897446612883726887759027',
'105998541783430149775727421996',
'341169051686499038639076947780',
'325717725917936111984472378531',
'428850463548334801644760142611',
'781934259287694496226330372888',
'641603812893894032991425920812',
'460162766824820347683925736161',
'631658251519889571494449361215',
'106313597029940800941244566862',
'143766724899934970018595299202',
'391824866657135174557286294510',
'514344743662888488245427616345',
'498236900314623193920446770389',
'658566791312980230099908864549',
'774413125009153563719772422741',
'974058737215347381495971327730',
'115547909237929401131526423185',
'362374148090710199372745233601',
'296344961754315371982171070976',
'146166947843577991288875382034',
'559361037648741383840761591895',
'337906068720819194338458880900',
'755383815075088642939124118666',
'608935689207038204728415237322',
'650873581611025512200814967282',
'669446411836541735059675251548',
'686025075309339686656854131315',
'705660186943441435870126787055',
'831771288851073118631400067125',
'917930149334096644093183392393',
'803967673029459553111654434083',
'174229302957923731595562410787',
'512864142938963408838813695180',
'587043025668170431297006712964',
'105966207655060122010572559956',
'415151721722852086676812639472',
'577764885427692370763595269264',
'172483059627083774343067062887',
'326791570039359335636640296972',
'267109783594255580984596465109',
'325244230737158219509151431219',
'849107388237132972995362983512',
'386991473440395143982719361695',
'746076745349172197678530877598',
'553111273147085833689674789473',
'552819402688825916418006810211',
'157748850517924426899850886999',
'412764619425990567039801182258',
'961659183917562473494459011036',
'623465542112562270745464051030',
'797007009732575894057640171666',
'845504576701651037571518682757',
'384661109701067959505387348697',
'489102480890082844756404145540',
'646185893722997168630752874927',
'786711251777832385467421772646',
'807983339325875495848134794528',
'257559210307024768770677911019',
'217727866827517523068275084552',
'370236943553127951725353812159',
'183384172971793360613805735101',
'342605659330923901335786454675',
'475103916949623740297351261043',
'850696992238518008247881370082',
'930854410439584948970271818737',
'855875423348056979039594049254',
'781775404884850000971899823328',
'486425450511742849161253967289',
'903187182222459725630568023537',
'986701021008430114486107164949',
'829342926358976880996692482638',
'248710398284448642163220955204',
'530961414714936881613478575611',
'205192444848556606118383536255',
'143849145422159716613609527480',
'812510504855133947145156762890',
'901329317454534155025332852100',
'105762928367379336160427873487',
'867399440270443172632591015663',
'693090092679740397633420556506',
'295127236909057209723577124316',
'414530052902327868169791842151',
'456283219795637206738792028109',
'473948565914623527289770329317',
'571182395930431349372481163743',
'741384297678511475412489489383',
'547437331511560248021682536846',
'601029439584413947022047611401',
'141456804502773017335161994970',
'804971335540223761372826025679',
'900776842172732072208056968859',
'514200527148394531841766479590',
'714182387769241535668690528719',
'664291080618094717113451381947',
'353277647892797831307478582609',
'668288236409569928042427630667',
'511915073754146747462866852807',
'418671428605695172124547745928',
'720589073744056273943898474404',
'870549806541964157558370540246',
'733213962010965750598115223433',
'804723902486023484913008493767',
'635588700142548055772503278144',
'451360942320752641636110496240',
'555784242121390136714575451892',
'271372915496730440232432560081',
'729809658270495618918999983655',
'592045275436225256786514120238',
'971935810472742044360668190797',
'392036365292116606833586438652',
'409682636946612020267358853098',
'979631128305637984464172542029',
'301492052036479146650234465688',
'807760491569517748175143911419',
'181215571049356404279259380816',
'296468830963655406162038974250',
'668846076938046394259648288760',
'484858300659231939965409011950',
'751956015520921340473375921906',
'569955115408011973789449251450',
'994891651048443821810136714749',
'808740776103721566121219549851',
'223000882888096375266300193461',
'494245081726415726799084473311',
'635270172577018771213002267299',
'944084059346218273815654678016',
'992007356549812847085112158057',
'516365901528342820950934265261',
'531128021720266397521259521909',
'267538698912785436113637524640',
'596591062615863536490145364372',
'989631502797145268870808553866',
'817205098464788567060647331605',
'559165794086134690334133380443',
'688677512547438153739807358375',
'768834656263243884993875624730',
'602960877177412849594376458265',
'564392838324946131001405839456',
'976030916700673876561940835451',
'541306142935114554619998034202',
'582142997171455569444164398089',
'973097776106132236046192738317',
'897646029600441669723672353951',
'564365768682563403132226564984',
'476000097441090095004503588606',
'812437813109526368707419381218',
'260532951119297799997677123482',
'522610877016172077744495849052',
'468185802336260108290080084558',
'232136804146929831831369523405',
'824399237144122749976372477930',
'945313403270811712092786993546',
'722724505639192280034063675398',
'953220729199211323374267118661',
'280382158012538429121932016381',
'672548300945815051181317358498',
'603789177397435409390892641382',
'500412661209086278121463511695',
'693936228351224587853575479089',
'953351055838525441240063788603',
'218123308326940987029983821157',
'953459767757051993698393155391',
'389666496294758123940400098481',
'460504429316691894511973586542',
'247661429114873675508741475225',
'672612193162731354862800613082',
'939529103735122843445507626794',
'944901287950788366440789344173',
'610151152281744863837139442223',
'601108484683871601004016026208',
'587135878776959327292480287450',
'219172848086552133544893422230',
'356455551971549412885261034245',
'200642203987833822292778131347',
'687113325031250784095847490591',
'211599209299414811219161877049',
'794123851607825760609875067911',
'320809965630188113075061547550',
'565444443635213762113601412584',
'148028177279829337669131015899',
'214150244754385454598317240445',
'972117574766160387400224913208',
'101775587719038562998854568520',
'845784043669569623247364292336',
'589593371499566619005095759334',
'775192990056119640650899537505',
'701660243437635797551854516741',
'731468886295669160112116597522',
'116943464325196445224752890463',
'426436621506582317610948088547',
'303070792495413262580393234221',
'247477982562027287511156276998',
'442949415690425285274184229701',
'555743794292355492469310482484',
'868511813868550333791151945133',
'854652233665345149501928859666',
'368137559059774004356335885953',
'674219048308350271158162075931',
'509897694684081138289316410097',
'713933539309522010485770260463',
'847649538547097744433686567082',
'291888570326869943571304292166',
'635328074382950809347196621319',
'615936549590522185304917242254',
'893302372840362726249946969775',
'523235504806198761632542086757',
'690590436372410492283763741768',
'976217308722164868204794669949',
'688571919118256672773722455428',
'528890507918518084363224343931',
'863012970308514916491458982073',
'405459508771842473037021677468',
'830642416538631371372066965809',
'608126247199017047183504095516',
'119733693002294445532306999602',
'591718215399369181108349490259',
'976486901283321758220919262194',
'683817986015132848854689389015',
'418705031999866541999395252189',
'532529478686570493662460482386',
'123877553675076493506972832266',
'368047030538927030730157621446',
'608053692540120830184987958543',
'164099670750150493603361037744',
'149701047865376137545756724629',
'794732048556027718044824590999',
'589091583988538506615676473760',
'560238692795780963724466375348',
'679392421503690109904733490138',
'480016004279183484423828890449',
'133990807748134539594623852330',
'385725748354724314866532781832',
'231859120239637258466484467812',
'212397046672026189558577571694',
'444533755026816459805129350945',
'293355887144469193642794365959',
'938018159165486538285615249819',
'906556709228417844845568915828',
'578646224265536002802104221694',
'958311822875947307999512567835',
'137195435844931061014925328234',
'582002401399515814980301435895',
'871971110827583713609608593920',
'443628922452571344320363785050',
'603369639471954825788422019144',
'601876297123396451302974648778',
'571144473592384123676483780652',
'597901474720670073804613984392',
'757792301077221931240748061784',
'948356287263620691203568835745',
'624197728368198607558268319697',
'582945479706448756121356960204',
'187675796930497927087727447999',
'362150157242296921188708548940',
'880936812883333009239373045984',
'866095292147067059015822795162',
'417006489887175304646607298858',
'114190929832820580808241823057',
'600847402040736069411294150261',
'699981113482246553194139457468',
'296067815204919316017580115146',
'515989245443315673667705713148',
'292304624799341147768004329308',
'949918869551742340856534317795',
'485645184854585784986612335126',
'693787868191752822924555920338',
'884780081000367474551302091594',
'255502416310650153567444566571',
'373914678635576484663093682497',
'302737515279964872990451720505',
'315539535090859264296112536887',
'857719532091596980127102289285',
'424362975342290309938368989364',
'182726291892115984341403843767',
'584266495615030328859202067479',
'715842346814222589085374475728',
'682736120648224698307666852022',
'224858955575240240606754366332',
'262759045868879039288305098612',
'136625148501278801192440225455',
'865634328835130023461632092988',
'122969076684512079818701632801',
'493314041378142735606444778363',
'942692936987450128391556334984',
'828558247643055608378284029371',
'496637885526462523956280845116',
'341217637758938849952590577378',
'257833878212940877986205881012',
'723723420762369999143654712737',
'966477132140535607713147066061',
'875433626596472900805152313202',
'303359179950990360746960769764',
'780207926270058890504694611879',
'959772880814854532041485631228',
'692411956686045909031441698786',
'789192166472290968803642025536',
'517442460723585550361089581788',
'182205236342189892319650436831',
'409407160098639646858030823228',
'515277394418059453475316545369',
'202094445606416510496411664436',
'793471178384128740521147450339',
'241784938975652469563149351579',
'847325756369244097322089312624',
'609228319378291345479102873781',
'453077959396828242462432883989',
'898816211041682471470278737276',
'188896022855885240938371550897',
'718792074191291359301596338849',
'490589246478530844543555972985',
'960115088333815694458927561048',
'730643242160671462805176081776',
'562808916599090290996233256896',
'440918893811254095373980786646',
'837470824515516645358651737359',
'944263730823537907238252268747',
'160588463291472498828995149787',
'987175157296946854561340848501',
'610657154240733224994859671668',
'643137475464952258142996544642',
'785083814395476419316590732094',
'164363482009788316548166837535',
'294155643055511778517629476249',
'215740736993995815526490631804',
'807465248955277455702128388473',
'550663081742960783412132182593',
'953709464484360996533834686168',
'531283348084858679583027758260',
'892636042867816199680669494443',
'300837465156510833264473052278',
'330620296737723633385155789486',
'459277891501174328814368913575',
'222313490672284171271747159754',
'616710826393017988504385041855',
'628363500013188566776927956713',
'560932241378268972826261034431',
'144384258346579866706591314714',
'441497600993187865475504349042',
'819815448367938425240447573529',
'207598291298592828847173051490',
'222067420203013523015615550805',
'225022724727782714923504355330',
'947679786251071524458699563191',
'832027060473271589911661893075',
'405893174286752710272090649896',
'412176750797259158440289540491',
'626072246789970515705046119527',
'457212447264493513145665217093',
'932086466693613610342256785233',
'114165730048397255252619998219',
'757851938561398446110584445111',
'291295031271494131831881515192',
'467170801541912780402166167713',
'829581997275652967917298468458',
'273812054056281979314722315591',
'426634744133552380100487741391',
'509362132119918504570696453980',
'439790967826669872643839960135',
'401161228187176755182854845457',
'690060197452938101469476977633',
'864991553936469228576697954114',
'211055685559011722774270642064',
'382259631470690285819619215110',
'197231856531041455899772681660',
'208429736909544124703344692249',
'998577828791973522940464535078',
'431449811005916195073075711446',
'291675701921610958121918817981',
'581251689870123045720863745713',
'650128325503051886596558351077',
'290698935753338868737778337756',
'573356475831837748022342790374',
'562910936408319368489764055322',
'911565136157730757109987928056',
'646797647406520226775378392261',
'977357982909616884695685665340',
'243947720496012880071000372159',
'268486898219564231433461980475',
'522802629625543452939679475958',
'157937004593410520045784964346',
'399190443370380987174916852743',
'104346123318816317380814027829',
'620002061795054449163259028994',
'898296707175888887394020025466',
'841345243571473278202795283716',
'308402937548420836005573224750',
'665137813717953086487971955753',
'784923995013485755669687920269',
'366072705576497801356897267196',
'179052847424142423367434414162',
'399063577694852425135322661758',
'681749125057391140402984349543',
'312919309720238397297619767146',
'421851064485029198330873729174',
'689405436341258349726172237459',
'726388576342224025546311698221',
'748308640264297519605149662151',
'252847883886398561344016972927',
'779298047537843208432209083944',
'169877024158659336013686659329',
'863632836922751618769543654338',
'651593503215771829841592068435',
'143118320115048762684804113990',
'355148358107331085662842963917',
'771669074821465368811667992016',
'602641475085382671523831220641',
'125060903500399345492968845985',
'289723970382061269677965060049',
'384209827472410908057561264294',
'560269775670244284207650173774',
'254213934306318349705235373036',
'537948457203881432164374119414',
'467061368268530326751739781270',
'472481241869012329285854689988',
'926033216876771525558455980086',
'587100363406329561619625510278',
'543152330227836480700604064267',
'790411124848164152302306171469',
'604565155886250208771052090731',
'894701972947342716335043415150',
'981961025697970000478736410626',
'166314894084219032676350580749',
'510579259337065074314811918145',
'113778195852076471930699349255',
'334190456128543745971091725136',
'211020865327447738797675089543',
'344666526078656310551623182971',
'851602845591257370825830186370',
'231435026634468685087247434172',
'699855208377992580685017487220',
'978407984897767056503647611199',
'133163930044312801730550216732',
'874911562582676915567047490512',
'882300531652172331255475266654',
'542795186950142726014731645415',
'949686666956371163817797866036',
'957758909905510321236319740690',
'592390374239530508596238937130',
'108178518597120586699565822019',
'728824071213843194857601820985',
'341481318069759737200773079708',
'463248861688479173118730590514',
'395451742988288376732397841557',
'661177312276595930093891196277',
'883369953551690402072861317573',
'829333689250537209824333347175',
'249202795704647769506166231470',
'526242257490278707266163636278',
'672563713062074634757294339902',
'608757772585519405690337512007',
'256812359501842975736286746640',
'125022791403456740525751658359',
'588202380702642090725092826282',
'642412854785578402019922120292',
'567145783648649094674589076646',
'149986586896970437896328630038',
'805349191656997202856800522615',
'306018667437540907231863624440',
'114052553126528919879517694205',
'561226093383864594225984456275',
'927239499872646768215660093568',
'582746754184149885206797267818',
'692198051273962537951312882084',
'449086193921193025671041192277',
'202042784279932501966375297906',
'874659343551441453914955895729',
'680251452412078700546617220135',
'590014781939512558532280211365',
'375595917706292526294770425367',
'563001480495538612310337517632',
'765766730350313089361322965724',
'288016683274695579540081995848',
'569069901922423200162339542063',
'137938998718286985199581314512',
'583864632736098312683620852623',
'321421970757439602449613817301',
'178495842875718230584911711835',
'795586991009973604629026421628',
'715438024418087472480423421966',
'664407750565311646944416614723',
'445386613333257685528135376303',
'452835616251240744479281171436',
'516044423655213121613998472453',
'260531788072516923282273552637',
'909502065309596088374293560875',
'545191996210496004900026350672',
'804436073374670712867856976198',
'183204133058022516058590085087',
'858073849214542092821388167863',
'604057322348810085301524623274',
'603912848680466853207985883308',
'239499372253695274273515827671',
'811718373410363122235115160925',
'555033404990398348638568182156',
'494201503468856500355114917023',
'969462523984846038827644919050',
'474717987360530209528263366539',
'972790397753157635695441956649',
'801707937141678116035468125803',
'684020238434545371557291947664',
'273198185404488186410764720849',
'589909310064749095724290466777',
'233709914646389893635084712927',
'739241735871163552261952045380',
'852816890340420139120964532749',
'270908490113025503690469337827',
'554161582268330339402750039744',
'627725098204597656277612990685',
'950548274753682686072857057790',
'506049709474942718859514384839',
'475845213112068481975142337534',
'881940792402953429567095469655',
'482063113686162328645374266026',
'324623326963741168981566748426',
'129741514322714954096591834482',
'760638516686846961985533269643',
'889135822300973733163734791367',
'558507768390323802312046638454',
'881761195730414057548269593634',
'156138311714749054408222797070',
'659533622257642026988928547398',
'519681224958866090500096639213',
'540788784887565296433749634158',
'828545645250114241080621381222',
'813786168217638959576992596996',
'582996412986211487035008733927',
'334919598573326668502601095619',
'801843837243137931961138967209',
'852589811689525311624040377341',
'416726812290534234843343580830',
'325195801102133358108828915897',
'638347293296228788297125672122',
'725336397655488690468843862250',
'765186678564756636723842858545',
'994538475080494451759090852454',
'249016304867677712986422581988',
'761962593972646266624669379489',
'686268274091994178828288635881',
'938787031365825391920671251727',
'336533081063556538920369141622',
'971118957610679392977113875558',
'867870356032363239311446993154',
'443034339906525564785028426432',
'705866700554097135717674799524',
'211552180803818940768563695590',
'310019477098583989869720899835',
'430608093159324945006955147705',
'620113951240682595099949575917',
'107824546805060961932678760844',
'489820307168861353607287974251',
'877795630023521517890738423404',
'515742107936316075015653769444',
'681712143823937642641637659870',
'563825322145050966875042055957',
'828211893137615572776003380609',
'377927808757827587644221191300',
'222476286281235360955877299654',
'118356244550056510055046482452',
'101999957339121665600200811167',
'324754741946078310345368826828',
'647349256466547903145973030403',
'297113805303479830853593289807',
'633861505812359607288031896288',
'229208237769090366356483232552',
'858832645153490104720473135225',
'196227554580753076163154044253',
'305362853617380897461515485266',
'278833497681378484893704261331',
'518367486739617985333434976967',
'210559847268387190077900056632',
'437570634954231131043032986982',
'651668553922081847957056211613',
'558705234050446647177753189831',
'466904326802660483218383982023',
'651868581684052336495778056659',
'290123129698017236026636959827',
'979900357664067926422479859995',
'730054479593561104848874364524',
'530784113401663058010518168103',
'830046386106686608602599960611',
'341102878305521148694987615046',
'158178327725513822776300552699',
'502364521665719987775504392567',
'440676083779239956784532814977',
'188157042378672709449326447837',
'861021570103955581516679740153',
'328131760792437462533470586447',
'436925043325952362546249451148',
'793464680337050990081668795550',
'695218294926926045265543422816',
'393322751891283573462429865549',
'649703199261657562691239617731',
'568454786969298446538946574866',
'351668242958910539517091129020',
'765337430471017494054137044618',
'167384704183593006877986152911',
'791342612690867203339887556507',
'423562775316913329857554993831',
'804296597344587461798926740971',
'176410444702271492818759624294',
'212307865070272958064858193639',
'485974854063527598653622912726',
'928814470855426548003929275038',
'782790967193786770622230566528',
'458714339881634842432598771624',
'206740507362161739133326543660',
'902863159181372081261808066451',
'810370891693661699963979649644',
'551386392349791573360733319580',
'293390574497793659048123330955',
'572061062908979994146660423508',
'493069137790180632669245063277',
'410899901257388608671353257250',
'161395544451033451102320961216',
'433222015226245981331089443737',
'232363187192274235911925333536',
'676789684247969847017493937273',
'629019176642978041272286784505',
'524176120733121215668074542472',
'388595240353910149433369284745',
'160440425329449914573401562099',
'827348773941782183887176024101',
'610020573418995389299449997718',
'500481120260548079605937724295',
'269124805578188742170092078835',
'836843048250439172417283696936',
'452408835717758976845366696115',
'841490512553527549935193011124',
'981380179670524386774571273751',
'512647293764453965328655690354',
'992606081091096498770594977245',
'929635125972479161599978511852',
'956677426701886939462826720726',
'458402972877664527651617969426',
'643696426576954713845814483439',
'133347238535920350334654157471',
'681609678174169633938767448007',
'141683539441677608135926675245',
'689429332075654360228108532528',
'274562472296463517982299130395',
'172589063388346418463661684590',
'625956732963878225411949988609',
'322377755993713638030249219356',
'805853417503617958268107263848',
'262671658715012062989174382731',
'377315194319510599576422921663',
'873653107405148627098956443236',
'681823475673359543571145512264',
'356450788868379665470580166626',
'914548522940630779218125640388',
'661002437717725005365141936562',
'444562902032881614729298374671',
'982173028218244993161988927382',
'594993162533055080446766069844',
'447539303280417655141125758857',
'407655216497053463789418711038',
'134971302579938677402862178895',
'869575750945641043721699317894',
'199532015922055347473721960977',
'572359654369752884564461673101',
'916445273482657688852978769434',
'847259930685936592873848091684',
'319804881327944625450188616504',
'308079615871537126336393359785',
'328003987837337251730702412828',
'492408113575331777516226572173',
'794692901025756073071544946744',
'501839298066719242735659651659',
'574971119760053623086326980514',
'707805865212299710149435237535',
'923474432635561565578040596754',
'781551423323157669213706187962',
'161338282016226640440120864721',
'257974440717133927010105484933',
'273673847081096806605008687409',
'219963297504283488397300793006',
'854042682442990482376988846487',
'277581573818191942959665720379',
'404054180213949914556867172198',
'652983122481580582200973589060',
'481895691437954379167854856948',
'740228150158117540271208568714',
'623533344143365228766799567290',
'795528196329828888391317186323',
'678338381710298559990542118945',
'287484185066524119610733719501',
'669388103365239366253568294983',
'724079412654124067588822056776',
'650661158479335099579588477136',
'241635459238322076619566359225',
'966343386720598566228177660158',
'120351923632937041950370617928',
'680943248708617630754864978600',
'253179064261512945925714036782',
'922991979051073081519202116607',
'404649323596512959282420092898',
'196366438859441518550185634236',
'897985892462072895103190812536',
'360470171742843604689997014130',
'770417979152052777988388172141',
'950976159798585875851780676219',
'781060351488040117271732980465',
'789012579839137649924907542767',
'715038720994467217706737820411',
'187351256862032126064076160483',
'109602826670147821414294039891',
'808071195615993581682540770931',
'969088837253187549793965413737',
'988661106187991670887440349014',
'828807116494492868583469662526',
'257081994132890994512116050425',
'726888338391053996224736993102',
'204091398399229855163962579078',
'912364733451946252907281028321',
'615634799999235307930528670336',
'954260550684812043371736883753',
'538004844443626720309090129272',
'227023437624749499434723885069',
'467453111421846203588317053004',
'999239689845855194246616025362',
'515588099040191587266905520454',
'796889450697947052051564065705',
'362166579677861223710525894684',
'117084518383733208402309926740',
'577319302742910268011007637586',
'394281587270254071097713788046',
'156969528301193044123939932041',
'290110559460252358147863344442',
'647858145897549324399824963424',
'940076994391789464567993021149',
'253402300415975030305587088510',
'889434794748120292140712768271',
'450046464536515809564341687092',
'975169599125960712220318413103',
'607536385236461711309311287896',
'574796364233521935248466148244',
'884776407784341361643489179656',
'999749447682540608225377546758',
'445380338736781616569502541611',
'366768403820296144003185367262',
'749396304355763671629218038502',
'322009738368121435961193281088',
'970714014707259011042666321016',
'103399513617146516762576822515',
'428643554901383235589522868014',
'553709781252793443507990567214',
'686146419521750740079616621725',
'942372016314116798765668899620',
'485460342944627215832593320480',
'893361132635565331535494547307',
'516411073416353148323024259166',
'207800854474789887607792913717',
'182865789286426041355968053918',
'509296886864332186416280484964',
'442322730337577495091940063491',
'798141948474758021280474385500',
'561509120724688677457644262792',
'649215885128245134214936073516',
'641506811206195463751788631660',
'226894241616357285357920387406',
'858430720736068171782936631881',
'691101580023881100715569640441',
'271101184733760239064102569453',
'840407167576358603473194159764',
'261028654202859512204753215727',
'871109561275481241932000260886',
'887921515451759604175372580356',
'269307337796748027683912634073',
'134553977786641631493942614797',
'556772042065014323239156757107',
'968187947945229849012127147927',
'258744014776850388682580736560',
'861201250978296806217417876795',
'575383466980551756435754722429',
'305261514405193355244517267570',
'936070867363825237386756489228',
'157628589680514294017732284228',
'384915402473540886354721983575',
'150842109037969786560421093470',
'233275876387639620864014926476',
'671700544584765460604277632947',
'741954516594478759633474641742',
'283483389767613347241065832714',
'200858624918716301886059351847',
'598666085293838540682977670466',
'313088109627168115754878715950',
'975266486607461046677778997621',
'222080404532596900944694133514',
'479725801307759244812756148649',
'408614413062689155286952171799',
'306688995785381296359995617491',
'507639625878075962293545015268',
'949180267416915529397946066312',
'308459287330867517007865537931',
'390752143480036918799928914708',
'908377285363591446496836633091',
'767784659776464831212866473151',
'830813623358543830445114774304',
'303043759060445896325343643078',
'727105563712057462827323056026',
'591674086707426299923873384186',
'325387035507188454721284533414',
'177591427108164628587248966157',
'603699570818694874263546265739',
'116317146772491852670493257797',
'381792970299656540054002322847',
'262123026522989193046626864473',
'474011780017744652020695831719',
'476920507097813521705751241727',
'578583968909343693696778466175',
'923109402430071175639685759544',
'465081665185131216496263959736',
'408145743886767152548899112061',
'992185519293237720420627093573',
'795240923197594813195779851367',
'397440675749532565024386364815',
'382996325055478299738497237807',
'247597648554982694947454580402',
'875442900445124378999272556447',
'535688875068980872877224318876',
'522727593840471281329382853206',
'868111340525317953532588055858',
'500988314767466983465861364644',
'262885578457776314575361992622',
'934155443888296543389267422904',
'461450447677630072954907099521',
'297144592362715956658256421456',
'878600363819360954001587199969',
'927110070323717550841294276308',
'740114698945822918211453278736',
'774759240697849647877278761751',
'636320115940489670909251668719',
'788031293736811783590253430890',
'105212178507560753554858042477',
'754828100268836564911873741033',
'845004573473879658918575096282',
'114349984045473513459090236863',
'949017335636739961745469398840',
'430244421008574665047076264242',
'574690643926346683777391755391',
'868968211267539130047664077499',
'242008077101286715523912980557',
'172519028386451762160843133590',
'678348590459654885331726036665',
'612598271621198661770058084627',
'975777469132642231061892675820',
'297877958551154079478828135340',
'635801905147318184506371022993',
'226957783791616278358955891195',
'345079361476343334572083812219',
'315208958055593888628735395481',
'538111096381625420856794197246',
'169043586740522811422685477989',
'310530018011863366605466615249',
'663958846094655475357200257345',
'267935259912195324714753060037',
'272616326977250785748368459085',
'181246385625214223356471746288',
'912251335392049573450556129366',
'868782802920435160807147486430',
'759640710877840923945495059887',
'318197356828271856172424025042',
'384004701509941386609566694311',
'710335026518735725864880226560',
'955317849920042463260592997683',
'549182524004756979819025138135',
'889749285276197900363322616444',
'975195039443011007981446733896',
'230486900512040330412105976897',
'222018713219923623925437153166',
'352962481954912755467545392804',
'639496305860119649660738556691',
'930919871377329291023406244822',
'545929841915375824317273151981',
'355887577475812304015522740940',
'887959526036818414351951044860',
'618078884692035339672908656325',
'419769598618657711607040644430',
'962617040416294959981360951976',
'213384198891589462720156347239',
'184041524285892131864741288814',
'424016685674239937597065849211',
'517994403019080503050687121401',
'825001500429881056834686558682',
'917981025846019713941768682660',
'391920859120236459181672627006',
'195636397348780103689532279638',
'544831300869985832606841186568',
'829354156211575860223962474650',
'651822606086026180082704323875',
'904315118566377173267199756333',
'310216622399784740974177666792',
'376949021706671862977768659675',
'825890685364222731363843360789',
'592183544345816989124044313226',
'377033758212959429300741620578',
'950247303560645176616285058224',
'904552096234895414486117590397',
'714081220657091742372738772249',
'224902052981255400844031671119',
'784247514347753210777380220010',
'545016659726175042747404086700',
'312639629392769449277167814374',
'779696070653657933968517819798',
'239041794825178854464456143112',
'981238527512218150668029437243',
'765239874107652366940000546020',
'889722011803895619104743871623',
'720239418958325676883219658825',
'661642200744033087623171120666',
'148716595722791931190048083266',
'487642192455567185819201738717',
'320515516447539580077459718261',
'205234782269989368895618523544',
'550274988747433422592496757804',
'614292576686968946256009989636',
'545068298081718146165632444393',
'444802035240846244771489859787',
'195661624745650752630106120937',
'825056402787076386632256918966',
'793950917137521266942365325248',
'554871783215333961216528439704',
'599732110898088751199024322645',
'164144448494241616914545779674',
'857313553865088564148392868245',
'152117665593577630317235553032',
'539784013042438803824421254570',
'404754813169894833240991170712',
'934578934718836250530158566620',
'401029680538424149559226851449',
'807952687268354793479367265473',
'284950232308222903340735440643',
'390737976759521680626603146611',
'121154449503737515933256880151',
'917440929991575713653710499948',
'713919280918982203994762358589',
'492115883913720058887905780406',
'196315548910630175120055078995',
'858329340133114423684679712511',
'726390741876213346673680814809',
'437180929508436197113536396550',
'933355340228441125167692140637',
'781730270305963555738504947330',
'427784363655042309611521337833',
'855898030687465130218865439835',
'841077968838268336994882647780',
'663983029074748403036204693221',
'915856212118655535583477444991',
'484650607069640624679929061933',
'720488482380335724938612853056',
'294610766402260278454476785073',
'416236701254457947827503786636',
'397945298523442252921519092621',
'322217613266820595921464582277',
'397063259500545124462424348567',
'704440900513298711261539539773',
'406187258214351291485993739294',
'984486579655292755031842133846',
'380080001685689762756824076899',
'216541149369929378566407332443',
'787140282552867859332810564362',
'308260723541156053667698650862',
'328759136986333664722323043183',
'957494206346860592337745355525',
'666779793294076725292890981729',
'659294937198897656480113074451',
'611604270393456488700638613432',
'548850896255569797654400965457',
'931723253236115145627307972190',
'857670770072098123103012959947',
'629535966349516657407884719844',
'613356291847916250864508625991',
'104981317305669331968591136273',
'311620992157387155696968283773',
'678737859305436659925997534434',
'873212465983519290022558535186',
'566185256566518067992246634286',
'426210088286026515785723410924',
'867177758545886432371284937015',
'264732329088956022066516540924',
'284658536416892764730109064709',
'837438652286826468779396916313',
'479660745608416160742631264971',
'662442062387596359791192911569',
'757170123582469338776904031850',
'794276348816466818898011132295',
'801774870718333273463626256186',
'787059107963670207126063651865',
'617639567408371592404936983939',
'532327928393662130042495719908',
'506675573238224271476881252057',
'612845883430818685596662694176',
'818400087249016161857750360057',
'640096145570462191340967242213',
'656149811000156853890318119982',
'691091652000744698818949939684',
'944204635144790274035804066617',
'360749278437861519712929161010',
'888069376740197711542562722899',
'302759655796367919802783526878',
'900471613237233964170938675707',
'233441151195090766186118983128',
'465519689820693684547941735108',
'966666500459843399521070738848',
'745754350978388167792272032861',
'199836222307483436470415338451',
'104262276701810412385210511592',
'326202295612797546540794118995',
'513852561498645294487501456172',
'692581970760955461840591994799',
'447509817412889200032258052581',
'122980786920757407328823712060',
'357864915800319307654702067586',
'775753836450712725563322020940',
'781044420347476369186460332829',
'117505330070362647805053159538',
'628314886212953871282515443838',
'134660740710923506985392772616',
'530865075335253066875808873542',
'411511427185056635055123349712',
'927108776547654855081115328191',
'980196731607162253384272069467',
'650330662198315762158083358902',
'278182508431349399972590075028',
'153253771977811028108173429629',
'852248422063416364952406324904',
'573184375978398492235472730246',
'780933156096229504705700115449',
'745469312112273912458481946513',
'271683126516647238747712813454',
'445592288853390065081835296398',
'262283941586981963405089728277',
'676872344754997191459247379838',
'592157480436754528881727149392',
'762313812463398083076050626734',
'176645318698576888500205679334',
'943008711741214500495659889186',
'511478340171750934522875731278',
'240578948521524985163030062622',
'566408008789178811648107853635',
'846291508430652626392307920081',
'957352442135812955107914168051',
'455646947443849919247702720445',
'197692730476888872761432673501',
'339094063375914009743877456762',
'390187911146421898471191212205',
'328233165043695991673440230188',
'471304746441228590728131819782',
'508985062484338041565612875862',
'392323967727583929495812761331',
'589641483054733870285262435871',
'639942310440591294372360263561',
'168889559749685556796144648782',
'158076034348169828937111578312',
'747547394243459444108948445029',
'587666151270946649140111636359',
'701421966710328590916969252301',
'859683147732146969333094729019',
'445570889466811800934081771878',
'504937892036647453374059116723',
'854103921731922549976101789210',
'148121875537470050524032393246',
'891216244915828838995727455471',
'737623298618758449767707064673',
'809578893484185110199794561973',
'650363538575483333756968462168',
'382584312313278234035707557513',
'268562629524619890950594427271',
'578627477060188115196125080333',
'594206942927678249720311230363',
'445536636840478406224294922293',
'402589959276669736591976537014',
'514146869038931242701478861555',
'645954146434450119562297281145',
'747806819649684802757948182788',
'456944199163011689780423862817',
'735216761208272874800984379481',
'979439627515868591447752531609',
'827985686605147609017255399711',
'906347909827658471900899573777',
'407702725956379542886066957929',
'558851813110341166744879127007',
'467003718534451469471379069024',
'492501624832021846288290452558',
'256798277427971480645218277553',
'924314406020073831218631270989',
'739829605065144775642345432057',
'881096668195219240128571127352',
'159955745159590673236681334834',
'328015294617224116667342730401',
'584812231494914667705294128037',
'229767785594096287293700362653',
'207934165408965348544364178703',
'435228407301978447103275144514',
'565948639712268314016391330194',
'583700004505388433110871265294',
'466766555505915060669723412171',
'779357796921074209012357743947',
'115571593779959303498078658817',
'264196061277442060052315229387',
'695129542548056982722570425281',
'337198661633859957428130870169',
'638785898487731139527118090328',
'777144601583674361883042493059',
'249022586716394727767125439020',
'691055629364044591128921823432',
'984242340945346109144323419511',
'846423572846410505812227617587',
'362745330438517252918920134016',
'481407863913513299012747552268',
'869797224742478961243845033342',
'262141691662968417081729464898',
'256502765348828408537116211243',
'861433659508250007302364831496',
'138521518630588855325755026118',
'874648434515174924078317833761',
'601140611078018494706007162305',
'348719023221627311415854872372',
'144199751625358699538338115486',
'351703655773149783219703597676',
'599813515589149844808898498367',
'207264807313948447609094463371',
'439802776811274684595573044252',
'928530203027264432652689556974',
'668526109857840001396740953279',
'956867584566573454953361175107',
'582516070656161183379808318783',
'620917066619492205370813085358',
'545961977638846315044424720020',
'875850964397655906890336637739',
'240390055265170805994633385448',
'946183185517272066286429788651',
'885045194050927703363002215343',
'974690934547410404451204048766',
'540724513987146797553042976859',
'992927325219422117984934667219',
'701685201673950841062471579191',
'955998833549510900573149124873',
'964281498426582783463873370278',
'875384400594828555981485540894',
'563794218745818637949768810934',
'650962177537325254180193998498',
'231365903435336080681164536442',
'351313358890153867250769467903',
'540545088055911789435762095008',
'393096430206761044296738426411',
'888085922862918925277574059368',
'784469118696477589009666040750',
'377484230753376451062595551771',
'225571771747937990902445940674',
'931302224731947700291033312942',
'571817950254187619794358762595',
'578412078792641923765653221763',
'831997053759230319448586867488',
'943791487838448593708166912797',
'327981272102946232657843413736',
'955924073316449914857696760685',
'353309883158892156887583572579',
'999799845382958731447022240520',
'241122559516730922772916574429',
'334410668860982767768718237258',
'401373148923164644449317031159',
'254302888000165403657817988490',
'599161620817617530708611755484',
'539698903616435598549507934978',
'360825521303159715970734442006',
'136447123912858502658738893797',
'537426919259578009451765012878',
'718285541752628215154207299872',
'562656748194151505922433075391',
'926027141456811901846496571275',
'871597126453065235802744552161',
'541350846727719188772471485854',
'171430288747017627856576522957',
'117614725619532554572968145996',
'291156644851971769107411339933',
'991221509709720632188353287448',
'477893787412539806261090976761',
'505011396022144278534873816957',
'384511559025251658702489451884',
'165888564688548223058399930815',
'281007114379442113113193446421',
'666425481244661702573228888417',
'105531612091251834277252066931',
'309299094910545770151498933526',
'618390754342864457387650967209',
'561648248913126165881046733207',
'388914078170161882712879775410',
'508603604576212263933002823227',
'835071934576866660665091526420',
'831953036074625805576959528799',
'345332489866352952497248643759',
'965690322564993193127331168905',
'996813731840834162428182094973',
'994516748814596075423182642837',
'648095787791593424976863385605',
'640182537510194860302136131448',
'178906324127276081256951152403',
'656852067427028741073909376237',
'239985790072357456086815417181',
'722626239039275301159110134580',
'984825788471678645356754715350',
'587153327908685201371877244800',
'737257477276423075420226857978',
'633092226109238576063159039486',
'580357599752782686466218176141',
'293468839921231681985145042290',
'913309182510394251114727843220',
'634071945835733998990302689930',
'563513912225960319446442777000',
'973073318756646596735077751911',
'713037313301499334735461984967',
'211346882828262037248488039205',
'981518136985052055080457819969',
'492065783515831412115784549250',
'582690090288165663583796532809',
'499511055385177172134984719030',
'196357372833440474876228020820',
'629700892969235094745221957893',
'682616952557163697303860356803',
'710890148005787306630346762962',
'785586163141039202852172446065',
'109894183691284642851299482699',
'277758099460099151589330838335',
'986517652702583329731035081859',
'704782749889753801159968986715',
'750613323281974955608614333515',
'871799944719736186223225157741',
'396917179022752039865576955462',
'578926226390623866197982952580',
'379284272246950478040504990383',
'512489809878929862208029683742',
'410745989423739347935006259361',
'449188263932690148856532760490',
'501979026795119306872933687773',
'459753428783999090986659674513',
'331042657772079663012878356332',
'296592344865095637866764471068',
'891584480636621654729477821594',
'408223023962758934134999514682',
'947430791923294468525693649132',
'943381847495549804301210592697',
'104753303977811488181238092938',
'938213927328336633078562143941',
'901014622739374809194620313193',
'609345248612315757620917912277',
'396336455732363992722823310491',
'977420644084192243477278434819',
'164698984720672710575739958166',
'928504794709319880757171042516',
'425418999422835147460968394674',
'558569140956310451361342586292',
'616694635219258674597131159307',
'222225623604160444106156326284',
'987913902349041790185157736474',
'680014416149541798067272532741',
'860473118148992712331818179639',
'176842527615299878884405222213',
'364297248116681831582823682101',
'876650300628470871578836528109',
'811770241123957285108577423329',
'534111345342579273992589814503',
'131064078127669407142330681536',
'340503307484092159311173825772',
'433590076978722370960145849806',
'175261068289598849426523944781',
'497185239007249685972113061354',
'420941713807913863763285993872',
'864904087912463964067004118913',
'104146915002459956601917931006',
'241672403010546422721811937702',
'827028556799362405803819862927',
'455285835041386625205289664869',
'946827796522888831703228526210',
'714243430819394036207696663779',
'657794774079785931852288013626',
'331698174748223573984698853957',
'260735255846485624335884191659',
'927376246017662408705615517828',
'291342860743514305959532886255',
'827530109149410827619487798059',
'539287448720133329616282538345',
'785318895499859104060976043215',
'676361488480340233141669431136',
'908146281643713348336322162565',
'354103248777299492435753073880',
'768028614069050491639763831931',
'507064326399015193802369588661',
'186259680399281655915928279247',
'773473777988449935901310460474',
'777591423797456196206331266567',
'661910918838473642525453152189',
'863667426989524087129410282531',
'858406680003916042651975290704',
'627385574019177922803838895928',
'828314885959348171098471881953',
'807698110766887478869132780697',
'838610417075246246846654623298',
'655179148905586336489850314820',
'294703688910738181105810647164',
'379921063320775089042840841687',
'467260955726555619818851934298',
'688956198401957981820816726375',
'369237996848478234049594672401',
'625786999977838640819697469868',
'988299447668925725492702635095',
'293295839420728089564146538130',
'719497438082529398866201948835',
'451240865570187869497069952922',
'211114726013414516778193189206',
'491077814718978632175466145936',
'141566262648933899146142035001',
'548374582995535055740830173990',
'788080426587885448475859171248',
'503401111199874441031169660994',
'484434829610883935595512033647',
'806017139831051135658238157636',
'707524868484594401075538349221',
'709592230515569394940456656331',
'453690565647348804345640165116',
'126152617725560935076712267936',
'300454384845931254229375843375',
'253230012191360833616490322009',
'624892987190157835688518322612',
'765885882055556031159181073283',
'563107988359090542611817325248',
'358404314288689016070744863772',
'180738831453220370203941197276',
'509282950118979614193955040333',
'617664309679710764803460558958',
'945524723466187009867785945820',
'119248423899233034257918769346',
'801347486070378146626508257747',
'353312601435473978878874468872',
'126245163136833072401883827771',
'347936820072891726551403948987',
'791497643489225381676992029594',
'332413723270468431572611733043',
'388982141011082842981110598563',
'721011466668512230410982237382',
'465787584721983396326171944629',
'643806777114569580032117483978',
'336246190277395965064643865439',
'822437737215433320439976965030',
'613556972261952995315758458590',
'132778663528690554843327739533',
'430122178535245777553407424312',
'626035716925827481960854049684',
'711001698578637636093789592653',
'264517775288957126325977929993',
'521685540340680097038381087238',
'575182489749855705900905279737',
'566941000524043499213534214652',
'184180200188397072861353359014',
'583905939521891748159226662414',
'543938919887134142690847524773',
'795293409302713659680241286930',
'510919266689183893538132480838',
'245791378234080622351307410844',
'278332032506879594002692517297',
'781388479613899712226514330674',
'878929136230119234142599332624',
'342244427108922556349887558091',
'570422420467529253799111510197',
'871985693147717471834163964566',
'859717578971861281024616456585',
'396345679081446412135552238107',
'884042910917832024887034324996',
'138106793206488203900031826576',
'960508593366651861775522525594',
'867142276726170290393350347413',
'789233464193473945034054744389',
'627496114007892961574338439165',
'160244409444210359491539478415',
'465626859844954877662639692369',
'790687511923062462074483940096',
'223814130589951983113582969912',
'891272908778976439909407396000',
'262582300831079890051216482412',
'439995821133232669886640689606',
'696704931773360075643589288748',
'268593832414829641453137053520',
'136017368135443076606313739624',
'632626366300146546298754245382',
'771459317297042415789134055378',
'995575303187134794104854337357',
'450531533938070210388632579633',
'663809499545538654051034991531',
'973035974185843303430704397433',
'477001216766772694516650750645',
'679925124762998772671252351435',
'861578237568120251465089648175',
'203669369465265957600115872182',
'707104688405524730864029258230',
'281110819565217683855473197028',
'579107319327615963730936656873',
'933150972929836730753784068314',
'326595059899515931162879464053',
'595134016270176180637703772811',
'878129051609531246115908112989',
'909933697044321458777690013648',
'134001240607195769669500743674',
'455864301683828360419778736938',
'294412254189719141305775581651',
'455870776959180046285782279270',
'385317034278611950488264236967',
'313998814407980491128571664731',
'924887116792090585066615072550',
'544178564958879969791409534927',
'188574109613782257892973165750',
'658358980784171786875107478680',
'102567711993213816682034889215',
'464482445560872829901232631880',
'133423878098351243279416859164',
'492966742092665615948002086259',
'192574488455968179442301180319',
'455224905180087466997065139982',
'819829450584021658962082876359',
'768229017817188256656444869619',
'419199399441172055886260026659',
'366952474439549463679435856889',
'802613204919421695784544192173',
'812162449286262539122224677220',
'269499784608063640148919742362',
'356086292921112558851113615277',
'483881973716754313734252023833',
'747882841812589964813371735146',
'154741928054950656832292513172',
'594001239945560694730340641451',
'745271429944369475148786490719',
'918380097158672410461200662986',
'715661233263588112429332257748',
'375380216420245699604136352745',
'669966113425970172950722546646',
'476909307174582829549120522179',
'344420357667173295000438521960',
'952862622935875857985840626157',
'465790226571692245933951545086',
'790403296000446492494208583496',
'751561355558798004079108582133',
'280582360123873791280523627995',
'970340506910412047293550888237',
'283346636203814316341424382034',
'940091609057336376186192772228',
'632399304851491193596017997399',
'715879589311635041004557277816',
'631477951641778037896486927660',
'944592936245315111976504245657',
'429539094055228323234444488377',
'581652846465892040036680966157',
'185858036881942432743874087575',
'183318382664712716092438274236',
'947147563675081721973879355053',
'889201667678861245933157437802',
'738454158734436457460696366905',
'510373959560283232308607062884',
'696031387174637445181371735442',
'520387781649163876547207660109',
'807821802057423345053265743199',
'142961620075652790450278761880',
'379832375126759129155983054880',
'644073836206852467942007898397',
'366109800147469039208407831142',
'831582827367881028949490697615',
'105564077295859568521951931798',
'943904056317849444287923153253',
'887793763891347250436890382108',
'296722267049055244281904130338',
'405421106683377653937643650330',
'974840537794099977271346681465',
'775801034362972302821792315550',
'113684936504914566723636416253',
'113980650322395927160148272888',
'284730302312690899820152479901',
'115380662102926823105220231094',
'744553283319936109051236968826',
'739979198519992622776011793684',
'556582274109648072847993644661',
'120153478943280725307639870267',
'896276354804291410093922968212',
'900445366774626266125283536714',
'710502565257183099953283352183',
'895227298879860729577178226832',
'277478482646498917345243920741',
'705326349162343134683912356488',
'677362689859551739565311529120',
'788028551114028786870786510683',
'830491346832411961812784627129',
'344672171397891369425986488263',
'980725557713619602308347227950',
'965409387897214634722442033920',
'888426625463644163868680341416',
'247263908542850703153523341613',
'570511184739741210555343298913',
'874347968352127927148789323409',
'806733722020843642826893842272',
'550491897716571831935974480112',
'447853092491344132723244353531',
'269538414618577459502712381061',
'701551092360180033820097562918',
'207416744678054346753933477147',
'839848454107128343987440038515',
'918978952632360222526985280857',
'251294995973872875725636452063',
'911249632187475433760053860586',
'419175657669142982549110237972',
'304944313120295742301324372571',
'316746707147911969815665313238',
'986938048174376587617045320245',
'116699175057342604938588933368',
'320323286080488184413619372285',
'762643983282428183780254120293',
'563327505314966884971195071935',
'171513259985423862266863280357',
'579464698009513420944854937172',
'153628175403094099540232419673',
'766713447681491908570960946640',
'217610689354221760294123238279',
'283034813494646329110656092383',
'772672840034159598837139445910',
'324475278675339317717448733188',
'195949401510369108203802792822',
'315969950535886411378621225602',
'997836097559726895722461578566',
'390329666459637299359988873560',
'110382778705863164898551441628',
'850746069892591455925809253409',
'623025909281630381712278241318',
'176694610273466351801213985768',
'562820177875616977390915927037',
'265311971186552396293433985051',
'823357400883749433502923288235',
'815763786560889121185154048974',
'465538804002857187312888296070',
'638801512238293758194796790071',
'898114484795453948942206386158',
'482214631021225562600934390214',
'660700236026348061238979465563',
'837505140050059876609496139007',
'614868976534387404226277731812',
'906905286994297234437325046616',
'120038305065336754762658665268',
'238071575765438427280193227918',
'270794566441890423007318190061',
'184400731082797803119260927667',
'636219835693452530524453667043',
'691088253480820016963612196077',
'708493442617484802045511595725',
'481152594737056482913739417407',
'318123696845640404587514278910',
'496821406680326154936492395301',
'174944704523660793504030090999',
'423754831306097539832003462046',
'876069077411762604674978487424',
'236826439914189283455536842667',
'416935265063756671868957832591',
'251856280883210722389870854730',
'656410252386063553699785269376',
'152371948568181634957172859821',
'771976597862713093292170777161',
'393582939614531192712999592948',
'559958820355526328214435050890',
'135251119481060775374889544014',
'184962245406318487169341542621',
'832874313644177743992783025489',
'833091403461770056528821259662',
'728046278961021331614621680264',
'233782215683194515727542967769',
'549523761433445869878669086877',
'657653487236790056035730828173',
'247292152571771620140818662680',
'545146878854135599686927321775',
'465130812342029131163324823893',
'306020428409361711312822872451',
'992513134843173414060759042470',
'602779609149963747950419560556',
'636303417664535342516032876726',
'380267626460978733645454935762',
'466125175798275197309527934203',
'277968874402946784964105953470',
'888144993201156375988834122564',
'261877225118841746516411416265',
'884426507233576906937474069256',
'479082672983233915713567691727',
'581572252889297829665768771408',
'340058551731575214087295260193',
'587588892215899995611595554506',
'362793863766067727241763494219',
'518829496073248391295486544073',
'194223246984972260203760056086',
'624651423026234337251397935047',
'472594139471851933992329347811',
'428393402462378433385441980563',
'727585223133641689201467293175',
'611194183379448604321085055222',
'849630866680994634795298295737',
'395668615665378625806344590840',
'336851246468879662538859358575',
'234932409378518106050616290420',
'636278069791284505529255671684',
'965757013796276175979921286823',
'627401487232291021977378198595',
'121235387395191067392918996864',
'659534821613134852666385281464',
'168301071742051027804302443153',
'696478023278396729300440390689',
'562394795158817610617730356824',
'956225457970631870559826734846',
'102338100583012636732585116202',
'630007978298847161868334546883',
'418437545267694115399888936877',
'745824699275177438468774649520',
'320494991573727918433331881151',
'477476691570692282454180994377',
'896691564428333140540172422092',
'212176245774164344094354623614',
'974053966234860303407572510266',
'240067549127330449357496452675',
'245843163089182336260682825534',
'682657068488556993837895015699',
'154767746348670157480575356278',
'312069217782256948267621785646',
'147681481127491167660418827623',
'677811289887990886255550529057',
'828507528086276942980633964995',
'719690274665095252795712243734',
'441372597115981367145336753841',
'308090830211038512364066391901',
'697785554767980387178022434884',
'660116678032890540770738643168',
'787460605201693048937247670438',
'496211466870688893554463343683',
'108341665975485747619779598998',
'425410576041425273620403183035',
'724704587838524184397390389992',
'858141757316813452339106794368',
'326419446917170080114601334544',
'233831684334696457226936079522',
'924469064798571421642536286518',
'851512735152126444222284067061',
'986270363835394228821317555569',
'300512478832963316792360659540',
'541976387029743779593130956608',
'965391098973799863281124418016',
'574113759400939736333781121674',
'964302785041982439828486798268',
'703995649460246628560425355126',
'513319626782558702636164250690',
'590977333547740596485278689665',
'708145352555688299074156440506',
'371664182803091756147347853594',
'394265176643714262392692963574',
'832606490885143176943161750117',
'111082400188463919237603567500',
'193779918221649955730343312778',
'690876385391823379360980047191',
'462075534826039901924608898736',
'226482803242872325949078611022',
'860203474147414851836356844969',
'603853200314859833353625530347',
'779332394972428538815012065294',
'471338481624788066595308885779',
'452891486303197507717216312482',
'255255361122583594225769128632',
'332043233270641090486118830438',
'978124965303313536042029017006',
'757877659840713426998622555349',
'583684976736251466478037311771',
'180172348760247353852003266147',
'513424418936339507860411710521',
'378287098112694009919310661714',
'440381375717480608163409529311',
'746031609306281111882520532569',
'778444257988443778071814772315',
'913132540975176721959263593726',
'796744870622771853306691184800',
'460488747972942939276402350202',
'866621210693822461770182214863',
'953812895895527213067213012361',
'214140229314896918243090977898',
'743189243002723543928124848466',
'440757889244721137716348988794',
'854623481014439818142192534459',
'354213892861331901854025480130',
'701422315507550287975168619812',
'630682290225284030529735921083',
'948150100084258913724526645023',
'697756991723848066790805323177',
'878244794116744036611694327309',
'301022532284923351942406938961',
'909884552676855144749579948254',
'863035748359096833188172116970',
'162193322169178893811371021967',
'924906584820456519962059262555',
'456122998987286536924126856668',
'258430970021733251797542189929',
'447079871590083574445545178517',
'255331942862012236861467061995',
'172189424553358735114105861694',
'692528644646814846169761360151',
'245450518366395452762104774237',
'512326283056487829579402279311',
'183056112984744480828253772897',
'484607484870390840732948916658',
'194408630238180387556190550164',
'256029211625764587507280855203',
'510443558133773733520839963654',
'317643216445052319926456480966',
'544451229955460433699419050449',
'274701104483315145107693654435',
'844748805355466048678093055468',
'138612571963666248801310455706',
'181740098374264461204484054296',
'181761756477374844541178388557',
'781173080814334993721532970756',
'314621864208995035418301012369',
'566831361380890762864974129036',
'453172066955951867602596125250',
'797005535531532485125495892917',
'124317913368885418016333395338',
'571773185158855950712108658399',
'163060948262813513008947914132',
'580511919301049478693589485451',
'871315429410242476371004828743',
'457268305764226486028728127104',
'192181125757123834978735770843',
'704290664762316535975067623134',
'733988889892066179096389432593',
'115867199538714277119196543051',
'930138944608122266431429019433',
'939568586944563072545923837811',
'513396948260827559348364082896',
'900167110058515855455029541490',
'248524858839898965587042223694',
'169041653614410607087887623156',
'180839277376020690605040658271',
'256699028546263793660516123842',
'794641020097734873204190152878',
'842570183818497846909422763106',
'988536586412698824275550899347',
'839147380075638322776789154915',
'915498471100014228808530741250',
'727720250316135342812731975115',
'720792335021961373149606375085',
'726186521034857977308800213003',
'331907046750667732230808183706',
'118913583435574274959325114170',
'429293435232446024224946813612',
'877734115962298957991159317859',
'498974814948578217190964986094',
'129830059683873750421985639235',
'150876438302885109668830498867',
'430594075824235952302832013299',
'468548049515113916529823593722',
'190712407349771294080270956147',
'382839930046512799839541919402',
'416054659980913050562784920334',
'812193843141371736484822862837',
'511275186370459717803993529452',
'870386402876542749468406672750',
'136176234346258073293200492198',
'248636855297591806375279798964',
'551922048983833907743364597193',
'886168900957047661374408255632',
'696538496095657120874867457805',
'406319952219439374732955594860',
'227521898879060194528387567122',
'419593683097872809338704846468',
'997433890858555834521602053492',
'353131677856448880009915850400',
'128245054353361488815414530192',
'216760742320799433040596857753',
'667974988826230762228370471991',
'472716035630717838276150746031',
'916039215071032597788724676287',
'104402778604385330406426661480',
'330303533640056938775390547254',
'586726414273659254170585327295',
'552303312500734466750278541422',
'421995267296010648517813265831',
'771155864141990847386476435257',
'358213589762542445949312757901',
'596423099262722944064600087973',
'754940744670215005075011645587',
'789987427512797818675202591004',
'920137782162253208288139927853',
'775680030834144513095892098962',
'272013204059966927281684854039',
'761347859969077774779760369756',
'263191579846136201606103885408',
'990097962715016174246079697876',
'563281465099472729222484728065',
'541138501735244917454871362966',
'773052436919928387282094527031',
'550650325970815401306687840681',
'615206157414296339418612835229',
'631248489208014340745402544529',
'898934291512271260734103373467',
'475252876239666978970282436784',
'822355733746435360960646199839',
'195922156353590032008260371308',
'359465548079938243123770771457',
'905100971044149281426316759545',
'594560603707485570287669765314',
'285004225378510951671903273501',
'733664982305883262146601582444',
'367703808775833195474778215192',
'927135093542746043867217118587',
'341122189060382693938989911322',
'397393455429238206295950716005',
'140557577562094504490931018541',
'715545217438742902068152812068',
'751665178446116869113364052707',
'499431390901575178611145123784',
'177411104871166816110978150520',
'112166048469478743089403378014',
'840590517280597089344026813174',
'120844784106595362135853959703',
'536172444092747794302740856586',
'110480839265660082341336257622',
'561980842386037684141534588306',
'806439081736024053814290639045',
'745642730643584164122641055267',
'872106569083579724082017486107',
'776521616723297139538325525388',
'276074621549151919498084848153',
'967314284279131045576788537172',
'663024197068292607055960399193',
'767339579612941260359827840101',
'886803141538778767704456129530',
'574269687646520779923254580143',
'649135504943457554004305328789',
'395826141658232804177822895996',
'253813227018329051125258949347',
'674639843898253034271000060576',
'842139746489784526855515771295',
'610510767769448383577497853483',
'752046129556567109666256720086',
'810561859331142100876569336503',
'445218434053888068753528940782',
'464447089361678261135197415909',
'891172965611681154231944234383',
'559067993185088938759040843019',
'856105602276463937450718550480',
'245030866885543925806893629148',
'889812744331854256763717832575',
'979340597267680876014280182110',
'878585044061692152154666928908',
'562649218036623850151606932327',
'988048553839152249628242335346',
'438369922809448505864538026700',
'535478311550134302045053975441',
'820949749613571516808337217633',
'255190500403220433598171999483',
'169203998711567921595479443106',
'586483582067939556525639327526',
'770811809458920063149211710676',
'984300521145617097387517794651',
'196187235948052274257967086387',
'590424077772398167922338413511',
'419374440953749435430061562207',
'272796325788430061354363674103',
'835841084644348016580835852684',
'469472113371312216320754045549',
'136143882990019921590329566923',
'450306053204178570609730376015',
'701847584023424608608539055481',
'316819805386598438864031370124',
'690848130496333054295678319587',
'444882278785013360543503171873',
'147134590022594795075284458278',
'584791642926150089916350369066',
'144667780438674757668708651526',
'605995424037299603592389054548',
'858320766842525954765584892654',
'573829771881214877935828688812',
'788139910462078445090714697279',
'176287800418677318519329420338',
'686460636164285082819861240573',
'850292209582256959944277597195',
'338679365817016745977395055824',
'177690229547664195766844742172',
'520370545952684888649823211849',
'578449210504430734926821199676',
'742084811400844467781353281241',
'207708640417959197134638461988',
'157651945410458581882449792803',
'648031479343490550128145658794',
'266500119655174184343171010133',
'254051440156246175261101747008',
'928127445690739663071152443734',
'805566518996867596492762563183',
'509254510308980894285299727973',
'171128354842016076505757764046',
'892676887218468491456098452770',
'373155196419111637277787869976',
'118940584921275690527601811887',
'347006717464938161510269587337',
'799572294346961991089948866588',
'869448406045451708644897549678',
'484834169592457644478134273315',
'767753157945218688446855831953',
'967515598515823749849861693304',
'767791753026129990459895670405',
'915454628498219894815823562705',
'394908405764985509800598460692',
'857993254983220526330238615681',
'882174283465779736862120074347',
'858192584808724158926192151803',
'957684313997856362438379562451',
'766531984668497552085295019791',
'463325568888046746758139467439',
'830911436035511675459960016071',
'290422790955776815430074152730',
'512020015360570785341787531996',
'501181227210562343395186388017',
'667815018543299616695213422516',
'513577008000498077201786784843',
'632409812754959997703913480830',
'719605978422196258508796491970',
'172463733042637572655680674555',
'594506059221022583552876385600',
'359574646170288735882114284140',
'294694134814947263843371515591',
'363298795523069610827857648393',
'262339148010130134816255253023',
'497050178874482144051974770405',
'530340369407960950050213662657',
'332019022525448655747910290149',
'130757269659970856978773498121',
'820124259259487469276400411022',
'164767607981250812968146799358',
'482220348335766040265689481479',
'519194182941297680973451880265',
'720381996122843478984226230802',
'601710493085482799058872339253',
'157498653097583012342204441207',
'139122596684091396149668634828',
'341019615144822819778994919701',
'733933783223271103751556861009',
'269816597555222622423413163781',
'726054363765385418852480473466',
'602584123038039551704441635734',
'115786030358763283543975664942',
'380628654638592789620446210805',
'895469083795663124756213167003',
'453830770872998490341760552138',
'754563291312746526256757438687',
'792882286137342250071871119119',
'251680403635975845960427052977',
'429167996189488771019414759234',
'127708448102651204428612388434',
'318744516873819532693071191550',
'418494213707188349874627435381',
'847287151882515104597268467106',
'175100446331867019121675959053',
'649625129091123124412934611757',
'290583595815592916783064213816',
'254067916974347203970194236298',
'111068076797126967227789563944',
'749196092814695237320866235281',
'473802836140528950885700492060',
'365339933031716461500572237246',
'729265746390851260418083373691',
'217040707841813587544304975761',
'788152329535114904871188694162',
'978117177966834031828934058075',
'329075171179324847045026538710',
'705221099174326522155386837652',
'909168302880096839588938982775',
'575503716101792397768975266019',
'102907248301840170655566319724',
'449372764346043503678453994787',
'838552999329149073642283846936',
'722134141952044838460026426413',
'688851424691967908644434427418',
'104157876902318310845500890257',
'240489188275823633216023875140',
'143557554565957835481948582984',
'104124064719599078727556397173',
'324663828866858679035269442072',
'167793057329420441371506030455',
'399979488145594255743984973838',
'379209348598948222765837674133',
'914863609270475971530385440449',
'868147411056633361161307815669',
'108864101458925847372268883075',
'448176813333473591348921850033',
'220017421653222668314497553437',
'875730173074341915093496026151',
'840924233634834287341559566343',
'888330235836246689183741796224',
'110227762445187721242606219826',
'110011813358446542666265717873',
'190971999508413109258017131678',
'432816926337911296807792971349',
'229786727733362377151855729371',
'238600586442459513177346398895',
'522534614781161992415573199127',
'985766186956921455871074491377',
'577224047920312525449205040105',
'209976668207882414201363949781',
'485319726683629891317963287437',
'795538443701589462673421288013',
'830460604336922835377544970647',
'333552299041062423914482326105',
'315316433857175292925582110041',
'839354141579830165423582634107',
'180514129968174408093284121001',
'847734095955492225598210114847',
'917409891722760389612935169682',
'574096585451799257197887762648',
'395870927975288393467557568891',
'439812235531372534208960378972',
'662447147620380943239350142370',
'263140271357620463824551690268',
'574499580373055478899477117048',
'263654806541026648774203211754',
'545181076656442352220748565714',
'805230945752948073740830760621',
'388175369512122625872971966873',
'697517502789331509319426547812',
'113308796171465323589548920962',
'820061028399249952692033169310',
'721242691131077116773122792310',
'727767256589276649119783276131',
'835339538908422460642714197923',
'911180918948716604777251385905',
'931426645690646575882074446052',
'445855538196826475119715543849',
'129615481802944349755285587441',
'786844514862046377796276145884',
'234582559038965479739810486739',
'452353283690139524489448080060',
'969078944837319444119940838557',
'599599956465431826862562458086',
'897470280270213149956450212478',
'509114666174710742735158346951',
'886691034016839252293738266177',
'406662148677554231057885280105',
'698735888870147869974813345520',
'330180117311299957683749832859',
'546090826696665567730655024938',
'336214279449710687500171155523',
'437756385664964327389867567908',
'367940341298117342915698910357',
'486651640054258738893633151033',
'870432879182653645981164943674',
'285364441402881706447841484553',
'625396544461928685553981566829',
'887915582205765529568030169633',
'885506212626156400852055697118',
'361260750422447077102597398883',
'574231204543484980298282920920',
'418972967358536713192112956382',
'356199272907883812120033351896',
'401330908849238557767015847698',
'129768170368330744773630964774',
'917286778270351109274408356864',
'930579900232454473774084256047',
'896773487116471589970618670898',
'450570369892672861953031954460',
'126277803199584911842788629634',
'570601585758115399285993834771',
'796238239839284440776144449636',
'494553674994824626517481559440',
'551875234937554606268603527492',
'224696875203499136976942099737',
'617128447635784792679051422531',
'704313004903610788917798297752',
'646587805047788670819308385716',
'450500973430440318105314860798',
'147249141272242132184755640404',
'691860876004127515844938488501',
'742865946462178106940926649665',
'828864944165614613303254249949',
'572089143301764856510861061444',
'241209079586096986273059310008',
'208360744403475366875225220435',
'429158763880050379959078940877',
'902463665257752155460501022111',
'534772447102510993582427989213',
'214039346144273534288194890907',
'804051544973372950034845536226',
'779271030204089143957792311601',
'728843699627824207221984247647',
'567349371262550522488016853556',
'498661543341893622704836499846',
'487399458584572607843588992415',
'312831130724768216550099011114',
'322402224893817727662939565510',
'882682191098792807817997032259',
'903823078503892294893056663774',
'939489444442624453070319181732',
'566734254446465939679075552888',
'966905975002297715750826974724',
'208891495472663739609425191855',
'972872362120984169827673620642',
'882771821791885516163261062135',
'584364784788758475392239419332',
'645747916531445207724365864191',
'331369341380959052021687935585',
'976004739561484276087809588652',
'342097217280077415056007399099',
'499838364579513043636061633250',
'481140534005589275970166489486',
'447031205313190258846123917006',
'921659563834761688549421318101',
'742450591229599610026032851256',
'620584339085889703807268512788',
'249303822988765306237575727438',
'472361028225082003195546140249',
'786596952199781877651660043113',
'388111163078243473479065444244',
'162468340181539513932404896835',
'414908202138485388676114757345',
'537295980805315014168628753552',
'950217220823434905790827332022',
'333874413336197407282154390718',
'851963735097646164006572297231',
'354989287654412254141266166713',
'343820839039151557043313686504',
'815091767792270466241984217800',
'803993540854120584358234036729',
'164761290177454737776876947729',
'535009139683974787282394720265',
'376233540126547894294959869187',
'504592046306017894836610978364',
'576130972404376706559162289429',
'406833092695184245081410183008',
'776304396559082904118986245182',
'511885989246519562164033677187',
'444332983371397082336378137208',
'200856280652241431173996150964',
'686117704728161873353995725338',
'713418380327059057742142985403',
'765234306730149985190397895824',
'850918877168319714664961358149',
'745104241094160460823043163973',
'280735987695722805548309395408',
'705215745374254747960727293628',
'418855822503243927619327051723',
'143652781662271163368287719647',
'443509831459113582530987073225',
'965563861920371592060496429477',
'317619082281332800698637758002',
'593361815799725674333522371732',
'619281459425033228909729642018',
'724457292100961183058885324015',
'183304472551077604315241288969',
'266662370704485198256279146511',
'343533960280917633653203889939',
'202150640676541189411870260149',
'845333048937025558020671834531',
'718189935031413727429924251838',
'381889049815067857008580662678',
'757663193857942707708893174173',
'752080001576165916849460223073',
'669811958570160369195950746866',
'409088971904851429531005911516',
'418499696191758649604632971738',
'438632125934968541832973266270',
'733361579226147522164602880873',
'454713933590613946443278467543',
'515304723419765779960704326600',
'852923330938818191509491029955',
'153694481707634194913454639559',
'450734170560089243049793694814',
'807573714813638394701415865928',
'831259677209423091296320143338',
'640330861169764077701657087674',
'756719041593511252582796315599',
'299535671017349012745808520425',
'447356424788551881311169424272',
'553708530735763189959878610354',
'640112941359381870559101452275',
'682708350526953444390560630929',
'708003548514812860114375986029',
'151060976612340193880583967230',
'835805001888796393356690553714',
'935383007286541327335712978086',
'505981533849769770426297683000',
'337516210550447505238953433152',
'566234753449673765938395288914',
'224492586981149893927984534628',
'211964806439754863228673424842',
'676725403752190044745925353122',
'964930708214138787888581522091',
'162118518560570097461831342946',
'749344556363280049048257092926',
'459857163227680239905731785554',
'865174980517191921633296254400',
'297561442104667633452628913165',
'241390864053725602307202347630',
'200721836993463850490573522350',
'101178120048852943885780071899',
'667358452157835074491301989744',
'627996449186840764212516180205',
'203611287209051036700864068954',
'713278252204689356762291676990',
'798687683941742524433033771707',
'758222992179574459469002792459',
'764900731316144069867110620956',
'272010087804725085228061416256',
'205112120946489136590913922399',
'298477763314645589943920281803',
'822378163511055412341973558371',
'310867165051190426228480654774',
'361197489816460712267774467782',
'734590892813538417951002434625',
'386407476661744216189246697181',
'808121146289093531241607890750',
'736704418816541854061083856381',
'720876879521794718626965049871',
'875080527602771958576483312012',
'256124355458277008133206399766',
'307912795239842169217887668266',
'438307671232164265745276921620',
'419441266503786162732168563924',
'605178111984637417341002469496',
'726163055590395269429493814193',
'700766393879468249935095869945',
'714448549754554712832003069589',
'248150284809280428578767447687',
'148542710042752277782157279928',
'191047414833848510593252171118',
'427837653559671745264016466929',
'382399193435813936493462777805',
'926440632593371379342288915267',
'290020241079541037101935796751',
'169958212443174502880623030068',
'876792599011259060468345114046',
'420644590362177340471916132543',
'254060592896018487429550862917',
'903015575407075743359607392294',
'176684646194034940042784562398',
'236564662395817270100602538220',
'812439898485642349272762351342',
'631858568643125212206628167215',
'437113624026659435653398354474',
'154858263499689469597637785729',
'303595015374325155062495018963',
'217884434375976610033633792093',
'225017304173091203354396980026',
'167493837022230796364662935127',
'105116962625998975274057382563',
'580786078391929253007657887154',
'196102763014356102817352160415',
'845104424628295214160309699824',
'503639468928594836055811531258',
'553225154975324208058881979859',
'929176466593629382634006474595',
'401722067542447139879689467096',
'986780201324820374383711062169',
'420328941350854690139331629932',
'836866600092446107066672445528',
'709671789201312634291927594359',
'141937186295212627126931696417',
'675384706566378477098158315803',
'244252872994054068100767061636',
'871228196762860529674652542701',
'594350817796857621316999662583',
'954089925247611868257889590025',
'399991939890155023650348052329',
'606595047304952714690716336572',
'946907000273525667458855531653',
'213459835435861231504474920025',
'716519660015262164746166560580',
'836823340072737553292985274182',
'371821181682814220414879675914',
'303272755215259197844300865488',
'537851778150577320416979355284',
'116045932309721600254938523534',
'475462611326980783014109521745',
'854897245656677901996566197506',
'359213679982382648456358665791',
'864256056728943662445028830637',
'914786124984675443481013432217',
'540851158651421151376227860972',
'843049878182174425117244446026',
'459371796491140001159205395352',
'754495350740941747985861676781',
'839624455989157081296582440308',
'948092668013878596849631537640',
'109258001736014117873638538468',
'261118467469329007020189623270',
'804569617933060672393599470350',
'188124179458356054185491850976',
'792087008395857391195465036127',
'919164383698679303759540572580',
'755706627880486114424381679465',
'457950454670848803957883085646',
'184684517425581344025327915797',
'202877915790173328157413522665',
'472479378194630787644200485909',
'490696856583815502448845330778',
'192194491018594614830697231491',
'822691878249723708423535856118',
'986497193911284292725991312936',
'576633456995825725904995194306',
'237958947386247563900183135510',
'813868559484841631905527144240',
'130299287492137893581975981741',
'399044485426941579209840963010',
'688532397594956691957456379343',
'447104821742720475303674125514',
'643785335406737173214654354124',
'831292560862471360543630883658',
'654726172532974770869258744014',
'669306910414883473325150084159',
'396539425637686677032535727493',
'792148804256299234528914668818',
'348053620815859961887976498217',
'146263519027265256406535161741',
'218748974708549936131184512172',
'858629838711387580785638531410',
'701582798886212197981604289845',
'954109889592542253282492972808',
'591849589254015824685936152885',
'442702150572812236581987853751',
'535994617142351334087929838493',
'559566862236329750513199090331',
'621089091988391358141539013479',
'767105303354761695070243487297',
'884801798690158294959393045624',
'564779552140462046180614246005',
'553697029563974532693471764370',
'669273523404284194066755179228',
'394767628437986826792537626367',
'703647285995840940636752481651',
'605776653673036124110540928277',
'661440914052030576322702215017',
'108152142216659900521041056414',
'871762004481869183716588154895',
'849934690197832854226870799852',
'883621468287395407935598028977',
'361846063413874497561429785486',
'615587089673790746281766244968',
'323669425194788290609613793932',
'416451564849212007569254220875',
'505967599794161728966479087550',
'548646041671066003444299318272',
'958276295935615740766664439342',
'482597020371863756577423959495',
'513235120959860642270241029835',
'817731153672458383269561783154',
'932853157293184197448401669342',
'432002586203558829547291668899',
'890503471914999958478592275044',
'964701594105652703031497559133',
'117021234743187326141023613080',
'987010100706795446657133176377',
'246882043732364349758731854609',
'902082647574189998905346240297',
'313215523391292660241312397483',
'111610676420049319244057335637',
'187057526929961296537233888807',
'545725933453243866937378062781',
'754364092440426067763319466407',
'457786991552349689935754875872',
'537188066091583755605416685734',
'266803430788372632379393968693',
'464928209129575997191247168781',
'484756100604339990816600673310',
'887039597921217672616948718079',
'531100930963926369304857135909',
'928499169265877679048415132647',
'320305920450456755128879063131',
'845812478347755563424808628230',
'696474528460639879874082269652',
'649423659602291967780669443257',
'260525256328812567841815538703',
'366468819927246004202095596891',
'919550758028733878698747195250',
'611937736504424010647456852861',
'569562007489219187771663055622',
'174442890109638266081514341260',
'231444127190115187543787751523',
'698192139158171360801654540559',
'499318784283696059480739888277',
'729251199513458576561658099706',
'143720289210069113541889186125',
'207325336129785553404158033545',
'789572411149476454211256544976',
'561803937560711127194500572275',
'392348189466721849511127141866',
'808992637881774154533252992365',
'590309010520512708767612092071',
'791305250964945737251266198140',
'299404961549951037754128272196',
'865514124073567052057071126083',
'319032914568484510964701443488',
'243010658956127995909810487223',
'656735084913673302736033962729',
'312923306608433557120341982989',
'593818295587571772068355417077',
'744923686937966192118585625642',
'278239091236122429458497324397',
'741164491632530631771271368835',
'377331803354175197707456819033',
'755362419745544093157228267587',
'806217963777535890621510618428',
'968150822007138686260687856199',
'650152322408531597925146158012',
'454308943509316715688364517980',
'240614452783924010791330254403',
'384219398767466861286616854586',
'799646276547330552079763849994',
'896136521844812604824934417265',
'838814396916881997573370210789',
'398411930128286121778741914201',
'842955943463927902909367658186',
'949216154312876028226300027658',
'846678506287113585037946931327',
'381287884321964783009077862879',
'659624882968061874777612368849',
'939993865521520544949388170953',
'944762212855057213136793219142',
'554257215165639842873924991936',
'354216051750225742246725995713',
'947403348006137865834440934734',
'126766450266615422710490124771',
'377137036415453729981544968297',
'869138858698512628192945136936',
'677112598067826985610370881412',
'317667422738498235280616612285',
'955765974126675284940774288473',
'244981089459191169773662465944',
'265287008287055798744789084102',
'904455910258974180874616383525',
'652433092778550321706462251940',
'876124872756743267950824854556',
'659052953001488368218179011940',
'951071472550697686803506755896',
'904213469074516784692722210056',
'500362888422474512963063439564',
'826208769238560239791862626257',
'861918233300936371372879815270',
'819890019310095845757161288610',
'763892482177470009446312494691',
'115041652714243545403180029935',
'179572937585073095379978399513',
'978626368704212883036089177832',
'876464566606234958510999549688',
'303061471508341407694393610646',
'758577661487845691798561415158',
'408932655303250892526576092439',
'758102522859691674736171235898',
'485804107008825601976319287706',
'356097394372845788844002199417',
'666916649966517572939326189847',
'648852044366199665973622154798',
'116718654504725466748672214830',
'680853772603646990609671110498',
'331728044877992516559209571047',
'330056043946133875096633250099',
'864455626037718293621916728026',
'608521814943428663977483248773',
'187240452748321123224372467913',
'609447577200480376420098526641',
'324818850517939226455530166058',
'447802564893243279903540554429',
'101470137165763819337560134093',
'781795288888229548006877463045',
'665734357116994061271634921486',
'172465888111040954988286014598',
'547005974243993639734556075716',
'919995766207974240713796779028',
'931573111011943946305136245041',
'963353129047078615283280429074',
'728601375589534863475754464463',
'955767556215319931012975031722',
'185885101257372369702728943088',
'795977463083499218760463830670',
'358424264380672570855904724252',
'893590665202050761988779626035',
'818407488935889913437821410529',
'892307113363622049754977154159',
'951019874601346678792370044494',
'972688931382391779782214599043',
'510510643564232156220490942617',
'650587440170848859531175930081',
'501212002175246557213582127810',
'434329602163123654907432456939',
'423414942323583872486279940975',
'468126354837334744230954589129',
'703582460261983036103846039706',
'441548398442474406343523572873',
'668214186224023297399477915884',
'360111674815231840868425166848',
'428276947632777509624144598737',
'858372892061534879351529676931',
'934555339310711261988156637267',
'215942773594659527383737457847',
'624435180548510664187762824109',
'561511380521562979821157642595',
'409243124313110791455192821458',
'787172026065271158576641043534',
'195645880403151707977037841269',
'470562707874477753408420111843',
'695966258960839719042109076486',
'979314403282823158712897883324',
'808792468630341375202550216323',
'921784709741077495455963953633',
'540496585972887228298855731333',
'309764317557911534005973028247',
'693448823069027227239078798719',
'243776302602743888063801814939',
'502572779498541611788896128526',
'112360322399310751098030330934',
'381003307373415294935690721244',
'246896148869859777920299654208',
'882132286398465778274822995804',
'747428706219145502333403499123',
'217810534224714194749083467538',
'494526846193598895743546431578',
'206655493646125708494300734595',
'921590006190673403901494690771',
'498304249068348121126248251391',
'389727562090725086525430276402',
'921334417876986709090425953011',
'790680864935523557992963420846',
'219536246358070438267177895197',
'827317457574883902026161284523',
'589811937326791972544022758221',
'348196211130342521936063286601',
'510752485949345116663751095106',
'110068991320746572601937580100',
'889051759369818440878360058128',
'588430202792589528352718164898',
'119247123774442509440944393686',
'599349413844134116093692928582',
'737116938681688716091226624574',
'630897517168160452847977441692',
'399461363173015082632568446576',
'633217616167597825522436563909',
'545890927993848929697561070597',
'740416838652258181738517956864',
'127380577704576451505935426312',
'316076662281220105505267578298',
'153018731937083169135085155605',
'874003182509057125371282148766',
'791860859714586866862666586376',
'226707300635489762123282451227',
'972161544655953588669745230248',
'885385815478650688950664270225',
'131041914009736204331101675301',
'114855006007941976420459339638',
'321880549915715166995613072747',
'282897024070733907803235099188',
'850656330917329938774148463264',
'718850135346059598947650797167',
'645543893331812973769120197561',
'923288328749418635167137693023',
'550529105967844190585098743228',
'994162434680699356543285249447',
'322392556914732618829605053801',
'867039483458265438080465077900',
'165304956158372990437849749016',
'682160540322969177344892276592',
'488840757353582591562105263547',
'949045529316569550316811463964',
'830213453350084608452543897195',
'287195980871816549632112131740',
'679246872797964526680954575793',
'360752954351353061805647982161',
'668620735773534640620777176046',
'496215891777350606322313296163',
'232746901210112665539107067817',
'911787516222257269462571739675',
'317390417347682918023439475724',
'891438681767291708176094330422',
'216781829012830544264676519938',
'975892508323076053803833394645',
'171146537807681256643111141199',
'882751710298017774988378587781',
'237341482576118565251534277505',
'838518493598767933996910653017',
'498433808291083846284445311360',
'421138087918145673829657785651',
'420636911502570825306399215362',
'743135153343981075704036457946',
'874003397892382619631337549585',
'765559664807695887394094429449',
'184298393397932917360663157856',
'909468851367141339742524499678',
'327820323795910452955846742845',
'962050226727737653783523531232',
'698516963389130278821515937040',
'568766407344816762708462692538',
'235470868022947972944942440080',
'209277853445019427721164479035',
'468353786855712942993491264719',
'915265243032750545008041533776',
'417924347423555349707058990853',
'844518361480090263620052047521',
'369884822829316497174574788480',
'429429834150546478823022836733',
'140947167653860385896712299792',
'876842791192016334626670845648',
'583459093390570636542204647711',
'450166068388297169887901898643',
'268820795052630667216619121957',
'389565315164092873819335598742',
'880061553414545974167530437994',
'687382333034187813278595112822',
'915645893622781680902232518069',
'204220960230022286727360237942',
'556988866615416259552095522273',
'478011202772567114156848116261',
'275262278031120128175559315708',
'891313321462133468960137118514',
'686032195379173627240912337825',
'626491319387496887726539298624',
'298526751288126898893512227043',
'134194192390398627760800240376',
'919840784637956377270343721514',
'191990797078293036543794481141',
'869239460691435318669695928572',
'351651669905164297750332074789',
'458916479337892001461179216681',
'573354353238142727538951146203',
'212506730356192407851356653858',
'282554296778541503590382579770',
'933695157577131014496986562411',
'542130533871983733096549031160',
'207157063169179069994793042884',
'502514588519053912659968939664',
'277388760046576376352880578477',
'695281649774741664332766086641',
'569606502166136668704711577736',
'673607469217421131981196949586',
'450574207116692770674798351992',
'580751336526593206083051827918',
'630541261402259075562390341759',
'533753501364231464597893673387',
'139056087051821342120175850690',
'299604340283158899864313758726',
'879653454502493552276446953569',
'811628694483764286940444758852',
'238890376895148167808698255074',
'631006412428884476233833349619',
'151735190585520076971648035579',
'250501273433224228875791939537',
'128796622067622689773234988131',
'219517655545726379073149645957',
'614261418278534878940179387960',
'294900591941715610890312698249',
'429962865999178726758542359214',
'276363776469698210158762949382',
'631201518835496249435570581866',
'952569715174297686967629174376',
'395959197890621393704076826582',
'540288833338867103705287146007',
'355581776951030305672545495231',
'250722711420247021409846387901',
'288354396718616775695732627319',
'304889387684938399644623818707',
'763494730990824929480449310762',
'934396994287318919060536889980',
'468480543063564673144592295237',
'330931095426725063659007537479',
'661789478155026934789893415448',
'726660541630693884235428760369',
'172041213992744975815044220886',
'626332644294748972214935269759',
'731886423988639130582388299795',
'205402955559424945064822088381',
'388780188922278825399445298880',
'533579819075760271593358040490',
'189225647990224614501314536762',
'399297400023481920190355641167',
'880758296685483613257360986064',
'264489045682454028753794133274',
'870128595975510109981459321562',
'462917757305441843663132887667',
'567113009671113099840496577678',
'493463884402647160026854310474',
'250909189849691803812861013694',
'938621788473725013201985673604',
'135539766331439955025745397014',
'457413047583288884902675418660',
'442907808468977895206344318196',
'212794957044512767075075217193',
'978544735586941930804948023335',
'587164903880189581447790040796',
'106491818489363412586500080935',
'628332057742353786100826413030',
'120362014474444692179342611038',
'287778080171114244794236133557',
'435620411338644422734426937818',
'985820782898454120811039074063',
'564302973776157259897653458016',
'624897986785135735384800284900',
'221082512205553301686236040360',
'549291333106765765357659380982',
'704276858513517294317797395118',
'301438201345693379267945473800',
'470858603455770593290465149205',
'910920959436033499243377452307',
'756239987275949711424149947367',
'885485446594399322940879283567',
'868169153779271163432265660784',
'916162071207546075584803396923',
'420301966247634227862042337722',
'269629314537383898627657666456',
'251681709017219466361227911036',
'703318957122846466979149492742',
'589644952732585763885571198660',
'290062591775533230761355819939',
'142264951088672023321246288224',
'734177880889278312871010326425',
'332161474107683480157246757903',
'222721675884766356361523061530',
'112803691008966163968640664888',
'116008639056773761291493252764',
'144897784407444859423643742114',
'718028999586310921130788476337',
'630774688109072375215535028417',
'349838303679818708964957138428',
'534027759050853980360545553778',
'241745522571184503739881213421',
'117451574522716218640927535051',
'817921886581613899233963692754',
'664566376575476182707580777803',
'922501610439766348611297428683',
'104745696092290786392330138162',
'480495490572422319774207334199',
'791634149925575145260636642605',
'523566712979743033196090960670',
'977010119105096593018852674180',
'520633630058535961414538137358',
'121326104610374771467183670252',
'624809383796449632517947184859',
'840244967802945577723030031511',
'560552702622848891940352951894',
'828710347134371107071306464887',
'647843565509370665756001822865',
'882116125655276888348362811026',
'368435617754214262886828328522',
'845596892188223711301465437399',
'913084483881880577541575476666',
'277342455729547674806796417908',
'793967394197454275475041769923',
'522766770241885204698269040763',
'183814476821057403049299832731',
'781767027060360580597408611905',
'757783963871457887654312933458',
'522901725390013275380051277833',
'535993103185331625597536851221',
'558381832962015172372164441594',
'888540563431045138348179211114',
'869094833299621808990265860780',
'962634347291283052535506845120',
'668615585728143099953076368121',
'469118230471196275590482281856',
'355504100285819563633095428734',
'571018004875388297196750774911',
'467174481856412901663653788382',
'698895964911964364015198369441',
'530976102140491272633726712399',
'747854930996792222794834758633',
'227090495887342261828033439645',
'835903174497684461503038253167',
'813285110643377496362401237065',
'290721110450792632627419071148',
'915774738649691212641965945679',
'737783270659432214502592264701',
'891890087383246251772537437171',
'475359615648356016666306743725',
'670509254318149608638996716319',
'814228618541731338195244713465',
'936136148797727098227614052698',
'562953906225352914565355164925',
'116448335997980970710473825516',
'379663417301913777168660587994',
'710700544008910561585154138679',
'636984618281068931758156359434',
'397850468685655511169526094597',
'422360531525079468947193566394',
'591274211301383029521979027454',
'627532098302517158095126976487',
'353481168187186284342463617266',
'585020461060710308399086220439',
'920724681831422019705609578558',
'827177558365954362081426377248',
'141820198958193897888349336697',
'160485728158560938703812718635',
'872019322499241831108410979491',
'801165194540045938791380317202',
'253186049036713774848929594649',
'709838317628144981718707789517',
'616127431026540288592226338893',
'994890314657227611166778521115',
'180228964954393603123001985177',
'341639924352947653893432166806',
'550209343398871796521482830850',
'976853417218680591367079917598',
'534742021100786842467046162501',
'415579364897593031255839919722',
'163215416153234683262100669804',
'801926435332297360804221272652',
'333898970929241356969520484308',
'141873042532774992400770530810',
'836834784319534843746852089087',
'930290733180316840291714963565',
'778843245593846457838358685112',
'355920344703246523102645310616',
'325660030857991010530913137243',
'177284190112976472577225978090',
'204040965863779323503484180258',
'121184806858060864511015116020',
'669586919672744918673751456410',
'181643336052450297927887894407',
'400830714730041790274838338235',
'617172522326391243626412386562',
'226217406231077911227094425777',
'984540948383638743578762712165',
'340932138700043540390452149043',
'708550894299957612356090987065',
'194609788196826808738702513800',
'849701476837926302469746818111',
'417722888802938932044611348541',
'463130335320557754494793835924',
'700673617988088165931052960046',
'407858461352089921324189214043',
'191325442994248834897134073000',
'721024759600083957347006433054',
'402499740889834599044354935703',
'240425597366351408349781024010',
'670194037545595594206694764097',
'818540185044065971973177991466',
'276919696031723734694586952058',
'299689568765918313206544041606',
'686455310266476663752810288805',
'793810594614426336830735998041',
'947968317961596493776599328957',
'237039415674412369397082994978',
'910577540266040248753910568187',
'125845412251853453332045531449',
'690125669548240323479739710040',
'619027027464554384714971019418',
'693175384601711212996334797876',
'740419242684046001385390890648',
'852933528582688222764887324250',
'289878831380396863715478893636',
'333906503361961288623109814571',
'121502729056837842039641273024',
'539818877680135155442001456743',
'196586429794734359544925522591',
'418736233763191521061046933266',
'555416569374822622697769813630',
'683607589861122156143043470699',
'118915408849395111353318218630',
'427043623383742641815988412737',
'760328931549782103403944858415',
'914236282675766557871714480403',
'364640241415455696127846921113',
'289143228944548747948674514219',
'899536349497626618701857944162',
'614448550153694909367337817456',
'855741093683495461826374478987',
'883481705112422282166281510222',
'613198298351562139917603224971',
'952252228690929691941188452176',
'476766859859293909338394090803',
'408668064268537863619847228039',
'824454995583738899885730850680',
'326441975230025192482913627995',
'164107546212230716547602141477',
'355939079144440236664284251185',
'716982801193962214169085216150',
'949705333534658935889467784188',
'298402743826277928222876751563',
'701949958816481299204682467762',
'643977851537850741273844417209',
'845774179696249496727547771466',
'329726106131663031286435764096',
'403934920053582254374489825807',
'919823462735527985111952386736',
'111225608081712791861331864732',
'673991638221549105844495634259',
'699049491949744710473828034228',
'354180043602334008973737990017',
'648301635044568896905377738514',
'170362214478672091353049422765',
'392444111800069970406252323352',
'550283875056372556667455428021',
'877032071340363331590861889952',
'643937876449910082327048590813',
'853050346258630605797880039799',
'143969469065310821251979328256',
'644778776123819208719157549835',
'228474207035839476512699617373',
'282528849203626440241754464259',
'491666020265871329610959458395',
'126905629907246131580790468873',
'500538221291770607879898280699',
'884962101548383770446412452078',
'373015534190725610121944780711',
'180684884555999694700831630473',
'478625502510857265985986185230',
'882961724487211135272930843595',
'766054669365927029979782115755',
'174098430950062979438112439913',
'498295259965268409735345296711',
'320294263976095482221242723705',
'340998418912322037932335413955',
'446879379255540610035575450441',
'620549053299736911312937687271',
'117203624458619657882447682421',
'969866598788298017488092881936',
'550138936559424734826977746882',
'963919632349780835879287636138',
'179616165523241024064320034828',
'489879538953715414119618838711',
'654068182872533002071348161780',
'674877273654861690739552837374',
'803383280684481906203558140096',
'300855870773841416470600787374',
'606834320199910267535657075746',
'742398634069337705347507474181',
'261837310034049167183569496653',
'739235932758846662700269528541',
'852687089128414058195266260005',
'967939529028323594233124512525',
'843823545004281778467385533910',
'617989250042872233046705950138',
'882220191473670060987387014351',
'785408677142816045895166016783',
'509557186631154774961693152595',
'111038832409595153295557440964',
'652099021956580324688185060788',
'239554431029094076008713551959',
'893954077127732223439591778373',
'505317112182355062152835550634',
'999844319164660457003633426389',
'770264384032895133016479651914',
'310459320638569142804923397213',
'671119432703490660926516826686',
'365709266156419650308987721013',
'862725113444749603381105250562',
'354130914065118326826713664835',
'939602093302799686387649750192',
'801462165740117931946582475308',
'603446975825270904274843971556',
'243481192835869272432333445194',
'304047640036343916647880639213',
'161559622728666992049207964359',
'662232090371521155844357342060',
'563451499488110253889804699043',
'303110898825278912880514382870',
'614961125797273264125531359949',
'110961830034518724585310970675',
'958346285654137942099782837132',
'905734705126280383588053340387',
'874930172272128873073476980043',
'569588503510142060979401974756',
'581047779352163428033050695447',
'964010206037428454250428821812',
'897217837587863566604587818613',
'351885788661236346007505985068',
'400991936275394344303155082724',
'379754921145429244125924934410',
'983442171600855150641314131872',
'231956829135883248809772145930',
'409626153302619316283944287588',
'650360876925264987677261854050',
'176651285576318493467710161947',
'815315709248366016509864252339',
'981000410996775235286831787513',
'260736401633155340892139555689',
'597519039472759638446681033412',
'986716752373170780114784284523',
'534167194261041446240949755629',
'330768413523064985358810718588',
'618950376518360202583521359208',
'208913221295780787400880036550',
'463149179962051179287887177802',
'224711027102914273912325816266',
'883022072686299918406885843733',
'842025379288781944909777265345',
'263635885432385091578684231142',
'108807466964471844041548271564',
'874533154854839734361778691601',
'751446171839575973346072213918',
'556737599039824135687451720409',
'116746887593126449532805913452',
'470273063700989604985017815090',
'312297156905345881125524578062',
'430610699326289383158693353305',
'153971221283970676003475065069',
'458025711458371748286141637019',
'609758549980649272736268390334',
'971134931935133597098590045410',
'314972135804617814155659377439',
'500470311707477509509148497366',
'198957711570519871568122895146',
'998651648270839369105216098385',
'835112800151751613583128767238',
'962575281574998378168940397861',
'626890320709631823501964450661',
'355122608390331214188346261641',
'882944275219070844034534868359',
'919265048962250280390824080404',
'963929707705944715472495217375',
'127370408878397167167502657207',
'213824862989940315811113661069',
'242730635313056986674819610421',
'534599088353661735256569599401',
'917392000833750412077683016166',
'575396013251366059063671885144',
'914461305831470460575211524139',
'684070702413927600993531943764',
'250944639418253553413678483533',
'825495947491636744724089511285',
'915358284856079387955809139809',
'998111690409522875016148228933',
'730279330639740877061568787644',
'856289380496582371059889953710',
'349220810089638218399918288117',
'178631226593296138796349081727',
'338451360568080206034328460054',
'691072150110835975985072119218',
'348422721086015497622589023890',
'987011944390394857718199365043',
'926591926702636039001434517446',
'956635230874320606269802238317',
'295319913054969613191594977900',
'630045053605445187508695961012',
'307168257051340180107788068073',
'225238118824275461094109398873',
'642567991288452362112839956702',
'171954861472118641783184288952',
'670732997094178760322919729943',
'749580527848772379533334766245',
'227727685587684918906740186893',
'254887417302028888312847978452',
'899774971646595922221393415404',
'736763295794630431042701140234',
'551652764663842366875174518986',
'832306660200239914204309883508',
'399917724433428708827350315717',
'219779196650980709474017418990',
'329043012312013530129342491522',
'227689210997071742543095138195',
'836377124073058326825336187855',
'289594859614960731895746857053',
'273228715164466462791306554812',
'112636260655591433184261834864',
'957790722187484660264758268890',
'781154636150371276815761470925',
'348427554348633627055295312298',
'196031890674261500737138040765',
'484827158984677781203231388046',
'549526947745443981368930673789',
'990240653075278103665672240695',
'342764763443423742069072850028',
'279533760439610503127312681573',
'497866216855991807020093951824',
'571882181262830611408768782005',
'814629388628618195210681472074',
'689449514272623073753685954290',
'863840636681187597585993439469',
'637344500518243162398319662599',
'685254943577281672998628098005',
'649123082656976442700529880513',
'349926846088484992256652018287',
'133809490360590061379361466513',
'938160077060181930745125080385',
'457552773920776367400278659195',
'812786841227956175719308959204',
'223041051122640785908688016003',
'455207055722165263256454139434',
'435295459929510410862617024473',
'291820710912677549437197849345',
'797915672118989161396763456401',
'281767998642583097608471387421',
'594718620145914480457695071012',
'937735567622696071402080454332',
'757873330422427727068582735404',
'717883413765158348811521130141',
'990581670883767918781334661538',
'955341695618852115308141671221',
'401166687968934528039856640023',
'561162835198356901458525290064',
'831131871578876826358015377916',
'712215361522261185578230215093',
'995671721520657774384343781769',
'462644549492712695215454380021',
'462585474130652583319875133924',
'508511744554986099420697221785',
'471264049842525946843908060560',
'360511969919458681968001236209',
'429756312377525548545842679715',
'701857400879540241062251740892',
'738337341684977924014695126590',
'182924918969486203123905649249',
'264813069413258171863028574982',
'511659684233040506363687333851',
'259328773783690333293884076738',
'719900444566795205510122215966',
'887051574619781582805897413109',
'890292714062856297232769594561',
'200840040459432955982641191949',
'600226639667862096158222879599',
'908958229163279297139546343572',
'786445163223685969459302998364',
'427861142663482019737492851917',
'449064778928838766722520580103',
'640471334201762108449321697442',
'608427563456349665832090125813',
'608595668561146817802568971405',
'998111149810513646862843664389',
'676513343119972870340259364104',
'188900860284523655095387522276',
'914142595854158849975782753013',
'617336680475064655538429427317',
'104847642396828133753675453221',
'396037774235980681659527154524',
'563689782946292613112485392849',
'429474884321394801347973072683',
'492313126022553100783375333080',
'888354651969727954171958680440',
'337520610715251469113043589424',
'850482451982662936852518177055',
'218536690278144679456561223455',
'985011001517322499211770183860',
'953211796849070580284114615751',
'653462997514173280188328297720',
'773605580098373009569086740978',
'782777579644261183672957484793',
'278896566121470967336660973829',
'471686924387194365875557838550',
'376852489875570879690081248685',
'602961713589788567205350897515',
'991915824640430278189408861708',
'799185358034767116906159436811',
'740763650649545277040560151211',
'266996407215012508284878327253',
'681479822916938672670602570258',
'323031322569298372085340539883',
'407424831481266080261505540688',
'865824394508078593135405849277',
'997719961786185585599831354905',
'542517911892053849319429360502',
'119975494085263999984084238552',
'218121609302665481842240757058',
'932037664558420915784648433222',
'419429688703844436126406355998',
'398155847289684967464476223979',
'874254524981197126637717682734',
'199976820055159404852056416325',
'849054475547218286297079226700',
'289773198445419119900079484967',
'137890889529434350995124230580',
'452438856187276075782863874351',
'527492025467528782555581892125',
'591815327347388319218495820524',
'798398076909892667639444949371',
'335916557521658138085110935086',
'233586376348448718756718074171',
'278806046748772882137546922720',
'604012598316984607562224737630',
'838799609747630811837012551227',
'122009267041463960502667236118',
'345961135644487325980702458388',
'439376476065488978495095283216',
'992917993658545923008691592715',
'312503135063683327656537492195',
'718013666140329844306378525305',
'449161612321871526668179522241',
'726570753175766355607889612464',
'627913387455274952161143060467',
'760574008988530498226586334186',
'455000827154191164945232283773',
'340375941679531789896196740541',
'711235778316379126312055224612',
'797259482297233719214287659732',
'722674320612030356076313313766',
'156286374013274113281314845015',
'143645951756534554835390927368',
'837044915485635710144643997060',
'301690576673027594088297536506',
'796858148258260286115966961023',
'893937214650423298570958321323',
'657674199053748277509780346938',
'943365588111830203787375480740',
'297638318163892013948822356686',
'846377580104075939433460273867',
'454837359257681818725723288062',
'229268493165199124266984627497',
'911493616539872829249886182310',
'133966400143118513866300754906',
'255292280289628035672825816759',
'943574353555898590597021592554',
'546601689236898068172183955719',
'627945978373343498304707020200',
'970303099342482836871403685662',
'765516453025286943955191171717',
'199952808126942184552739698523',
'231512577358244114787859229984',
'820419064042619478985260729631',
'917113684511069410357176117867',
'742117536974253046183613210017',
'526820626973843608048508488065',
'717329053644411042134275276422',
'561403664283340296418300090722',
'849492663487466563270287947921',
'565760401738181599683364925235',
'288726137638957727255591063285',
'898978451558239275112081285953',
'269994907352950960624221179378',
'150489129219624003155129763081',
'212952729025188884838637715826',
'748510016154125012282533560749',
'410518340936596505397410094482',
'750303778214642738284013612210',
'652044134680238161634540315964',
'361995050053351810062383634610',
'444474113556446414767755092502',
'771209469566578885034305360510',
'984751616352856458489647825593',
'660541924818331280136661653828',
'290052112311359516211848175540',
'384771285086091004558097373381',
'802214533181178458302780932331',
'376579189011936247115003338819',
'265369812490273934404921220594',
'968264882866869774568862453596',
'888188761883284545194285828324',
'611424389648381893367323743899',
'840674073760471169362167394361',
'437765421633848933164494065150',
'154247710188793327494940969915',
'328086317553399056062358069182',
'282457524131930237961820767376',
'682440955861371882096102590092',
'407016610516071048081775331019',
'720635240566522530620446725098',
'958053653120882592207286387277',
'219775798143332287558043681787',
'793125705397860422925253325821',
'369028172673797927997661618582',
'216594810075168826698731036603',
'874925490362738494078670923780',
'940033314575999295804837910358',
'173138224085328145304191053719',
'355837907470520982751976680623',
'924848324893512057745075466619',
'383825556887920071723689213393',
'375694712361539583025501043720',
'585426463556817650508764530647',
'693009220173234357558427274079',
'979638154122541598893537055871',
'224203035184612190946206829099',
'539489841594723591341319512603',
'956880117894194836137798284853',
'636905105311278798064946871192',
'839550116810385407172890779925',
'949339362285319536150929836858',
'305964884684448502412351935080',
'899945663093715162458949240862',
'923216823377690277882008076344',
'412933397769457628686197412438',
'752572783849684864278608981871',
'541581554833344996814657246265',
'480848607815888347210018550563',
'336702524341643960453886267618',
'357232988636581469462123273790',
'107180570877535602433000396100',
'215796509877298249943625535782',
'541790243099975860151085148291',
'182439780163645276177460985726',
'770408348907115594740105297811',
'756756695845469473923586760635',
'571428574291889357096322222309',
'144779581409030633609297498088',
'182632798377063252146190459154',
'863561903684983763716167637314',
'698004490620986755495178988454',
'887270045661478196153448370570',
'421113184878819273887727983581',
'364540057954316271375822462111',
'901450658866171414627109361758',
'144037066222396069413698896760',
'306774408034410386820491841003',
'787374069204067646546313787103',
'466276238408344986472065338035',
'770445433334378392867656761990',
'704310234394013979081424366786',
'448883138190379361064574517825',
'663781668494111963073088992765',
'391487509929071691291413621246',
'135471050253643259145995063746',
'101080781842317910164751673013',
'198776379754826312597596890013',
'904976034484618243026881812311',
'285752879988028510880957139847',
'465795150775850708206157262409',
'767015889374142413015322496697',
'104089402854862445842781551224',
'272060398270077169510713833891',
'770110243145555378074847775174',
'935864952004474380068929644292',
'877485695246668948203862663902',
'726761035435198373754350995224',
'574406770811759379756932720490',
'891471758769797223009515781682',
'511407827032596653372720047084',
'476599390743336142159030724211',
'423632510654810248763876979883',
'856597799060170402481920421611',
'575322862661784075066471642003',
'121904330419188805517563362948',
'183407339616733072579633788348',
'970247608136685291216062797687',
'482887128441375995988681611334',
'852987070831215532038830064485',
'153476349509281859133738520265',
'948757093838485438069132514798',
'113003655885091656008000754353',
'225219004983224191155978193148',
'610978636348958045738540999985',
'981479514364221527544962173764',
'341510408413750618261180438809',
'730774474548880080754961040782',
'251108580461629821292614133852',
'799060706234637008414151933575',
'306652280830081780040991890393',
'808888767002416295362693669395',
'912934839219216193859645192719',
'815540954078981945637274175395',
'564433679483068147015664551667',
'611585482025349982941742692399',
'686416194601732827974480246051',
'432088606540567267904002519268',
'430787186119268848050382331968',
'705839420350176724562542146860',
'680165178311187315290598831929',
'784742912865973033801430025503',
'457047038514728548248151857715',
'208956781139734774961421035140',
'994833645536989199391288145139',
'250687023763394542468508615110',
'667841184947855792505397138120',
'885722308278586425825678888635',
'107238113366963308208087694565',
'862568962477482568436922565211',
'509430781046432265501020794884',
'417320556731566164679966424602',
'433841506489465468499301776848',
'502772032341077628573656478977',
'548891630026662601281847999487',
'155555793818159224331836594511',
'543423217515834420714822535706',
'467416137876998863940082237327',
'634991074422638130959673326659',
'632043324704787349672486930727',
'774227746025322314473147456139',
'540011994663288239336841283491',
'284586400221727223020564292471',
'917736917477262275830851422216',
'358977130002892982988017932236',
'247870312469330751586964590050',
'988014968887085812678715960729',
'313389082035424386102987040461',
'962891642841690382820363576472',
'860598816072993447023890099141',
'945985818296054051389035595900',
'281074239653382484125863863853',
'903004067531610148546576165094',
'990799821621510525168843585426',
'974316733688896702712762464620',
'715036294304827665206370075381',
'929517615712734417315219083122',
'292872460363353218854581254705',
'863967223233525768030661753157',
'635541396843489174538847920187',
'619240172809235027145630629107',
'643228461247311096206875233244',
'393715326856870985379152684215',
'265102412546929551246224756636',
'952650944550966271079602582201',
'592349007921023665591782168939',
'178565534259630888224133413668',
'292801676030281483694020094418',
'878417241224545599644420954052',
'483759851277799680722452542516',
'225998710816325410950095839275',
'120201146357553244111674778070',
'898054226017574846928459126017',
'421492223009751792160418470448',
'322538584579091698371938565756',
'513577873408472926258457242616',
'186413181233785615587363689398',
'591001965484991500582669788131',
'820842820192395347502030786162',
'302751426188764951299365064382',
'985345433544383642023627473199',
'532096514664532346708334290197',
'667976754584779580685409444015',
'415516385956245768655628993124',
'641783184578290301041824025766',
'291116319906031266458404795492',
'811181080044556554390082936721',
'202556412008370561255106942601',
'228060214509649762530100233132',
'417371736824083690396583139186',
'977218182381676176177926470605',
'625232755024255791414191420134',
'512492013612033816004134058379',
'772769905756754634549818654432',
'496966582977010122596913034590',
'148503858677157599693449325497',
'393999563742868942240082272441',
'976520872002149183162926816329',
'190428977255488102977120558458',
'647680541989964795559967454921',
'134445458988699543424187826832',
'313818700503891693198770298690',
'946960650372067225238023013887',
'876110018503474849940283846631',
'136188242016277481781619167207',
'441409293680373678277485653273',
'243087293310987464520930555049',
'647019987065327911320849845136',
'762937693114751138196261456259',
'163582057248942274791449878786',
'399253233781952878309168970561',
'840588480015119177319914422014',
'531376462948270210708290820396',
'935201635820226713993849757238',
'100268458858097218728798535760',
'914046815550542749021394967558',
'964619354662395846518392366349',
'696063448107280268290781572674',
'947219837995736318605534466209',
'525224415233044424804295361767',
'332903751079262927814947736581',
'593582768779361106099086936131',
'502581309220142326288157846164',
'741611999830192642651141288887',
'368348748048141729171434125971',
'643455875391172386536159848280',
'912671400647589538090635630348',
'585681469095385323466624323523',
'194599310970654609007067778738',
'312626408849733283381019730468',
'135556457501782891369089382526',
'828149847475093171953875438709',
'879316521936726419491619072556',
'709802872055287362237380492388',
'584418986895667838454172882200',
'368033962564769996885359182951',
'360315595941938742716611472318',
'968696256708656161138251335671',
'266396640264368682468327028855',
'947144795463950475697857612726',
'252971968292479047859511337658',
'126268447080938112477238051290',
'653718940823179467476782361487',
'384343500499091030093380779436',
'915913732154453284472663773206',
'311910101312952705581158659850',
'347375519587527011624887557350',
'659456860594975597023524919109',
'256912623833362821947007973991',
'573582390556398905003502659982',
'691471412005570417570101211155',
'520124096749313484629124249872',
'216527051865422474238655460396',
'639339496695169124136098396448',
'365131517375293584976297067800',
'258066654775066922736232498759',
'340520258443765133809353210665',
'546193187414365824586495850426',
'619403735643113231792397596237',
'605742275799771911584148693191',
'791519324745168273280463452756',
'201557522721190105206216585721',
'730687253668017230201866344786',
'352959695365727587247020244163',
'539909607135272213252217153417',
'555267535012265512094462164311',
'311781485166854893635213237515',
'514736522425784615404710665310',
'834417411350221868271987195279',
'429068850535658847829454878893',
'620150682167796910252531338924',
'632056134336648825194948325569',
'822254693506545230177174373641',
'553329503412593355274902186937',
'345347992048749169006375364409',
'105369734227091406149659532078',
'660700148156490585686825047897',
'188030629093397955078564971849',
'646213437756832377884696793060',
'424876751052278080092172555320',
'718745048309119209654667990745',
'751264268791110431811493855461',
'283796600695152639311564514777',
'320626283104690523209783176884',
'220412998996235080899887711915',
'871463218873629592626011428967',
'849080193813091439711386251252',
'104514538232354510454420167728',
'264545176889886910709586858941',
'825121859572685660898762723074',
'122370989784217293284108184552',
'755339797267995337246136182203',
'371727326816236875699460347380',
'601064729299481695787412955266',
'896671838390157781343011293630',
'855138214915965424690916466083',
'148295832971848810680323341622',
'856269556706424682731800122120',
'943206609855145234373901442298',
'910614532777027514193087488134',
'141743179098433189392066418510',
'407477056657460505333996497255',
'669037305677912142732977842271',
'594564119370862244962913636836',
'264250670847548813545933678292',
'613789220907233273012542097934',
'650233133246945670535585972443',
'297840281588597860120178426864',
'607012898116824387595847463077',
'800573795411271485307116556942',
'376653685490819233074454940691',
'626480325281420431895815378430',
'763421359407658245791954799336',
'743544573776369817783842482009',
'782242742920138849970210642021',
'352301373246936689138555120974',
'372435650330427446135361011732',
'700289469916433940460272973506',
'305590984320827442466451528642',
'870326179207381420936097696517',
'378990469284995517329917851453',
'715323278643355384457514939717',
'312353670170320680420063913926',
'840684245622361885701293582215',
'244389397517494322250635382401',
'977928763710910767952695453536',
'878973498503936191967378818530',
'672883752908179295278767211377',
'559076230711648686536501633162',
'268443046656167558703744498663',
'120906621057127939556637048741',
'533845754881260232036215366874',
'582514587537717123160716319328',
'904114099041668661305335177995',
'120716111810860446238353774112',
'975137786489374741654103290332',
'602517767805960438774655378472',
'778565369368296505334960768380',
'779573039227680549312320094015',
'735041056559355763821347145852',
'632732903666745328046052673922',
'178607871240668508514946554460',
'701326111046498858631865096405',
'947352182995548646309042773380',
'204166067069924818219416510811',
'770060777172346863257210230786',
'412919835690157104633810470636',
'440296463785551979832436135765',
'245514672889233456875621739400',
'908513831783894540131540454078',
'694371931119153319472881188716',
'423592024206467251121390850967',
'282594360073585293242913065378',
'265771985766579646991456220143',
'590721972480218230513803950315',
'917685453408752988958628723495',
'927848168821252908714778634947',
'750590416092839734235379395139',
'122851758088125650815902985450',
'857348396369831363845108928404',
'359733832006313639555070758295',
'839215534280312142285513753800',
'995847714009499081063112852545',
'718610509693278531813436178546',
'458058462018169683994637388753',
'771225594851544346698041561905',
'137452765811133548698701039450',
'837384152270110756322178630800',
'622320515164059160028650735648',
'522851874601368723021311930308',
'165417193989127221635728343664',
'161076790056277656991815963013',
'188135534093898070769701549945',
'992194404592273331141267783026',
'416294473412925501287390698157',
'623501177127440201804331116557',
'634430907533512511177497874437',
'811839458316634252723933837386',
'876745920237363852336368820510',
'832623757938610072443841174078',
'648565580781350655841877890581',
'645897420594098671976946548634',
'548749720771121308082095212875',
'217821810917595432849836826236',
'740308659390120314710625894950',
'630938766028211936972384425717',
'492492206218330240868476674254',
'739795251654940192614898868526',
'997243414823077244949076167520',
'631004757748840974871412980045',
'631901145309974315251795299730',
'342236671649831916555842543478',
'266193081798092774203997964157',
'665350804469653124785501057820',
'974479327453761983996867562103',
'470505778682478577699667988596',
'451442026869475632456373846023',
'720710717294049265678661065062',
'730854035661747214647325364064',
'482652734812877277335030366535',
'656119785759375728128447036197',
'364970036295824557782261591760',
'754062348731823849645770132467',
'751338485789184420044076384480',
'694179744010286535998798457363',
'588375409437786140666239270151',
'950072594013082872387123916584',
'845504493308365237133096277390',
'588008012195511780317573492691',
'758547989984938366808869988794',
'610615372300166348681949275504',
'963298160396945245924039297308',
'701417384554312817028392026160',
'638803384287918161723810575875',
'188571106282923349148993417394',
'150043257482921415352881060631',
'851869674511412142887757010229',
'550343122081492437290782277020',
'421958867867547285796720569842',
'193240542107226726938505197536',
'599207065047670753249509168900',
'105183578419796908590093713784',
'125028681542846840123954972533',
'140885261225587733695537188983',
'448515330416790547000890585242',
'746948144415071583050164921577',
'458852407338272434205983875168',
'748546382820440893754393880538',
'881989690187425333879902145727',
'755723869919116132150718821699',
'901758982546462788713325555664',
'582362881204690483424334882733',
'711281972307469850059559053909',
'427892071638743586595192213572',
'217085847026147961835571435390',
'609585115026097640208032191450',
'622640727178824924406919343343',
'297563418750646074053284285973',
'347228929146491731134348111620',
'348841697207182017016338540981',
'238244042860288085663665089623',
'493666191178472258521097782233',
'363324827391144236411390682284',
'168521175504764589887707722118',
'612769811214786312581665057836',
'651914805542767164477235691674',
'808242135542371767630281447902',
'434101824704761989590874618703',
'877996610442959635923178997268',
'164957320490726763087554336586',
'806587253728246318630337741557',
'913921144923153417347299117593',
'505987129390147441729539424167',
'471031974577989781416637557857',
'114853690754480538654900455198',
'749901792465986208841233265436',
'601717642187063207700796044166',
'854196339198361636620714891812',
'254600293843763023412028198287',
'997029535757141375676714097993',
'845287938289656909241946172997',
'844861758347067811169278399866',
'737005149397224482727107313998',
'124380225513039671419538754813',
'830063182212484451680730859441',
'707133308169348170270835471872',
'208869711055159808062370556055',
'219349071349010199973192170216',
'938226642580512254718145462120',
'211749205557729146131447654629',
'329883284078552067057311536510',
'757472025622589374193626772146',
'229706672541483508611835182591',
'603980778896347571369770080355',
'433264698027846234715851974407',
'466732481615690759368729973882',
'644646774170296529423834058084',
'461089663892370897951410083032',
'387402833226436260310406299319',
'394242209059954727731909953469',
'451010889702164623498142058701',
'958778179083242408931062013036',
'625236349571433998606207178765',
'910298331605621701006761981261',
'994925013183464744317786116818',
'758868306011820100451182092286',
'525845533427775593140317631848',
'119986787467930736262769560722',
'262677044975818500388805893510',
'941905844631367265385269598998',
'847048365191666333182138012998',
'838787698350190171756012454287',
'403270231464883675230032919610',
'934595345422764461537113771227',
'357262725302613285845336974361',
'168414193115538236613282280971',
'441252756024250644388260473638',
'902818012231115198867965456635',
'931470385260075061890612796559',
'459694686725123674688940721283',
'236286531247925352476728443856',
'122489059594018400748397268539',
'629592230365870190911601875701',
'695727413945026913338898885362',
'972200305896229645426034045315',
'283741762382449967944488684676',
'862629292254923695682326731130',
'374020837988958164592559281166',
'738537300517571643214676494892',
'995572991492113181268091349617',
'741187142567965072139545736146',
'262778872387882450974905455017',
'750030068335942049985437482720',
'407164633809228676167323054074',
'182054445312445223207192593449',
'838387417850217320865316056443',
'942966955935320407954559082336',
'728601793396373132278843925560',
'586568387369284659353858493396',
'556163669969195030519327115781',
'151206202829030062889707457453',
'571644972800936063298118636927',
'896549561713172956269146042725',
'100714997817080651640421927515',
'609997369087273420034680135429',
'411737820845842489264958438461',
'799626308392518048964235982302',
'281167401245474900206489424877',
'576224992880521013696390476108',
'685266241815341795731767930936',
'532720098452462668923274043571',
'910292507117767170481750235107',
'902136369673048302062502989470',
'140190607755399512186155226231',
'396005240334358105757210841110',
'462859620667885436099126190852',
'558284504010845314365290571727',
'725097837102786519238527249697',
'803502411846578038966365243218',
'763445967974351555818507749060',
'961300626136987890151508173303',
'540601486305370235609657597380',
'342661315135423694552218316803',
'115316819440359696434111782466',
'353785431631630796657279583467',
'180873488111547925862034343402',
'991764873731259455993923833381',
'841302547787981165838406327951',
'462743126302280091153079331843',
'628969580277058727562142247367',
'682444735967040169635940366890',
'671409889501830323879140217799',
'856764222113787384269582748947',
'575376798525275645778772051488',
'993355848762565224274298040808',
'311835130688486487244632915251',
'558294881055078650638607414855',
'449292391851329027925875783276',
'578858199327122476167061990538',
'632167438256999570311108438144',
'192600258941549827561660015927',
'439042058501032491959843383583',
'284795534191145281496301916140',
'337642308980299072696762785786',
'752407867176345441313752964376',
'895551807146578501143864295247',
'747095170499822172923676618317',
'833647533098173283800701472235',
'156465909629571207383706941821',
'447172344385935225952371146131',
'751484055968792320098710534733',
'293102770024655232428763415775',
'899128939979533196787193529808',
'346147347900025054159127913252',
'725569604854698486071786895532',
'613375060153654762460723855857',
'944977440230315871400404961382',
'187566969269876151209588813962',
'299248067430523103939040414543',
'978387466870597075953736014682',
'424328406421646504370011397850',
'789960554773455642058172640874',
'728650316753684833220970894077',
'855497318264066903100563535262',
'376943840924948080520132262127',
'849913347110414733971707880335',
'390126696594014984212248851244',
'957560980614887328491353666116',
'391484936306474267470791549350',
'886029291042438505528800315452',
'158758415293976387365959458803',
'754383258883699105158823939819',
'680536341357945315448488948462',
'991870106077547173461908513255',
'300292590423265954549888140863',
'485550554233489613135043036951',
'673218949373656028450594495834',
'913094319042513394744766933158',
'543175017397227027105800176819',
'937240970410574134362144312313',
'175843807365333807647027973154',
'412751848535042658261989163880',
'825338598841951736659305844824',
'872961689781687731284022098474',
'776502445334548665472975549371',
'401198088015893442790649257410',
'293316334867371069618838347291',
'385248328150223363043227991011',
'623129847148834435365964299749',
'689719012291129525977130244699',
'982184312530487248312190181783',
'739413316513141150781571739235',
'778487034920856669459033717260',
'617830218228890515703807690423',
'914356824224345570182826358776',
'824563317065030444585226771204',
'484057947749537051771035937633',
'496891259777698325018709966839',
'154675915861347908904122933652',
'900700109025093548875276892827',
'866727586770857518021727276692',
'788069322926817156599029293432',
'172108183757570917131352313586',
'172107842677072352769746638271',
'104847966415468980640607058842',
'861075506517261914668582347410',
'333840724218869694181274162616',
'864673109613136911809685646583',
'917043573881674844001136664362',
'437021620232030769649794491661',
'997142354582585630993679514785',
'372288556372241630200096520682',
'155313980782224878738630684885',
'447027885195232375276386731843',
'891082238723175332046898553231',
'557204612950128595087902623508',
'506303557875989586756957085244',
'653015507669484251406486681519',
'878431236296748545870584065090',
'658118380619761944141515830905',
'495962075879251487228753237220',
'285971740988784755750193549601',
'458886892684349818740555072822',
'420135547694613418489205335947',
'106805025362710990216770725290',
'368457619804819015870311537236',
'817936062110888154087531873169',
'116111270429017982289096137763',
'970366692446911394524167865645',
'704730354010493217616275749122',
'606487027719791059546669380054',
'975887456636110011864943374936',
'441187671082144312238408928669',
'148612892501584674603792086399',
'837669369363472178356223729733',
'354019049062689860442503572843',
'379471629984336194446543955094',
'831272032453926496758576482708',
'736470732656756717080100826569',
'354462153263243849008867373839',
'247097961419183351669299151646',
'831886222831682672413911994099',
'757242680611721832488450986239',
'549706138377576086926349680690',
'930541030400110586930533691365',
'245744350535164524267752571048',
'275671044775940684770173752123',
'738456391124333065514849358886',
'482408324480790156546064481649',
'513545817786673671647884898969',
'702887935399090546581268229614',
'604890495702538722226722724324',
'648616626256010338046298155818',
'928565601123865245457631524999',
'963799316958343742750624989140',
'464658054828997465916153414535',
'608309048618856785444410884758',
'754048358324055761122966872482',
'660470254880780049207773723358',
'289623558977244721476867193890',
'957764641942979022476848813305',
'907327862138396769103475189599',
'366977329825115913107506057587',
'151890692476038504693946773318',
'617429878238420776361339422027',
'836902346142549326700496894957',
'633799080564418308309712238186',
'634104140618455215460567433459',
'933470104141367840682685647797',
'224545913734583866700884013866',
'193356509764029843141484084182',
'288191221698845548640641474676',
'631150312439944018079017272649',
'123102360521130174841249597837',
'617194761222662636556494330084',
'468525170371924274927217620579',
'228660360194360286354709031323',
'987082691009415743697909567244',
'297722169152853718884146134872',
'327271635467990896109513717212',
'389562106008299387746935695923',
'838177305371258603287830460622',
'959177211203391469404919239673',
'631673982224051248073545736780',
'537691136086966706251821749059',
'364334819804161268461889245688',
'626035834140032591562293992577',
'906585073999549941621069154928',
'132051737117132685991303219445',
'420858607994483322727639242119',
'107265787274137671886028063773',
'758180596058258873207481488467',
'978159216020424984673875631707',
'347091701240491104236818450554',
'911325019838325537490774652521',
'312858850010785668921954677051',
'382948431183659436484780545673',
'635152999864815026167445513675',
'761889794858698980729485780205',
'532788030847012329794608477686',
'605944094765763036016270256735',
'931388832835372147273817887870',
'822907198651523608662800672756',
'997881954056882929682261789342',
'865585307717841290156749152166',
'778352907598868908754431538381',
'580837827124363695434366625818',
'310420538960996981347123686683',
'235331350824918773987800420823',
'418709689952590337792218528555',
'571011186038396462668055284072',
'297527612266511724089255625965',
'464013423628424554671220513355',
'316670668440679997438729217819',
'217438787812542362369547890067',
'201738634512295592313047233653',
'128245370013194703937360940709',
'589965443032156012936014263324',
'132634347941669694911336412331',
'485013494838661633439239058572',
'779865325119240541948703024003',
'947630356353686457882573546661',
'235123035535222173889466760361',
'762655624674712664229083970991',
'627961626674828635292276037535',
'218451155119862707374380966729',
'387351753735752108586803466537',
'598692477771261726711646235683',
'574260767552830856344877712268',
'662443611955186936436957288892',
'503439471281979398277294855548',
'746441415065978026967315518194',
'927399906816735613479523713245',
'428267972454444902884333768689',
'507748520455963092054961025727',
'242996209072328828910466019803',
'731088224633227180471891184954',
'664541665206344032535135465532',
'297058731194740356011061445366',
'526173109188688306284909679901',
'923760050317674051642352042176',
'245005456445349091066931843260',
'359347796585516052577804354737',
'576489762000688974350734294354',
'905440366232316993916042667435',
'718034071152492501569279881649',
'218571230426241814208434185030',
'203973063887134344274688117435',
'656545528508746806823007880677',
'795849573243769461172770266437',
'891671340219699376526175795635',
'662676354175137737285024480448',
'580998819375311949294823478982',
'101796665974680020534633750047',
'750579921830329832081346023316',
'673677543133581029044564595146',
'604462348439332114501698475708',
'677272125618893038046098917501',
'585911272247851237267756216226',
'412044691471918422722852635131',
'980855823813218946781140962497',
'235072657135773683712514895913',
'374715674596968020379328727588',
'959303428938359619740633058833',
'784197326284118783929437340915',
'324489887590577734218807998175',
'978540564310478971242835445667',
'617402649091311140881096319163',
'177069255681522972020782985963',
'530750998549917898461963422415',
'324786253569417984555803887244',
'492094865580062163584484469703',
'336980007026059292210849324999',
'273189354524473217729111793176',
'574557340525797680357021178620',
'517107196266950954518822812077',
'864830395208530022180569510431',
'870397414304539278500714123701',
'385205180919855301346746885030',
'579705139178783952399382764140',
'679836847085682850116916844076',
'163446541276092855742741737423',
'178463658057193501569159869772',
'362750664556971892172871668526',
'332263681279378626934822068684',
'822242135547233536820295074654',
'864970932102480006484255387916',
'790400036270670242403068280877',
'818300870047413358325649236871',
'812379186492775399125484782127',
'468484686764755425589373095375',
'462430060061538329117822090902',
'209680955467421803219086265683',
'445063483231922115082834966626',
'988074315616492733236531094341',
'798845387235232801762529138506',
'305088370697275466607293723544',
'709281751887050208405570634430',
'732770305440364025403952522217',
'952079657420668802728294744370',
'982003669312054527723577795062',
'381511780864528003654936112233',
'669619617636369491954515733636',
'380300260481750617611889035531',
'899234342010211919168479823344',
'500155918696830814262231343287',
'664832860793566658550366615239',
'105134784037888553653172830979',
'477275148303310739475596626483',
'584413754258778701407153758751',
'272755277952660217579149986138',
'251013428916961007784219725547',
'876158293718998685269532124991',
'116748711962896056008345556976',
'464095774358527436210169143662',
'629347039519534791436957813826',
'902710162038756365768030866261',
'354529195481823385327354375018',
'395947716272799353565184496419',
'581900238276366463143369586503',
'219925903059260234539056715947',
'137138225121192843906190580806',
'658917703014851489835587583030',
'550366187849614140629805798676',
'832425358658752487485186127045',
'784076298399086267364528327528',
'418758092535656268139050876961',
'263081472035047686624948415603',
'154676171912985626150603760299',
'288692710899566105615312737403',
'784795520889928047567105734922',
'859323912570647366792415539175',
'759338074914777035284608552758',
'221514636414627226203801450864',
'248814594515241562447811026414',
'264993342045692516688109019546',
'945567994926742803749764854884',
'924250626872679889663827753825',
'684995929874714441665661548980',
'220627893025919937943104914957',
'436165018667721852850278275279',
'748129621906729072013305764149',
'568706530760139377383612772851',
'254081156359053990867303447225',
'510119867445989292066502478829',
'975226614351978356892738315937',
'284395956857428223224305512663',
'584322891283553157788883720313',
'347475469376695369239975631448',
'125128222996615091965845985846',
'244191830426929547761408747843',
'278154335598782569510977128518',
'604146453234527906776940281134',
'288306731504894007877009062389',
'978949296667061282794697194065',
'713693548842932191375868878953',
'659474504157232296821617863007',
'695350307124063840624965193845',
'549016082749727441544065046589',
'634161767252262318894138349806',
'520996865305750936047057596368',
'537984663943474619300027982512',
'302570769409553656086738279911',
'156762731018719235436731810019',
'764312008706274465219232358296',
'370269014810999000622912485127',
'519811139922142467944368135787',
'354229293758743005961852285570',
'291560804397676234703688024366',
'992298974371357487013687067118',
'937069570500685282901655251120',
'679939365343125296997217845666',
'218547812678812322966026215563',
'530266831989541443870128824947',
'133465446064955039549805834295',
'116866409925433141658573391678',
'965222912672525888462537142902',
'714262823392958372447971921350',
'331293938934451693995529148723',
'145433344563871277510308216701',
'680214474635185507235425223524',
'429739634741224286396573628958',
'279794324922580578237054625920',
'492653394164064825665438041317',
'247391245372868495591546569364',
'498119757262315534540945179838',
'719079985232144178536644174013',
'825203902489587606090076491840',
'562561078895576645559467361875',
'714073686216257078317030315413',
'566037214648313458114534319530',
'351524231008733891435304175355',
'449721855195789213601115050170',
'167689107573082998549221554704',
'933600267231839454149746578943',
'198932451462659919625677988912',
'443552716894140995457789429970',
'790132161284951876843296159968',
'131241570019061547708896141945',
'522180768026851695391331113381',
'507537855814252104274807819232',
'312960601785880555504599130145',
'878978130679799859217196858779',
'993077147449973352063846572233',
'343908430051528875728776726004',
'415911372830240985373995916637',
'161829490757544799187276216771',
'657206101571444553452426529371',
'353492165017040417622367164045',
'402472311269968202034940643637',
'169713453607115749213378294115',
'455008741147867345512439790684',
'810651278247835113033817611210',
'128687456421447087399932113742',
'894510791016177499923181661112',
'654484264331278557057122835233',
'924295861967196363385666091924',
'232785325631643680241151878075',
'928300399563381030740894534874',
'689152350536028698228682540567',
'780281338085275582131273110822',
'353261642216012732196304820586',
'582726631076068545942624547201',
'427479591269090026397503273870',
'417589296922375611832924492416',
'431990946709758484009282124861',
'184053183639798240703380488078',
'488892698546577332011672723852',
'580785254062933633822189748964',
'271773491771887269921605488634',
'645976346258762784753350389113',
'356006678898072501614222766891',
'199413116421612181070085740330',
'407423894638441913952903076792',
'155361260852655296831401111509',
'624953231707714921570981949180',
'615041820729966005053771018308',
'463946339996497503376129892716',
'139765884351359755018320496908',
'537397185807780926029958714332',
'397857507585960226345270365042',
'917843196966131900939070721634',
'125759590245529898884651964874',
'430363191713376995507236078218',
'684433593150113610068834322815',
'919236458510388554026261918542',
'210413858589471803278214690677',
'422021845794620283322641220161',
'567169555030877993418810695395',
'580116009735757243338148993273',
'518496246745464121300912318261',
'261226006961015113759713995001',
'634879004548686914177457026167',
'769212476998760470516952090850',
'485225629306357908097711819363',
'642369107901489584109510564864',
'541442414036577973523228280370',
'632770747471978398838098874872',
'768148483632285231110901632416',
'193097555378584326966880314325',
'409718529749679812167674456912',
'134149681676472497970807589606',
'278532429751182685598974437682',
'944605347705327348715695758149',
'764904120767629627561900664386',
'457272132966523536587491017148',
'137865935568163248500421363441',
'276280612925843590065541879928',
'937482565091665778533707218112',
'789782050233479796984992635398',
'460841094818073564360335874557',
'786637660271129000638423988289',
'328105945915645045874534144073',
'571902699493095344456465146054',
'615172450108670539649041125926',
'646536084044914718508495325799',
'398583772655795781317184473086',
'307565325413659140751032610569',
'821504539954413367545464017698',
'846606464717468560856828515547',
'674428769626137284588429681219',
'881752138938781472486889190101',
'902759092939541623274217584056',
'409097631429621247773782415959',
'635066604156668216548070357419',
'159424998476439001499304059531',
'380928426783478392645672595828',
'946027586329538717945166072986',
'264449553177055309975327130456',
'860815909420966168049817390489',
'995503428853751051942938828973',
'543223936208380359967278642503',
'744631605528664786378393174756',
'177151816091538790325337453034',
'274959877853715997179810735919',
'488608917950625456234597032282',
'471002020050643982045896431235',
'862503430078371541727502434534',
'711444414599942451983976128125',
'303022824203083968385763097786',
'477470359142684627694872920356',
'194485062258123918983168836275',
'206909377059168571990104744378',
'415233448911591878930712719952',
'460188220159330821645520162474',
'685854847710843445299183611913',
'654390653465597834879820525562',
'308472033064867947214676929212',
'350966836830280028220294737961',
'491553059767176599164977150062',
'524013729809486729586527237495',
'269393756436675539903838694643',
'177917003711997911635648224814',
'591883392010393184956289583746',
'211440100274123019132999492299',
'716531208160851084162173783830',
'212916092765892177733689015303',
'614526335141020433550261393600',
'577509981704473111388610534190',
'795819245134282380926061088746',
'892778352023498003409882747604',
'667756623795598220122268055636',
'554693125654126623499800837964',
'685807763587051667786394513981',
'453789307511129390468389142350',
'374253643831427570346039556417',
'656906676057795103383924043776',
'439152239921077817751330140413',
'773965604119845369865523766576',
'400637270065128639894884928573',
'396524921166877392736836446572',
'138116796237159018108773392288',
'645340515849371324399438979657',
'316707569103719317473558943550',
'490663299207749261651798116078',
'531617102515743191603111520418',
'437929609269166070971513841996',
'207103975018480690122461596497',
'329397490604826260262203528693',
'428058528486925820503189335294',
'170874778939222609999706634862',
'902344052864546976371002241929',
'739984931985656757803768511236',
'738969101974692744754319044408',
'418182940917394916785154798760',
'154304742534676002513704690758',
'553681631827553565717356196675',
'594170864681123275694691424323',
'268221604411133673803845942812',
'657979369519659457189566440545',
'345807245284737717256973930993',
'582867739417852698665884473359',
'527193625530016708511384818875',
'143150914073268549305205070234',
'382950270474330531745599810336',
'178234966374141434946875316866',
'986401028420979162725406987040',
'558391598139890502330566832461',
'115244076666715513422957818900',
'171938125026191226092722621404',
'590743212155140719552360042884',
'924111097903143582261456684980',
'632923644088568025881101349494',
'844568742588923752975385177884',
'155956637037424500958118719333',
'492697671914929212381024135064',
'362312522916387565874130014254',
'735120706834332597954260515062',
'609041201283846915736710912963',
'585778982005653904393984124449',
'257857107155582844370290843398',
'860596664507228467600233572293',
'226524045279572319039005385176',
'205479656847810569324159950695',
'145128051741282740519789426514',
'667835455749269020994645942535',
'408896847457678230616497873940',
'636605680901437917991834278176',
'944029583041836476724151589309',
'733343911953016553358549973340',
'825653370128852860020561443714',
'213536606622870206095632975877',
'480198293201413746469487681812',
'999033407213357698592356167226',
'927318416811453454035655298982',
'315894008454480815677081545836',
'129544895133793868441747960125',
'413552910894784771326309752345',
'791274407183160676467266242009',
'221521743142826898241023668452',
'491121121189776328514672595354',
'862259134622376699467897532173',
'385623815834046339532893377339',
'124321906874029739335945774495',
'980660488523829270306572036291',
'864095660008454499975647756610',
'527660360482073881833593535296',
'811605789321535787461461664354',
'148012465418541648823897172225',
'499304666373458139041720150316',
'320024409867882145081848929181',
'567579765247013819779354380789',
'189765237685552562270415952084',
'937872304977346845192726829368',
'266577792381034571958144985190',
'479104587258094703850221771789',
'327075448785160518867952314331',
'442466157854656933281245656014',
'296580413851213787678057112682',
'545025128712875853564765249990',
'413030719274782403779555667709',
'596952518731798743827693856141',
'591149475137110968282020233608',
'692453507262891995145861898525',
'678571121954417068637879144131',
'944477927782036072576722865246',
'530456972299998939812708988202',
'348239191020562742735080248082',
'451852010523186057251950167356',
'275078559540815690849063737094',
'637913407931067358482639142318',
'883340837149969249894480256658',
'418063630555456900827075419206',
'742656329402491297990707514695',
'745876200162619842744971190835',
'883844985736176275556317284049',
'537683033069395416509186629284',
'655407543813177350058266212396',
'922500367751786148908290034674',
'356941177245785923229811562172',
'915952402080857614817290222229',
'302035971059399236957670663027',
'286873907416072502757409258457',
'354052471617427019615071953607',
'464228229992624024206199647714',
'329915471983353158348177866265',
'899473421911930935948509440968',
'614314372392247830561774397044',
'558791011274570042630550037598',
'473885948323069233544611525204',
'735473972548652190475692256522',
'904937761767745858441713017940',
'552717911059677570513374930947',
'691203783957038626331492286965',
'823460804387356352549245763753',
'634099277622253103384416733623',
'442424649767034079527464578162',
'212431425348242497188512834294',
'443322711391739207703733713222',
'788739579687536845675852598175',
'175620292119842657472872739777',
'545502904810366505496699093597',
'902429794200257952340445687246',
'910275936625598867076541079421',
'112295636205898187345696315146',
'151499782681367245133824961734',
'456921665310653834637731930907',
'589867151934791651513491563415',
'922335542075124931333770161592',
'816708368289651655613359946054',
'120498762755687964432344676743',
'901878260754213197201105112834',
'233939006470956979846106268618',
'984950675649276649128332387450',
'998727554602226799925241787836',
'842593478538148292768060928050',
'351158246203053393428113143432',
'472880711315550092395975835474',
'843343663674229854776227336460',
'921524816844690849292011362196',
'620458291384561611919102721032',
'915126686907021060381249725684',
'398953071388682586086469658683',
'741455574044444463974565977895',
'803059938856813961839807686630',
'214902831142862040874299746562',
'388862645739851280079910749918',
'253647901501965217886833569378',
'313308775109520677425706323433',
'473013189202285262375201870796',
'867582642542890559376634226761',
'725491492802565398633635397461',
'917541028639687930963970345879',
'673590885079782356730989845359',
'262397783199651400684686962104',
'714301675916487063912129222851',
'221244094386940546985814231934',
'648393591894595398102528351574',
'761369025569052783718436847306',
'263705382900855622527513129297',
'428585258890012470290761869981',
'558991676802711133998973095298',
'143956280830633923790527851018',
'300615715522757662379623573672',
'100655368415411736468220418562',
'258082152643179750675627245884',
'528016112778644031476616946947',
'699332444666142584666259432396',
'298144654658370006826742418768',
'372872796532790021575511186266',
'798209334444194125948304823728',
'503189247724011535195060349971',
'684539163262931684754035615268',
'155533210148143342459747163220',
'479774824387330625307947277127',
'596912249819666697471354358367',
'954414721378433602801142539151',
'722084710565717474592144390151',
'636164171657047700340760153935',
'193517580592173471079176389819',
'534193124762294898159070528445',
'199537427721819256297183414774',
'185373465371089710342481150448',
'542098936628225946877387420658',
'700175742724227111448811567092',
'426280293413920269361154947426',
'845280752422295599269975458068',
'789785758448594897021474358797',
'525372789316789598061053764843',
'945976164581282118573099368813',
'464083532664085750879962314074',
'129612075143961637388921816361',
'752395571852418492408422352959',
'873664666650455046279809884962',
'598626088230430239510503074690',
'967103102430859754545437164492',
'767961995943954738402214149191',
'224151994042986690393577887505',
'393646091182094484037679350630',
'263450533518855484817391993694',
'548164115146029220384144415853',
'227287675040021976442444867741',
'842847054301651339900115124726',
'349184574875452228103497798157',
'109683109489110584002745084307',
'190332337573586094322446739603',
'533253644458858645838492532901',
'286501423759950983873445319478',
'482769378165075583783858911801',
'550356413109282217326540160667',
'862259199523777963226011952088',
'601866695094999879487107825458',
'393281126602224199712679416901',
'627451782313727174265358350681',
'302600949665876255144429943713',
'227017185509343847810177343557',
'104196723798022608620373050640',
'268475935354740639488055592942',
'822941685273811935127110743120',
'354815267979737955267865011048',
'664659688397382552632091796545',
'151239859439187938337172817308',
'766021749981849532042360385895',
'283153450653181356122369753251',
'390996194004768444845512521729',
'804982261984114935754467326031',
'267147024038132320080399726946',
'585690635573370255507172622007',
'469692451877170583850773880890',
'842926930083681576619889496359',
'356468301597485356320027684041',
'962167636167539084932036441105',
'811927981488474557203313485438',
'735443332122064596413474611409',
'414980729854117615861554854935',
'885733695063332078053153496141',
'145236549856556509562125669047',
'322091738098416447227968873372',
'209648301508268227024195064354',
'688568753931758911337526799033',
'664720111302599955344544396844',
'179147315185335327566865846266',
'795523757209939026500961915565',
'772946823001781787061695536406',
'111514005399538912422184014650',
'492525633192612517495036490406',
'794461313488912093840974996747',
'835333263170187178881004181844',
'470437668157974631696652192414',
'920353008492180763684272851801',
'956768415486796804660661066338',
'557409609467122586138185895292',
'825403011808727203688163325242',
'573559111520927355906079373998',
'598107117371344481735813953410',
'621229969243749588494488297843',
'137378566197284604882143654603',
'337447397214165149318934917488',
'998512220866073912626038325523',
'125012522854754359153527952648',
'358558303916740838959264037199',
'644347459002581819078070753552',
'409466681781460598849804394268',
'780955340030890318781520598771',
'334104589850658311127453314811',
'967274075686914053709669829627',
'165952688039280588657617146043',
'841744179904444742203537393812',
'841888327837966010302900978601',
'621807170163721555668329353849',
'362950710392955384225218459982',
'134732820111240307521011753717',
'809141459418941491555213743892',
'429489993338862366966136813439',
'297729727356884395759371317172',
'515402212552786384256883819418',
'496458619084966083566905198977',
'108561534581138209323791134271',
'337985761675642630929355227957',
'558011354370348148402111933955',
'120228923418030459058141713299',
'696706838173126899114243655764',
'109156305307137497069634167700',
'656029184088854616309180697833',
'967006944270661779389324433725',
'995458304031341086028725871587',
'937420089634156804514658628959',
'965184063635342145074432795355',
'970925435578186621983872828289',
'542305570733018304910992699904',
'866247423969220514271364064874',
'360847680132493776112895853444',
'234014759003418511407826624666',
'182751832970153838901320228845',
'579018770754427197125508337206',
'773682976638598907720055698917',
'343851307433798858732297985656',
'528891785383813117310483347882',
'264294236267174611273791067807',
'925864367248355433439476617975',
'494807837656380398746053420711',
'771421714552746564083625685653',
'767640870192177102767891045004',
'149518364810265482688704026111',
'706628656035550916321038841685',
'120910704559126262983859462322',
'804024288035741599811635471138',
'929142821999071568686018631485',
'728565789526240235974483451182',
'284863723688218194268006961524',
'270066912879826728691447449025',
'296228031176819941451791442727',
'739871282173736316514072617707',
'461529016215744180754036370893',
'149612235776484258183684031076',
'198497418173859810556801439735',
'374773374641172500232247541999',
'361798759156266375553139590382',
'716795696245828525142603875772',
'656356319668713288692161563750',
'212417474534077952387484454796',
'355964166499396164741014455331',
'159695533905577852435057755472',
'901731509194889485944614149765',
'117332379945867112149054159572',
'893671398054717836600560073462',
'120289177373838831880586060335',
'913057559139766520527014591208',
'472010028844829835925250378013',
'463299325620036515150266396948',
'140708328254345624724982322760',
'243944968768998533816969542745',
'293375169779724405984803555343',
'501600018897039950962657336034',
'770541688090865300354885623346',
'404967811361548481756637337423',
'674940394865637169759254545531',
'698554726198152016085887216834',
'830369257604012651840956819143',
'819198695324485175973153650682',
'110726078675611044681400283711',
'472820368029579458143942476656',
'373920185945029068342665825029',
'546516720659785020356783485115',
'860961890655566896400856117077',
'252926847195288599375401194812',
'661466099777296657849252518572',
'883712868327644906450086119157',
'138450405691075774876576558341',
'659930522223486934713749879373',
'252922072913980461129588730221',
'395911411857990900267761160966',
'975701615270889563557175679082',
'710997364658038056058059064729',
'714243495182714793663444134242',
'131745704353219457938132753569',
'687549895198775821948708744032',
'971195561389637527094510263174',
'302731516559676673254680246480',
'431757811854493044441363212257',
'820122790983931679336725971338',
'454794012726764518252594624964',
'802339326764445603978468347524',
'380868684008134438893917962809',
'650056438841427878626758736177',
'151775807509617043630063692517',
'121823174015472011950349628566',
'780883470406092639887513216997',
'772781052510095278045417859480',
'239029419182420511679874930730',
'624371789057414371581387999404',
'200670457134151269352617485165',
'588261619566750150874659172331',
'243712538521285823748564993297',
'134044422945233812039695330871',
'664505895836523065790565176367',
'738268034559092784755793432272',
'735493074365125302411814490476',
'339689227190217685312298338233',
'862083099861181771096437241635',
'961822590920055623494939888848',
'691789334124578601292163250956',
'818714436944527649880402277863',
'632507672270315933948199412489',
'769946091480459233957279174941',
'574163898208224328530592059826',
'794193252249977386307869715900',
'963663333605253720851162361782',
'497819938586031057500502210920',
'792699601684575462646597950002',
'964436184406264709510407380759',
'877237693714276680630743344483',
'553156637706257410198242867012',
'347532959640513176146209952756',
'928011055626199915543710329035',
'158462449428980277750003497287',
'759646085269322101037621419221',
'263141901958129919476387166252',
'967781412693688385732184290697',
'174975587107117231664824496967',
'779243829648194433350067537358',
'579823071647825654779566516984',
'118952195369987678945634050624',
'909309638082466667512974353917',
'399632496869631640308729127441',
'455365209988658131500376034094',
'560659839832324735369634859264',
'368467553684534260580124917055',
'108678341636942869242587733542',
'189362903196783443525837080473',
'634373289646449587215020938292',
'974993824500843271922382436369',
'345836163338482464729529877495',
'543537452337962782305849687694',
'116353436529252509131040824334',
'593719240645199569283433025613',
'984456962540669494584157798857',
'199790579859133942183894835123',
'389342708161346486839001725747',
'799660220232642746955284958243',
'977116263664748416338990193226',
'665389065336892976351649390242',
'172429532176913184708496451145',
'980300025490847546989784369547',
'334152268828927701163219339947',
'520866119374480691341407824302',
'765888693546772549573733295725',
'548174122454110533350397244026',
'686274278953946298216268136288',
'273627788053231931646197645855',
'172354029210829618777971593098',
'136065225973198086857090328249',
'235415777462747041332746346398',
'559586698072284842166563972865',
'662228120188260691993811990940',
'844131266754471251676257635153',
'427163073961463718090987755732',
'968588141283628121000954968245',
'847624467545133304747748015328',
'915268768702318582753088117093',
'694340183559556774748565428727',
'983335180061894266850898960862',
'356986995204743136168466652323',
'429224465538586755328511749042',
'535492443083737575256372190329',
'991438669035472138801652341370',
'607742889518345092463290751184',
'364609286635672454859819894883',
'842331402108114816876455544604',
'306061327846253605835640185164',
'191796602103520693434047491979',
'883475075430113204608097333829',
'579344907839192371888677768228',
'319044063647164173982989915158',
'330711920965263308901131727263',
'737709962632375997999283911863',
'674300284651085202679361478220',
'132409068536144278141412350908',
'168233986110327338091952334316',
'464526202333158498031247751054',
'967480295630924573944206279185',
'477364507043424846701745648498',
'136354472818330980058690799380',
'910541795115924024349807573177',
'991039239881060532589004424071',
'380905206420257047482941666666',
'748282388097922065544398017252',
'210956044965734168395321098161',
'317328248032153322439149831233',
'166807415992061292808373070815',
'366959888776432769476440097638',
'486000042242463256409588258917',
'206854255880135208625568830805',
'683264274173179665273249095452',
'102546373776815236122247085558',
'446372936219382344957039592198',
'753508428741866288963469518301',
'272284582406312083871429834537',
'897213534113569793728898055027',
'191123089919359786535373285006',
'138527198269987042376704042513',
'958360720976469041375748752195',
'130252532201767613657585129972',
'807356938409983251880567434584',
'165314377377372736941153934992',
'896841745905899843651357847707',
'596855649860124942247936429242',
'761930311673557627503266864713',
'892966220438437336250007220020',
'918830860580820748810258171823',
'911538147034034408147521885561',
'862747355282616967917422448005',
'452010299325855326655209328518',
'151094709603214468029512473775',
'845821056606763020594992193869',
'685666109032247674149470732620',
'639028182054817892957042442498',
'223241682550082141782115992929',
'163275837833010804014757898900',
'282314790336180950924582795503',
'993451048772444697948566651571',
'966830749234474676933279975434',
'745746084588783719562782913218',
'908271073486874246912641513101',
'996553787223854165049566160141',
'704809729708986431894547948326',
'883755268748843979017521633781',
'217998672429492008775210820666',
'128784907518351352992874069837',
'463592639801591298910346576692',
'790720621000744299523174027256',
'364208649347531016964831051010',
'702309346941561353443837583273',
'496008565328386464658485898608',
'933421346569266277188118536992',
'272092589703732410967553670592',
'221290757376727489142092695683',
'891004897438671563926120419419',
'581806232285913629441627456567',
'372920010629815600949251997446',
'799880084301243433773979642999',
'360242962268456426940597124456',
'825246480829512802498235527193',
'363353785387535966845737566495',
'815886746398994168717657438967',
'754153093336895941336319540267',
'197746976432539547423635498928',
'523515747635455754574944120440',
'730123804619435400929318928906',
'649059697613814802864951859183',
'283833617464922917550291991311',
'847537410283830627903621940209',
'861064671491731707047537411567',
'251384166574482757908304195526',
'670663399686563794662716475966',
'120366738167521414666544187104',
'410384147316597404533255920900',
'565790898725754723660118658739',
'204537140710553112673726987816',
'645632575798379331012681982166',
'874696221453256199226262349388',
'408230886628315404871262874909',
'904640034368296993891958218466',
'563237462343471469265652864439',
'331312341134532324589559052964',
'120647363094398993662867545587',
'167289831985237159818685646199',
'178550019218086977031744632236',
'428417533857818129032887199803',
'406836653681844096833878587749',
'686766411225813090168792449837',
'831503513398821071959982613944',
'318114910350366963154814794733',
'643932059758498084683906959133',
'473323389685910068976847369454',
'518044996414588935331134659429',
'804668102276483749054800933785',
'939949203349095459003546059305',
'164781362073176472686612956691',
'726537632322767541973816554551',
'609671448800260161196943115705',
'878818195329870013981103888904',
'125554356145971773433754756793',
'918704776771334266349902494429',
'647312304937627415379134129947',
'433502201569684740119420389924',
'110019778629390397465935262753',
'196328501523212725731138423999',
'270093576218695804448517355035',
'880953251580973846602553750299',
'465514890280713853580567539986',
'774403683601822863713359234445',
'221947694112990695062474431926',
'420446566942277709993211424902',
'294387122939341718877726493804',
'604595403249497937910376323478',
'782499755373115879986243726460',
'254703475277122846960242188891',
'760981302947263698295983146886',
'593410838308989922800563474886',
'156812094689320215151683581575',
'483185762334691830771852723744',
'165543516937592198155920224608',
'285281160774180448214283541992',
'769615725840427705426397063002',
'578418382356484027889872658470',
'354426918509091112193824736611',
'564318290888024069570632722277',
'539828822111946795470770710639',
'622584442736168004473738111370',
'706348924050418945471163844318',
'476176170562011636823193447095',
'472382512095541395623818787065',
'965542305045082121626375496137',
'346505598394147213652202844731',
'954341163658092164840060670271',
'810635370950953460570904579693',
'751792679346377997792936926899',
'611812216898248143551788866137',
'388608316380226766154631548269',
'586807701043742577017748030018',
'990353190415267836302393257198',
'196661836861824248330131459664',
'862059198876163475417661971871',
'434027367568557208050384151459',
'964730812381213150382041874904',
'996868919340244443215764814424',
'256984684371123633356871684637',
'610409253516949886273402437717',
'140594878916341167405507818288',
'164641173012747232821745890667',
'710057122327894585045906511582',
'717671384926963203200141022869',
'784718869181694378097792377995',
'948393914575195766262798458832',
'203198486458842891661290248909',
'997434907494744084767889256147',
'676391603154475664997928833393',
'861736555135810300856040879213',
'751178473266612680348381313511',
'678776567004476469237340189439',
'898062269478757057873644466201',
'291570083013972879405389716038',
'774794027630552135572951717983',
'803606905531149897142279967489',
'155710579441722707365247383209',
'524347822186262042430369616615',
'473150047920576772077413056047',
'346518443429478009739575028132',
'258675047009949856545350763848',
'277872209891094146187925852995',
'393215723584744047945655251545',
'779992155135361819071671570263',
'814792364921742533790702793177',
'980311924137524981022792983036',
'451214021298777078126810886522',
'188661322974826632389246487148',
'263562720003935859214396483643',
'126200360495358440719092852090',
'969399002805614459011613769626',
'355169975787411398541368676698',
'924342191470453703483925865081',
'166196983775824549237991838227',
'502336756485490110438160569758',
'783089107362367941883925211545',
'917930248978297183919379928059',
'441047299553389827894093414981',
'220567462660174224503094253145',
'853035268623263014852397211047',
'758812546507372864451685831456',
'808573972592513964800507096631',
'108506214291881802635878322177',
'304094218775986437749553867254',
'114299233586640120062287361222',
'303697197078349168024925627461',
'907328723556293058083439373266',
'776444677039178746796148614688',
'452971012197360918962628467848',
'927725704278466971273427216268',
'808175568275024137256666130373',
'529441425378786731694398264256',
'856248877543335131403914784707',
'443356863060829918242381139805',
'927209425381782604755112444144',
'820300163390329959717600252730',
'771955308579254933896318491423',
'575777608498040084268281496505',
'540337973363911284164360167215',
'552644957568491259059028658884',
'783229161025218865717198551931',
'953041506883757258562436710233',
'777131807028365507018214658805',
'114390615059515167686128922378',
'815050844429794598994376534532',
'287715398721293560923478341413',
'479727063028662497323462911789',
'273623333058047286074891091809',
'752514125916196482928543235965',
'762427015138365480564901371327',
'112309002067518702750971996004',
'893865891419496904660980027977',
'429860258061665041983003356965',
'860247166848194012315066041294',
'685460674084640155864476385343',
'491339718457717588971732980849',
'751495866701379481796436950844',
'792767227509199935439369834851',
'995476952790535204669332949745',
'136161184724772898502058020286',
'423709543675944884142882847337',
'419242117705644805062781172675',
'777294175298857626168370985243',
'679482406570759845489115156891',
'581601137061733473106812817411',
'790659572756938974411896187361',
'916985003387728446268780694580',
'679500240200336945984132854164',
'568149170458246101262001561897',
'716977403489855052044503718008',
'268650692964073349598726183322',
'287813172894858231286727693739',
'611480151334116610980724473907',
'668122622029449425322695233706',
'767181372036654633609943545821',
'353051952333772802788016480935',
'642342714371372492516318036496',
'984620852549080723488855381247',
'404834812188373770854315466510',
'592676035717299218384089741512',
'998888251255359433923170087452',
'556965553610158942784083815779',
'210966957220552895125688321973',
'615609624542156645174545038240',
'229252523653176656405423240158',
'658165421393721827583298955191',
'318486786307611914304324136181',
'265083036796867572337439947037',
'472633024416754363251849480603',
'625249904019468150955511979217',
'593031671578024934929564313678',
'967450496991261442531512883199',
'832745775330730194816276674336',
'792791085631456533628972212775',
'298995264174133085889879082338',
'931549404830377357189522319799',
'946966014126229259662971136482',
'514202090026047963995753465993',
'700722518500689331888549888374',
'403313702685215847305190490863',
'521033859936814382548542880723',
'844403931047845888807867066335',
'934664233646670484018007551839',
'903426043793576542079429678561',
'904002029378995652870029245241',
'118677004979867048258637894268',
'881044469942135334178705915812',
'772802327619919994395049153691',
'663016888298311420325944254951',
'902987765067864912545609976541',
'568187092584529992427097342889',
'165710630540937597242647798508',
'467580944019224813412047190203',
'824615097899760200047123479801',
'732290347220141695238378219570',
'365138723023010265242507862347',
'179974990245164034989050533466',
'260519878187979904703486797910',
'328252231278820029144203253453',
'140506383133419317483901035348',
'468897687416368646571999498097',
'775796912662755205031413113781',
'103826354253346296550987588912',
'721105674554294003696762834263',
'274566427501082040864529584560',
'412843961678190552649060180049',
'894472086857331919671214475404',
'122845575965073678413147126361',
'283830627372345820089409012872',
'222850008304637421167786684516',
'875820100046870432077881624127',
'372668522259724757761035497026',
'989348469673969726880513829557',
'110926283138276784022946073958',
'901492410209294996935069398394',
'129606396695315165776083897082',
'209681609384135354367168133664',
'904785472671069163844235418977',
'467391359260030944845672663734',
'796553315579544501615992911711',
'878356774508748110631086854827',
'997711781351860308402735597066',
'444379862905117350466413891639',
'754554413406911102582225723129',
'194094868584331842498232211453',
'838621000261165247326649869349',
'622186476555795745622050072199',
'967120179973763921394880056943',
'128545462775056133086825231980',
'399751091211591058411238653975',
'539536051941114439193370776741',
'838578593717088679160424796808',
'584254230831268187296485988554',
'218383791271245064068287146309',
'793694501391849239845345992658',
'571340306410476220272880215838',
'592556223964715676873558863878',
'777810608008879513487200091634',
'971451266600931374000156029310',
'454648776944944572868681258821',
'360233160067925698571469392822',
'313604407504863083164558597650',
'792669113824541882280279412995',
'915544290403667477962189590732',
'275348834682519246639241666130',
'689656177675239050040644156364',
'306391640349978756043803422037',
'545220753787159960300358221045',
'495878341807629663726132892916',
'836231771739579247630726193514',
'515264418972423522783017539842',
'901038041875722468464836833992',
'212754731220652553731448651219',
'508204042179045282800541110037',
'428546852044165365766719622734',
'558222741975020889758452556222',
'378044157448862786666593888978',
'890177281008139923559416745662',
'565278374460258128093465030573',
'197237533051462177880560679360',
'565092234987227186879165476451',
'674104583588715682645445773915',
'110105015142030199447759474942',
'310067264690669948622742817370',
'275140837965374976052396892585',
'195410500655825267286290763504',
'200378762856171098281859015193',
'451424119283556151731237859672',
'766748685206923729727649923124',
'680505857636650557118877119627',
'683456689292220717163960867972',
'922614313167867056633121273335',
'721691381690371147862369319567',
'925371742277955942184186330268',
'635134069870428835032071198129',
'425737662613011979806279220840',
'516209811256858717843630981430',
'343486171937465298650979127144',
'689009626101179020412835081272',
'276118872437047248945906181883',
'407888815272465985393678962299',
'104775017305032471142236831453',
'702738830191956579719712135311',
'677900840170568026688835174009',
'789356090287033914399827833823',
'758830731569268326371123836157',
'909488667019422002725466723389',
'217913739758040665096859840737',
'490531300693716914339518167438',
'677718847187234444971111992327',
'970201464876153535190004135996',
'479261466356825890588131683996',
'636965329818277719773454194192',
'991809837988454701694355485238',
'254396117023485734603756247501',
'129793189942178904156018744513',
'142237057008780124566864662655',
'741166403686216620953004588615',
'632547924492347087485465212371',
'931704135836790805674942634289',
'198781939136779622264626723591',
'438531820417668126346779363101',
'787541897884718364678743292831',
'787004461582232086706752481329',
'550610646889456269959525293036',
'396326624851045675198900893596',
'165239698237277407283185152099',
'482250635312992652937046872914',
'180633022684072969858928767902',
'162410626329428777746466646484',
'461787334481397143338732245686',
'243998543390279919857772336479',
'910279222686374759825380749833',
'281232359243428258881512155725',
'218392379669139181656122015769',
'907435231122648747926269386356',
'832865461144539191453286228152',
'689761630344155492993850391926',
'749573583158765952458475594027',
'443962909656842134198526945297',
'110705978231126698878703667820',
'986365807053660962733241059925',
'182384306825591556584650432860',
'744113858535960816629396111325',
'820222525624310390701139838257',
'178668001856395715648018743968',
'547536530948180656512461373384',
'568814525765347796880115786121',
'176416817984141680758414719641',
'225278858419434025957060771526',
'230017238436998729835902149351',
'430373919135049363636107040717',
'514328181766522948630142517779',
'470437334785094476135904785505',
'635729969875780297414725565248',
'204161359990593089517029968022',
'461994444254172718491674369307',
'226833260291164209602765523693',
'917974081130981352270124384867',
'874517337655518503240023099895',
'208823442094915761498956489622',
'807725725799667123486405765259',
'854063277265990868411616335762',
'620632219174887451751052156771',
'771577185880626536858819381225',
'246370283515843193853215588398',
'760700224963716619580595286525',
'234392064576552030816961690501',
'211795489905847812019713174679',
'209796906661252714594903360646',
'158967107576127312313133945796',
'642274303516175177019731192953',
'713202800700081181913168134889',
'741235088384369516155724369192',
'747594849931945442486894649497',
'348020046595635423854482499448',
'665100837494752215635186665299',
'537413415574991402826788348604',
'170922876982229176384411073442',
'723553266162366622368898083806',
'527267048308838305702741021345',
'573221899389396596963865499652',
'473868058544449582116482865644',
'968150205932354899961659438059',
'821423013761877730660961187122',
'116827796583975082658739987822',
'625539789147333011602857619924',
'129808047849286840595691435473',
'824017930284428585137408674270',
'530919827227495297491128674856',
'121745406602139806562473076851',
'141636715368612116212057975116',
'888634501434382256451938855999',
'837718826122190259812324394465',
'230746297214991657185716143776',
'582709981438425962151744964176',
'591159009597263435953774099750',
'382134921256169597882691922911',
'455258170227836598953696819440',
'325318905591396161359333250569',
'618803484616275721626654886421',
'417173687176081288868942860582',
'567052104880854829068241860645',
'252087588664557106578641065248',
'403916982481057656176819624976',
'143084267277053001293254892506',
'218129844638947554341644525021',
'300443335446856461246092697664',
'440673010082975918919034232259',
'446416326271462900976699912389',
'948226361932994391147508362344',
'505927966024879715247675675440',
'756689995009941040828133944689',
'709118159694948066926707975290',
'732045098363468658373601281878',
'947315957088680689625860984348',
'246462976335325237490248624004',
'197772921084268585672269613332',
'458354753812339325681801389905',
'773280546064595305193093840950',
'795587979278758421965849897389',
'898252829349333363066064546859',
'559798734859119628021929756107',
'899320632000763535488845846250',
'797376302157316264015970941801',
'448541523158856756151435915243',
'665818345570254690656769037306',
'690114831084268655875561098550',
'284406250276551579923703115166',
'887661661726138609009459324526',
'718506879264725065720686291924',
'129657742357389381860659626367',
'416616623995261514130813628055',
'313077441779157394978981863584',
'123111024470574465539248158452',
'795246386587466780380368455949',
'634170393478495900671965679185',
'834825778596892955228535090708',
'539251555674018856054895830559',
'702790965544099065347570799557',
'943015430235571809179175667554',
'218909021617834753856347659471',
'157486208103398281707086214138',
'753147746220094726406978791991',
'395579580818951359156532924167',
'842394772331392118381023228046',
'896234006386124452074276934942',
'811843818158890627017952442399',
'388609144118597858325809298096',
'152906926040281680242395225904',
'654636265140994982839499254325',
'835360125584152236124475714973',
'204403811879618898688664033047',
'498587085098178351619851736105',
'597025044399561879898052391327',
'878998681599451719103940889683',
'413774860938782550183490987493',
'259036935198124101415912725928',
'672973020487149989573653998659',
'394509120145886075290955962664',
'332792862062544530978904812050',
'440194370840468818074277673963',
'179927601640473689046894658577',
'155119329989366028587289811963',
'795580927718840658695087319738',
'407800632747938286179716763920',
'965685652146591155535378673930',
'907953231107867938966661917474',
'472697322969984725282909280554',
'491411254338387635315812057877',
'199344427719424899383465162194',
'249452598502428718744154647253',
'382399627504846694105562152699',
'758956080109367142975315812795',
'256942672012076717881046210751',
'651864143699233648226635458875',
'385116523544823196464041336363',
'803541529558736486716482573065',
'532659956239591137955700633067',
'563135614716611889165614723554',
'110286853389986209032296170782',
'115257359627274887840827666559',
'807676721973653523732761380798',
'191404322571482726568236388654',
'642076432213830545676270619548',
'624227004658375551159376285439',
'739948393935756988757030471757',
'744791713151494297821055088923',
'226198171243310506766457871154',
'470352234774490471270126152766',
'686840743724363450349824218679',
'865983489522819549523826548831',
'230359483019614673846921772814',
'606715902625595197872817614233',
'269664451536544459943006332966',
'697013577299181835652249872774',
'847786593648793943960459262311',
'252167620891681849560901650186',
'135134437913655015240561486550',
'521343668214361483930411034210',
'363106758686848402973255344880',
'545861240599139471639196512186',
'597465710012682521340388091268',
'920420088225387616223029437141',
'667395064342393951619207579916',
'219414878113798887474502394045',
'750625618316662481314179093341',
'326873486264592649998207962759',
'114097822907167445480215778732',
'622134108442162938636940968415',
'917588391130962527139916229094',
'289875221363390963243054062662',
'265197004973455639551085196274',
'765645863018897958629679963921',
'862534052613887729773715610416',
'277309326999723996821614050582',
'152992176427848313447443922271',
'666755025432749056916515436877',
'642651148301370300819087499453',
'156302881714863994712783972481',
'647678998918953366579880274947',
'490696080098672607725331046927',
'613529926025246719728899271581',
'292300633234756178502056277144',
'516270647640658257619581163646',
'455358058032543195020493933858',
'450133882357929347822009720173',
'763385015071811702010021020591',
'264301884370628931050278368833',
'308733501462414545890812981879',
'636935122074812228099034530419',
'290028618271515308641984034359',
'936009505401187410826354354002',
'894917301265015802203868378799',
'724976968983111370319284234224',
'108044208032842578415573172343',
'340714678077053708947006510270',
'930147696932760815941081222460',
'979652707864225491596818850989',
'992730626955950212734795469117',
'237024096802699139658914994334',
'949229034110934353320097888375',
'949280192291748788654262260968',
'740441514240722616663888036310',
'969768464170837784580749730381',
'974234258798829988800226663264',
'522249060457321325072412584154',
'330139809554267154110845761513',
'784052417556141727491909148580',
'235918499983587927308794714082',
'116610719433118045847655277052',
'103330932521684098983015382892',
'532201852904624202037503083832',
'265537502315412417903235110252',
'710880719773098772812906975382',
'936886445605269370783701374343',
'875813738982238068202784187774',
'914284357575484926491925658598',
'995027233454282235440132479733',
'417277813400991461060821929018',
'935513476414552023959305614900',
'910461756893442870884306571106',
'564395795469298413118448362218',
'198607398447388266911279554413',
'279974697249779793443059218980',
'771917401557722425805108080299',
'788591876368072558841988459346',
'235084223367882757645161149961',
'531557218287659882775923685748',
'225065010959035688149467717865',
'442803096952449521608683650565',
'639053179997659146401260055185',
'898932873577974796969103914665',
'992898835121392078415662978282',
'716798603881833318342594654017',
'681469828114285408169131977385',
'370991596582791732187563533954',
'309276500061787043985071189351',
'308771684904394492718790638342',
'369048744664286886710991270174',
'190373785042887468099212584088',
'810007216607170334657559357841',
'995197774883231597242213253899',
'522860794187687335671221227748',
'621122161929279555979721840120',
'309919708246889360257889033284',
'222419497726836283212965561097',
'278967905803993886185708261755',
'296947285694288550453841866988',
'385658353210537105748498431023',
'871881102227417056386086567064',
'978294417861198277318150747902',
'262482314913443493614560475714',
'338947870223913885020349521047',
'391379018953318308973954262783',
'190555797344579357047575076502',
'221061821370377452549730081096',
'282822903235538971414628187732',
'663034946740814798541090354011',
'742238053378963283581536424205',
'742111853016565301226164037753',
'145393822381441764356023242085',
'134359622636234086735245281167',
'720465728620816170718380412077',
'955864959792971060936238438118',
'674429636394886230100515174212',
'498912316635478633159291535139',
'848404091570061913306215099813',
'831130752688421067379789938068',
'216857502977554094108469430723',
'228145007046668638323593684354',
'724584239446792798422185012902',
'819116909185677436212751145448',
'252383374198738012828315822428',
'941616021593275412385770736321',
'310659756991893228952323645179',
'446588237905877879692452545345',
'366627216267622982340162046063',
'759777796195652602591946863511',
'226390686178638650834204998335',
'774959411108186687268626432390',
'114334809145129694697821293943',
'855158077902966763532229951688',
'411167779297890199003204471927',
'157558512340548274171623954235',
'996110746720583812015589636582',
'713688148333758874398121443856',
'489963463883378317435028952076',
'916615026093459224990802246651',
'672666816901226754076272965362',
'298230792248849084267982247970',
'415566889220999913098224058840',
'269987698011921960729400120356',
'677273804079570235459063726672',
'206753747119513885724807173382',
'680188893170874945361436483438',
'675746128828330656929659776300',
'248877307632389479665009262695',
'889574006865445547237481026401',
'752430028257474305936192347477',
'762018810823962070090700647462',
'683350281620585721092916130891',
'243543019104212439704074420567',
'502943891742887907922077798715',
'398472254748471725280051365958',
'501206493342272220883538081392',
'240526834063725284294479288607',
'868222815870614626546616328755',
'634274908177299778257486256329',
'793874508521442526733877838503',
'952670803422446063490282680111',
'805070910456590664396847680507',
'256218844894859216414036220479',
'150625368365224381424955174053',
'294865860340729082554468870900',
'438973176152056758896817247338',
'514142163998550298536871163691',
'265645572322923396304787266510',
'367026388831325906232647558793',
'460827943790441458155015582171',
'885953859282216040630190260810',
'869407283549050929159241895369',
'505093740872915017885634078316',
'615129363762244579220053465647',
'191693149143928207880535744728',
'317345684076091500212579262334',
'550903516420382757678427077710',
'400394517843691437784854329125',
'815625240142173683965520592801',
'107668791060574931334493758083',
'454047449714317668177914031577',
'474591144981586056222066391959',
'996160406019254222038139082739',
'183154864460033804015376419024',
'391825060182863542528068978902',
'776456890045343715533493326256',
'580025897603358841015937127224',
'101960470386144711103641210070',
'173326817439966679424586652036',
'859010346014155248589842112150',
'190257016518448704015207278273',
'360160141168068260797628730678',
'115400451100028511630944731574',
'897069851270547514202513393296',
'439884883124282107384656613977',
'220055563823582494887701696734',
'350541365566637165064894634699',
'787619556681328449103606226607',
'580400460657368619253606790054',
'863330507457722309244489790484',
'789582990822536381843303982808',
'161666099533541501895181066620',
'923337124997957830285070521850',
'222991653039751759765369732362',
'468782264031449190082957046546',
'627991238378952119963471336056',
'921476683262917684152658726468',
'879869272984263827961524523796',
'883112739484318503193290498309',
'186399442998389575890587567516',
'568469255541586639659719350402',
'729551575091550275524390472350',
'549557769510155488662336387857',
'764476775159338988112773619562',
'170463211763952556175292771077',
'652481790510856234329827671359',
'466531657674841360415386037203',
'706302378446462667092200537330',
'905170841956266697078294384881',
'115065654942644430801088271655',
'614835563616573524274269250099',
'261669960073824063535858368437',
'199171236113011120892057172855',
'460981420331510368014208320641',
'672871450256429610647942378971',
'108129110886313774884847779744',
'958969629284257843160240876007',
'488123552453486344124595181195',
'890856520403131543191703719175',
'543929355012454944299391955325',
'614376166162141006001871022513',
'547655588842032206915384958719',
'691150900133560958542611430891',
'759998315596660234518376211059',
'483392747335893929693002215745',
'597105462557418848748550727854',
'403913702038395773109556867904',
'620959086121528636616413939886',
'310062680709887580670773698247',
'665729647800619807232466192383',
'298603005367861155215884262295',
'612491386189370704845098945467',
'383402337744226239255542691873',
'658549144036814406387231174103',
'526188079962336789983128481781',
'886789668711273678028838667548',
'404428832009020812260883915728',
'695907472047177538170872145108',
'892735101733991154626211525462',
'571510878843486257964016283767',
'784577310087171672370920553788',
'809092143103758779203866544770',
'140040764271166439119349555088',
'319682950192386336698631586016',
'723141184291658606220373285417',
'338703804850714435387873969149',
'143001014084368549238310071738',
'928616609434265882033919896963',
'797727994423972869462386640384',
'690012966049549759510861290389',
'666716358507797054783224879149',
'252773546984934534185445982615',
'823746576106064992607735269741',
'812018330317122483629084334207',
'988090338601576928685269365526',
'789861707339228018445047212494',
'894230336105813971394762349034',
'231510185603380826464263852939',
'146115560688434491202362314238',
'209209878382210247805217533725',
'466285670556576982250492886726',
'638588394222429740708595546105',
'782296615025363537659732515196',
'440557036230353804459807838976',
'980164783771065170483725542927',
'607769094492470345951889053750',
'480439421207764465846515636863',
'128900724008322383550198018638',
'858828154841382769636971649020',
'490888253808496442866371770716',
'248584953151957762657588918644',
'530716876077565824037023344205',
'490763493251179557422189218460',
'966691363724274220519133177567',
'243025332372063107772929921207',
'346350971345285290980134825618',
'249184437843632124331677414186',
'211232849817692252787559749500',
'124339109930624739794121327527',
'874058006224438961541582492383',
'143057437919221208279182131118',
'617715632216291668427984155053',
'805952843785681776562375425202',
'678717806168544944096457568456',
'694900919928318523196686322942',
'508963878465954570510505657935',
'658143905229779037014767017872',
'569513512326470334330720512052',
'992830154151212465430447053407',
'661878052407248893105005435263',
'447709227780719672695213720876',
'128809749438669255315119227884',
'293990124890968209805088055803',
'929419552361096279258724411097',
'524214428778332206651756125384',
'974841344431662531677502517313',
'892697955373482425055544025349',
'995522681430688692742632177707',
'862636917610136207011913083595',
'608259730807497412287970937380',
'584738405417729626742016438874',
'102139616188535182538650298816',
'792424392077536803339051481213',
'382186486049599437750893745892',
'583970007790347490209375138716',
'303123829442328045396279272262',
'121316126949072880284049363078',
'811401838583382411613218160496',
'223764959454368506369855173744',
'764628744765984761613806560229',
'140297670748775654338260741947',
'648575690468737884199550147428',
'630755555313729809911640935793',
'864294816261423460695472757484',
'144654868132090189538324240660',
'855279289819091795218373818188',
'948786210736146026104706672730',
'366335441561578281292055611850',
'356004216830858757261253166393',
'298137190874144165123306753068',
'145241862514981512386791777849',
'665603069829216603460510794972',
'273686689734775450293327894257',
'257581423716888467140570544422',
'415049817261259153027217083056',
'451030347254527941373856367016',
'308874948994937059613677155559',
'912018944630021526091466910494',
'737116540633079815780994176299',
'511402410852570025036496375207',
'988905588046011213726932564284',
'725504033186745409468173985030',
'994152092768722320096628072147',
'101564575447440312267758588769',
'456183815032849690916954831119',
'589991673519971271280704198141',
'268513989356677594749100611556',
'506182387957284399956571136373',
'279492280963788212576084213878',
'178451540511767011024268536499',
'538572947519420999039519863665',
'386198471418856932313459153621',
'884829031344717631044016880248',
'524704023532847224698329476080',
'117207274917425958545083398886',
'301643283981443316045970014912',
'365252438776357402333492650968',
'277536114139860031018674848747',
'818135436647690660539067249897',
'235914633928773781816088282428',
'188896899696657024935956873110',
'546529466677019174777806920056',
'464364201663174875677420313136',
'918437232903724839460569295870',
'419441472606731190907105192423',
'853462791094479135932006856550',
'933171052910734756422034929628',
'977470218904397974935472699713',
'146665904051239622856772837762',
'756555254795750369932112087181',
'130831443225595022843798538491',
'732766925192421865000502035140',
'691580965450076487458986475128',
'207587466463268767600213773778',
'885749361355815165184186883999',
'840454779510181169294128672201',
'886854532364151925334134022638',
'900259325944445562046334713353',
'769726688210413119230663649348',
'925293369980898375488709992285',
'892445435011447698502583365548',
'880517086051451180557104972801',
'397365110426416950868169880934',
'468430440445840079983630999269',
'971956339511182791463719063421',
'625236043644481553822598092554',
'206462177640742228126793281041',
'243921295858625022798595713310',
'455717927731432337432398727556',
'742260443264861875331658621569',
'618688197572150683736078274164',
'937710885327324038753114288777',
'378433661866835920679700216762',
'747649458819371466168734738079',
'489940049045831641173016125164',
'280578371569518033524455765633',
'240809641158551578229362097260',
'105476173012146278687868482405',
'407709507594615746289501887745',
'397696312678057423329598835500',
'341195230104514583356059986028',
'513441346515888112830799927695',
'330330208773194122488623677992',
'548134012455679837889901467571',
'893068334057723463961639734101',
'175773164338734229879485767876',
'715550695269773485089910998100',
'287000474722661382184955183593',
'739566065872190499493051260095',
'646289945978412467737039640563',
'721478515686999245311700437923',
'856332122820918055742554118628',
'340045641220590162371541228582',
'617253701923668611487212255909',
'864849969373142838882241779884',
'837567587792754629611166581954',
'791409287464688563732668125305',
'807038259492071480188013737731',
'191824968494772211231567058590',
'746136738039192814691946293849',
'651585313840422472600457913604',
'455102675409674322188255592873',
'719849378787415804413147173428',
'426094056606739809964988014947',
'304398135440948077195971762985',
'692513034424953828963325166309',
'432456094758759795784339874228',
'750806111607579025984532097435',
'358053309977755080809627157534',
'127560860889125315600509051983',
'163411646983259829840574442408',
'470138170789355176521494630291',
'354060497933644256560504683000',
'781208950297547213820041768871',
'888561056580159893519318467865',
'737002284732828325777933540002',
'898223105307925275539192735415',
'783457410502441286700336069524',
'118838894636249985889422099311',
'121533371445752821612658373338',
'475610420147044624271750288485',
'755189049001099157199402949314',
'860183058118552845414190969891',
'274324193166026337859523631187',
'663958945126243711843204715541',
'859810912123362321140576064358',
'791816781100856292881726948928',
'644379291039169478495237876960',
'909756249612777123227615728744',
'485608038892246098443608817016',
'932986779062699040113021442120',
'364221691527184840655622463442',
'160347678995642546676797760921',
'441293001003862002163213659365',
'445902335654569034853867597560',
'501676672578796920110139372237',
'165236871016644741331458686817',
'265981358968169730473248999847',
'473225466635425414324909052502',
'710505701273732655240330164443',
'934655044312395730286310745952',
'950235941574921400166287869086',
'182385972263170266500974826960',
'990019149093489307643016329689',
'251366854232567194985261760584',
'947067184816493302244170755349',
'564580919494273003425956164912',
'458215129689823875626844058879',
'133053886135088182246937178034',
'604007457067994615716108027722',
'900586868952370736423650982243',
'167579690507857577923815233569',
'393333158557194232788234478117',
'741369819437378264750278523133',
'870561868063513807538556697955',
'918970146723884735645436642450',
'525321344086692794699899229353',
'904584358787678080259024891552',
'164445122570922630439504786209',
'158505283947957143326253724885',
'793456720030890632855988595343',
'272542421053359520847713122872',
'359343340860922044324228196587',
'672551350897058512276268322094',
'499772997881376332455418559152',
'857696609415343399101127530375',
'599286516755491160874211347376',
'421131378768137152021094162603',
'334582011336048158875239266561',
'329456841746478080132567498659',
'200098949211083686583585715245',
'455236055430613768708528011544',
'438275150824869797677836366501',
'751918054063122469309253927923',
'939580586875138805216831540759',
'365515314047548530127392629198',
'519222651774667577707313883900',
'479437878636432132684379420525',
'290508436992378456723543064588',
'173216019033631701705925475645',
'453306272783012188925512675995',
'116710326765628695159210346002',
'844735836531319451767976848380',
'442628060208362552871943076965',
'165652359997216814015443287252',
'221920574415877831680180963102',
'551906536892556181137337972454',
'664185024538536882517688514368',
'896936630494576062384591783734',
'955510501415012235294420594098',
'615731672748595949366522547139',
'310018876666683418306794576740',
'509233403239559001351458664811',
'900236062159418824327132623827',
'305550970597846417536924028507',
'986790624410657870130222378103',
'177576990422619212977654457134',
'946374061529429317568222317687',
'293556101777040930374522396793',
'400946960854538427040143385740',
'228908159095212669086485834538',
'984737475671794274150021017873',
'767629094835571880247478727342',
'760394237077698739360924855420',
'916193123816236566124207095849',
'496706479283134169882248692243',
'261266195221614571119257857059',
'433074083588720154167975243820',
'970984627012547704926659776098',
'540617388697690325523879694708',
'854553155892482364527805677238',
'215290444039521173221866458487',
'172174679278071155400478748610',
'599682455042291684414187876414',
'612658731565522428148943879776',
'854848673083246095445586944128',
'738868787885443942718614772447',
'689572067742516417718235119936',
'700667393554972971628839078641',
'927928046701381591578146968787',
'728290489512152727948518619047',
'199284235332829476574657342987',
'364501278742184234580777944877',
'834950100216325079903180135779',
'134347547108044505528465773405',
'672484652032532049316499259324',
'109753358245813047157051216180',
'686620104534161025299305272009',
'232477436175930466571577568940',
'185056547439168537956516281676',
'377038381361173273787538263857',
'455028719539754065735884082699',
'346952163248117105834093541782',
'546433719047366865127261526152',
'907858674330522947482918864224',
'459386794016081446001536128194',
'361339855061349935285835246838',
'536587723157431996898167291184',
'974572903373390843524909911754',
'459920927605156381828825866832',
'323212740754520470297680116327',
'917783969344392059374433153153',
'637968329899833444510794382006',
'965962053721979928313249482614',
'741518436108418769669077765634',
'742101061345854310657190914294',
'203755685363065979081397350011',
'464218671335115296392535318884',
'855361204091339670008856254995',
'652357677925857680313783642675',
'680057423182123266587013971418',
'495845179671029490212971422107',
'319988890636169202221933884257',
'813469719012160270751993565432',
'260793980168848714334380016889',
'409893805333684953900644238238',
'758188159020996649320838783390',
'134084423149179080202948643227',
'539723534497443463730444422798',
'513613533520416402746293688930',
'418263193177997189917347162183',
'543422431296843893550575937426',
'943312987784730302490566815610',
'361857218299355532318728622999',
'736756523294532760987141623291',
'186972342851959005556378774325',
'947542481421737615190679447587',
'255526995524518284101236130036',
'176272355800132080688144414757',
'606021082313443674117965110425',
'635457018413557847961692411147',
'789995113577228871917167735555',
'897005054558114800308090330379',
'967082166276333971654533248647',
'313538648332921722364416797222',
'718113240347594003739349776242',
'623557488258827655160410939847',
'148987494778667369321057122756',
'824068355594746312085219628705',
'952654986726913699539263027373',
'683516338042284132627027923220',
'961024496897860481470620456122',
'954752295563316991312359352095',
'894521406775189458711949041306',
'158014053635243145960973138695',
'397720103819215709372913731019',
'678415090053964531459794827364',
'912640380102649408350343133497',
'917870789059958217517509470415',
'838855405152897450445921837874',
'712965971974829860383695128276',
'308431781133826469052749080360',
'602252928163776004408071049649',
'230620688621431343668903146970',
'742430211186117822080410010768',
'982824193502717968489763531610',
'870552341992452511988737276401',
'381428013953893843956627824537',
'511329804645483516797427496404',
'659927816375589905087626142360',
'175894235301820481228129294034',
'497155361279057921870285339222',
'962593656522131396156498443858',
'167636971279848694595344355110',
'332280030727389412710336387667',
'102370703460195668079086398733',
'349631975441870702051308211300',
'288975155586754433072819146873',
'340509768273878275452113669365',
'869682409195211616991875771588',
'659662962929791212920636034994',
'825702862297769582372597996677',
'933523903419186027955545396466',
'681995887056394585053930214656',
'115612224875973048411341223190',
'504576744889724251021146274961',
'664743877732917328439830960183',
'671078467470975125291724460464',
'351078167225349663489605178170',
'701851072516744234023557543973',
'665712679830129254750507155871',
'829634165160024246058539855401',
'168242850726537946208558672125',
'974769662978532076220652092735',
'515086510682555832256721652025',
'535998392762281628386698449765',
'583123099517072887944913384985',
'395632520054926883757671450892',
'929343025985225877904866575941',
'940362105473034420861217929406',
'710802134183119418573399738265',
'434521991880517754830676173617',
'461117871950050476986529792474',
'799984429075874615443751657554',
'573531696484910486693737613747',
'248844477314998262607625619456',
'646304224817312026026019893330',
'570124999782837173813286471299',
'933316597808820955953876176036',
'508698666771182517003494645788',
'529025001653132557007538134531',
'203836088483864074100174077362',
'375209048466439552333827452184',
'609888600329884250106918172170',
'984017252734499618203665599410',
'277795511484856686676586276290',
'241319871941355678631257146702',
'335040793420643695542448441122',
'328254435746523649152891179636',
'188707407478913998277736681604',
'577848471296478274318702460864',
'561051856416925840126886887047',
'107662924736324062627139068787',
'642211833824235659227699921505',
'581634996054535810580807433987',
'750931224909877732839701534146',
'672319664087559019374326439583',
'609597619136714318523975095897',
'342887117097281454866381248934',
'565964389088041625368091459925',
'713490509491037544471943011302',
'526610852539296993010147238474',
'807106614723995277272323042739',
'482698594721874397455218092027',
'563035265419695450254139631150',
'689700328183279061835328986234',
'939859463340028428213644468457',
'814781091131924747292324229169',
'886639024209820399294539573391',
'774037177957545656189948240636',
'701839461311089953061069573273',
'727493658641611588221872491636',
'558798685223436776921951234082',
'422834289363638529057293257697',
'651264808526655841063365382350',
'461833464589793581636940872644',
'341363065108041165782297830447',
'715235003920383568956419884114',
'524948493211213778930013586834',
'966528881052573467669365751513',
'443825078420244577283963226093',
'751696396794373801890344771963',
'962570551533183350300393066106',
'335444534162056463831604650201',
'143325074188875016758866967880',
'757204615102298826186950068852',
'301662386821691330469304376113',
'922092193730257100765017344324',
'902582150387616675814960535573',
'304093087294216798396638627860',
'409455686239135705094230532156',
'883182019139531918211175123623',
'310264356654774143883065556854',
'976094320475150363045416473435',
'674186103983922422990595288186',
'605387860831061578712804825436',
'177473022255137681154029618486',
'181875519292770921012023783624',
'335399354547045890216312561422',
'536670941536212472589415919082',
'934416437011565232383693058216',
'129163363952367683370756337648',
'211862241882184185030057179657',
'175838980683037074209559713987',
'712721818846559481857587354893',
'346749344257838342688832934491',
'812549394232497377449732265209',
'730535236940476338188237454965',
'443272483975571637446318642444',
'477518499613913500769755488839',
'806115319160036823028043131285',
'956616067375618611764874327945',
'317682219242365099864430274238',
'608519187710899482916285157572',
'847211028587088314151674237571',
'348403131211978043159967213187',
'935004523871156434476877324799',
'604005660609729006626365837844',
'653647786118818979578781190668',
'328127701963091026984667379652',
'307803041626822320890992435843',
'735466948851047168264981237749',
'339930612381275218443732351882',
'853899565028656990345935778334',
'906508191707024828529184630220',
'731408327525147198031605624574',
'640667540316464131090512381573',
'990476587175747019310819868768',
'504199262805219849499481412698',
'182904415654045878636471331331',
'887712234084970986964073766938',
'612739820435763820398794287812',
'710087925039593536142599472423',
'854160446269737855369708526460',
'312448803714950028765307537334',
'838646017688813915426975180066',
'596793447342682342625651616818',
'450599644954843887847321157362',
'666259611907861019563478583014',
'601448578754882059723459028773',
'100190825728638576063242114710',
'117106337803738080421645993586',
'211383346421992979947429912905',
'935342833718826698504934634063',
'807339602622510227596707434713',
'620780212955353925535088070479',
'117001231267164463225031387640',
'387916570871198416671604559865',
'726443488450355472978995354062',
'801576025983177919136512843567',
'341797893665265562470763769892',
'394472320655661785740601141033',
'258527137583463055792286236176',
'647327560981646216041107542407',
'954602534055717552462331998184',
'596144869136456123701380424032',
'557431786787761733389575025530',
'769605310456451802484204420494',
'846001852282997484860910713173',
'973481721703967611678344935251',
'522159633903624140726215150379',
'152248047061374225699825117708',
'150899444484785458251396979552',
'505908276503596110839215520708',
'688068693040875640725206184170',
'439148782962212869996690748424',
'776526087173873601710352526570',
'915080501603827487103547811422',
'642233305145345772119408790583',
'741862239537834783700266546781',
'453326045239037575214717554610',
'323830157194549060284398488270',
'578103264750138921989647785805',
'762176505197864926048494039199',
'114635412156964916047089039475',
'536367138068451757100148378998',
'388146579682833516418693949221',
'397521472371090616807248713592',
'264678767185942742527157218258',
'805081764663321727896845969977',
'263572793858098664957318944976',
'894588971232778533498391439761',
'541545634262142764654816718215',
'382219013232392163111190828135',
'434831906439468649142284212946',
'568907795424233896291100440913',
'612299695361353029442467131006',
'186863249388964040072654574071',
'399448359079722591259547589164',
'839967971279384083586853567664',
'420103627763515793520941249422',
'747658843004194815579270579019',
'912521602527465588444773198245',
'120150481629725698595969945194',
'384867632776748313746699894643',
'712915959404063764661058855942',
'598081488548346828234510192155',
'798123305724845475263515560482',
'949444681557958259233842318515',
'283133100752838252287593434787',
'545961440332449614472627222263',
'153152550143148027267051193172',
'845546674671533756253840673843',
'279903880009097244255498713606',
'692261148594340724730768952705',
'428465997441765052797780689479',
'146223111110859613276514519294',
'596897576038799633231004644928',
'372027647759397938641701771957',
'219620355919560865275901396574',
'270389344480436394489141621920',
'771718678537548331306287091902',
'991560935538814157767067692762',
'979469060645211674192829179318',
'144496881803470772207481987112',
'695395277367184127722461947332',
'455123724995129401531302285978',
'735420561623245664461230857930',
'512770751722838333351726926515',
'765943330884036662543721832213',
'538708533034837102350349233789',
'632716481792965631479144638401',
'679707782261760572565800677551',
'715268851062143087306418959499',
'366374732469177227992866225944',
'522245635233553115208262289786',
'936277078901819662181456474316',
'919774022112456257243400548743',
'123433316972250772893157980277',
'702374077494880959845825723332',
'824701072860336632796762834814',
'957575470152152345280188011320',
'726647366392853298166120594900',
'895119654834148646763588769845',
'485091713753952069735150077105',
'598272418952475144832472573368',
'247583028946145155688953428599',
'414725882881436489525915987755',
'489515926252011302015439295806',
'275396883471936081139453219814',
'773392909752866976526973649348',
'597304338351770968147972486122',
'988401267911624730211916147870',
'595143494285131399078031442678',
'486589730931263911515778166295',
'324073702239273909369171428296',
'754267034319317312901429816660',
'684779467688943123041034491902',
'998996159351726253637358452206',
'605062350562745119074179891644',
'768497567801791555411072742287',
'963855731294929632298420157892',
'824718505769060975955465334828',
'903442227242966148181119687203',
'816617825098689304483716372919',
'879750645266051093962182828099',
'990734470816331759263794716135',
'356692763589567241373447914946',
'500603998492459560918978859166',
'235004608703684929386564652336',
'542466161237275718259337842580',
'413226302290155018137103113823',
'444859031875254778140500131477',
'999486314087274723148344258258',
'670251634560224141096151344094',
'894918693854138834203790959392',
'443486807224232950689648861845',
'970493584143894900855523194348',
'932682088908128223799204763542',
'608409244392099781390859388999',
'724869531648177375342142975296',
'547844292232417634336448186248',
'900259711832887065221289122184',
'995804078059178197535275262184',
'867962726736382019215721778441',
'349274135818771941594138673338',
'680721024256064229500196331805',
'432433566578555618327244032481',
'862430654096335434798388012687',
'607224426965538245711865839535',
'527323039141276887415980448778',
'291289565119056276442677736875',
'806960401273227593238530369032',
'466580975581541148287933235313',
'991206218022223067569872972180',
'972478121211189478576031179142',
'429825115312584939584647254994',
'253869195582487059683300025429',
'282649217268138173990853271018',
'519201319421214263569712938718',
'827742054057831737791020940788',
'890031840858755115067463524920',
'944586527547062466943824745133',
'943362510930984898111190392802',
'597625741961481572323497633031',
'372775661441338402592708579785',
'541719689342238027849304419710',
'404987730257557570982867687821',
'994672814429242076217954395225',
'967201009774923088400769073878',
'397386354962636020111813659319',
'972759162047231199790816998153',
'944424461604684247361033119403',
'989593820811190787548253042918',
'874859678273617585684582012651',
'828269946421370766503419060487',
'375139988773620515351079137162',
'272969174885890960196056663151',
'225263007827190989108655388491',
'592161880939861958435616732309',
'286070828070253431185224399994',
'995209405418331171579145037460',
'429097507461453886363159125544',
'837025141241670161760794150640',
'425240240418951393639205313675',
'437378150630715338593430070413',
'600982388702818783143270347709',
'122934887590029053763696938379',
'622844353032528206204022431245',
'832616648001095790187061828308',
'454007796405948866560953154809',
'423644809369976175714056463019',
'549827536348281596134637277045',
'126514210668354974512750163357',
'471169188592595358401609291144',
'319264362450565219044834358891',
'997592670676060610136636756587',
'369064740571928889605819327583',
'865762213405057934214159721364',
'360638740573781507717362048295',
'706236631749132007577424192552',
'528421460433510118660026527426',
'730553863182717131537588963704',
'531130268494315318998427365356',
'318579726526910556933586761467',
'690817202224018146824430019652',
'719644932885897939317649979325',
'363884771143369643472942951644',
'662750180851275561119048726564',
'479261660752184461061375410288',
'974695063238129145462621692508',
'551506934578970480969945349921',
'706920638651118992404318548586',
'566548556803879472246746876882',
'703218985001338832569431987905',
'738784258605963415636682821406',
'327086407610346105092108839673',
'539847237447623230161270987242',
'320631669006850684655722659933',
'215319134485661449449775934635',
'306346456908426149041894290255',
'495818157731795464101016392749',
'829107265898173872869881146560',
'591926523229532722212600869492',
'643327430234268183440137099242',
'405187341549838964034172288677',
'553528407356326319541603471732',
'716756092416036424906437933968',
'303181718784222742020037793513',
'401792284041554045910343631030',
'731693839046435535876519480725',
'897659607552686779100021996513',
'585786319737332129846696596106',
'510318122481879951935502183761',
'461839613080145659634388722671',
'997549395068781188555983617584',
'295590130891969082130497993419',
'244135078701112648407371557069',
'884007832682054739331891423690',
'607031733907399880715848340009',
'816622024133823210897071615397',
'823550502065384700580648042699',
'707876544896759641004382396748',
'545463724964418459592337598268',
'444457006996444994499752099372',
'602401511047593887219996823630',
'242018594086693281688964416064',
'665337923060884401912860291627',
'929543565530685449808321998449',
'179893718857837288358814299600',
'317158383646852636672557331814',
'350667984916661412975052298295',
'893947328538888148036028035188',
'993687431073734018260592667450',
'603408663278047380591940530681',
'343024622413127944819756198095',
'661059424624683555117440592527',
'324600832426711089721853931291',
'707814264705641240195663858957',
'294145093507030420708529070190',
'792688756774447855630291021695',
'838913074585757241923899358894',
'668187860260947711198599345806',
'862224487885833635387685560763',
'851078934948285930371015637369',
'312193747384111119090003829286',
'263859095433516405335499969799',
'514229600409522261884397435111',
'139403402719839129903192095271',
'992958574354187445002163134334',
'965035013720334573523958978348',
'359593615074021714908080890405',
'935848554061882100753241756725',
'458298686023329335089509796459',
'221891693569774048599752082927',
'657869276233224406518637963560',
'355440292399075527455685552063',
'347995231447458276357823274433',
'851393121572353568367520266886',
'818323596878023017744967116654',
'586611789448283083907217327972',
'406995924977838915090236830165',
'411975553960712467109361397682',
'622230254733077917245693987566',
'826722204849312442430250166280',
'255075235188755602698908579624',
'826754668257081922216913892432',
'486133907369329011707558614272',
'489580413873666202463823837855',
'216896611964816619579617885936',
'135643919464864692779875025367',
'299952793324831864810197797180',
'953087801532669002937371913905',
'573411604362779401560824770144',
'877175658393927988752586751355',
'247138154775783380814752280742',
'415918538389962027016329639511',
'385829555445693576043570680881',
'751129239071135551213771099097',
'975839472729210974158296124257',
'517306599926559584320853841537',
'827007585162085579389276464645',
'187429460558414436106451376713',
'645849816556513130954651045125',
'234476896471355802344777713161',
'925852725420976364703097663184',
'434973070390834176984891173991',
'145205883300897157123629946312',
'447160804334753429147453648343',
'411744381318269866578321234557',
'365557879726944157346358370635',
'320831973057865863782224212764',
'935881050539267384543205275965',
'553071226724767602465851235236',
'465508636246926007007860560278',
'189996038777188484749426723163',
'137946285921579198626162355118',
'649254610711874775115135294964',
'276030666541591805696137058587',
'289041478818636769729769446544',
'616537669490754678029909977602',
'136913980332023358565522337029',
'392248217515143890577413119404',
'756241699261534549266737917178',
'915443292570237374671426953450',
'288721356208745579128248145716',
'718623258066618188771308122768',
'898497912544346711529138971645',
'282260284209989745360018973368',
'225015516899488606292446443985',
'406710413468822347212716672702',
'768305675670716757567591661109',
'241023824071155396594176142216',
'774076607451721096980860219960',
'678976214887340379205282921185',
'873497258652891495299390072618',
'823109484222783701338350762678',
'522571469485134529039579178810',
'651725025192052794333524062708',
'352290359909794511933416250643',
'817090545027379445817576785027',
'436228243978155267171270268849',
'835122006181615300194325816223',
'604621388064528547660333923600',
'474525305858980779051869587495',
'472710646266973797823347715565',
'972399115252965896506080261378',
'599624991247472658317059110506',
'390528407882781844993427991919',
'856467933594063643733726479330',
'228655893322231446470367152586',
'922449726004412047955855838530',
'306569248115377873010626651039',
'235303495197421456522801268325',
'937503544931198789370373979136',
'543352015723124690262344583338',
'112331383766368880510075475753',
'661809112620860412634954125790',
'702819764566769459492033730474',
'840781267224636257284342488359',
'305753638097593383196208717824',
'325318773801825501736904921353',
'918321549596572163279805279558',
'856463677551741728034898958758',
'923065653576387863509480816922',
'252220618177693157769269274962',
'730686156990719531624301416436',
'772250134278967992042526314151',
'446257073192082495435797922826',
'209834328324845636052313354325',
'104292964522070655844637927121',
'109910527146138348864114164575',
'134666656158656385985626397800',
'648501689813344047430444739193',
'412560259555522935191084921129',
'185877316437798272385085621799',
'193549741090539486034648868897',
'456243081157371400618005578707',
'445585878233710652742186098033',
'312455350482315393685385270039',
'482166439734111306496155324888',
'468494954876479522466960841801',
'699609740975863958134752942569',
'593971405953479665818797183242',
'260920422685728951706596351927',
'732815470924271447326196093494',
'985567427213123255917565465546',
'253962393556544837245451841007',
'138037306816623075735830731155',
'873286183635794847069942941980',
'231966922354527650403726993170',
'603948241191651256973852851515',
'880067599468731339964101328256',
'259808422032355279617842569521',
'877064741571635564009581331143',
'416277376490358205242254316159',
'787826830114595894981777514037',
'284815828439952182794664028064',
'144170419150886952291808485276',
'132091443190667548928119186516',
'677903218119779949959539663008',
'513073690828339621510085424511',
'803017957595289843702819288209',
'615141355179345378822495771559',
'449908749753597437530892549676',
'210682263185095640619618373541',
'100770717986993241083356971630',
'513725177987657272702694052377',
'782601977863335295399536324923',
'246739323771999684826275685500',
'979888299996028404830635495972',
'980853498324579838049467062895',
'943839835442794905587722327910',
'901258918548999121891012461455',
'710487572503699681540645081887',
'672861208348851555681175059008',
'361820791858827197836433699008',
'279926149068041955411296044521',
'595323910072650460800196472210',
'481945112969775765967877780634',
'600276316308845166201840613934',
'447070958982847173744709338195',
'562943805818795568807179364223',
'459551888964593518781804119691',
'771137290220279423343673684627',
'800164517953061122443393097766',
'274343903469529255121758311318',
'486047047995471767624103074265',
'987993826855670759390170441677',
'972957576352954837115557960021',
'240808043707931972346305227266',
'423666787564525814275175343071',
'872432794748358881363822068817',
'552031872731668795600879798004',
'231006157465775590318832881640',
'245426001942649821745174648817',
'751544441430963638551020037463',
'285770428231823211203318128891',
'926123385443445934708185528125',
'824273897456838327072770252186',
'415099883565919180016806510365',
'539990906643810818038608131395',
'118935130890239114314404183898',
'650612360476050512536608696368',
'259743881751766464632902437717',
'605048135818178296949470047192',
'992604237538112649250064825929',
'851615688107853495559456225302',
'542436270396417988378580678403',
'389323021565060320317457058922',
'357573520893680479450408165749',
'703009530672883430603680429906',
'878310257178155939921184087949',
'538573218962071907553188477556',
'427658715008864575790671778666',
'949395074676656128368923360048',
'328988994930699641970915471095',
'193418364500098961916693883666',
'853925823653265196802871844424',
'498955558722776406232697065620',
'530165445402176878750928354110',
'873889535017069325275172927448',
'341866320463636151873769720810',
'688019030419887485669652537325',
'356971008762443464654045753156',
'194973867511090770205432470333',
'208873818540394566476125910579',
'271467950122875742423566743243',
'550333241992135018542494347074',
'191338499542236182523688376420',
'443754568610049341377774467679',
'955334732356317137995382929734',
'400648193501422736054890271401',
'856359623923121249897072653648',
'958465834276990866185534562226',
'666127768864390822648353611888',
'392403252250847091769734732520',
'400432863752932353747335358021',
'468734902917738342594445088755',
'465777785084732298516497799955',
'126564263042143232833562351232',
'595725544466624871977428145471',
'756035903160861428120122832428',
'224299583662814821620005034625',
'953350156701612130495328760711',
'685455866121688745529565178526',
'660576788875177572349224884835',
'358984073330816497136363473841',
'296988780115560570417093211268',
'740287932047771623989275947876',
'802056022123656576866451846381',
'346271072812928085833502062883',
'515939756216330504862436558495',
'289680448179894083864240412741',
'809970396917931881028771569835',
'922029658792066136143783890982',
'186582463809490084123851362499',
'365741949739062604596995083952',
'880702054701935013942203491135',
'638334689846610068619327734690',
'656373513297424196691746029170',
'294139787150820320790237756693',
'109839251311740707424730575371',
'386770606364310492369932657225',
'152830929355134105688104993666',
'760892847874413796764423335574',
'777915899577670994021867022828',
'239714286125379253216639222031',
'213820254087352640996239292715',
'721746634400679617384271149446',
'481959611855685322587262494448',
'134929656957834089686448613445',
'477148171736243209793139366720',
'128136277868455774675826735554',
'408037285525857730909604244186',
'628909846533870396690675684445',
'419844519166911346818282711891',
'722540910861275033542507965108',
'353946173808378931434027798903',
'650407295739235474632890619473',
'857609031795628708852792923426',
'353448841014577918037651971775',
'947078264156550478108407189561',
'691732086195372072102798859528',
'324821359453388217644803067279',
'348438585096739762732417562160',
'921381271398053221994379840400',
'635071705238925934636153018274',
'461836772103289087546148036803',
'422035792638648312875858435860',
'496507180669216699239862417401',
'994250805059793317718176327672',
'686835361433034878741839426794',
'615442472519987023643642327978',
'215619581389472581131289354424',
'713294045171225356439762257216',
'527267751159213112034097143241',
'597181622072781410848805874846',
'233615155810876846182359999624',
'102776449443458016674692253765',
'607538160279231550104530253090',
'446361928363525871525385128031',
'854127702785694315252520684651',
'149389998591123123886176818306',
'103522875731375171572373077568',
'882362916224078879718100836773',
'559619201666019562018378538220',
'253406596487085012911034489494',
'818489628480186411446000869460',
'177264532729458762679516227299',
'786637841615867600042623895022',
'714656969924985413292159534464',
'180482102000543215184637419054',
'364640293883584898250600515656',
'829955282938016394380987867230',
'624696765575823613877265775719',
'806158211266985085935152122579',
'715880354829755822912347772197',
'124288724824291182351900462534',
'931786185443194744686785749929',
'945563545798190755580975283988',
'619068919282017064887259758530',
'233684842102854021666854912062',
'285953930375412595004034928733',
'147155399336486521615361310873',
'646616302330686824827438873066',
'788742890135972423390169749332',
'357338847505079473434321596411',
'168280638785460431947689566336',
'222075063870427800409279113323',
'530156407316298337987923195128',
'759363518023048500325447144416',
'725110637943344593679491681607',
'157100241398223632699684781277',
'252416055565077005750361998309',
'191930714585639554161467160876',
'298413199289449535959489673353',
'572219380218565437706358471958',
'293496075560151399315805898052',
'330711059554239096203797059217',
'216419464126755374876755990688',
'605773135622271429928734116003',
'912522217358959551820558217406',
'103388288935262072979646528033',
'297672497769363514074746489475',
'989895716537392859627085147289',
'448804326872436280984351048526',
'728335800310461383953723251151',
'960508253762556959283981472374',
'310967018422186763143695748778',
'743146679880233825915671016204',
'629887957787897882563274342513',
'720609985415856521352347249132',
'108616556815893639503805453502',
'337202082279210748878716514639',
'901575059882872357961674894299',
'166723472405244081579895089363',
'846128160238360745395182912124',
'404302690861975990506435178082',
'192847188919724845707808128934',
'269481977315999331997659778961',
'115670869201233753801992014889',
'213298434083155349074508050359',
'402018772363021963748062094076',
'389340346397775532587433769178',
'888478027853337078421490457037',
'972285257833271892973912248525',
'135416641516492526067460366364',
'767978853285449102917210799121',
'611606941261423499342745982056',
'271857939169734011969651730892',
'215770239051035413364654426616',
'590622473402758910953778960028',
'531830331176071680532793741566',
'619163668428265277902219723748',
'303741196780888475679304451158',
'499781844148910317908349253295',
'584971602541493691930819583781',
'993468192056834916831459352355',
'936748627882883117631776862929',
'645988588049914474574236887422',
'107749951442827047253308958526',
'489801171472728072513959898245',
'748198509837213557783860439235',
'138839745335039430085192735325',
'807995958111080503360595015175',
'318467137531771834893739832353',
'614327395662656294201792462072',
'945994526180435776427038159984',
'888617927991842393759644373221',
'212121030744157666078248482906',
'605819400469325634569822714572',
'792685277963052195475978316981',
'564498817410815210279750116854',
'892373863064210031468780685397',
'742588182729044365519739358403',
'246565811986031670924747676061',
'334031961557898971213656328506',
'718253798792739529272303845178',
'474767565237216263460040925548',
'255010585487489297410405323584',
'207029065208128118779882922263',
'249251591732731225396821392364',
'178359651712372890956474892541',
'123407860419229291402695740762',
'603983458164932351639445667629',
'517678758046295953184184911747',
'553969749511390767877508025665',
'772337055767281262631347094913',
'353970633564294353418627398706',
'478239640424739028715462935676',
'847619987885613461780298789247',
'752821471483466457937798880866',
'804653806060289183501764652709',
'634439457118120449339167954167',
'203064696940961445988880770521',
'309163201753793363617664971929',
'262651296692789136457635037097',
'877844548991413478194745719231',
'504671060381720959048270676079',
'107787338416592304482350776251',
'906744541864845225299127580859',
'657157708390491435323732391284',
'350251450122030962492818585813',
'548799929082851098130714332885',
'957024974039845739724300429444',
'688157526622559434420807144198',
'603709933481530722469716717654',
'999638822616572955031306127696',
'333898363252259682824507522802',
'991061867588714392518858497739',
'653434368648265132794607420813',
'252819930397547022760043027387',
'935384907249658576573577016668',
'117181574126676742705498457002',
'539120183854871094484889527113',
'878046421474020955846866165861',
'691021408231642014546305759146',
'240051654113362722086258835950',
'293360169731039983249857220698',
'869503195038825654056509342659',
'809619478705186690589771219334',
'591190406906883113113601982861',
'961757802019183533597022746589',
'889474827884353103196509124745',
'866636899078542450718174282537',
'245454470224511005676604570075',
'751579831064843675023066999077',
'895814076007053467830152448874',
'572718717168912376438777950946',
'223453541511931161272655391922',
'971916010039189612976753223691',
'801863895889181023739825629058',
'661395770551271934374588696100',
'694947919650252397461306425271',
'304182858171428038936756088068',
'593710708284126227676465595840',
'573412124783741541380846612755',
'543416149010137986033609124476',
'247979789183212645858073583834',
'723303708148692525577513636493',
'404640369472048448856137647491',
'435117137618911984072936130854',
'696005740402194304997335425252',
'146100903585666006778948137349',
'502077412627863013991667530691',
'945365890555723496365067318008',
'141740533937541006063412943416',
'827722255438228016872654668097',
'338827329530085599780288554217',
'289223055858545117422633199289',
'879122485229680263083693411549',
'554298301472855626458949086581',
'613004808299666899937585399402',
'898530335129883402745609273227',
'770818901123636832116897844940',
'103731938543864985994200017464',
'632438677229813913543932651826',
'738429416570569022006652460058',
'296329642345844213570957337461',
'108766859796031476630607817185',
'752672523276417851956893987789',
'995734100619525036668107084290',
'491798532756544564643452416400',
'185273207881174181782978798578',
'649159260225099517483247922672',
'523878411604537422568138069287',
'192866821318038570979274078114',
'223415851270999563607327593662',
'192930230812143014983179111704',
'198309541371475488417032357213',
'363114191849752166213299231609',
'206552994789718703908072912334',
'903182901422672111505523245726',
'428390036860265565831862538096',
'615408736767799112081975559855',
'850589653191547669255724653159',
'886347375124461689314593063729',
'849839849258521471516251253507',
'642519701888579935502096963688',
'574337496640243724203080767379',
'596344300093083946464015626144',
'869094584574553297761707249787',
'844512057063376464104738939216',
'470545061992857451441505232147',
'843909932192346375526881315087',
'357764493449169554774399217534',
'781485435659861262857294765534',
'898456873345647178321890178630',
'420290365200137134885711560020',
'148984584467361303600517691361',
'486905831467716398620146828999',
'195741938788263022670965092889',
'437089196606186738827487013021',
'478915551725283448327886098942',
'791216681965234922747327260905',
'218727986415311420192263422564',
'529814655103874372858725968688',
'171431873213382836225569265056',
'344844241232858289155635179005',
'964291830230840195319654490273',
'369002986892189828946164346099',
'475338672850593500381407352018',
'970663769403077096403082152907',
'276781016674451597732222140462',
'811946032306338561816827285623',
'194109484563293908213685716566',
'362209246459087626332960298956',
'831532867955442223123304855779',
'779158996045058803243821417077',
'578822152668274299708571132944',
'862221118669760081241546433196',
'786058017341578890771348011758',
'149096879433496316894408444058',
'370196515773659166582113578439',
'892241515681976814230606134515',
'537396546454687806087185269420',
'759705914236866755148545453604',
'422709357329796548819343749815',
'818251051934783860272622638483',
'647955035595871744996000825224',
'851548384182633555101660521130',
'704601693208252025192294142083',
'946831988490244881642907020072',
'653943094994790648534270319258',
'621331216899447109447251285595',
'706825014397948043542064828522',
'859278806645129079649043458013',
'881058698466371530847012022082',
'216851619253889509191829895843',
'798734886889312229274680858933',
'338417471979252134161945528222',
'334683663406948699256201962851',
'855379546353965674057566326257',
'452454212628239046019676150266',
'167499757632770646960700215208',
'468446250601287058177409742958',
'770588122047533649271825912610',
'183761378398036710748524542534',
'620837424600227648506858423693',
'573898756284472449168990331616',
'407694286697519376803108958843',
'463202792399568991978141670549',
'653720671617721582330446326055',
'386720455213057139069603588780',
'115384984885964403924459382411',
'881905497376113255664947944478',
'317594325426965345879670098237',
'121472461379075141168066173978',
'278915837828825550626463956427',
'288944838618188804984104213363',
'552607579966751457465549550989',
'645542282035113034921265984682',
'907172286692052811060884740323',
'198401276520591396092035074001',
'448840268020837938331459653560',
'941873255045889384317246512678',
'444991444816582101519131536774',
'490306514902094726527798134893',
'257283205911639963483661252494',
'183111312217288396255087216150',
'636072538665674986402430038548',
'217995859012873428897873117380',
'758088490614235684752006391539',
'604913028926649541577788597730',
'772080123196416604424926856310',
'420216903870437698274621417835',
'266144521277484810107543278118',
'944493832530182944332898456848',
'253643453887777526144664547089',
'951007683713298165162546345377',
'654488096919775488897503984973',
'735763647653362396090425667545',
'787590555671625603721980192326',
'341370258951921787559249573529',
'573506449486559921737505115237',
'597390902959322242520311822075',
'656125598398551010499152189178',
'489135343663310642157671933485',
'790707148110138085991569870210',
'774560166755151160332484621335',
'594292221899176298472612796312',
'110448236809986354895959788552',
'237125869413574497064951146212',
'622146279835595443662966364299',
'640195310632568794586149247917',
'542097468969443876869141326326',
'940937949663540603134435344367',
'936341615324225814335134356814',
'134283011112353673263868370217',
'827194366418770574551346658444',
'420592797808561217357681144988',
'347746488406717949373288659573',
'139132649955673631300622763467',
'344365129397768101008275165347',
'270380052570410879633588974738',
'978294408859281856198704241410',
'536481293812217879359450038023',
'544407023875898626786785073306',
'792253578846230596471533887517',
'127990880845468351110959622880',
'861251212393363315070786453723',
'940571647695265724432128426619',
'800931277245783522119056976204',
'860897085088015064575219290333',
'713195049530959834726284485629',
'161068475193439680817067471790',
'208571350299144322381993888325',
'717906813364897891387841668751',
'523339840027284582457745956445',
'167256930924490290490544420390',
'779618473283871723687507079019',
'461975306068210676207483344700',
'390224877638924932446327146098',
'501984729341396670317220911429',
'562582755596622697197735941770',
'546476641036742261712391473138',
'560408368700067242457433520527',
'401424429718162475232198436558',
'184121253665971654919230738332',
'736039166289417254878567234702',
'850430292355154226845962444353',
'516331737394546320622328259968',
'994891221087562240853398243248',
'681649912699195715384716948706',
'573352927610775499443646814815',
'487354613518032665791682488575',
'215757092773240691833543331545',
'364650904038311531972913977956',
'279902444170337979824758410547',
'502958456821223378152177627184',
'721128190058596733890808223695',
'966438652866989035234199532736',
'541517686435445801987746946860',
'934325546078859856494560030706',
'282107223868578160374391396884',
'277953797242659250367448673783',
'273453595050746550153898824595',
'336348624164627671137454918040',
'707935591430521425979121436238',
'931701717177687726830954474734',
'831423513315978133699217795449',
'712692901374328847764028669457',
'203013228119124425180354558167',
'514990518891546374563706769856',
'573684677916758678539433055548',
'993792634315694415558862018999',
'706761365162590851893131961127',
'983819414202595951056836419937',
'977918265468436835829295046077',
'847508696864793218208169050083',
'546989418519914604564467968177',
'632899851059837826909606341041',
'562637215819781845849782114405',
'639920279615513615521852095019',
'854906097714663148690339129160',
'298852697126819079051518431321',
'631161115754661454836560547959',
'720530543047332529693835884375',
'136656297281225323933510093228',
'479040017582168472959473310987',
'907381114203083845421791027756',
'833219579070423842492332063267',
'924147774380433292872540470789',
'689776495210428508026798523717',
'713983813576376486357594651002',
'271884918111812717520269930088',
'710031778548795584461356894864',
'432415065615528462354655795691',
'862097328305783104716636089021',
'862166838024869454667192551146',
'195267242236445826734026215408',
'937872872722461802238573323361',
'559908203318789463129902744331',
'958940753569776379648003571248',
'657937904721163730467841845854',
'341294729559196718050767194642',
'735052361056924973084825365862',
'238321164748643325834846468086',
'218963888471110669740328576150',
'656907294203176656613129028606',
'738652676783490138643367520842',
'801708154623415667519784521362',
'849758598440595744727171833596',
'283366750083273661741017746430',
'727914985218445078463712577024',
'476884088944595395057671333338',
'484363962546681589758260874009',
'193936380091871884745986952663',
'410975330878739446612845920105',
'865624988044887096352209629680',
'719766515699786152356579977359',
'464629833144115590523766193800',
'897168794007897532995248339100',
'184963854056047875812373717813',
'625770510210051031364170857171',
'891326930308576655151256280674',
'436018305256269258953920070465',
'253556938533378810958805515345',
'108453323257781926367917479619',
'124174132640857464572070014189',
'597115328208839766631833321730',
'561968287178734287272167815852',
'553374852119999125721308812213',
'302604760126248607334808913913',
'303859591763038320901869551298',
'209383172558171918042400561891',
'639615267427647183665297556423',
'546445390894435832537943654064',
'242297939891937449258613614436',
'664388989857726949938522148080',
'601241151828746344530395989668',
'191905015240362741686357935656',
'836636406694719112470071348161',
'821528577482877069398037278135',
'304052782213171157367104696093',
'778021497438843046562524249565',
'341094706314578097122275164123',
'981090702404594994176762872400',
'451374493593279821928029098560',
'708166648327866871955419158514',
'389942474257889794967546891382',
'964841354148410723008833686702',
'372707144766241363970361563940',
'404488071214597495447495311659',
'679986798236926002770647299002',
'881137302079483314815298493513',
'403327364621065632176676235462',
'474016908978353146045880383527',
'420120214941972906845308797373',
'447935415771798046898449419593',
'518381102764534907701863434979',
'865431173419644729968749160324',
'890338095883466379612536882248',
'684514714661567095272046451461',
'891499343593732300195131879129',
'764365013584230357308335077692',
'892614730356380490341986368549',
'333706037758073046865515746270',
'856843165653417425480614297940',
'856278684941853110144930967367',
'610831922534521196681449095014',
'565401508022336075390186771055',
'355711075749444142904325190285',
'520219200177091215871962748922',
'439633277295232190053883669901',
'878303634345517221339770460046',
'352201109111882882016493131831',
'541381211674461138743587255870',
'409778604977689939271697536283',
'972416931991347842813827929561',
'899286449825277553137017469200',
'644281586464540317812137813104',
'285887765337847722442326133315',
'222903391780859798828542229843',
'101272609467091698869134735367',
'809539834972611735450485963496',
'279865140601568788514061534337',
'420038887681853059726682620976',
'995911233991729761159877550291',
'414086279067951357038267170278',
'262891482915560328463948841165',
'955760502805129050966270699017',
'778687417212450428518322520735',
'589218281480741049514594381644',
'424527285405513673779425873793',
'865191129231911901418961362289',
'530915402007170097135176788555',
'853779809124644890998333660131',
'249050068367642945846304128275',
'573100753087789299089690220459',
'539414068900043804032510870817',
'429088549097554984546280852264',
'381443622562546557092546632458',
'495861636173179010770426033596',
'191571022035840610868259542588',
'904848199872849891948009790597',
'919644839741461710158419548240',
'410280507567359526907151131103',
'330771147215851154748766592981',
'109428661180343980211369697357',
'128496218464423179896366664033',
'918078685505490720702781187074',
'959562017410554189550793968310',
'582655477242828895711483791894',
'506540460497627183253101810610',
'141605136982076597875816856067',
'960614570057071606718219597141',
'102292466291327534254850211006',
'122294059903436575155287458250',
'422727464397857373662652411113',
'332279076479775074710135757404',
'392931878553393720959372685344',
'597762861696884285365291896066',
'718418875398759185744479819740',
'809560484103775889522329721549',
'897281492289151372898071679410',
'866120924015275161173245110191',
'744550496509882032600876590917',
'485557118242146361611077464058',
'491031050683692851812326424002',
'603506886535667799494421745912',
'127661493631030886009221932987',
'946923772347374301758682297046',
'820561375595939780959178953024',
'747115111886550925327575346037',
'584938133475318385757767969588',
'755252745446126603861773820473',
'824682780352967143978638115029',
'234494531902677999089235088789',
'459908292976711594540298358990',
'971795625545372317211977513077',
'705557845273859060992439984984',
'292107885108680468088236667887',
'416298301516443802062987354559',
'699741540919095652804577442698',
'138368212465493684995139581381',
'881683989005931756983049761170',
'420282627117150484907025544739',
'619652833084546485294532520270',
'318230804243477552701864648829',
'123588003166626049630004022511',
'605269528649677935004229296018',
'853539089723145876134901914136',
'151945029473476642452822376128',
'889210523096016332815152521018',
'946098687284648289204268589795',
'719911754584073368188648648328',
'634597085023576003992977493753',
'789857541716230113199981334777',
'735073116007940691589288463958',
'923100337193067170810123116770',
'115079887339611991676171063818',
'851396407337996744214170864966',
'769873647479195682841788121751',
'545237359324919645664621582678',
'611360051418940184329807816485',
'452523905545722449525366039816',
'635710389252294397783269573287',
'555204999585576712596091926848',
'988566786270464854047181839453',
'393358392525983391171085463768',
'678462481174999797214966641742',
'272931766455833446606709871805',
'292657605199983799748975428257',
'185070659344490416216704969856',
'395535036983263361899617644539',
'900119246724946390463139160937',
'257873862648310103998264116472',
'117613457768447555997567337082',
'685753437731479546913277594619',
'110861636261915012363678921948',
'605241655614970992756753920805',
'114979224563110106955652910861',
'345367692721189224770418825738',
'217240302869771593208023453978',
'944782503507111322459545881564',
'315017129872061240181917121982',
'192479939122251244327017682773',
'341872991010182890598059792917',
'783661490946974524660649286123',
'961745994254352441572648273583',
'245750549802294582868522636284',
'880120007241078223397225769558',
'690321248037357654378330972493',
'335385900502495203920015714070',
'603067868771570952890353796502',
'601654446541213361177192797448',
'313098885744081370707796213826',
'972592038774267004590542278226',
'486079674730144440077400164583',
'196024161288476456604932164885',
'147338116987212076057320370715',
'702362095537479937151103372431',
'995259019345926141274693269648',
'111420413484670016846941144257',
'760742778608756821783918090523',
'690038925947238803312587590856',
'142619660916164742666647397016',
'850897387430985170234843521651',
'553557356417474208617141223789',
'732125278496516185435322131591',
'470784287118159203293529054944',
'673134127339145711598342626837',
'679867483891919227155176253565',
'353820162451648904221970592050',
'385855116845269022791122988701',
'973856860096882953947659117400',
'910138291897138455545583543487',
'929025435566627322779680119865',
'875047669614570650406544624642',
'143959836426385951155377487855',
'987760755500182394983352876244',
'769952134891897677008082818680',
'901689607726796493606487783970',
'848522489214660395590593593452',
'182716870185064142023051653441',
'163879743674798737162080398746',
'328603205205153192964581618255',
'266506376797017376837491331240',
'505071879684984222539952195877',
'192505493243773232245643694440',
'322692344446320451174860624378',
'354260625000840990017425253162',
'382504089031416027769440014085',
'109904486092023547719797312235',
'599440059549557751396517832644',
'314183182238524278801080989980',
'374311626887886884750717266273',
'394271981192990197734156233189',
'261194728497110125276628731328',
'542046922300486511963215772337',
'408374622136611710668905035405',
'759427490384071848470808946172',
'942029889000864348959863969845',
'284287299457275459857986767303',
'773225991316480891531451332364',
'383530225726428210523228818352',
'843063390966527475622844738986',
'967170955162866687298007257751',
'459215968936162329371729698011',
'152130427044121272865209515853',
'895574106149566420290860210371',
'618058666828732362699962064910',
'183900969039660263780929041657',
'432815837789763076287390129847',
'753381934937691675534771356282',
'734300522513461723616572026398',
'596380653799849809667134136218',
'953792858578562739404008041058',
'845820536501795868477347618473',
'911765535046156144574399560801',
'357396796282477304318468940781',
'576667863189127534480426061709',
'137683232605366646640653216455',
'756666926128895418483015056253',
'214832255253850077834291896894',
'421486604970363993133418725240',
'458381479631593222228588669575',
'688624093100178833591164464835',
'480022892726364968976077723982',
'347745187964410735862892572039',
'202420918636099077378803431111',
'944788089630886236645560734269',
'564065801422731032650018295769',
'887884556322026757830856757374',
'432312456443040155689920845039',
'940383893734050606646961199152',
'929991028335179881479553440441',
'206340880207370926366205759008',
'106646034963971254546820893081',
'422671256329272400063060856911',
'226824516786683051258046019617',
'790951107488966799522504023077',
'374880285876034068201681236248',
'784799787017987799654915372517',
'822532105785935960880196143035',
'302672733907870524218024033810',
'702687548547099384295393317745',
'182289113141749166478116691128',
'667181151986094212817205246320',
'104691376341565750226898280720',
'759477679293685111716367767291',
'862743188726976415103857298302',
'866683703291978173198198973108',
'773917618174050115337345089710',
'664191861778419918515132069729',
'627702172766713723180340742917',
'566917913479460943352374082280',
'443152431061918747770407223402',
'813866129277686127622220761070',
'851466216565250174483625880533',
'451313435921334218669019335196',
'537134146987550278798869826782',
'267098324345172803979874184472',
'882576395189090962337587710833',
'608061229696820891852013719087',
'627177944465238851488768786425',
'300197911036748298528256587094',
'758858505986293879196820853215',
'575052582199464141219841528415',
'490064227783419962571253291257',
'600937928371619507071407260347',
'660427469476749050339590271426',
'303844061454028045342720271460',
'804851707973862640591962445448',
'628966073457516336615328060354',
'381430224954778534462969941586',
'641746967439096693696148445215',
'447981709299059159938495936639',
'157487543907166343241527762992',
'243300947261993681605308018071',
'795999380465851555468424886213',
'510590659817670640199951079954',
'963281690760099617047570377964',
'744089727101027692204917480106',
'372124721056074396155474339426',
'653437659263824030277025948516',
'530205150055442206717609519753',
'578509458415796578725666145899',
'142139509198038466311215015526',
'485385633755564024950069044904',
'659765041816176671066611916504',
'801299737395792897144832426956',
'261743082746670769519714350672',
'832866153975113327754262041469',
'911592256548052856848030980502',
'675529314752518149628848198575',
'437708633205372375708640296413',
'175657922553857178202356291838',
'587129454660479836265418545934',
'608603610159284804436928444992',
'547246476557913980790099787472',
'296996634699026882017681165004',
'343794151673382566583392628685',
'221088334749640071154365338416',
'356753159185742350049886289230',
'271173390912821602364065156037',
'258342495141231983872131792880',
'533865935381580965728711756336',
'447232226198591220839041957668',
'820807206110777525401656655687',
'510401659830717020054347316171',
'904045138073943567332220054370',
'381274809270181395256103437943',
'821165709227979235857932756716',
'908077357096518175261612569355',
'434146304913466748284102645897',
'577344207434142864471074165894',
'902008872440549744101405465023',
'264284382571344119299266459157',
'595237063803833023391663060924',
'618746532177246752696049126842',
'651725771457444457854356767236',
'682157762232570049964819398453',
'942637698421854827452769323985',
'803819505562791374255697033148',
'167266706805324405317692322267',
'567945059451442789484985220180',
'845870228020217639013193269246',
'410460320706127103625975689510',
'916297973890627858753569193297',
'104387333062673929632564562673',
'424845427008335872599859776840',
'231290144783331696728623247372',
'307052493303954178255108610629',
'828671366372369685082672039533',
'681005655805458061863002182139',
'697416655653155908750667828148',
'792232775345840322596378321627',
'582567661955252017430524852368',
'209898822101313747242256567486',
'156029963141884816000558237470',
'888751306475058327798344545571',
'941414628920211487830117513579',
'528477755139963493624223450435',
'346744208240439706753503051903',
'582508398853696099688008750178',
'809040537969093600825724758369',
'963249047388539906986865241823',
'376326778379196013171029146980',
'243786190280249797295687859174',
'938764837138053876660587866098',
'818024917382726013921809391378',
'436975307430452475607547680898',
'645599379086633713141440643511',
'378415386019519867635730459538',
'827593173881930748124075222900',
'550775652293129489376073031273',
'792704865679055551983826553315',
'689993004683368891729874719495',
'353129006014686322578881544197',
'865606881643944195579704219824',
'821094312811179583558034313548',
'289454276624674765225828492709',
'818218598767795837836180865633',
'293805382946273705084198443104',
'849153868482018671783363352026',
'749821005010932494134046726891',
'690400014819049084547420799487',
'547767440488451572818960318320',
'211660410214088281978902291077',
'511423802677180814072987546192',
'763998350586096152177181295939',
'795251062668677512201635411696',
'572642405844515041786810496544',
'640600075097186069366770373462',
'223378686599899428163168034421',
'915036085695127874373280750841',
'101415592470496221329168372034',
'672596845181536242637397042090',
'496127932029788803991993825807',
'815233242259540714699733314957',
'587599691285521166453071233789',
'856732665231946940765055131323',
'349525537785479455786226022746',
'932942461705433619721444073911',
'461243190060753019832502355171',
'304861728838256670883829388282',
'303946875830317764413113946918',
'398332102793925343231747411799',
'889588319638199736160436683554',
'889109804885526283835451947829',
'188983386289247870884331671148',
'193027908316337980021181020791',
'334275906393598751498188319651',
'728665010513593830707361337223',
'182307369414381952752751513575',
'485307595503654803515402998966',
'115105538977584216279788722572',
'550295929092642893770418644258',
'564610792405678201549719624329',
'432728758482714281177366727260',
'621354600666464539968544249302',
'515617409518392366551419131183',
'831247132129747430168327688461',
'133650569992792543926701079765',
'450392417760718357190980174933',
'469556427153744068791845562966',
'588107462168710135138724321962',
'441236751475525358514978617735',
'897728845183218744158681469148',
'613441644161085617078172631263',
'200988708208698761491130559842',
'964660459261534887288862947618',
'381993923345555199451102448850',
'957744207060934693080897461274',
'914006293628498945066453220063',
'684760858746725217168670958054',
'359778289609619956962841585315',
'314110008277686703227858039856',
'368150800385550487349058714912',
'399087968061399979858445880585',
'126451619085566474459282248466',
'288708573505071079415954773619',
'515056939818218141941774861405',
'793865765816376263976306529876',
'492993364799564757012351821131',
'534262014031721160025831836867',
'404422554446231298584206061450',
'372332067736370502254185040459',
'552908769064430763195461565941',
'490159192047544883272350343107',
'356588350935088895111659365022',
'732965796940814025306785010215',
'309648547650327629259030686388',
'281533300694054830715912467152',
'495200166626914040947804428245',
'406262122990845073256415041440',
'612384379322465381684190422038',
'328576643292553927810053866239',
'589602914007263641538280241260',
'847349781795266863059157746778',
'373225956314621011103078568469',
'664142982958496821026860988056',
'585099070272913390315547327293',
'123900087009274951094422284707',
'253117782545214566581932334783',
'542216358620817438153598956443',
'588746073583777493465438695190',
'204561653676711699323358497760',
'272801134228630455846196511919',
'493077838124213483698506489341',
'742501944531853647132414224197',
'443323944669717832430448674696',
'917873607892472417755556828151',
'528287039179097582372566111760',
'775587739016221060499759028888',
'138624705410985715342595670882',
'568407929053716433263417827397',
'870305463634575172135302390990',
'104514154776956258603828967722',
'234528396414013084781613799661',
'435579675519161305029840563786',
'135673746158378948999858558396',
'540747640347824154936298722449',
'187580329918426450277254859017',
'565461522605244049037584355647',
'638528319152055166201554036403',
'997784074068454816593711340712',
'936592840638820026181165040029',
'663017390524513669008579451682',
'450327007762521891578159548420',
'649347977402082034231045462918',
'349704493017262430664549475419',
'622286186865543565727369733842',
'163903659767311876480014137202',
'554494807632526463655813280245',
'715903711937692031289131746533',
'897960678739175115394516397542',
'837220494238916617937075515441',
'896277165688977518774369556657',
'209740350586241212346728531354',
'219299706032374510085246579192',
'820302380178451763775774067590',
'858379605284485525210804814297',
'444859843178832274764103717987',
'315361982838817374682760230341',
'251619929488031643527570945003',
'533233470568137752612616315684',
'573411028679761474939362765636',
'852998055029972566968936871610',
'556544242877766125826924612198',
'929928748596471306809906327832',
'989108324087926914813714533270',
'637313989903422980562942791047',
'933180138087431968452064687932',
'713631922216245496777986199582',
'754429551308741184773730768724',
'164232136118344483266843144971',
'985106372413085295477913947643',
'504649003525335992531225371083',
'335699347142312204938744986674',
'963263410570017975271777780026',
'378060876340411567111331591980',
'973788434631378373299095851558',
'531119568725456619971638725436',
'710346139431126241242810798006',
'216683691861212532465589567691',
'260348589893719357999883932655',
'431470735393882829105738284566',
'643561502567248961817750724122',
'701536482397129099694654875469',
'732793766892174249086229777287',
'810868326667691499909502519733',
'194819310048139996751019417559',
'153185379333999734002040165140',
'208384126909285584145736927157',
'899444044971985057798483448098',
'775712782925877625529246334695',
'796107216623060432057904471306',
'483568591744984671989816236798',
'464842413298333817444628588586',
'238356706461031310184608126568',
'120579068607135737620485091995',
'506189706721729956170504867940',
'147174036858534684951273187712',
'230599278476946684472811854114',
'604126232216998349954893358949',
'659557558394857656267600582867',
'520704493595227445445721857274',
'953985008082457335378266666822',
'553189401858219315352582128645',
'625588955569438240061574394920',
'444783157719765512849400835950',
'580161392750817211845809581259',
'619482459497884573539390369798',
'199895803667772087924019672044',
'721906028841711704225749019767',
'476473748141661931218373463811',
'672723238033318834678487714950',
'450095811973542709023155297013',
'222418639213073430672785625725',
'422383408736895153510395458734',
'914923599157162618783735427382',
'816732927235966600537500124665',
'154718050247580822856688153613',
'782421849513365217166883365169',
'718430936870432590039545943852',
'453354576669071546236051815009',
'209138543626115326392025286330',
'858952475106356011360179327926',
'819407838255914336228117598783',
'807972029395182010430162055215',
'987304384985065981675211560751',
'606008301154472625908733059040',
'243495264521034330887055116662',
'185073024476030136118136962851',
'909734164522648216239637442940',
'948181366897043712242734712788',
'799026424286118010322837967462',
'144221007296978331466271280000',
'542257525918474587174009932778',
'332414179043826914318354249369',
'677754506556683451356035493640',
'968979559351461117671706787497',
'532689228337744710502200552169',
'198048092270573441606812914056',
'642283117060023625799657626345',
'539030379409191150567211524680',
'321779536392983564067141247319',
'591419679576629383887080566565',
'775956847458843987086944752535',
'791907933843499368622039684129',
'252739902861874778144409910615',
'190263902358911563481242921414',
'375455569803718467671761914643',
'912960315573595241647389060970',
'930627341181211771896153368217',
'719838204317550781422655419396',
'638844818281425472589713920824',
'906074024852497823248903293934',
'175142688461912676698068968430',
'195833228340040263391023652146',
'657285334071934408300925861514',
'450013889713065238063059488176',
'993025956532341877508154637915',
'195572964161890717662012650556',
'751222719670785347474280194835',
'147944239551372601823300618709',
'726608672431252360218190866227',
'535758341764433458334538354583',
'659734059601686266916046947526',
'776966892489616801323402597972',
'521601787768613577945061864480',
'869381541034612601801510647586',
'111848954240270817017827213121',
'771188944992321173584593750112',
'381233327875482391722540138630',
'297840847915332355054179846390',
'581880064643774281659176477860',
'950798964620845563049728418195',
'131055957716462528984302638299',
'370213931102062081040801523403',
'182683903277775827532590065821',
'855289258668032616570168014363',
'709425461550381875879303368976',
'750959490381430334189428527644',
'264337122068482160554762666651',
'158487148968215638632951874021',
'122239348856866648110452388070',
'578513231987977206403910674217',
'723332949444394644030863390258',
'374864868436192113834700380868',
'839442497637519977171965127254',
'924645903048968790320037623129',
'444734912319562125626776484931',
'169349556629897104909318374491',
'236327454438474237331229136677',
'723961505303829018873186214398',
'644829629359846120387446177303',
'407777535988888974163983281451',
'692148884987924199790918090733',
'799498467744142084636348653718',
'335520628300753879087494331716',
'783843956583414641270492590665',
'471942617488113834849160234560',
'557466361899616131680083611366',
'211452180198526884671574665113',
'513294351370982811351849060810',
'107550628187513976163232031324',
'323456492147912341392604990612',
'446452965448215449885225994566',
'101937328060558879795677532204',
'741149767598528968979697998687',
'753076273746068532220466115016',
'491274536279616336746056991287',
'990471739935343159937037549923',
'756535714172918227605962541131',
'816496472817526166038957081899',
'498616509581424297612695574829',
'499179175953990096721252679709',
'846632003615539405924018876806',
'648310870275811927769751535808',
'283087492503556283080984130178',
'767539964789926750125659734834',
'759528842638227722852379253237',
'595247579957689856000432760865',
'952161950852242244725034978362',
'438395386446068777799987316666',
'984614116952529870475876147679',
'200843235306222635606892489002',
'327812614800539337422044476756',
'486913802868575686967149461212',
'252412329655859174697354767573',
'835919162148322943874882472029',
'118392951507615011298507497615',
'361251752119785956986735755838',
'668428138449191427058650020540',
'395614755181167995556617447255',
'284589332406168894814930077732',
'575428961824842292656333352546',
'599133586924794348834975949368',
'413967162743964113259675879713',
'903839122109070815263420029991',
'834092984165859262170465699720',
'347653978317779047386141222666',
'401855467864397553261051279141',
'646432940171453478229384776294',
'324531707200930493451469397083',
'620404454965316968590700385750',
'135630300923315027790872316855',
'304058543159582499276985875827',
'347899640131142202523801234657',
'381285118794827222603334079981',
'165182447738736992851586018817',
'671949026468277353280249539716',
'824543696410619980318436430327',
'160934281680798198134022032468',
'565375372591242981759339389271',
'694899977828415525279702827634',
'389056982698378662236878173053',
'446884126311892085037000233777',
'206693174357546698486213614254',
'331748481332072908876863939388',
'731827217760318145115462357907',
'113487449011316430370849789883',
'394312510240074075700472997738',
'215170157039196387295487349886',
'867339565585250751461738857747',
'644026959895596814020549963547',
'286419346708273159815909129505',
'637111563928930241112709381597',
'390598127581772876434644820116',
'191384495924362316097725214621',
'620583779202662981172086537239',
'728730484610111776889097369572',
'423371721671754308317158823888',
'759397343713212105417492888031',
'652896247365024775983732798231',
'323083261435626437919794846215',
'294714530640743969946660950183',
'536015585392239406640233460899',
'679399070005987400455144677496',
'280548182425538765266096938450',
'675046154638957677091024637737',
'797265015602823748254310199360',
'200231061729025447316433298670',
'304825356972271896078150920941',
'626622620441780222192210279096',
'949511461656588941564689246146',
'333994678598717695037153891343',
'100090621598055926152434326020',
'306994403533739759587134628264',
'773426813451759498614129526469',
'585376212104976065039545998328',
'267546249151081108103776071334',
'157791011618615445404880861641',
'943437751908953116706927353987',
'633124207715445043708525977939',
'369464933549259999817928817902',
'510622594470496154431897063608',
'347357444098427625709856053208',
'383680775582484594199839411561',
'165654380208055317523738630334',
'935013432953238448450741914855',
'760990380428518089346852826589',
'490083525067149578015745076043',
'509860906143980344321099838380',
'583362995654113788995123034667',
'435328792389468597231445056753',
'182213391368933533515597240907',
'799808257120481517990562287698',
'594445208395238914759142249928',
'358455544990293417365947750959',
'853351260063766735585701629947',
'373705533344370867611378511567',
'316007174702776557438970150535',
'169711234078841130479284666324',
'576978057938070727318119933542',
'839738119895228007476707195918',
'541388831669164685103443361592',
'312013862664137597658399163967',
'760702704412656852291714832683',
'844151279085263847413797495146',
'302581197706630832120424583393',
'215916481166294435383451013640',
'366196696912951170263563753853',
'766758733002187168731910096564',
'826911929935319691095749412325',
'400503238714940270742617243978',
'495600719079423609807673191694',
'450491444928558808732033139966',
'455185706437726036135247476599',
'931931246523224850884662146128',
'801499678137614891521421035999',
'264623823744599216552590086834',
'147255666419675563011795676322',
'100222014889966431221245446059',
'764253165743587889389528371189',
'368508089787779517865279875246',
'960382203462034108223706680553',
'948598495488541844521219078004',
'177269807355389666309319240933',
'658231073626286593897465449277',
'658232174266154040478234761019',
'621163104004917450804360628043',
'930903599384345947378265220025',
'538675955178384303371828626568',
'973434350430849487145671156925',
'195436021575514388948780468407',
'996636912479343725968081348789',
'531004624386227586172760882356',
'577940390912780884974962418233',
'289137495447429513117490437311',
'157353761010426780175845924681',
'270875584322145722697829490202',
'360345719995927400462848983232',
'722685023411215971440297097790',
'849201794424778330883011941691',
'797446010309025378653282360674',
'572206270604240169854992610792',
'721012686079837336326491239059',
'196412489973455182998721787509',
'281478343825941517400321661346',
'570447685503424115674854416563',
'949703971708211359983960522624',
'329748795140588893945028972118',
'317942729849048380939671343146',
'370894137876336372013801534031',
'185924230725475916956485077983',
'466519594136740110377530583743',
'540548012014275219390391276114',
'912372503479999862728719922423',
'681129664108368381570749479647',
'295676849981586924298245584401',
'115010017851796736007212581733',
'137850724831798059045729981650',
'893045152231272040271903139696',
'278365894911521173119801494666',
'170666300370486517297325712332',
'817418847724599293176567466454',
'679727898489465461315835591923',
'587623623753836792567277074770',
'642177423648244355154122798941',
'579542668627385740873234930546',
'493618772928282949234843364269',
'436074942798082399956469165022',
'331620762367775413852077745286',
'597092346694647306653711650583',
'843950593936025460566500099139',
'866190894965196678077205812506',
'127169663244551567771546456067',
'261929087802636293207604250311',
'330720081454386149849988423182',
'653196330845217568289554724117',
'944462723187434816272940142658',
'705648504833495439403596588283',
'645461677755032089426914884801',
'634209961059975283419308597831',
'976238773241749517588028169051',
'278580474914031019908271846544',
'563642171489113114298496830105',
'989031041567326820037610094174',
'665949696154292072087212999934',
'386753466842231247250602737077',
'763488656676026977442854464451',
'795266594892993625617524537083',
'863179164885522374330245299691',
'293728351336327637996456818880',
'633321278707720449179809039022',
'805191336421436949336092533201',
'720855074684014614524821294890',
'406089204844949059718245499029',
'669040554564376043469826185904',
'278456777731431757323003540203',
'602251311457860778076255741553',
'785818730779973081016619013712',
'952667178573474713982140639193',
'292406845671836768327106472575',
'908897077025221014804291574766',
'363643724250168605576547135011',
'837746072742335990625719325978',
'877760890338090899555611752142',
'713458927374815933916122510531',
'556304934414480964491032296942',
'955422002156482543593270629783',
'736699330894416244092445423174',
'957292447475086404777454550533',
'491369003314853602446549155283',
'366266670387642351744084422386',
'397133365942679766429761076959',
'570019726188069877815498725164',
'658864561496172299274672980046',
'336557164926012320980881459333',
'246297605437665011359233172933',
'748687838032749462954616144978',
'219675611406340083871219463609',
'355483258275951401941305772689',
'368087851407653231432730859404',
'667939919987962897031768151008',
'191033266446966917485232895507',
'487914064446966280276575059972',
'520778178689945692825339270660',
'853367892209825813821440384288',
'481909644651330003301761531928',
'299753910724311247923719456406',
'970010531455845629442914170813',
'132715447945074434835560420844',
'465876375883285516292093512712',
'894226723961075457439776325001',
'568592017278232142045896320010',
'456507935161342836417387941690',
'907807298530943505778471886420',
'874276742578930557573265268409',
'384572319137135811117719268858',
'477475716027687602663686260966',
'357728943622233624612557687069',
'818701793616143017434220713440',
'227298887851534688181236382179',
'968475899262888468430806122053',
'970937301626099813488794397182',
'955971576003062757415568996758',
'446920285066568084357417999482',
'278566990891568985465525525836',
'199587767714058953435564954812',
'490805516153124839284336078581',
'699803257355871152938861210515',
'434849198533983722943460352098',
'504885371366753219080989636112',
'840841386295821965920055418612',
'638925958544280277636000741351',
'637915472495881088383987637206',
'461665456512394143397714491104',
'479857097484560935064711699738',
'661196677078638317167900595089',
'420989878456284747411176756326',
'684617964122451456817482737745',
'877963343411610387608641813031',
'681633163242661372677969171120',
'962332422503676761907533149960',
'216946292902289577137286861612',
'231958361883420817277840288170',
'929078360088664576624261964753',
'919238319103819235498277444025',
'195518973492497999932895216382',
'138178745956717771163489739638',
'658898856643113740239591227966',
'951320298987523400219827417465',
'502400183045966503237910249852',
'101216166747425640697438855756',
'344060072430572998248380114979',
'840986132187054714926010422978',
'640380382742312798245674333039',
'576334670760066559481004916073',
'177237599746983456409985067940',
'361476723544742108022773637338',
'974766413460876326206704920738',
'580130904233033106807998063731',
'409315068525623866715280629208',
'366615892935346722520227596344',
'698075207854544429441390845683',
'670164091882730902908263326425',
'454657434135073575144312615245',
'972065620319292727859472015483',
'276607364924648251014016256796',
'375384027149431746172561062418',
'234793309774264889113849659425',
'813851724087194804380142166632',
'448706912813930588219112735600',
'481326982839764467256238282358',
'669292943947965722385085135773',
'451462091402314457659178988181',
'364568658965049498243974964350',
'991534405004072418516902447850',
'126052103716019829756874127931',
'493128102820799799673438994302',
'910035115805689631860747193992',
'178170513650437888874764235319',
'458375283899530559168739369327',
'193819944120485564828089813913',
'281080775658030188844938591778',
'690920404720018509402818148444',
'254722684790083543885271380367',
'421561392964610778295840524981',
'811513911971254656150686299074',
'342048005308962099086081281177',
'866675799720797581878319057376',
'136481858711043359502411853951',
'215684355194750887785526768766',
'993386818234721288443999565049',
'433854233885345297002243682360',
'576234450845171855752460624235',
'766193995398042552427968365262',
'530894191800145338171698116685',
'498017966032776203014787051802',
'490829372996670201017211576205',
'549158238066490120456590855550',
'388249435641053701596903776481',
'915393049949398037699937775387',
'792101992461731950319995240172',
'422637327699667017202235738453',
'745976277828970828224810515360',
'199631732681358923113239158289',
'895283080823843249152465533234',
'412596984020269844173147596136',
'456685551971674997631551385261',
'376039879816217038596634294815',
'409018200527414703039134181593',
'731138077401495885815049319348',
'462477274981884734409548660172',
'631497986411375342171651046788',
'428469096911431337155078175926',
'853512412072122004811065363875',
'489673205867638970052572755677',
'819905129231170674280398320650',
'355487077249039907206669193811',
'103076601609056259513451385708',
'234829518244060178228012129066',
'856926613463150628329106584331',
'739269125734383154134166634604',
'496359197138698298530141437676',
'401350983372761796785191837459',
'112451316616512701743106366337',
'542620415017695009303001210791',
'446163826950341746489884065511',
'791209536816078523609354341123',
'486159711706058264874413347599',
'317163970688915505881488243316',
'192784982072011857039466263025',
'338976172881090818609934887960',
'717677741602112914714659590475',
'168574132167415315729137822534',
'560066536277923377935265993320',
'445949301848424432377114483905',
'959562184261929837592895332649',
'311250006961519239797843278055',
'993141893120240303796419637063',
'174442711356582551129917034980',
'518943071675296515354537083568',
'829213992969394316742027559361',
'679314666627777985239169556123',
'267999566237538074062568163921',
'477286466690512575998449345122',
'897250818564252597647887387417',
'665342500856835547820141614074',
'102180377346744020773341654061',
'131874633159353007703091190314',
'207221555051396667549037874751',
'165420041889731665489545352944',
'368584180608094581681248128337',
'259267150074690295263596286354',
'595941341319116208170758989267',
'640080304516965370283000548967',
'492839988053225354025126054578',
'835222420496160853720740318064',
'168684881640478441199533586925',
'289212731157737193029986613779',
'404392405714986238128361515771',
'505745629056955037883325989588',
'195442639460894767224312218934',
'653800825069826042185695290165',
'739119773291876429403370942781',
'704657547591627889394035477137',
'218497036420165314720863162661',
'173751754668826194160069757114',
'229471111103476099583774365046',
'363154634386970955338660728062',
'985999681998457815589750854290',
'969099183708317665155464515923',
'321336961458762171751028416561',
'615513844751161033951351697915',
'216788975645597117085331613743',
'659786436606117259826463421662',
'818369956298851491853254449385',
'154416348990079681887048667213',
'148510825318180755048526938108',
'232663583521345237973268336016',
'390644127228174647910493010046',
'103444036037237266367992458921',
'353341557556042857553613462279',
'872655851066248689320695522218',
'657251574239624339060660663598',
'498546344055199347674638757323',
'363401080967232203955198449325',
'334694384881628613539176389634',
'882001415664530727008095839258',
'682803019260527343901050133119',
'395772807967023431764991888436',
'294215898152323128993324145411',
'195496622699193790133584557780',
'477982755761187124379950329292',
'173785944194649267260944671693',
'388483368704012894371662760050',
'211240953961547646810879769372',
'776782498962339104543795270259',
'127012362781132641444733854108',
'540609156159726977455640418379',
'133437078335952562476299360490',
'370530497487618591002342095916',
'868381432691442006736317786448',
'829009471314233510471542030945',
'921195906099191389498946121028',
'857686665763643651537743179873',
'461319792013581677529666177032',
'238879853887088499023211469546',
'188746106835168682549193096147',
'526466221003646596894308752690',
'905206296313862274774377893359',
'791627979655056632134063669975',
'138643976970720069241393174115',
'374730394041045872819869651096',
'437415775501778560314538165700',
'222549743327911943774803349446',
'884490963285799858235604078621',
'923785860425082398027617865894',
'231668043984031717439725113019',
'421602959886677053946716256436',
'348973770751468525237087932062',
'202945585672465263700420741233',
'571454015656073249866962521507',
'538026672339996900491280812691',
'688562032657744700660265631297',
'635919088426878400977074241610',
'491137680227280276185002454130',
'221194765485585116883344583732',
'211077133222098263971588285049',
'290855420990594172127215317587',
'963833592004167456073771853817',
'492528346115311757290565844036',
'208423546199233785332846295478',
'432499439923674468008398545250',
'765522823192497636903221210243',
'161391273223376077162428752570',
'274804693001151890807531741566',
'217835038598252198239065294539',
'967421601719449496268303539219',
'420570422957990991782005532506',
'186418756905690195705503046270',
'993968067629996629859605960406',
'387291250932720085790579329826',
'875620418259142481744334679186',
'104703769436742653591460859937',
'825448525961996378433750188662',
'906148111104129442470047619515',
'261524748328778690374577119775',
'772230769045731119434837350515',
'529300657335519931354801526177',
'662591569446091392980795361396',
'864286753066111364419099818018',
'463306440078344603322878245538',
'242797285179541581588466673253',
'300859988926054390902965371158',
'400425576988849126843069558043',
'707486575372582946130661747980',
'949834391006481906140257538453',
'962820504101280884056443486713',
'656737506751476621288049338752',
'185737642329018819236203111610',
'445322345368961496313872152799',
'737178743419329912624078642573',
'833896645036718730850660787150',
'589028403002928869366103830323',
'222984007855425190307947086706',
'779837994430928124842470488403',
'148591645781476356573803489371',
'820583405588991500631811783163',
'214622353484063691537510435017',
'451837512887253960205173556339',
'167027392496621458018311085981',
'474991260797023660052314092551',
'979796128721059003132038951847',
'531272529253995515515797866087',
'490369285488668803930754210941',
'152730199215463200684404578938',
'967404622959339467555064362880',
'310742902815878014462978819372',
'270716237841866616508329867787',
'656872734546359075111866768256',
'448747735568125150324516542680',
'739967896591689028369739354555',
'510045347595693617328658637681',
'903446217075251325046573191245',
'255535671058184869219078468322',
'922821140914974042549785255370',
'836590308863293165047971950558',
'394319589367839563930329169063',
'960558348196742542246109223341',
'572428549101521259238516536303',
'479064454087694258290112982709',
'237321722425323208096683728396',
'375434049893618774982909826148',
'663798792396390848588749181694',
'731837573829557292596274824901',
'466418657543779779862797337480',
'179367445044073826799700048374',
'315179293767333374624039053669',
'402729838666572510823822484781',
'340334431180823715172811194793',
'631370458981714953473842571695',
'702237305308694916521001731511',
'517988158045986336759223496267',
'529270335426284356289383229883',
'767113537484465882864405354965',
'427395531538239226226378683451',
'566049981609182973100080885653',
'782977502227713778162030496717',
'468116346656325138281021699952',
'347637434608164553925753548679',
'454287757879289073478132959285',
'639562543964717671984088829872',
'907240419723353913238805850122',
'554353168501993534749061488439',
'720449771187527316623893277702',
'243362593032063271131684113492',
'288634327755747633652116968554',
'748891538821385956342291552768',
'271349869105637101276047928890',
'399138470503251451279985439861',
'169643006542395742917411281914',
'632892114365280326131286999956',
'309703466155049401023109469691',
'890419545904878604045533698275',
'746033456661288072551362020706',
'772658791562312780199586250588',
'568637731348940737106951858068',
'716094035699873607113519240383',
'958650936446649516367386433296',
'295986415752982001982005821257',
'624938231867982431603463570655',
'113562108438482213032617966201',
'158318266935671877284119967568',
'525964495650475681227353899529',
'251336572273420790606636878583',
'917479417558712811606754823627',
'362269205676967906477648130586',
'856624758407538350346950718014',
'140845055973245099571351976041',
'781151135331862998534965241786',
'891182811259519876169331723279',
'554188938521276898920323855601',
'377884963456931024535092847527',
'916126024281962867363291828779',
'946641160316171067659770614120',
'793593672346746736910356132215',
'463943485955930622486025519802',
'106555258599230298384022828852',
'418701025882199944022601577912',
'136499061486084004250356530194',
'241812509184927972691952115739',
'517281677481038844483118023347',
'677018102058777650134240738332',
'487421183207347014014068131748',
'930622453355669232926607417258',
'568207355548227642361543519456',
'709986863489015296482832225966',
'733053890788671367720167476151',
'794168219819741721437406591410',
'821895266517193284877518761510',
'453225226417192088599091149026',
'377095653696558653685084166267',
'386536042045663209137640794211',
'234619898382176110682893855328',
'758054315721288490059585368649',
'173758707327072160631239627293',
'372952960256715105506283635245',
'253606384839827198633727225772',
'285107090760285355942463760874',
'679027251697339898181997063964',
'209212056883267650210113685451',
'873209522968762876194418868548',
'626704606566253231368051279376',
'679416647693296050467458991543',
'689497139946980671939850766727',
'303560691514236416677520545522',
'782115222914426439455690677042',
'151729735077049239448578347922',
'259555477020813560564833159154',
'529976908170187667123110721345',
'729386223397848740076999387239',
'376662072188813919426261495298',
'166498917220359088472381113736',
'711354268359278864830334869313',
'553604414723669871009309037638',
'210071802059786448504884910475',
'667354218347090036021988444683',
'367782458643172029095891822421',
'206937352853627461574533556148',
'524255790005848975368042059168',
'162037009154758380583704533066',
'970771891821532311899060626912',
'716153018504791637503823365121',
'688311611035258082132973725617',
'826511638327882669477535557366',
'706891358337631441570059962841',
'586564058592836752623834422468',
'991519953111420958894401438107',
'457460365687278957046254944913',
'673676633640339603538447917730',
'524148701173398111601880312732',
'821385445305049058273207468994',
'115414801398981590260802761414',
'394775408350052837085920831196',
'944982597055921429411417735186',
'737481514991246660861233080799',
'407293778583486883482134240144',
'193462863670350975598392332513',
'157228849996489008972003527846',
'527058349393748094687861049177',
'205002378127496465644482489510',
'720836291896684056574900528456',
'522964388662825260955139829828',
'569821158810749309219358415756',
'516560384002172760779182765958',
'448152324278417095822662453153',
'362210195955678620139839236982',
'842586960134511494365426638761',
'906876627274298573210556490885',
'785178924404141623274264817747',
'281208033851852579592767841877',
'335598247674939854597468887679',
'335957851974873771584275120098',
'973524961699752958576590598905',
'650509752530543708876722445876',
'204617561340028883713241262156',
'284097537430193555011302431508',
'670364070634492865352860960661',
'923128302295559367160094889093',
'942806153752746198742345269862',
'375117775410040971323158266700',
'465821703202733627491216568298',
'555208234766562513873127232426',
'873513152462491018623266350777',
'588429856913759257484651532293',
'933926783773862204076229519696',
'162216162982640763321384147319',
'914860380061995364320796573792',
'419426309287327870355656462160',
'702607494490329902208719066808',
'457817980870991123801539030128',
'945862316138857036646919785205',
'838005231576187120658942438296',
'246323858716627888331691121680',
'920238519058898342343319315295',
'480907524991520574078704375195',
'482025339267931337522867989516',
'839317722256982372116814113896',
'507879205526431446732324653175',
'615072286628643860318740538227',
'261137301493164843147570321830',
'859436383471972670811042531190',
'509500992784572352168169156893',
'649366418502538943698753039872',
'599145415776565717999816435552',
'787454966601974086106222638548',
'435440327353514693389318718099',
'332136781837726459641294616692',
'403153358326756253891002474605',
'901661118906555064498846457902',
'638916947199610636854870071004',
'946692667214920393569962950835',
'893702688808633965694321893838',
'796206937729498292536760864991',
'670581506041285303440881078091',
'650157166173766766842601731545',
'323253840594284876105536732885',
'597708960209063663396370056391',
'905312102619684516018486569765',
'283567355767393099300247757216',
'838656522123769590700275952517',
'162178649823252184460350258021',
'923749439595971079810360057998',
'225396366593734959747971258646',
'131372025910487041868718220885',
'356183042177526106305113682520',
'664025896786336454076546181342',
'352316761432980842603228237941',
'789760104065575374077957193557',
'577042465165250572293116472069',
'818100015754387036366805218996',
'811441445241372768112598577888',
'732823931107770832577087194349',
'920378501973181868285412976259',
'784084078899990250340231759755',
'549812364171694779421424749044',
'457993110197976861821817737880',
'391382475083371950931354466091',
'256167743098939970284884442507',
'118699205043920441356179273386',
'793425098702613391948371233662',
'484584768559941570794018624102',
'324097452335121922715247089470',
'637531089510188339729941713912',
'705570195323141006870807117107',
'535635495277283028078696451763',
'281031748044717558935670514498',
'586643610659095029341710874878',
'600016269796032873479387933277',
'539277843588428743818732426009',
'242610476790456596819135899745',
'802419262696761508166971877942',
'460954528950428783009337261567',
'161752560082693659862722863576',
'281226105871779069085867672437',
'144341803327692384901439039105',
'326880588547953052556944189096',
'387379334940055984557064482354',
'110724908307253809900382150849',
'417539508337922471089298054128',
'368072738429578218966721252949',
'197251959512262535914813816398',
'524273439508321699034579955859',
'717827828991870364769277529305',
'413395603886430070487696721158',
'936599440585171896900948157645',
'913199726472735565932044410970',
'922958383724125354532083678350',
'530020690923580786508585419468',
'632335832379221129159763091254',
'164183594364726274208316327616',
'503231285223237499283389586057',
'799595612003275707997975052469',
'463960293471228768181498629563',
'765929044106378239042667095739',
'271126715588567428638402519391',
'870030493306739916109126978565',
'705514968745180334096216178841',
'828944915111555087198886140738',
'549584826856858122250276289226',
'599592213346178815898021333094',
'674034548220439802319587149091',
'893846814206273073183864638326',
'861450924711841277765904962546',
'397899741852286669513273993751',
'478076899831791852021645454977',
'871681392333373930796177778720',
'849104549887351691970068036475',
'146993838191941664869296522315',
'113656445580583955163281545223',
'811062175610255505143244824203',
'613375591496180727129238317826',
'310302900871061846434022961842',
'532527674883854327441943526484',
'430812894249663441597694431914',
'717856480446557575008377351139',
'174077363075059765174761195187',
'440358339560925779796547781978',
'265462779334597044409064890466',
'281593281678873999286397270832',
'217305294624494628589036245164',
'103847210716424171401478665397',
'514103081222325892611290933415',
'570346681245772169827179011999',
'714188461987491035154156693484',
'381842735205989058789293915127',
'165108454365070291841100375306',
'650147539338068449748812133862',
'676420546559926983845624962964',
'799346984050536684987036613870',
'891453897437843650532350870690',
'537519911668983090026538774884',
'455435332281769045109377656876',
'407504770824520479419770031166',
'649024624836523188243409666488',
'399886422979447887760123472457',
'746807928974129730766883972774',
'877661723790435191350447425608',
'611184700611642638966874264328',
'363065184974654784960904469176',
'268541353844424314411319853970',
'314178385174047614570357345530',
'941366220158936750125722617970',
'961816555952632631318455879192',
'525988749512321671600555893417',
'256905162279943305614992848777',
'143706592153158857577904841994',
'559194608180791546012787885988',
'192123293350198840951877416294',
'722004368089933924871780361391',
'231454076287720472013620831124',
'262970542928864988326522171266',
'314280962099957654096630572493',
'939412161508740668498415451925',
'842369888049053689201783671278',
'995807733870347468379490139036',
'472896518621342876150550876038',
'474162833320225955537192650331',
'842711753157474994340552423077',
'641626512695110983140747137107',
'972356792922034625104396925161',
'290607155965436845702051655466',
'298836406386997175652184973213',
'467182715591245100219013398724',
'911560058433676494672985218414',
'478538111029295103062429124986',
'241637646231299854651540545602',
'612657786984135952011313984307',
'554072629089457820568349577669',
'852638866225681653133321332768',
'598172573743692437925270366302',
'478500813278193687223774639928',
'837958534423685812246882446948',
'668383343212596519919900627274',
'991048721689313808001972757228',
'627995345236828551684528528524',
'332507908952865532915379826549',
'550339767440346969365240688590',
'564380233718755412904148538290',
'516047819496124811983506957977',
'819428958127679696806325346835',
'650229569123525891169008677200',
'214119642415775836655475241743',
'305766998524620919409346130392',
'488900497987932024635975752940',
'149757919686760117516074530583',
'320937592819176978377600368398',
'617322659442559099747747713983',
'293597034076323792662988318273',
'532410174982156952187683049971',
'604628533595854998626965468661',
'357448291399983465220874791306',
'922502678338386879680423348082',
'771146618806364416758695470950',
'602222394550324173565246247795',
'354259870244629750591755537460',
'941911456165126274746444692448',
'230713614283570199675838137132',
'566263167526425909590624111418',
'717207391913078617111770759107',
'601658851286080318047858038786',
'643129824848986499106707950132',
'143359302839684146955852987560',
'434841791500843815737339923528',
'905849541698465489655201597737',
'324430934371534228140365813973',
'837168956339146104613322214857',
'354105073247411998821521474927',
'378201207224744215334612168814',
'938820261762821548439472064420',
'360994554963028108025191855242',
'189143956620540415035194373270',
'764876961832850216064085564010',
'749229264473783370911487043909',
'795231472702784248961529959343',
'644954518811447471936194375488',
'255864049501464717415663764565',
'160689330969783292006095774863',
'484991124800937165316911661101',
'327852419041396261884269371914',
'216327930387016513385110721523',
'906013679938493080441804165134',
'605038400329275669214023828181',
'208634200488993223158709837377',
'171062948690671295878254299878',
'160822235478847955056477026711',
'312698560872524635671152396260',
'766584638643011624847243488529',
'394984339015298957346367541671',
'334350124085519188001183295619',
'827667706393198221971560856585',
'418024912299152355987935512867',
'899626763058446870983883864201',
'562765800984567811172549621214',
'385325371805695276528143222602',
'942418617009143105244108294233',
'373965305395721519839881861213',
'190106033702281009824151410454',
'227806869223260757222393174557',
'299232359234076574453299565264',
'379892529854238409007854194807',
'434338886321567629276563315212',
'585725830823571201997959252454',
'531575467449535955765330489562',
'628686953726242586846135737219',
'292146905714564839350946130288',
'115970400214164705466848974307',
'323627595444524543608629096243',
'768211443037498135594178084641',
'327831686898032981891241282831',
'521531054371794369186268275477',
'440250163923423002393559774449',
'498330703175682963253850388702',
'807472022131370325505031945593',
'329495043757863096524640142302',
'632668731230634990656579471880',
'218280283486772429579745599276',
'853816429926524460829839640599',
'517320901740983885222028332572',
'562573092651029827449452578968',
'724253947127225816667766643657',
'224605012655967960943175666010',
'493274380688745747855442976668',
'334313268417270824423988652891',
'718869653953151404051008451610',
'224881616051192916589331955630',
'860585547312873830887021097097',
'216450801127629287760598637118',
'976539657206988622568861669434',
'720276973948330235296694147647',
'618330898292751484419096019079',
'874246611177451189240302257317',
'116559347871129550034064840081',
'490884828529661482285683098161',
'692233783024122063388511136083',
'845079641363046000923657450323',
'368398017877948482594212662059',
'401226342637483436554823973391',
'555203061469329047889494591305',
'512391114055597749105813239377',
'706203750624910537964016455708',
'137669817842120142854517595734',
'716482602152850066175181865902',
'394615920780856830922920423762',
'517099569887977268021846426920',
'474859034888973288425121738712',
'655924374605655148344946729684',
'970648179751233492446984745864',
'765971414250375646627968134093',
'287134519566995365350179059968',
'434974445163825884346528350192',
'497989344376771560988305389191',
'294082079403966001213830297213',
'322259392825294353198968151866',
'197473165599453831790727271390',
'742089160386593426251195824214',
'590057625503090859882365314914',
'890510619058534566152476445892',
'256903682649357054740012875995',
'910980040503694557714756380209',
'595628507803724539766434446192',
'789198753928834843931039836860',
'617235549813628978512277247390',
'615825764386062810513344724326',
'721195691010388251729067441734',
'627033159181317277094093512862',
'157860282299548792686238890340',
'550462946613011647492094771784',
'926610882208189484833578079075',
'181238736351780304662231839606',
'622129309313297259738112529519',
'952556578252677711834714720803',
'363207962870141665785488742305',
'672426026413230402407765114860',
'686404341231191400593992064101',
'749346394523067850649677283872',
'444096185584386028686684399769',
'374165309935746990563003050244',
'177893880040873856503052250643',
'643270575796961590946872330636',
'212448329728332799644485684500',
'442359252447489489436225865424',
'745994272439515178297335061363',
'244170898667893704342222535531',
'323815813739832731634348919675',
'168378853998418318539383647115',
'996439975231497494796245522496',
'693344753327665866872162083940',
'527067216300459269257720953476',
'342677576136551316453160728024',
'679448351282871782292866510809',
'785245701552755153587642213351',
'212441579569258200311349786941',
'707960112921087568633857639635',
'149118971088238084421134556640',
'189198628531626243751659795705',
'454262587483850375617020485435',
'285004200236336520680600745790',
'942485037439952830541071098498',
'404246986584213590342266183607',
'801077789932255289805295296642',
'971680928585913024235775979552',
'447307152703285024388318772360',
'277774477749128178338506055545',
'855980448982271667912707132450',
'470309602362157749811369393142',
'619090680967019547702361585188',
'870296905661849768852215385671',
'731557719863924856190320926324',
'993041166119785338932697082621',
'838530781248525537743810353601',
'979832574456440310237963159298',
'621892798569567831893931246753',
'916560308471578064532301278145',
'586018210882644476459582764176',
'258420645927550298841938929311',
'449182066379680832072163663657',
'827312838287913102119092936851',
'366352826213470265272202635982',
'468894168826394492066265393235',
'293746294956591714661764589968',
'421725087419912242882476861966',
'777553006823776690284583280665',
'996285718772618401699192290462',
'607425912223476640346619778432',
'829968505536251838454434719108',
'537325345441555563712279512778',
'162904455051497481681522747172',
'325323004713813051668000262527',
'790761630174057123078544399631',
'325156423015699040551023122354',
'779408732158485790693625864561',
'667006660058197808613490968002',
'949935838288421657082805158415',
'148256933962080560091888072896',
'723515452814640448016334096641',
'357253945410717535683948963573',
'888879539126671324514215241614',
'675062332687323580275639763442',
'490048114702147198555749659410',
'852714241027737233670640079122',
'640527140623821210862936521291',
'381077585802643401571971472614',
'332072492212114309085617481745',
'315438167520354055693343029361',
'201691930732313638184825272991',
'653196842118627054205098673040',
'280793143265148801622206697766',
'498984040165975180394658343973',
'991728640120867996480044330313',
'252434268906696737819854054942',
'137925564159623776944061034611',
'154493819225577373661522378970',
'759096484448710065186909675636',
'534702123473448859692380741545',
'133015433469411478400230828069',
'346143254589778510902232560067',
'407466521360117639728177767914',
'877487810232053892724930031170',
'568983949156532846820447891572',
'419638295245969546904013569672',
'976886735563485736945413598535',
'862658271024557482881593585475',
'615838121534520580368715143168',
'129734117327820338732078224379',
'365238819141511502820264060657',
'230638539685460186534617287802',
'968061220756568146788278766140',
'857981872482128197123095235690',
'169075571279137679034758917307',
'716783788226287897415905111219',
'957700184745349978294274023762',
'625858303295095976186902151856',
'428559502638562893101163171886',
'774360667799388372555401396544',
'559136540907377499476699969795',
'485779498492335557200750654098',
'375719455959832453042090895536',
'188035568055184421276855792652',
'217463660554281144223167124930',
'528318755641756744864437232560',
'605701576304352526835111137140',
'305369266410287147269464291728',
'321135500149681893448826564610',
'223157594532771139581921729046',
'662624968695622165943984385611',
'367780409651225546166152837294',
'166921860231844635685414611309',
'461976781548198415558028146731',
'296843713878622655589966194110',
'805494091891740156529241362390',
'941801018494513310257329386120',
'514752345410947065138809634591',
'231465414171279544766996421621',
'730150334150852117145986089154',
'834036725922360166891013328133',
'257394074950186586260192696193',
'933211676983384603106007784953',
'662124210853172770002424535460',
'904578039789097494795297859918',
'749884180969170962795302191329',
'975666821046468722800603918481',
'578324404405127044103671988876',
'196745882847018380274414983340',
'338870375944152285249160578342',
'161368393880376415672945451699',
'359718909565726764908246114182',
'983560688929697277194634025713',
'982569842642142044157244144113',
'116114965440984434217632595014',
'778970837120718281708799722564',
'764884320617739065472364529393',
'531907999577277020172196872355',
'416682491126093578695367099010',
'477575695239522518240910742221',
'531375792832870538527992093573',
'591828661039014234221262014708',
'355220769536557458434341736012',
'243363153343877141093635190444',
'643865326518263296022364660379',
'749794766918528453403342590497',
'937365690771645739665639468535',
'891066643047414176709290916367',
'261123001923821352991736143078',
'488633287793976867730695231861',
'266145050013440279426805655855',
'430496806594943699652472051744',
'332750496703427258988802081676',
'909058865123688707958393870723',
'953572045025566937495922248598',
'206862589215523822411191426273',
'122110029618869483568576866379',
'447927042094080131855793338746',
'874859864909239087254237081673',
'437003183034935094139246639041',
'231460698181898602559014196251',
'917154136747242460896897129493',
'403006684222588370745364825394',
'835939325578031388955934580340',
'767068236602122379921974857601',
'643753574940561524395717277884',
'365126613586682001595730574841',
'777176508826680927618688850751',
'256447973310187607047190269351',
'959453168896357379514296060637',
'134257620463331551143212534234',
'500890860713332043771585920744',
'661030457457666812400666923513',
'312090096216074488239880583450',
'973945235410160758064549980252',
'627979891080881474312665148372',
'218714125578430506264714818281',
'422866660378722224719033642388',
'395978287903083305353470067417',
'326338640170239798143398840798',
'511034439620197696102777020173',
'215411271822076187008618061880',
'209440241945576941183533121146',
'735533825925275312404449066696',
'462475788429191416143529867211',
'976598149475493766323750284313',
'679314783553845834882209157291',
'386772992698310315661119332847',
'941316073414777040914727153923',
'161925347432258160902611756415',
'956504533574361668931009629443',
'129786768604247152240273499188',
'857417692292122287404361125541',
'983978855210847146037027137089',
'133786388587244254992163141093',
'884051749242963024161244034646',
'302148305126441892243565027281',
'885303146824466761537307543524',
'752049982296265774843352686369',
'339964097837562493885180862706',
'460079697500198229875406927614',
'446054673845176341742149831645',
'731184058436099882067707274051',
'662515736244030849837166461228',
'917139623251530300976353486871',
'838456767305556128347949661579',
'519928505150970769194717562542',
'775085231834667216258922473844',
'816684677398083414635220439865',
'344802407849931672792023125861',
'422302142670393958420704082504',
'498975694028337858202699092418',
'913109220471022833295718916669',
'845453139762781151117979645395',
'838136159772195225514377050474',
'278190380264329670239658287884',
'287010777437090553252858287649',
'359869223538910604198710693354',
'252588641520239411884571275024',
'120742660386571287497421847617',
'726199326825691876507714832720',
'465388006065322367659442255081',
'401361158468695063319120695828',
'365093350771789815761327579304',
'478375677379985920446840997771',
'804494827101350474491438454021',
'397882734962557567050095873788',
'849777579662346872414944119341',
'329631230888329889142163043990',
'805641139325099567049213584651',
'691709558357227463105081036470',
'995252283567611549377029543818',
'685985036859233759179896919813',
'964215891200934995393204828391',
'282692395194020105273346180518',
'969346952207260776906667343219',
'377858221238927077053496866871',
'771369308936915618709674644848',
'410006512839823901116571166204',
'698783211812080136435675111491',
'139548463574137641391347968756',
'246369037281368355011962420926',
'821665968133339054046237359263',
'717079069908318807157991079375',
'666798332420519231650691522192',
'133908205950042807029519280653',
'285508439428389290089418945506',
'942275786330433241138471885504',
'196452421416454896932148291779',
'460066232934987791962226948294',
'203585722697582400422619410815',
'808510065608764168067921215840',
'696286038846747860819421823753',
'690495039897748189897780088877',
'649256627929498269602311584525',
'191731522388624105447933431185',
'763398007392796195075320852502',
'818690404011283556631021155536',
'513415353759122453711676717564',
'491902120890981498641796528048',
'371444562878332878509684978918',
'669738388087998468052219248978',
'575753673966324528092024628642',
'134764546537273336321622925993',
'842853976532485969718527119561',
'102253312310210704773637062997',
'860085917527518734756916868587',
'647138535975662008104078936395',
'955517672677155353346725217617',
'723965872399627231680591766839',
'750721586198881644841631742604',
'141939451991852389121058218113',
'653888009494324506767158966259',
'986084236709334903736000033412',
'468406072640628113425507856375',
'718554170415211408666523791950',
'863850486394576929018645591889',
'777518455303535606762378974421',
'237786971154217437454497657203',
'709969881835655004550931269434',
'446046592489114303346024019560',
'888093807376320031694165920256',
'965764867752015082923467347732',
'584993404408952216891046910300',
'895891249703559322747594967707',
'254013990310454774099411997908',
'905723293614213677245321871970',
'797352951965245976188883734982',
'662939712119763235695017795690',
'443743633400873222382518029880',
'311686066820013079080293977847',
'283280864542441410726353244095',
'280887624236140086586715551631',
'207334095949322477073843026654',
'333728438286545997097761739296',
'958933821541524503548650755418',
'696325015730914619706827456947',
'562882082308111096436082764011',
'132264344755921672407576260630',
'908023514166335314406848095062',
'706919751078738532244800965895',
'344059227929598020898995674657',
'118660693296295289439897632208',
'239290908108433750620005384666',
'669246992139250325893481251537',
'652436191659128446942950089601',
'478825803376356168908682427301',
'107470526305043451697246947639',
'939436623678466559071963251812',
'913049272057185112177000611771',
'191721786047054316740191779399',
'834620511032935747836053292452',
'565435053251698164823198489162',
'154410936297939023885897710849',
'363091758772994921179180238003',
'905890418625286217295851892900',
'119063789894574810330219526578',
'762950742241418610875538871134',
'476783217469923914828993184576',
'849232573545874284043595064792',
'929277144626570250966026799950',
'454452257595958709728268959750',
'198047695843021067689205371851',
'963146925439713306866772361756',
'748300389500376916456492829119',
'738867066966072864534301386549',
'483618034940110497412635855147',
'281167969414566260000888288019',
'339845165451089906026704568743',
'184684576745380241100599578545',
'587189962309660358228782041809',
'852417621097765662375432388935',
'629561005692487720573807712620',
'237231383347718974857708329163',
'668488435653950911297707329813',
'886578976142459751058573384293',
'357219501088745910434746147633',
'431032161084351560921158620687',
'480461544478542198559446144012',
'675034462428097112439263281336',
'940159313538415553020079452617',
'205655007524192317617780138090',
'549275021545541907056423455044',
'380279294661866451767768320958',
'331347895886455194789521572680',
'182390483113715144540880472486',
'589266084869962788028849789947',
'403541293099537436770402738770',
'937343009644314222766324396880',
'501834068206543125652262637633',
'451171514841116967128153465356',
'472380522758257930018555679799',
'985158883143550353306996995350',
'465650787772817298912607832655',
'459417700738684483501919026350',
'201364701497013920194447482268',
'935313730490553011790923499489',
'300361935368119980630436527887',
'897242324327025563497149879080',
'826121913278827985767210333225',
'104411476213914770275864924014',
'852016500958974650424942259722',
'656964514722465069218132664125',
'520298921408311986461002292531',
'328966518709866260642116313511',
'160016297732469842233766428349',
'502048418173383269340112735969',
'398518148567951029753061555396',
'466349314522047961815223864445',
'297458056318671556740718083138',
'103210073454668389810735068274',
'806612163913729075115675218786',
'298505431220530184329781312825',
'964015964443529175854497567354',
'892819576608284586322894580513',
'241146783917738303137266725450',
'532329317181883010713076551026',
'643640141378589206591088541446',
'915133955130091177059411330803',
'575110478408860222606370213696',
'125028144788026652990397566106',
'790278220486475141564372574071',
'724855738468532500347183414148',
'518822519111585870064402759917',
'931446113787984947241870698909',
'476329701981225190824432963965',
'746833805537220062098548968609',
'381172447070942559038117352282',
'737402225519085181132101527402',
'235610171402735378639425088206',
'927853442539623599447267374193',
'770626662455784609415802764834',
'955730688043745086662375272001',
'104158427550640406372016061408',
'939759282830188266154295647301',
'490312410845468105014393526607',
'651397366439958312871759766889',
'989350076051790586064762193767',
'160657001227927312152706315340',
'870552035118815267184766542718',
'469613287364699315603488921720',
'852041213241176780284693022085',
'679182198465136217980909896153',
'200837567590552492312974170501',
'770516018902547057498164669293',
'687862918028098229385519364639',
'753448966290236079887017463195',
'956497938609192227445575823735',
'636860442372172056665361321256',
'328452824135670928827764045085',
'646274130260465630145630047231',
'716421837887759088270625240341',
'966819767245330124369479978893',
'987973462019478532228910568480',
'825267959318482688479899523265',
'329284999226444706615474072613',
'872604165199256639722450513367',
'338584999537924653411026951156',
'984329542218262718049379117781',
'760699902015364986890721676835',
'678709540239896455728018852671',
'680344449856284266960605788313',
'939397261513537338049203251334',
'531387782085963568356243269098',
'227708247279946126255101574922',
'685942876835055399697779678263',
'972711722681296469928287654807',
'836756723388479379535642022695',
'800684494163896790394144775023',
'434907452309019766385919220930',
'612843880230280447637771934447',
'228232803008382627448946314527',
'548159802691872282894046669125',
'794947064008085478663764111350',
'974191320386234349478888729650',
'757603945384438485125195497750',
'125277106273371267792012940398',
'894072595755929384325900456418',
'562915239597028479184695495113',
'632527298984089913773087354312',
'324511114925815782494354188132',
'547640677050653388260361463201',
'558871152041812812331968078244',
'628147907025479517395422392547',
'580103896440626287928231964295',
'949311067023573544980747124438',
'317887191403584474762170863239',
'314464640892843935301747679244',
'641121695338551997836040838394',
'365397890695022365008383552440',
'862928804049584206569178198863',
'610160311969762119402710722814',
'222773490115380726055745990873',
'978137150498094603966828275310',
'472614326939195853267301694249',
'596421121325080592947505660592',
'547265033830535301393609344641',
'439841495499045971030952465405',
'599969206528266616649340868371',
'455486646409010557445264616221',
'156142970836841648672139929305',
'376519100218949107210467884472',
'987374723428676229413345046092',
'751307494213930578511168156762',
'786261768972590931081879868070',
'886912260327740171020398821202',
'117171919464420158667577517978',
'915660802808856799466178898055',
'152920877709283745101849585934',
'602114404103243564591203080994',
'969343169362484484604130117524',
'562115338541593857728878965904',
'203500307247412108009737777734',
'381125363975781136134545048145',
'452904653099811940256488313498',
'593620119512081468244632884440',
'856148501276040400931334471202',
'180775112175982907042109214722',
'465274467562050355965922536203',
'502088394511444991521551931899',
'692123967784072884008474458147',
'113188068404165103796437773760',
'253264705140210399700347464372',
'614718132201060061460612613440',
'663466502726965810126401592495',
'901389279600827951349141010141',
'692458114277714145107109714459',
'833309576537231788004128116498',
'313801267163997613816295412114',
'345800170480322465406229691411',
'229704728537257083284600358679',
'495466049773585882165783283674',
'628210648062443098757834582645',
'982825734296626347444740827728',
'292796436093949418204045872798',
'450683355716777583723388579620',
'507644981319459537356178689793',
'812218119917813425381556486977',
'105896137441224628928934398016',
'239339393597719251289123643482',
'535591156093399307742676191935',
'936087102264855041757150236629',
'663747315428015846877658317975',
'802350593038097066524369115313',
'155033439931222990908312563159',
'912420858595542149052948181468',
'241822813509159108836788956423',
'563680909616174696672420832595',
'936996915205161567318679085080',
'303618498463686248526361446151',
'307595046858665784578066651902',
'206250546918420675306747896923',
'340630712374590010511525722768',
'966822105570157096387194436137',
'283746551126573827850285695546',
'810189709291910073448791774257',
'990546410828433398802441390274',
'790700078371181741458057928643',
'684671930636480978700960352402',
'200629350233423271005818051681',
'280099102222312972456415667118',
'273174893207078492362986045392',
'947223557555137271873965882586',
'740857044219390736615641972674',
'597860326852067034522565226279',
'279168927849531278135175789889',
'976795459152791719158118255953',
'508095464495684995153630184390',
'217454641526710947540087896098',
'269184543751863126018526828456',
'711272042459672230990555533075',
'248880413955220961477336681873',
'318825553147981103081409738598',
'943408104602776542572085132680',
'518371505947997593186599422297',
'563267693543797377016129486724',
'145030202500023976341415179792',
'854772564195416873733692221453',
'559665441147736261852711454834',
'446282500212298780042004838347',
'981676667616746385188030484189',
'785703544337368419867477980936',
'268390218184671911448535561287',
'163547266755096287171970771236',
'882020175859853101007661025087',
'206433090638156258952744342753',
'720351254279443355904424248730',
'918954708402765180864574775742',
'169528135443237935596430657365',
'726176325873810002952142043198',
'263714265411567189903941590396',
'832638813625319860065753445472',
'549160816979310889616844273198',
'896880289069997663412512230485',
'758619393906691164337246478055',
'622312633169443773562872915776',
'260909483818220937561348495050',
'305932856803387171508107260962',
'440014584257726513771918013048',
'557967329934433650322086892960',
'264825392400993750570535374962',
'655599806543011725120641695102',
'374603837711319518509823576214',
'839069729327484422900165898012',
'945422135987010836758217061157',
'641249151190636048657644394241',
'756116829250987875450662966703',
'559289332973458624327400980944',
'126557419404998378523255621227',
'843652687668648642170168825264',
'584999314395160091203993179637',
'727928874779337154181900782958',
'613129122069172937471110471097',
'560638476137469531536082831242',
'428017800303229790102834620223',
'961381762368173902087043683178',
'472919657600945368977266142092',
'966944506818649820817378871907',
'203358313694914190933299626564',
'107380898317852119518646965291',
'322325016322423442999805517412',
'248021928592185879798121823323',
'973849136629153550831092255218',
'179510631390326034505379447519',
'212044886018079540325361056355',
'315168684234768615457866751250',
'286632682329879344822504185776',
'403697135474750748825499051989',
'801358566885857583788822840108',
'759212886577274995943874321643',
'395350973212088463996194596878',
'561493280886669939005965613222',
'327143577957070309716800111089',
'903420275978952948983683245343',
'531769626013112571499703128430',
'658625983590028120846786248867',
'418766169167848958175371381557',
'624077434896633939381232418774',
'952651228243643763774538140748',
'220639482881134631097890283546',
'459925108948295662189999529857',
'175013999608262597561404515627',
'942986843232936266706738712233',
'999972305205771366617123932613',
'223031309640668608448046576461',
'808343798167141031176909324498',
'306378460190763510699210560522',
'367541935670081669595519756720',
'756219008521245310556565241169',
'350526575187311938166423164475',
'305066849789556187608179375227',
'807371554555826940184220845119',
'939571686794158169089069375816',
'352995276770340639057930757565',
'128690802142642585725261388458',
'429168123340964181426862476852',
'973735618373464981494316589629',
'148623614737227541456543249740',
'215556297731340101303992328752',
'395568113794773765318295117443',
'353259734998890824478160990620',
'518618748297593866219285134182',
'915877682515578108561296026328',
'401425395989556756769545497431',
'198675209601665172158925997010',
'998774947665690985914434123569',
'151420826899241923808924147381',
'377993832257276715485014444946',
'994765401339689408122749895399',
'583013718322850144974601599698',
'317195498386625721783184674797',
'828140598867420803771409466357',
'556787725913172592909859657920',
'119515407562042424083829727060',
'124784919385354376552057124687',
'626930851802254785000964559512',
'228400358712043813969119878395',
'519954472817546512678961347104',
'742635297088959485129022371190',
'385557106522529435832582722840',
'306880003967832100482878620288',
'260494110804479857057985239061',
'866913866645436134660213293860',
'633914726946436749610810512384',
'984102414217759065487555285564',
'638184237268664021400731213143',
'282947396363264895113819629965',
'373626291353384842209022747992',
'441473560303028297586974466277',
'357519564664582699696730078742',
'351571135740368426181515667449',
'940267277193397069093146912895',
'435001831841365879941404394200',
'279080354186818189842340578646',
'847513620248233848330432473639',
'691953962149743448207218540185',
'964818821608430314325782676757',
'789698060206393917927233135291',
'297900519329638405672755523531',
'590984579149032312476321149694',
'585963418402532130242879362704',
'264637071853244368137421186725',
'667221233007569991688626817705',
'861839992625161674245826657819',
'367039563729927587133480338334',
'890124051377621661250702577935',
'197025090179488508222882021058',
'539216443897686306107891327501',
'820843608186415349186609951778',
'239386560425386359668261187394',
'777497755023060913922520981568',
'491980120750482994290978936780',
'537494518337054470522860764540',
'795903873470875103622361230203',
'603460801248152552797917131056',
'894128538534153280787712668558',
'164275822415122249706644286729',
'397179836846830382104359699560',
'371168743868595998986487958262',
'614271962029519244835831639327',
'215283692982089022524458056092',
'168017067249422882879118943879',
'300957192227159765005698273347',
'719279411391321109519806262887',
'551936573709053944924591854055',
'190524751710080998668392316429',
'459156536305224994487852735731',
'597133014759971572792980486090',
'922935576437043973361516981476',
'434833686947382608145716737504',
'196773703935881651202003080637',
'456586820344884090551782928496',
'828094552509667083061478124994',
'268621771069512509511119653540',
'756415149026284629155516353355',
'286514757963612124039579310396',
'132514060256891072602848695884',
'797897687228754097339327652852',
'152654833896770812784358289074',
'908404311352237590661942392280',
'597910104119275277723574727506',
'217590903980330856637657824130',
'471789949109424045140598825894',
'762723640504792593488208597161',
'523300599408673904301968953121',
'800298875335766648886667484290',
'148764099430319336546510482804',
'386304493614170655152855286251',
'808250965567494252182716468819',
'464447661680820130938679837003',
'974590088782941496564314835027',
'781738895890796482299436185969',
'708400049528725624031683785257',
'621744271401017614107966867802',
'389815050210048438406834286390',
'351294242986561976661585372351',
'884060331180936172118635114579',
'443841996595573680353036334108',
'874223203958382685750646825106',
'603016418873896510602758319449',
'471753720354197389127754543337',
'147101102011646465971415386704',
'612025959391592570292564426838',
'588344431053087015829055612531',
'848909678361422646554051812893',
'280357295304574235983154447976',
'446740760684887197857972543034',
'579610403964337063759690730002',
'681113701690924887484264593584',
'990377703017311526222821864951',
'626838688311929212704327018542',
'378490411111654781700913462508',
'512981574161296148720961943960',
'374041991161281374013868759482',
'932566981468983684098865216392',
'815845516177484913464498289749',
'403478019415084079890218868643',
'326700285440436615327459584498',
'386534447322284384349107823689',
'952047712529239656038027570522',
'987972121961988331576575831969',
'571337158247345507450473860325',
'568371871510343058054988581031',
'886667029306593282154257946211',
'565461897093087926270683362599',
'186950316725831343518181725517',
'224061966458682716666706878064',
'539048874482694851119036855645',
'812649748051132634830537232330',
'376369143029826890072071345755',
'113103104096983821243492988109',
'353560418221272197852308448794',
'346191794501314106145188563862',
'926628570443120595822069437496',
'964098690655813255558849458690',
'561932032892214622185081789636',
'654837160478093554592570479414',
'788231128562163547230273025716',
'228960860937884065776202434354',
'309115564337530272391186789380',
'385783879202080323128935029262',
'659873604900644574192035143700',
'999197130967782279696228455953',
'432687281877497575872875911375',
'616931775818454611861012782759',
'955815411480282660944789151095',
'916306610696127937860331732150',
'220171441854620355964068450543',
'584931291372446613821882321633',
'750227683649147787367181485846',
'598898182053131243130021896075',
'150925990909051881837153236010',
'718051179220136744828436892941',
'691473260681865429154620179081',
'606405021685816145539228016348',
'848817220287225543183723065896',
'280986679377099559164074691323',
'890181749424573405125872840892',
'420008283402640526995985656579',
'328353368520986356042997416044',
'649167781308592762128406588875',
'583488912410824159189251539747',
'640198485452170128323131570648',
'958517976267199518605670440726',
'413627553095758284399966091641',
'735022147711426500134441147791',
'929548235944917023442370049353',
'937452187470872304421845726243',
'919468320433375181214992352142',
'545008294853828726993995248358',
'930711512435267353502644683832',
'312633288961720533353476115381',
'240333335382639882338465488764',
'273174778565688753267165932233',
'805772875856386907748372096474',
'386728342882316847550972328461',
'415149654974083112123219057748',
'739323743542081681143947232303',
'663232457277769260079912710558',
'550580249093659597103777483244',
'949496574827497839265850492826',
'494133686277370279778476918976',
'316626606171734540441623624693',
'408535780663510442865920764186',
'827603251284512894578149840029',
'784152643889744760546194764198',
'512062185755826109877134523926',
'629204305153493105566373655923',
'299461728766280281369051314904',
'464305136117541571592483974633',
'655800406450324071042189712927',
'928161374953094338045643917485',
'453537823453891403318744837028',
'276740044518965776470204828852',
'338488059859973351099326779035',
'684863305783735911676000830930',
'661366889589657701886234090077',
'623181328036589617977429712107',
'431545660607615235678346381841',
'624304295212982520109320710308',
'805730453116232779112087210647',
'491909753703369406473357944512',
'664087387811441736641610173552',
'610062540644975788223535861980',
'868735657606576282067035838985',
'698083998990023877353703330759',
'979708872200124496418938614730',
'623565270175393312135550011159',
'983305365992015226850347560662',
'206601485825830431372394411389',
'168372073870131603283137638916',
'530514609293225412542303486340',
'178574675362566529167289186474',
'870660172132024260757539531701',
'761741256915140818130012513012',
'375999844771972784028414251796',
'997719306525744145697875320470',
'191176420581053928606377428040',
'670116718328557592416186516197',
'200802412722736322350243964286',
'138134985660751419159262160283',
'759230387451851831421406748030',
'619513804483682074699843481494',
'428999195387092025221186627131',
'521882864912537879407059638309',
'878908087661496494433733062278',
'456727981692771474213897816990',
'220232824450883663861264655167',
'399284611750595796025795391354',
'936564005114167717655279691370',
'469673947726334332936353870041',
'897953020354294116601403825481',
'887423003474781483885141949325',
'704144644965290568003411781497',
'207823898781050146865074058998',
'652823532160846399777575324855',
'117102962815917599058288097806',
'859397080897861996143229028456',
'425253120051032833792833815785',
'891592462445670283011163543169',
'973178168116519792582049573257',
'581942393197287960552453898965',
'892135276813121718891294532155',
'364095590919787951847272963713',
'233969797123885272926798595691',
'630997797324031335390314567932',
'107750325483389914442313469475',
'154248654578583263344275855880',
'913492785755531352684676313027',
'953359011712185799706480565547',
'410428002105418363573537746913',
'588844620977943779021826259052',
'268928052493965887353678984067',
'856004065021221350807948551899',
'805541076932317478756317037463',
'764412438590945043508881558263',
'796871449116735932607556214205',
'678141699573630249782241034587',
'899556189362448508525617753296',
'327279609418372192568931813722',
'342072178120267942414813115724',
'278775239668119448869397259583',
'503150010701482464577355676097',
'804349801632312461375629179808',
'231023508193544438669467418001',
'606154756518779979416037797232',
'411770241602728385427405718348',
'289152967557553031282747161262',
'814424194048379162650340191973',
'226007366312472751947796883310',
'948984928261928227539096097816',
'473657701400655970600718987298',
'708938710913039945523474149328',
'456157602847312232741807415665',
'314324402470716230530048042365',
'674995753928855214182221918042',
'126064057640160282783637953674',
'554668402961255556774195040054',
'944806592518392050899051326635',
'271847798462331966674431141473',
'857664752982464645751227091004',
'719360504498923876114328388705',
'401671826577847917533090387324',
'143680204102163403026388544299',
'558734808261833031371770676045',
'992025780416186823771754819898',
'355381543115550033934991074052',
'424295652862786893170138919886',
'166252618610514605494467173653',
'681481654147497043136076585418',
'378182124393255719196304198877',
'186736377218840364438433595449',
'618497522206390627174877195809',
'316730413662589231793292178049',
'146302443339846095692239039284',
'645566203296624922893414826867',
'664774924382465276706530781489',
'526127286709469133284281894454',
'650808689529723883775005670474',
'884998062004867809402318882318',
'446189761384048114295099337470',
'519973125278148245212666895399',
'767441727971536879675202796254',
'871221728099466949186557357676',
'834934155414160995576870727288',
'156254440337719023931473272838',
'170700973836340102600325493647',
'798373890251620082161290142867',
'418810809871877951779826061421',
'356905060231414206269371119318',
'859302987119072264960259382953',
'330013543532043680114098395327',
'632923226349782512619369210880',
'984505011482486566492037736409',
'228689507965056455401558824873',
'934957709766573046135932487592',
'829551270656593202623696985152',
'769929731707331226450375582328',
'150216760188527921287767997293',
'943671073213088868903352387670',
'734971504393089692258660025055',
'796127377808811339429611444476',
'742007858148247035666675398588',
'240760849789070540420891141343',
'724173967420338689699779658836',
'466576827866738494951436322334',
'389161689071576837607614695560',
'201949719188313130589934419890',
'711674860615641957594172936266',
'146590625236240735632056329983',
'330348830895524899847956893630',
'595423294537839003206241973498',
'563088944466753283908074999405',
'356092638046761057241969173211',
'813209636324229526451277083146',
'259627478212973883942557554757',
'938617226900883163985152010953',
'485540097281461695518029874068',
'101111086814397160335639065761',
'670414676587945258194551353144',
'849530512791447836295370833020',
'161634567190686911311974269764',
'256662670353877294591924069991',
'102214948503320310864109358309',
'606257938241675630310002540274',
'568800888656221304496995715807',
'195585163806017686924468811699',
'310552340665344739562978920629',
'851054320413593427065961994733',
'851409620412197806131285814101',
'244009063922880992858098734500',
'982873893902244309876057762968',
'488431071634781234778624351654',
'106265972743920390224396078954',
'378092405172051972898816833942',
'772386926954239658980441929622',
'215600836356835104237408530975',
'760488552692631398393232142711',
'775795997168149828193127547217',
'355325414915845383102577348105',
'258901260259722454440601552147',
'526156033160284950651371751548',
'102229830322918988135591068535',
'533850278362493665455142284503',
'991317247535966940131659067514',
'514275648414498532027194593743',
'148076477942796637082203250849',
'576975953558387226157341645380',
'534904011612058049291009425512',
'986463882916424801132938885321',
'153379438107977178865616493000',
'232010853509815791626827943338',
'550806810364139933681686162185',
'624532354211835239576555783497',
'488411697588229967494693660932',
'797481712661675541618433064432',
'946880075775224316079915866464',
'905075533972030477564234722691',
'693854212984552271824067131731',
'682580728880613429211484211415',
'237823419340851173381779560031',
'462710296750962811491709192219',
'864305455332528720757088112070',
'554796551057213721113775153808',
'149379684394971799643764448519',
'333932437845726286455244555628',
'104646868577413047598853761528',
'489943350541082114113218916960',
'697739472062776576954633563400',
'950453615505628012721101699747',
'316054038030323789770303449431',
'588585147458481743401843479376',
'645849274186559666030830575283',
'890990811088478755210365292522',
'303496934382056761726670220560',
'584065180474868149042187648392',
'262175663468737670650835848499',
'346537737642094994228073169930',
'359929995807944147061128157590',
'550496334819621426876756661954',
'676735472631793460324949012952',
'390406369800434801652458446863',
'457355915561651427147625423999',
'590352447373057614967223875349',
'180967027243373934350603575720',
'330322016627857836121982390786',
'212763222528654516167546691824',
'302342982863558726249714260739',
'613004575577414909689077157769',
'150559308907771707961543598126',
'140355635045697361059562373903',
'105604565895046871509278572443',
'142119883284836516803005110686',
'133274906293881135684653188487',
'221345573264884864373598335681',
'200009752582028629271426738681',
'238449743997364836282684790811',
'642103635816674193424505210198',
'766532592936052481838473420618',
'709004884016724633400125655292',
'889897659489596959873207861659',
'766947779525521804818159060177',
'329173359604920278431716525608',
'885999771262576941224341777671',
'192960485721881380973355261673',
'420615202971729962379617138965',
'351143894046070890935925343142',
'442251896819637237075982999607',
'656906522124497275075633280240',
'536405091596832298389941660205',
'122370450072146793784833337146',
'796318695212815635242694597864',
'940344320379164332136055642643',
'410518116661079550939493185834',
'775211547191172331341830355721',
'382591404532718862300204860502',
'613515843528179701121189544084',
'504313758465268682555800562313',
'807139747794233745384519470960',
'614093538352584699453673141143',
'343241230435478697706076171250',
'423271685586316654234005255174',
'509743191290336880213852269525',
'719626779304754946006793764835',
'364851510565828803068850317642',
'596930400070663458205859684256',
'409690887328868006805408441669',
'618708317136937469998945023239',
'672720845075685075468784388050',
'912065296851340114661095072252',
'431109402065377049962413371049',
'589371326131716979051801655682',
'330198384393419373352065084842',
'357631439890199967362342739448',
'149105601111666648089291963203',
'613695436401179702803761657646',
'752561744517120254909751914015',
'332767405378166634192727250700',
'631118404442828392855601773228',
'239660845944814776382742937412',
'347901445267720414826946793853',
'938934427331741865674114531687',
'881243831052181420175718394771',
'302927199117090499914485574451',
'326873415079452829533098390262',
'901682785468768079802821728129',
'356943398061485024441263177463',
'756585311956790623591583389520',
'103320286253349748798006058864',
'460230933481543478531819182769',
'438049647735868899152112844915',
'830826709789063304748394953329',
'382695555316864846026402214179',
'124767787001831245189615373580',
'522423402806167503309818373410',
'282063728718276921076233038473',
'376208562625035405467488368553',
'275639106738790573823015859180',
'141489422090179488458390687674',
'529372924348142672587919561772',
'567072637072489536950321259067',
'741074580102587060656242793478',
'994787020761750733218221036192',
'114334482945272067745376184052',
'268576368829184915170811171643',
'864242959704537707297444730305',
'135997215138111120200238976627',
'217909780333122787401987243575',
'247033111150546506739480863931',
'473140764529619834467749223415',
'987779159182525779286414015766',
'815837407822034452188793847427',
'190953197089216509927134522281',
'362665303408158919475111314729',
'336842442543748676410276916980',
'798176504478078594876047573971',
'473731667596246034456160484390',
'897488536559548503334661567470',
'341073263033294409324255121039',
'449640474048237164633871995453',
'691378682179255090349608397224',
'110562168964799465318926132830',
'164285667011743349717606215865',
'875790431168951996161082560325',
'870346734047688187950270913195',
'235759732343723867791284552650',
'613182179991312631442560282686',
'813485510579158107635078887548',
'808901915583528990096481250346',
'781096104668113143318048544695',
'493845932764718937187023527272',
'723611320090567774639537464346',
'534922751092290073092397349328',
'682633168136142562758904936212',
'791742545991819492625966810524',
'157710677298830169006219948004',
'114553939804410359435235296275',
'612962970475259672315955682387',
'947534634149394903269419225025',
'505686521335567156432408033593',
'639934570049623078611060294840',
'686018692203324929945012888197',
'893025385963085251962295979792',
'840349019326076369690976847632',
'629998638706718215106027022603',
'147430018762630178746862697440',
'977574474337499615009074669694',
'587283870001787477937805527199',
'148240063480333507182224384626',
'944329603717724112694682575026',
'778875803549010584724181033176',
'887617898937348873110270348697',
'128369455653484283740507460968',
'800903238945040264097572973434',
'297244066015181634649583920097',
'255452624640541794766690473154',
'615394470804732663521661771236',
'883775535871274313348155843550',
'287762131867434594974278590359',
'311102646172418762590639838525',
'968990870527657479772904373414',
'110268553028467326229688767412',
'861343581600461086663667720815',
'608626534912475658163819814750',
'616792830710772405023957210471',
'296686240144097716465165212379',
'765267466118957998300135178216',
'217347187932142542162740664992',
'641994108364524933218786273556',
'452995016694363561613914053473',
'779737658179382571620850997811',
'889962413340498151711474758514',
'272901086865349172633813059078',
'433424942403570002678585188435',
'668916805878969653816686744759',
'882625427834343038084970318279',
'363219113969994783143884620294',
'522695241351325532673228630144',
'372697708087959886574487755618',
'708035697518544502934901581927',
'779591217706339980402661374744',
'819634226334379165968858746428',
'375613172403558011437154857590',
'754659041800544056309808618955',
'185693581829550953486759737610',
'569511273009986161927263583622',
'156280039374218812386871619224',
'256126783626516148666376671957',
'920208984728393094076787090723',
'125284023162443464632911424201',
'158515567182144462628391634875',
'526813066757525991806204014125',
'754004172920232715841836756881',
'727156969724773787211396855042',
'254259937843513864418639583092',
'850733876026968105530882283978',
'762390226280536368119539629858',
'713551572605351737054799945813',
'230607088319091779517617744289',
'983977335484875358111118638775',
'109719765246390918834021125510',
'663028708666520462235586767466',
'817233812953326888895064089261',
'452307452931911523237409053177',
'290667822686152804476717638008',
'389340162355078267600145454651',
'926531008096694751141290153058',
'747422233178824107548542360105',
'539650006339254029339010713788',
'273090228720067203571958094413',
'504916319860726140420764533032',
'376489089089481325787890813870',
'174865740493259458957949758577',
'167814697981691237069013544087',
'217314078872297508815160670400',
'136610926833292478429916597126',
'561971515577172840825184996366',
'754460459191815647160746922722',
'906140735181272151480170046069',
'847893952616097161275735287389',
'677923957607347704354882882605',
'322314437406563017920669599583',
'764889991530976480840677315905',
'648617409101225273550630675917',
'851869300350237406460403020953',
'702417169919842058954416833451',
'341160706607569555096014961888',
'702353594534919302107222739854',
'625218526494567136408496772151',
'828743934395359376685622648561',
'871133634231563091530789717882',
'417933821919015281219222419672',
'410478616727363667550742252741',
'585606272507055460289661471072',
'339229575563725719034310365845',
'274742927983839521361447819842',
'434189907073440028571177014222',
'856013928385199206284925930971',
'326239361300897777024822515747',
'502085920288033181669177130965',
'936028357787361775466479718850',
'978909982423433209292886839747',
'547798595127154659668956238403',
'538328112274141348810662052924',
'645916961085661108395147317386',
'976661179082310046013836430978',
'936574705390030086597166260138',
'619497534430923942531559843845',
'743143203522286624465929782695',
'921193627369397664287370025236',
'714173419172682164457133397948',
'966117942833492325513594283210',
'881081327421339272146693953656',
'970351351446037218719558123922',
'663986013549980152745518144107',
'912893671896255437625104121268',
'976130789391744151076086156773',
'544961524287736142861664472377',
'709002139299917000534549452544',
'255416555269255245855990377021',
'370667446576556396998513539812',
'921537128701830851908426185585',
'628967506385872219887203683148',
'181543840208390606909026617505',
'127404586477720533083425466396',
'345824976951845325299627086835',
'785916976833454855022382895411',
'193744508100441852605907656981',
'955813874082983910029330380699',
'655579778968992568282293962568',
'802360773855331390908389970266',
'847067940379240970707100587043',
'791828964268787293647539864421',
'375617004831728605598534329964',
'243491164010146435737017077552',
'901465925947169185175397382022',
'494033113771755270372294455479',
'881980174812077590734541342280',
'485807904527727490864836649880',
'577015710280388613860967971556',
'914527563967880373621086286657',
'589500153872733643715352418455',
'821758826843965805647746819255',
'524163357400543768754126473264',
'772077561166837207031160928013',
'419381680705011258859437495935',
'201764182458459962431487429007',
'484598127535697082650595111509',
'112195681258142185581742394900',
'587497358380572104837635987738',
'224092585757893631999248948765',
'200733665226558167580844467951',
'299884191371431292286845841597',
'166891423266665470576114161147',
'124066162965556605585534458322',
'772074775673429902912755415555',
'538246141512944475118426747368',
'498857831993799137048899132760',
'350870551363640315785189720796',
'343104116433141484423406513566',
'849764863162673121708536699534',
'257228801669765116376406327601',
'126086184558422635564908849849',
'389340593420787978376199220061',
'425506913757860763463435567501',
'473303495664933829409884799458',
'308321115237151367966488459750',
'708952877800411270139221856079',
'647990335616251252870802535056',
'406464069012330188538178599923',
'199798287062153235419015721751',
'813726240134320737056111675639',
'410905542364950021800490623126',
'745359535638091383135157454614',
'631259820201868067648352774709',
'523589398555372572822940975934',
'509015103293019414025087575079',
'312275553804786221691129763412',
'161445048729884158083813696627',
'816095052138928022669874350867',
'726447269692242344926953474365',
'853381135074434594278610975156',
'824633990371243776850953851614',
'346903696726598825409983687406',
'895066432203884199491497498375',
'807640038660344929531020270594',
'888701092519698882132721999431',
'404513897057666042768989341067',
'270348907471350032584023156448',
'421921060711948770681768295477',
'449200098744475542220894264521',
'767112862395362935116697147172',
'676601437108092837909521819559',
'589459481238446339162887990738',
'251264495750948168691218164019',
'251898080029572573548560848243',
'570726129706847726518060189912',
'965353408900652067939608777165',
'907171585906854774946241924027',
'105803289600957907332162373527',
'130209884424439769296754413982',
'844455059554768144619182040181',
'715419891684549023338871446711',
'486366088460378418329518645213',
'574388438725075806463422426743',
'630301878564191965788091876528',
'124180877563263023625894551240',
'545054122241855683997531055278',
'382618034838713378423774985743',
'844019959517961866522883136252',
'204327779823839908418970436608',
'722978377789715097886013181710',
'879471028241534472566760859146',
'885393119862039092463793443143',
'368348733818254187511295167121',
'308299157171885484454571493985',
'406271994542387387840442739749',
'939032774292265402162737344383',
'187708547533744805191272331515',
'602140210200745803775850968447',
'813303095472191993410316159635',
'267982350599024051215374827754',
'649138979253513045320730745469',
'697209131136070489233449748073',
'889756373655271831140139434808',
'887037694523636249917972946061',
'131394626396989484379139294271',
'706895652972796412500254981441',
'833559488640919465842356922106',
'776418989068225474389159431167',
'954500600008712884994969991134',
'814704902464514543932166599703',
'291641292725914354509051118383',
'240673544905556976348379873565',
'156058867901643414570888225188',
'418965164480786070478975348324',
'695046224011632609966288749230',
'267815586094914542605704615156',
'219048686535140656598605569504',
'258129099503032261012470128456',
'519421170918070539317010619726',
'700641180567970826905460027904',
'673769759559636453467585944590',
'676429576958097942457050176371',
'313064020808347287366987956262',
'836073452412753650201753619289',
'963887900756116443383562421362',
'541915153023347144492276654914',
'433323411308442214640428227548',
'454686971269057858449343343514',
'925198866551128127331938181386',
'459014334017817641516220432438',
'249627754974620013754258131378',
'874144484595493664883238933260',
'688069835763968881359722817560',
'326030268590884919860795415213',
'101454903892710878131802167059',
'616375979663075693646657559264',
'862808124217434027523731057070',
'670123812531123220089385369589',
'746635268477214754536273536779',
'384417576685546933541393237132',
'166617061727922495079641390870',
'643045003182319791092247799202',
'814496253996533471687016776841',
'918488041340451172125375915925',
'882996113670539525055678699900',
'136634519652973079230587320408',
'201897130738445698381955323798',
'260033134505749734214542279891',
'610445998199647395155690429751',
'440120721637794593176881917648',
'172327080395512643943173874569',
'412708845863680853929150762633',
'641275207590448768014795377795',
'448539881503239029090112845205',
'112657603453187635264355290206',
'522458044969150229082735993753',
'900478457925135452980215469892',
'499144653097781564765458351438',
'902684004731682126864840863166',
'139823207767664658317172877594',
'204080809477288598994866264297',
'733017023709694318075969044483',
'657398191185458467012882580836',
'110030280339637111267908224579',
'995459246662962190681341421068',
'773470592014197725472514138814',
'341926515876998478493512680232',
'159085893537428907017204758264',
'858868385164650062195110571405',
'499451622836464021021396016748',
'617980595401717011405509743574',
'198986600909043798771613652722',
'176655219745826585369023035217',
'198736539966268484851469119384',
'672825930113240217170220257630',
'821875614940053058421621927725',
'813714346546956939212781154549',
'734371796422387684110366729769',
'486118957922577172365153085333',
'299578392254547196252113354183',
'515927108446920289447714783653',
'920313343017477420640736292948',
'323964468128955085342158770249',
'519574095088653784460604722687',
'801717990413646561518680449382',
'914750217084825206631809388652',
'230763406492723875505096512582',
'384295615421469128586856046816',
'679938660143323643476432319870',
'662462995232444654810310437295',
'106373163719695882170782150383',
'777654414424430374897880765040',
'522308808818571169496195494615',
'465477073610080560011375968327',
'268072132663168354112974649313',
'880762078813261480899306538597',
'866581689276811382751423569014',
'818612405789824774268810883373',
'463084687460686154525927110239',
'612369015059720871502024461271',
'386864436481124614069377044853',
'634919788631036277832772633704',
'922158117744242655937423624675',
'922506468202526297676622538712',
'305189380954631962194778554602',
'574809455285583317133689585332',
'914237349776436823003433514343',
'159541029630629915930082845844',
'647447771103924284627650344345',
'412885458706967825311030270877',
'614338410585657635189576588716',
'811775458614421161208594038295',
'315347161561380273007897578421',
'482814237863365443238323640285',
'465989624798452845627559486965',
'276428897161086001329808263633',
'478781343072757224415173266299',
'588474034710294390460257866781',
'942633528489933850732422457223',
'851044206359599675317620913113',
'879057322369383549463559911327',
'699906615233194188129125415160',
'541968786180042691514440915062',
'711737216349722214533588777566',
'796324221727177324459330582889',
'571309971327013989705832174092',
'250987602157554321026187883404',
'199499913260560777475466420595',
'633991266320241496167186748307',
'354744389561972008670846211141',
'257256089786489029006732787156',
'967424143769624732305307895331',
'228946729623260153133065453710',
'284629324916273993473114697722',
'703219638495129869816696894300',
'570777255107472249079062040489',
'789799662403791673922469151105',
'445179944237573299071125772240',
'363150646418531264604450682433',
'881631166006925694677388421843',
'819179859231324074885924991704',
'506773810070556540081508427758',
'817229335464694213688288663715',
'284314627813188265376781825176',
'328183880003477355783877947085',
'956306751814031291885173094500',
'892967616468358901169831566180',
'562850987592020264491094125426',
'674799969346834416750174970822',
'705119319727955646196705212097',
'855980185063369464367455430413',
'644371679816876920318569850721',
'672957009186484372582217525123',
'624571656712498266652087967959',
'516951308910077241820551129076',
'118980567274357046611038739304',
'343432751507441931388205815773',
'251215783245415434245655850067',
'550370852885636611615386882424',
'211821690882788330449315523448',
'689716269836761463650951150640',
'511202425314726115596722949844',
'319974563407597038325357648176',
'189223638582363254802106472907',
'117925119657437982673922693535',
'943270436194374288942151356179',
'850824968253369489351282933437',
'467014027168489430798865920108',
'562533797185422697735833716988',
'476175888875927861679593593396',
'728494347087575766686796173416',
'923123319642628032413013989918',
'428783938851127653740215866338',
'278609286667826016870022983427',
'173675275351265716190984276203',
'315767810723947584042678526637',
'671270683487222412052589166925',
'971433576075621194345906845426',
'590561465979270977873235280129',
'926876465537510230548652450623',
'332968503877547917469654763040',
'624514341260899918707284849933',
'232470169225951207331176214812',
'279166790221176869930087990293',
'370289161453513026637415829231',
'265700595802766484671083090323',
'336939401185353067980656349849',
'163578978004660144404815471947',
'863923491715433416492421822555',
'798685328874753961253269336859',
'698629897866835636879473884028',
'733321983416360388513491435120',
'635747363622911965751392181336',
'258790688962817799965895457624',
'498859929710455650104384086905',
'687625943179521528065224474562',
'293566144689955485864377340733',
'163747177853537177491102592294',
'391321233083449955955663573994',
'761410497630331444878980683434',
'222133949561760638163923329226',
'536094861414935771839423858328',
'423253640411536593391365849572',
'519936645193996368208724611820',
'487075035387793726983562268164',
'505556868602824686197721268333',
'333012091071139523807270053951',
'889315628067956814700659792577',
'600398791277137210000938735247',
'948488359561978453381492466563',
'673573912611180921056529742402',
'637215977217498785243290818903',
'482252456648151904902561069272',
'237184560314986179050037383949',
'519281399071481085118173090680',
'827082412828085017745175370084',
'258259089174223473819219774714',
'330163392664356802865138420477',
'449500547224215544075150436824',
'788700338658922359351044330284',
'813583696701022884501542957963',
'727863823781248424998627557279',
'234157819874875155990958363289',
'890951594722266923419931258976',
'678061256375271661843251045947',
'556875721527514259629770351137',
'449482449113053073444367136840',
'729086529414014086671934958673',
'912241454080320993547325583149',
'749311444532028130037315157888',
'294961615461215765575523560294',
'757270718497963615030176157713',
'537639096383196437310808977435',
'415030122140961115505795626601',
'260118733415561921075046236449',
'212155676360223053823263222446',
'355607903410883086217090177830',
'188598278968281640718364397169',
'891106952436151740912498544935',
'483301704893435930093255186607',
'100485339563096070735678051458',
'155452641271596112074018078690',
'534842852922487645190595280566',
'398497924670153895905688463781',
'621607612347596653157244042391',
'399588248150284992389014719219',
'945362650929941411234205937598',
'422018657389988836133401742649',
'145158838552546671693617623608',
'227120390034865788112535240134',
'151912100018260015470412279029',
'281458744246590959911203192085',
'327492773368469543063869918685',
'286136563596368486334177758102',
'424317233339471460863281030445',
'288016019496775106002341912680',
'833419859692490117377013045445',
'337811451794944093228413355113',
'192323440271399501207849928702',
'796538957726668968397576224133',
'905273816892781270137521015034',
'744130249846646391585674840242',
'492266347455641458554193368536',
'606857326689517084219805648330',
'814860789792088151214800026907',
'118903996476125087489331422573',
'544734804342383756661699699149',
'481660442825835224547655338960',
'934394676552112874005240712134',
'468647483685033828639857486032',
'350645738283516597927491122713',
'954397317201491377240973740821',
'403376690588098844093763932092',
'765984316970737942039884735022',
'430882713186373840758966799937',
'931785868693959643755230974161',
'630886139759635611452386884223',
'222877697977742022689209692137',
'940572987332348668782058985236',
'373639432384480051620444362768',
'268833779169411089615404890117',
'468355122487441721771113547106',
'692484252008357685095419853387',
'942629030537483750359763822739',
'410717239944192212016915126069',
'467380054141963215194394875773',
'279217391760067809457346510391',
'216980298515774952274541347547',
'249434055945313743192588239922',
'234441340412437285225280398073',
'453239714133299978602920418899',
'584813279418792447554784269887',
'188663231071188985919460442241',
'109008843953589614673891576729',
'535287248886341214413649594001',
'281377868322224610083971824127',
'535844300179976561689937194376',
'820796125544082921439594023249',
'507527695369034658393749330569',
'384191313855427291894152068229',
'955399739824068574641052232069',
'939081153302333445112002274787',
'390855422301195394086891374549',
'583391756828930963575718923206',
'259557746803636235295295110841',
'373154961046196615762510386559',
'389460981889015350179358961138',
'558140094893975952228690869556',
'707000475071663411869960941972',
'722139025557825527658958110416',
'957825245109026580416973372022',
'391407760666770908176861448265',
'893764571611240955830697437657',
'361994183045764569499464618507',
'821391983607798845844261898819',
'270622502681760460099468937850',
'637173296575617809315610113382',
'265143458883534891438952433717',
'339711645194110703074943110908',
'647699587879829659921804270357',
'117449006686144622572805529360',
'633659704556890469159401891225',
'667795372910716143982564513057',
'211724874834261524734324498727',
'200933896068596101191857216510',
'489999662263868818598072631978',
'641399078485257437340922534458',
'859644013789279594520495979863',
'424721174384643756970800054748',
'293543128136696737432180243711',
'579032542497269160509404818330',
'588925168234163009966046867440',
'312003946513068844233780433334',
'951926841536561587477412686257',
'204080077358397560025802124783',
'216062949531836053886820892485',
'967565910967197521062309352077',
'538574836720675296910928372602',
'638157934557164386564093668644',
'491014287492144475315690890344',
'535824361096159269828038188959',
'472300114394491493458722919664',
'716825793183963826586081241505',
'260780725017258214083041449190',
'233054321507469435806407867027',
'663942325309599218109158459350',
'275066915203043550376019256385',
'362347964799233874561292649394',
'749291787484690528461302787602',
'335421308806896255716427956326',
'939840698280255657580714874029',
'786303705577314045841616149904',
'815426836519346734698633610762',
'960361779687376275663351319040',
'839441656512388875305912522496',
'686722867075245926453235539408',
'324770621964471192121371939594',
'660103272446416754624554846636',
'502302830160486559656165038819',
'932385679585672832516657424139',
'980976552955923384489484231977',
'577998606493518560463406683360',
'757938549129155540537455816576',
'694377528657066403879820794938',
'164069762481117688229311569221',
'794353047719916766580535459973',
'609489025603748308002061958848',
'491192304680678952939570131093',
'254277092403515374227815527096',
'786520749494650129745814463924',
'913562969454241595344833750303',
'838530117105938091998992629759',
'536730262546229169878518097249',
'536009190165273119004405371594',
'465915308963452825318799668123',
'735299682212245212639494430492',
'758967479337140282236809582251',
'103380344625331504042387239216',
'519097287677458815983631027827',
'337769647815751005619745691542',
'972783735745486020726490212802',
'512090944603970310577927992173',
'315208788404221371093870421230',
'231180615883259902989610259627',
'133212423884383192750230286286',
'885151389759262851568043927524',
'840425828010999816018561111646',
'433334966527152644553102440670',
'165199909112784748042538865267',
'263479716750137118625697229075',
'828931300096495893693428017446',
'443031577615883168226998692893',
'821908943123151341623995010069',
'197017856055413958047867165056',
'899880790733659202332440294307',
'361477297965164577373487036603',
'542012965396275354885545039251',
'461568192343539671111605539961',
'229712875550420138812200463373',
'483231739137529252784661536761',
'109659635699728204454129143052',
'442743177828421409734995436958',
'510827710632962844571963475793',
'703666541914099482051295414027',
'365918517411438955086309819681',
'221043070573630380096738831323',
'981367652580046206884788412039',
'845532228808694158649527723015',
'276610980186384058763534085119',
'364027173649726955398574148758',
'859807566754450589769008031465',
'285995240261825733790336260869',
'947430766100861871796577956832',
'905717253004132017865743139110',
'528962658885382605859603659065',
'735125459601927432650967486469',
'383989235039693300143683348026',
'926648120066767429056320140255',
'321597417151538180193535148813',
'575670247167188242651907628938',
'266801213102214587880627395548',
'336587071636388015300844785733',
'932755744570675675545422222244',
'675893087364950006487274993838',
'689869437157855738170921111000',
'239222071101496146218082322916',
'719175270115695911542026165355',
'651288372061529541760440315349',
'514858052658189215423950292574',
'601814978311296398185074836829',
'799479140410556415051705448580',
'603222815882066182510851828420',
'720182660316045316670483647677',
'157231061453382458353485675832',
'965831604001444115674149143883',
'265279025083825817921279633288',
'642038436899066235007321180496',
'434695151560165636731301738939',
'374257263075972184790143183291',
'738900578690034804564794178747',
'201719504577424152990383682549',
'416804900964931929138763686658',
'958585208523618665866569271329',
'822685258971393132566340335796',
'469188060183366560473946520120',
'388622950820124091226006619997',
'469195326318928137169803642147',
'893024023001990834882912287316',
'867701455616024623593986599459',
'563626984311829712379576085708',
'134624911866667960217325934695',
'783306321671384810284144197810',
'861793078617396649672255916643',
'587506660186535784633029048640',
'227119800543084126326517857250',
'907759298069393766471054590479',
'403480113227560899288211182217',
'565273424466321598966710916254',
'916734656721577945387244643924',
'264301335566839236731955020446',
'681945841755466827449573343098',
'296367826843839003384685480475',
'592999785114449956444102119115',
'736732899383239279120821638553',
'441635154541279319246605976253',
'970276728848289367742727017782',
'952098209228819811049926951351',
'623744454937966093204600420261',
'579632706535485694792917081061',
'530655960040224797022053323316',
'363713960238650075722964938843',
'959202514744811924856967399872',
'852121485630328759883467152171',
'170187286819548455197710522763',
'357498150877898250827252550415',
'929505673843522644060962537673',
'762564294661776162652154951344',
'449015121913283339818522361408',
'295504323599596494126213867540',
'833379782958175151349561243244',
'555386922121265204280271058342',
'153950144107019324365364525109',
'372165243666983029726587776296',
'176701524358879541273585232907',
'164620721004497806746710966305',
'407497506955569441371192597655',
'834135689065839803715613588330',
'567567396102229929222855244645',
'798546463253487255518876165141',
'764446619115515726009114825945',
'528892105852461730979353619830',
'207568295791893868031146314898',
'701961731915820289575684115003',
'573231962743986272807564597547',
'689713545087231244968966710590',
'921854829925549885466023981381',
'744264917342816796308137396937',
'873212566906528254792853140358',
'283674321524469224276139615003',
'454268603596068534720269919044',
'167954778923855630211543747928',
'734459003372030236045246929612',
'990203921968384669227221514780',
'497761176131778058771182244012',
'964590030443678224110695188792',
'826588904904021771822881274286',
'917888776734779608005448084300',
'819665448929328536859912448929',
'970218520372392328655311254729',
'606648658783474383559886388321',
'155695406203994428179801797871',
'289613863991516161937795777406',
'603733784954973623614786053616',
'759164839763997693802491532775',
'425899426101326288106492521106',
'789308495291271688930185092893',
'165092817762145850693694559360',
'214578989618389207874229336744',
'309095132673226574680069471276',
'873859317943653066684696499936',
'364000973666711410457381082390',
'772970960178967574652943232194',
'614646135452121923073917255627',
'263696892201825062893682666786',
'405798671966227582799825429966',
'508988985786471889602625645158',
'164372117767419233005660080771',
'468755324844672226311043699088',
'465336015532131831872304641711',
'118956646183198311154583525887',
'413445564455075152139338992425',
'301851224502921672731822225081',
'888603224225637130404954788251',
'648412492785344246321176375140',
'149273411754943070945925314539',
'668366923224467032447952030550',
'126062143908160687661532876257',
'160511525407355686834196280255',
'686233741766238718999121453958',
'181993771295930232939969415353',
'537645984980986385731329381138',
'778051160816454932549214520366',
'550587334085256203806745016043',
'666030457737472548010991745436',
'565420841855083731364865938099',
'445821621158243214811675167456',
'785796517528631623828196098147',
'796918750488832416957306266641',
'534448534648677303513340816371',
'200599081146797086192530330570',
'849035948970373139442223466029',
'221829117406741453960916688368',
'451640169361911840411838994298',
'445336091680478902786693495191',
'579934334435172992649533626177',
'370159814611574292967176755669',
'247588283410783858640176143677',
'556452619168364789377396043170',
'878536898190122568652602039829',
'765195255794495832478009623104',
'841810412086827962910547029298',
'517586826549096997022811754442',
'990304247606884322580514886828',
'868043415556927751605421167348',
'245563082868019357539569581858',
'480087189330134245774338758752',
'591549243956875632384903374521',
'232097065960360396034653976040',
'353395366330817547762377554422',
'682705477139190113344092424072',
'785082460059336551250180659404',
'647049896734935181889885130485',
'857291066150494615989265877742',
'641199368671222137154329897060',
'718531556166762647569460317823',
'153995146519023528759105987339',
'451610206254882759199332999400',
'697488985429370814101437410581',
'166921342419565216631153532781',
'686092280050877847187579611266',
'141778716095767527647381465104',
'399169026950493466487673638455',
'344636096137936999393810962362',
'268697955289277739980046568817',
'190466004022575566141277945003',
'612914961158595379876179524737',
'213146941904418094512738644646',
'492728696744437459192210480681',
'666629779717430962136185658463',
'700348560496289275381722050103',
'183599108039440149753836951865',
'546671271251319385747246627787',
'669976549535586325814363459842',
'628274771474267739057718425559',
'506333863867356509190775019844',
'572072815456715201940908936018',
'436736168306995980413184810888',
'851438655856328404798910595834',
'886777695857241372349103695841',
'436208283914347736994035513760',
'298209999403320241931627671744',
'826816909601533855693441453738',
'517350951733832915415938786946',
'462531202751475409201298595440',
'817644282382844578569404991111',
'483585561073884447406235652436',
'150756882303091864736186169972',
'224707365915512538107460577953',
'106128915257836881175060658499',
'739677587832272404428950751904',
'522002722262067182140093379513',
'106744727230587761114842257323',
'516343791790522711642384928439',
'876649284103358821523162489274',
'258775797880788470092765894164',
'853167412851656115577243751256',
'549807843588048563521291194189',
'293702394780044153467240528623',
'749911125836888539447302989534',
'962288463951626418276151742041',
'118122721934596464503091954465',
'637546123648153758672152895110',
'212760921401664218609036032251',
'842589910662168216476368386087',
'572361387836659883649455397190',
'270052694992068838187118538322',
'409775523465015539974721094174',
'253702167403898565398002839801',
'990559361856460212075730385931',
'129617729652421483485763165154',
'773832511036848025014542479892',
'838191407192955002652328995685',
'380903152347948725669762079442',
'189616143364382479255805186278',
'778383594863757459876067438405',
'221494000567498898827921265143',
'731572943724234128813572096183',
'652882447115372619904267074206',
'291366265780033919481788813403',
'908704395628136392274184731915',
'841762715884587799149299791297',
'920280944395352397501832528735',
'584153685956535436335511023446',
'114565200656275022892012985261',
'472436320335883909964792021084',
'928301074968614345043029610767',
'521963601606085556510324911837',
'192012362302961238119481666170',
'123732602418432123241136291863',
'753850760059425559783860946178',
'367412152754034525597956635786',
'954834731078380455149957293977',
'473865945242271454148400595483',
'870992776366732548448478744889',
'550739073370044346173452537515',
'964082766732077005941177822294',
'905188938586228978024829780562',
'153102642978751802296323027368',
'437717897312456411335038133227',
'607220184583795327798417826977',
'874105648701760496442964365984',
'648679132038964718862516290849',
'256408068107296514896365733360',
'867857087223671484806633747585',
'592667977828517277543019970109',
'654304780412612864483373597787',
'438674916619777417042923057130',
'403585858623860630575287324315',
'521992824023868063286714633732',
'466602742111068668758213091050',
'385303742646068376623674499839',
'143046445645860523080113234079',
'696347601741291362227674791564',
'678622487100864240418561782461',
'360777255633344675662194421889',
'448707489592754345900183241875',
'874284554982782879706119346112',
'335707709062294206838767056405',
'978433057574331245641786986630',
'115114458158461471000120045564',
'298726565273927882850644882878',
'598695347562134985808647551053',
'591347614266136694477853021089',
'207621378919078616838740585337',
'834912266814466692082052554690',
'363368546455965037872382137460',
'315159365866359660612162872056',
'941568608792198864346989213773',
'593355438919389520416201256902',
'226606675010580904114238574090',
'790887638744239486292262837774',
'531453766823895772748159223875',
'656601464399352679042412017189',
'310803823307658178598823347992',
'592824591838486761904077295135',
'436851212161315845392524344755',
'430092819117286677883430929298',
'563642472899763021427303997087',
'479455289186776388301574766786',
'619283169331250456070874555205',
'789126524472550339757134044499',
'611755650244081550999640016810',
'745317518441058857317140344794',
'819752385619832330967738323230',
'817319393617372856091380287324',
'428632746279267262555562086345',
'322945447884385947107686310602',
'257218808887154450268787657056',
'857724637770450499086302552802',
'218859049503934771519765194842',
'381528262455416146458475164468',
'563343363509094767141277326430',
'335736232669559151786574645746',
'603723113387427663620485923781',
'451295527782088409154823479441',
'755922917516469305890967742365',
'433830744821098876260158534676',
'320032464032888418646384445379',
'435014435110447376983960160637',
'678649795570393087505122664524',
'271474545184278166770030620305',
'329523785419130144162628483222',
'667822067297275285511144077521',
'969490600401542927358809796507',
'754689294729057351276528687501',
'463652637528947161838459245179',
'799102536936154652846065962505',
'313244216094817895945899587823',
'965852727443187797028579711999',
'697326014480865516477257445763',
'782569855829520286470725395044',
'726597407856950128040383039899',
'828771749838892625259658139042',
'767568780770389351391946465971',
'713589369384757758756061842752',
'927684603263065305449614859880',
'426679189010882289629482649621',
'124327539476793774671635722009',
'432882041166885719919085645076',
'825124212600460141839098936098',
'220161803079272642220872437152',
'771135466820350745115456550475',
'553257919086632297278819969668',
'339787813981584176708296440868',
'146308338602446450806139245658',
'228254861892441226627508595529',
'749559542713213546829247778988',
'859623003269390692744414722111',
'899417783498484474219679466670',
'226584696455857848561394372945',
'628576311584727283952524833245',
'769385374080097744485100161521',
'492784568061182061874249665911',
'387194232096926758656790631997',
'819223953260528438520304574332',
'479791430420238167360690088092',
'214629916160265281682553875647',
'792061590125347025233920441894',
'410046604366928364134896238935',
'908333689109679818509436433248',
'375825840814338678034260963929',
'292664370404472567117384237552',
'506133747001750536056282763617',
'208942133392220094071445397095',
'908993641563924159132305777032',
'575664135846816128464748255771',
'784901614219428153718866263315',
'923748705395659231406326379096',
'102656496701529187426116711395',
'843744610212040438843677334789',
'969601319905832751657478344344',
'324252324999882658497844819471',
'963128769848354306925138742553',
'683480486491977017098283739124',
'326915805213239242994775980872',
'235136200600273963769633019996',
'744673228812560541467717149456',
'542513670471536685138536959489',
'948432123557034945511743872491',
'941384993333439200593286830794',
'542630982737086797093537977991',
'823284409908494379938837227133',
'666861466019644567514272641635',
'584732391702528426324364776511',
'856604303819057801917279783109',
'425342419860829400220082043955',
'740364222925380716009397375002',
'238779554222312125129670225099',
'390091349864447170960113823826',
'990416179460314452101553628516',
'815783771066464009912059529332',
'247352562914184309283592540166',
'820626861155063848454373743192',
'781136119605264743364626835219',
'352842108188142990473128840973',
'857500068322837590261925363443',
'510570395870661659144541373210',
'309531385600249727179770882745',
'647441926721747217744478655315',
'354994446614779069099877668591',
'693356568002139556669784715186',
'829839355218952278213433215489',
'848085694035847734191574024934',
'134826742386175442366829791445',
'282996465465766873393797077767',
'343911709394475289679947726441',
'524436961042495855413677769219',
'793034582142560728746085563173',
'854119150206722407078502855664',
'374240587561784073221416685820',
'821117419723341247466096598735',
'299057345138089116494823212537',
'162366582667115766220587929965',
'630062290097428768186243415830',
'223804733365122197739968089343',
'685786527658550621518455076782',
'778115095706765099789775634807',
'505686662753920081227345753545',
'616452613795292469558592928260',
'697513273530772791794030561683',
'985995893938086817646455781116',
'695134814527167113740236596521',
'563328555737439142118991658855',
'434699159101072928036521560977',
'163906162626851219082870030936',
'320455674524629890181468638369',
'975847362249247807200250354161',
'983605284983376689138062894370',
'434621608440896247308172341227',
'490501696414658350913442821313',
'841756342340668665349103394996',
'739703238175144220843140228959',
'113788842002027071849837682851',
'109944959775386119227703219918',
'969277638532429533041701757842',
'676569243248588516313750175421',
'258100027457757601429033731199',
'869804019890523323833326169081',
'862141070293387304728245484882',
'225270566964980607608924643114',
'547661178759066245407287050588',
'703330945080999401665281337308',
'367626446377160737840505362627',
'495489720272427657015886932981',
'158048311390025405130769879576',
'464680452588743043549878458680',
'485002609576890096543057822240',
'716649010894312680385863714405',
'947933420143391167982233525485',
'225766986142141134641020671039',
'662613748316642404655369513133',
'679487561277799071757194893708',
'250217428092079097522119568590',
'980110307402115684538769250206',
'333908020820272255434740131025',
'833681905373553854565853450008',
'685669269499934003130392354010',
'466462854817855165326802648333',
'474412279882168735643826521710',
'791000377522076884282240633197',
'335709254202915724213875396156',
'719548303422186844521808864494',
'344605064204184417832947068852',
'947684320228457688749534412379',
'269076372092090272682101061429',
'446739868148548062260750061028',
'524506743344056486459481919040',
'922336703480034313198501869258',
'947995464678914965208485091274',
'599763649913725663230303994736',
'801682046441678805674055483683',
'747423798128878430830565329378',
'232921323926577996886708352413',
'686443070583128732324651540271',
'538492896341455912720502281060',
'340382413833393986805629935113',
'634565391002160772411124244953',
'568384283383867107330496696838',
'485616621022085363096663371655',
'523671187661933830819635631604',
'626551875491951007969120940553',
'236378031169629658838739959213',
'133658951170771000658274799992',
'267538925094576337651875588399',
'124628465858836222234904772705',
'441549089472084330440944536962',
'997361102375761963256529371860',
'355136808141364175114300676484',
'534865520753510511718666665790',
'138431814365523470908978527309',
'672603111249121504099582764139',
'531959374539830685252489520908',
'282134484663192490138355949510',
'449435389637544235773847280226',
'260675891209138388021672885366',
'419516019237967749455750768574',
'255098046535536514150743861884',
'349681184411723113398735197003',
'415406852677125534677310410091',
'419174390910528731177709575065',
'779686835509354882830557437744',
'842842270611788845088364025885',
'799841340973555345770530331002',
'154335586258866571585726849978',
'114657875599313377795937341665',
'303880050615687287001972814857',
'379865328731222055811186293399',
'262410433494517970831380456256',
'142769210021046066043175777555',
'564921165318949775696719124376',
'615908823844354423075396386422',
'752698111934374529410778550873',
'657253226038674986304162252868',
'341141052131120686848591587482',
'207986472869560224780389567186',
'396670676742483585847109748677',
'756133776860970383247274470336',
'978327349175132657992479286499',
'579853381603448852385939016581',
'162765814778075665981716872567',
'640560596577917366128540991708',
'619607703679494569749474653924',
'475266255346821271754418797712',
'210844561785530429684714782024',
'942606830671568660673330631981',
'458938834105414897832487565171',
'295672912746151255081905097902',
'886749539594541291830316650052',
'946682560789830997703512240729',
'778076512472346596797387135894',
'536120599885029179775427292106',
'534692078358786105014353724998',
'218934745674410696863556671499',
'297608301022944934189996175774',
'407794087819187882745596183601',
'490352855907331343097713873552',
'664872695433631083849207119690',
'577695541103832280154249938818',
'891202867564162608758020947698',
'745985857356832332068924579291',
'129301689009272168629487470321',
'315664429994011853148608136190',
'329550236809013207826172864717',
'869053525630377856682653876629',
'970213331747522304344708566271',
'797767753001284097255998237161',
'298945636098047376136104252967',
'191116170379238783264906897261',
'153813679940865789286652186408',
'954245672900274696569194196869',
'565567631743497529862538625616',
'400943424374361611747711886436',
'182024258252948214029790562404',
'831387290699727646973882079854',
'116359087039714644686804125006',
'251262467264955099990675239546',
'392360237881989609212027248984',
'602001757319982126848963175281',
'165773820077977725680987719212',
'601755865697292805016548998161',
'227801271630280727126883784603',
'295649461897718261193338566207',
'577596381226290859907579498218',
'227772299366542076343153119550',
'133349262938147804562104883658',
'314504543595048050366136877724',
'166184398381527242419418555124',
'133443006177746435523861847367',
'347523962862155112408441676024',
'787500057686972621645512059156',
'477175348305225721545373188955',
'130313340676631183412563271653',
'508842391193339576472706891490',
'112018578948782130406381653984',
'493129940216750350559146112823',
'268682215602438734245319312201',
'726505939586755703189893772321',
'439435027724082763920434738066',
'254779627493616646400264468943',
'804710241705154819831135839058',
'427876314742624585971156224309',
'839712361203831110435661396568',
'651461083723554811960813464621'
]