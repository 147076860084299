import ReactGA from 'react-ga'
import { getCookiesMode, getStoredGameDate, getStoredNumberOfGames, getTitle } from "../../lib/localStorage"
import { recordGuess } from '../../bot/globalstats'

export function recordGaEvent(actionName: string): void {
  if(getCookiesMode()){
    ReactGA.event({
      category: getTitle()!,
      action: actionName,
      value: 1
    })
  } 
}

export function recordWinLossGaEvent(actionName: string, label: number): void {
  if(getCookiesMode()){
    ReactGA.event({
      category: getTitle()!,
      action: actionName,
      label: label.toString(),
      value: 1
    })
  }
}

export function recordGuessGaEvent(actionName: string, label: string, guessIndex: number): void {
  recordGuess(label, guessIndex, getStoredGameDate())
  if(getCookiesMode()){
    ReactGA.event({
      category: getTitle()!,
      action: actionName,
      label: label + '-' + guessIndex,
      value: 1
    })
  }
}

export function recordSpeedyResultGaEvent(result: string): void {
  if(getCookiesMode()){
    ReactGA.event({
      category: getTitle()!,
      action: 'speedy_result',
      label: result + '-' + getStoredNumberOfGames(),
      value: 1
    })
  }
}