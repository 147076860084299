import {
  addDays,
  startOfDay,
} from 'date-fns'
import { default as GraphemeSplitter } from 'grapheme-splitter'

import { SIX_LETTER_VALID_GUESSES, VALID_GUESSES, VALID_GUESSES_DE, VALID_GUESSES_ES, VALID_GUESSES_FR, VALID_GUESSES_PT } from '../constants/validGuesses'
import { GINJANNER_SIX_LETTER_WORDS, SIX_LETTER_WORDS, WORDS, WORDS_DE, WORDS_ES, WORDS_FR, WORDS_GINJANNER, WORDS_HORSLE, WORDS_PT, WORDS_SCALI, WORDS_TAYTAY } from '../constants/wordlist'
import { getToday } from './dateutils'
import { CharStatus, getGuessStatuses } from './statuses'
import { Language } from '../constants/language'
import { getCounter, getLanguage, getLanguageKey, getStoredCustomSolution, getStoredHiddenLetterMode, setStoredStatuses } from './localStorage'
import { getWrongSpotMessage, getTranslationWithInfo, getWinMessage } from '../context/messages'
import { getIsHorsle, getIsLazy, getIsScalizzi, getIsSpeedy, getIsStaircase, getIsTaytay, isCustom, isGinJanner, isSixLetter } from '../App'
import { MESSAGES, TranslationKey } from "../constants/strings";
import { MAX_CHALLENGES } from '../constants/settings'
import { isSpeedyGameDone } from './speedy'

// 1 January 2022 Game Epoch
export const firstGameDate = new Date(1970, 1, 1)

export const getWords = () => {
  if(getIsHorsle()){
    return WORDS_HORSLE
  }

  if(getIsScalizzi()){
    return WORDS_SCALI
  }

  if(getIsTaytay()){
    return WORDS_TAYTAY
  }

  if(getLanguage() === Language.PORTUGUESE){
    return WORDS_PT
  }

  if(getLanguage() === Language.FRENCH){
    return WORDS_FR
  }

  if(getLanguage() === Language.SPANISH){
    return WORDS_ES
  }

  if(getLanguage() === Language.GERMAN){
    return WORDS_DE
  }

  if(isGinJanner()){
    if(isSixLetter()){
      return GINJANNER_SIX_LETTER_WORDS
    }
    
    return WORDS_GINJANNER
  }

  if(isSixLetter()){
    return SIX_LETTER_WORDS
  }

  return WORDS
}

export const getValidGuesses = () => {
  if(getLanguage() === Language.PORTUGUESE){
    return VALID_GUESSES_PT
  }

  if(getLanguage() === Language.FRENCH){
    return VALID_GUESSES_FR
  }

  if(getLanguage() === Language.SPANISH){
    return VALID_GUESSES_ES
  }

  if(getLanguage() === Language.GERMAN){
    return VALID_GUESSES_DE
  }

  if(isSixLetter()){
    return SIX_LETTER_VALID_GUESSES
  }

  return VALID_GUESSES
}

export const isWordInWordList = (word: string) => {
  if(getIsHorsle()){
    return WORDS_HORSLE.includes(localeAwareLowerCase(word)) ||
      VALID_GUESSES.includes(localeAwareLowerCase(word))
  }

  if(getIsScalizzi()){
    return WORDS_SCALI.includes(localeAwareLowerCase(word)) ||
      VALID_GUESSES.includes(localeAwareLowerCase(word))
  }

  if(getIsTaytay()){
    return WORDS_TAYTAY.includes(localeAwareLowerCase(word)) ||
      VALID_GUESSES.includes(localeAwareLowerCase(word))
  }

  if(getLanguage() === Language.PORTUGUESE){
    return (
      WORDS_PT.includes(localeAwareLowerCase(word)) ||
      VALID_GUESSES_PT.includes(localeAwareLowerCase(word))
    )
  }

  if(getLanguage() === Language.FRENCH){
    return (
      WORDS_FR.includes(localeAwareLowerCase(word)) ||
      VALID_GUESSES_FR.includes(localeAwareLowerCase(word))
    )
  }

  if(getLanguage() === Language.SPANISH){
    return (
      WORDS_ES.includes(localeAwareLowerCase(word)) ||
      VALID_GUESSES_ES.includes(localeAwareLowerCase(word))
    )
  }

  if(getLanguage() === Language.GERMAN){
    return (
      WORDS_DE.includes(localeAwareLowerCase(word)) ||
      VALID_GUESSES_DE.includes(localeAwareLowerCase(word))
    )
  }

  if(isGinJanner()){
    if(isSixLetter()){
      return (
        GINJANNER_SIX_LETTER_WORDS.includes(localeAwareLowerCase(word)) ||
        SIX_LETTER_VALID_GUESSES.includes(localeAwareLowerCase(word))
      )
    }
    return (
      WORDS_GINJANNER.includes(localeAwareLowerCase(word)) ||
      VALID_GUESSES.includes(localeAwareLowerCase(word))
    )
  }

  if(isSixLetter()){
    return (
      SIX_LETTER_WORDS.includes(localeAwareLowerCase(word)) ||
      SIX_LETTER_VALID_GUESSES.includes(localeAwareLowerCase(word))
    )
  }

  return (
    WORDS.includes(localeAwareLowerCase(word)) ||
    VALID_GUESSES.includes(localeAwareLowerCase(word))
  )
}

export function addToPresent(
  letter: string,
  tempPresent: { [letter: string]: number }
): void {
  if (letter in tempPresent) {
    tempPresent[letter]++
  } else {
    tempPresent[letter] = 1
  }
}

export function mergePresent(present: { [letter: string]: number }, tempPresent: { [letter: string]: number }): void {
  for (const letter in tempPresent) {
    if ((letter in present && tempPresent[letter] > present[letter]) ||
      !(letter in present)) {
      present[letter] = tempPresent[letter]
    }
  }
}

export function allPresentLettersIn(present: { [letter: string]: number }, word: string): [boolean, string] {
  for (const letter in present) {
    if (countLettersInWord(letter, word) < present[letter]) {
      return [false, letter]
    }
  }

  return [true, '']
}

export function countLettersInWord(letter: string, word: string): number {
  let count = 0
  for (const wordLetter of word) {
    if (wordLetter === letter) {
      count++
    }
  }

  return count
}

// build a set of previously revealed letters - present and correct
// guess must use correct letters in that space and any other revealed letters
// also check if all revealed instances of a letter are used (i.e. two C's)
// only works in hardMode, but it's only used in hardmode
export const findFirstUnusedReveal = (word: string, guesses: string[], statuses: Map<number, CharStatus[]>, today: Date) => {
  if (guesses.length === 0) {
    return false
  }

  const indexGuess = guesses.length - 1
  const splitWord = unicodeSplit(word)
  
  if (typeof statuses.get(indexGuess) === 'undefined') {
    statuses = getGuessStatuses(indexGuess, word, guesses, statuses, today, getStoredHiddenLetterMode())
    setStoredStatuses(statuses)
  }
  
  const present: { [letter: string]: number } = {}

  for (let i = 0; i <= indexGuess; i++) {
    const tempPresent: { [letter: string]: number } = {}
    for (let j = 0; j < guesses[i].length; j++) {
      const status = statuses.get(i)![j]
      if (status === 'correct' || status === 'present') {
        addToPresent(guesses[i][j], tempPresent)
      }
      if (status === 'correct' && splitWord[j] !== guesses[i][j]) {
        return getWrongSpotMessage(guesses[i][j], j + 1)
      }
    }
    
    mergePresent(present, tempPresent)
  }

  var [result, letter]: [boolean, string] = allPresentLettersIn(present, word)
  if(result){
    return false
  }
  
  return getTranslationWithInfo(TranslationKey.NOT_CONTAINED_MESSAGE, letter)
}

export const unicodeSplit = (word: string) => {
  return new GraphemeSplitter().splitGraphemes(word)
}

export const unicodeLength = (word: string) => {
  return unicodeSplit(word).length
}

export const localeAwareLowerCase = (text: string) => {
  return process.env.REACT_APP_LOCALE_STRING
    ? text.toLocaleLowerCase(process.env.REACT_APP_LOCALE_STRING)
    : text.toLowerCase()
}

export const localeAwareUpperCase = (text: string) => {
  return process.env.REACT_APP_LOCALE_STRING
    ? text.toLocaleUpperCase(process.env.REACT_APP_LOCALE_STRING)
    : text.toUpperCase()
}

function getTimeFromDate(date: Date): number {
  return date.getTime() - (date.getTimezoneOffset() * 60 * 1000)
}

export const getIndex = (gameDate: Date) => {
  var diff = Math.abs(getTimeFromDate(gameDate) - getTimeFromDate(firstGameDate));
  const defaultIndex = Math.ceil(diff / (1000 * 3600 * 24)) - 1; 

  if(getIsLazy() && getIsSpeedy()){
    return defaultIndex * 7000
  } else if(getIsLazy()){
    return defaultIndex * 700
  } else if(getIsSpeedy()){
    return defaultIndex * 70
  }

  return defaultIndex
}

export const getFirstLetter = (gameDate: Date, rowIndex: number, inRowIndex: number) => {
  if (inRowIndex !== 0 || !isGinJanner() || rowIndex === (MAX_CHALLENGES - 1)) {
    return ''
  }

  const localIndex = (getIndex(gameDate) + rowIndex) % 26
  return String.fromCharCode(65 + localIndex);
}

export const getWordOfDay = (index: number) => {
  if (index < 0) {
    throw new Error('Invalid index')
  }
  if(getIsHorsle()){
    return localeAwareUpperCase(WORDS_HORSLE[index % WORDS_HORSLE.length])
  }

  if(getIsScalizzi()){
    return localeAwareUpperCase(WORDS_SCALI[index % WORDS_SCALI.length])
  }

  if(getIsTaytay()){
    return localeAwareUpperCase(WORDS_TAYTAY[index % WORDS_TAYTAY.length])
  }

  if(getLanguage() === Language.PORTUGUESE){
    return localeAwareUpperCase(WORDS_PT[index % WORDS_PT.length])
  }

  if(getLanguage() === Language.FRENCH){
    return localeAwareUpperCase(WORDS_FR[index % WORDS_FR.length])
  }

  if(getLanguage() === Language.SPANISH){
    return localeAwareUpperCase(WORDS_ES[index % WORDS_ES.length])
  }

  if(getLanguage() === Language.GERMAN){
    return localeAwareUpperCase(WORDS_DE[index % WORDS_DE.length])
  }

  if(isGinJanner()){
    if(isSixLetter()){
      return localeAwareUpperCase(GINJANNER_SIX_LETTER_WORDS[index % GINJANNER_SIX_LETTER_WORDS.length])
    }
    return localeAwareUpperCase(WORDS_GINJANNER[index % WORDS_GINJANNER.length])
  }

  if(isSixLetter()){
    return localeAwareUpperCase(SIX_LETTER_WORDS[index % SIX_LETTER_WORDS.length])
  }

  return localeAwareUpperCase(WORDS[index % WORDS.length])
}

export const getSolution = (index: number) => {
  if(isCustom() && getStoredCustomSolution() !== ''){
    return getStoredCustomSolution()!
  }

  return getWordOfDay(index)
}

export function getNewGameDate(isUnlimitedMode: boolean): Date {
  if(isUnlimitedMode){
    if(getIsStaircase()){
      var date = startOfDay(new Date(Math.floor((Math.random() * 2) * Date.now())));

      // needed to make sure we always have a sunday
      date = addDays(date, 0 - date.getDay())

      //to make sure we get only staircases and not special days that fall on sundays
      if(!MESSAGES[getLanguageKey()].WIN_MESSAGES.includes(getWinMessage(date))){
        //try again
        return getNewGameDate(isUnlimitedMode)
      }

      return date
    }

    return startOfDay(new Date(Math.floor((Math.random() * 2) * Date.now())));
  } 
  else if(getIsSpeedy()){
    if(isSpeedyGameDone()){
      return addDays(getToday(), 10 * (getCounter() - 1) * 7)
    }

    return addDays(getToday(), 10 * getCounter() * 7)
  }
  
  return getToday()
}

export function shuffleGuesses(guesses:string[]):string[] {
  let currentIndex = guesses.length,  randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [guesses[currentIndex], guesses[randomIndex]] = [
      guesses[randomIndex], guesses[currentIndex]];
  }

  return guesses;
}

export function getRandomGuesses(today: Date):string[] {
  const solution = getSolution(getIndex(today))
  const validGuesses = getWords()

  const guesses = []
  var i = 0
  while(i < MAX_CHALLENGES - 1){
    const guess = validGuesses[Math.floor(Math.random() * Date.now()) % validGuesses.length].toUpperCase()
    if(guess !== solution){
      guesses.push(guess)
      i++
    }
  }

  return guesses;
}