import classNames from 'classnames'

type Props = {
  index: string
  size: number
  label: string
  isPlayerGuess: boolean
  isSolution: boolean
  percentage: string
  skill: number
}

export const GlobalProgress = ({
  index,
  size,
  label,
  isPlayerGuess,
  isSolution,
  percentage,
  skill
}: Props) => {
  const currentRowClass = classNames(
    'justify-left text-xs font-medium text-blue-100 text-left p-1',
    { 'bg-blue-600': isPlayerGuess && !isSolution, 'bg-gray-600': !isPlayerGuess && !isSolution, 'bg-green-600': isSolution}
  )
  return (
    <div className="justify-left m-1 flex">
      <div style={{textAlign: "right"}} className="w-16">{index}</div>
      <div className="ml-4 w-56 justify-center mr-4">
        <div style={{ width: `${size}%` }} className={currentRowClass}>
          {label}
        </div>
      </div>
      <div style={{textAlign: "left"}} className="w-16">{percentage}</div>
      <div style={{textAlign: "left"}} className="w-16">{skill}</div>
    </div>
  )
}
