import { TranslationKey } from '../../../constants/strings'
import { isCoSpotle, isCustom } from '../../../App'
import { BaseModal } from '../BaseModal'
import { SwitchModal } from './SwitchModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
  setIsGameSettingsModalOpen: Function
  setIsThemeSettingsModalOpen: Function
  setIsGamesModalOpen: Function
  setIsLanguageModalOpen: Function
}

export const SettingsModal = ({
  isOpen,
  handleClose,
  setIsGameSettingsModalOpen,
  setIsThemeSettingsModalOpen
  
}: Props) => {
  return (
    <BaseModal title={TranslationKey.SETTINGS} isOpen={isOpen} handleClose={handleClose}>
      <div className="mt-2 flex flex-col divide-y">
        {(!isCustom() && !isCoSpotle()) && (
          <SwitchModal label={TranslationKey.GAME_SETTINGS} message={TranslationKey.GAME_SETTINGS_DESCRIPTION} 
            setIsModalOpen={setIsGameSettingsModalOpen} handleClosePrev={handleClose} index={0}/>
        )}
        <SwitchModal label={TranslationKey.THEME_SETTINGS} message={TranslationKey.THEME_SETTINGS_DESCRIPTION} 
          setIsModalOpen={setIsThemeSettingsModalOpen} handleClosePrev={handleClose} index={1}/>
      </div>
    </BaseModal>
  )
}
