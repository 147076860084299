import { getFirstLetter } from '../../lib/words'
import { CharStatus } from '../../lib/statuses'
import { Cell } from './Cell'
import { getStoredGameDate } from '../../lib/localStorage'

type Props = {
  index: number
  statuses: Map<number, CharStatus[]>
  botStats?: string[][]
  solution: string
}

export const EmptyRow = ({index, statuses, botStats, solution }: Props) => {
  const emptyCells = Array.from(Array(solution.length))
  if(typeof botStats !== 'undefined'){
    botStats[index].map((letter, i) => (
      emptyCells.push(letter)
    ))
  }

  var rowStatuses = new Array(solution.length)
  if(typeof statuses.get(index) !== "undefined"){
    rowStatuses = statuses.get(index)!
  }

  return (
    <div className="mb-1 flex justify-center">
      {emptyCells.map((_, i) => (
        <Cell 
          key={i} 
          value={getFirstLetter(getStoredGameDate(), index, i)}
          status={rowStatuses[i]}
          position={i}
        />
      ))}
    </div>
  )
}
