import moment from "moment";
import { useState } from "react";

interface CountUpTimerProps {
  startTime: number;
}

const CountUpTime = ({ startTime }: CountUpTimerProps): JSX.Element => {
  const [diff, setDiff] = useState(moment().unix() - startTime);

  if(startTime === undefined || startTime === 0){
    return<>00:00:00</>
  }

  setInterval(() => {
    setDiff(moment().unix() - startTime);
  }, 1000);

  return <>{moment.utc(moment.duration(diff, "s").as("ms")).format("HH:mm:ss")}</>;
};

export default CountUpTime;