import { CharStatus } from '@/lib/statuses'
import { unicodeSplit } from '../../lib/words'
import { Cell } from './Cell'

type Props = {
  guess: string
  className: string
  index: number
  statuses: Map<number, CharStatus[]>
  botStats?: string[][]
  solution: string,
}

export const CurrentRow = ({ guess, className, index, statuses, botStats, solution }: Props) => {
  const splitGuess = unicodeSplit(guess)

  const emptyCells = Array.from(Array(solution.length - splitGuess.length))
  if(typeof botStats !== 'undefined'){
    botStats[index].map((letter, i) => (
      emptyCells.push(letter)
    ))
  }

  const classes = `flex justify-center mb-1 ${className}`

  var rowStatuses = new Array(guess.length)
  if(typeof statuses.get(index) !== "undefined"){
    rowStatuses = statuses.get(index)!
  }

  return (
    <div className={classes}>
      {splitGuess.map((letter, i) => (
        <Cell 
          key={i} 
          value={letter} 
          status={rowStatuses[i]}
          position={i}
          />
      ))}
      {emptyCells.map((_, i) => (
        <Cell 
          key={i + splitGuess.length} 
          status={rowStatuses[i + splitGuess.length]}
          position={i + splitGuess.length}
        />
      ))}
    </div>
  )
}
