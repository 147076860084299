import { BaseModal } from '../BaseModal'
import { setLanguageText } from '../../../lib/localStorage'
import { Language } from '../../../constants/language'
import { getTranslation } from '../../../context/messages'
import { TranslationKey } from '../../../constants/strings'
import { getLanguagePageUrl } from '../../../route/GameRoute'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

function cleanPathFromLanguage(path: string):string {
  return path.replace('/english', '')
    .replace('/portuguese', '').replace('/portugues', '')
    .replace('/french', '').replace('/francais', '')
    .replace('/spanish', '').replace('/espanol', '')
    .replace('/german', '').replace('/deustch', '')
}

export const LanguageModal = ({
  isOpen,
  handleClose,
}: Props) => {
  const path = cleanPathFromLanguage(window.location.pathname)
  	
  const languagesArray = Object.keys(Language)
    .filter((item) => {return isNaN(Number(item))});

  return (
    <BaseModal title={TranslationKey.LANGUAGE} isOpen={isOpen} handleClose={handleClose}>
      <p className="mt-1 text-xs text-gray-500 dark:text-gray-300">
        {getTranslation(TranslationKey.SWITCH_LANGUAGE)}
      </p>
      <div>
        {languagesArray.map(
          (language: string, index: number): JSX.Element => {
            return (
              <div key={index} className="mt-2 flex flex-col divide-y">
                <button
                  key = {index}
                  type="button"
                  className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-base"
                  onClick={event => {
                    setLanguageText(language)
                    window.location.href=getLanguagePageUrl(path)
                  }}
                >

                <p className="cursor-pointer dark:stroke-white" />
                  {language.charAt(0).toUpperCase() + language.slice(1).toLowerCase()}
                </button>
              </div>
            );
          }
        )}
      </div>
    </BaseModal>
  );
}
