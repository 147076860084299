import { ShareIcon } from '@heroicons/react/outline'
import { TranslationKey } from '../../constants/strings'
import { BaseModal } from './BaseModal'
import discordIcon from './discord.svg'


type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const AnnouncementsModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title={TranslationKey.ANNOUNCEMENTS} isOpen={isOpen} handleClose={handleClose}>
      <p className="mt-6 text-sm italic text-gray-500 dark:text-gray-300">
        {'New Discord Server for Spotle players. Join below!!'} 
      </p>
      {' '}
      <div className="mt-2 items-center justify-center text-center"
        style={{
          scale: '0.5',
        }}>

        <p>
          <a href="https://discord.gg/uvFg7ggW4B" target="_blank" rel="noopener noreferrer">
            <img src={discordIcon} alt="spotleDC" />
          </a>
        </p>
      </div>
      <button
        type="button"
        className="mt-1 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-base"
        onClick={() => {
          window.open('https://discord.gg/uvFg7ggW4B', "_blank")
        }}
      >
        <a href="https://discord.gg/uvFg7ggW4B" target="_blank" rel="noopener noreferrer">
        </a>
        <ShareIcon className="mr-2 h-6 w-6 cursor-pointer dark:stroke-white" />
        {'Discord'}
      </button>
    </BaseModal>
  )
}
