import '../index.css'

import { useEffect } from 'react'
import ReactGA from 'react-ga'
import { Route, Routes, useLocation } from 'react-router-dom'

import Imperialists from './Imperialists'
import NaughtyApp from './NaughtyApp'
import { cookiesPolicy, privacyPolicy, tosPolicy } from '../constants/policies'
import { TranslationKey } from '../constants/strings'
import { getPageTitle } from '../context/messages'
import { SvenTime } from '../lib/SvenTime'
import {
  isCountrySet,
  isRussia,
  setCountry,
} from '../lib/localStorage'
import { getCountry } from '../lib/timezones'
import Policy from '../policies/Policy'
import GameRoute from './GameRoute'

const RoutedApp = () => {
  ReactGA.initialize('G-FHDSTY7F07')

  if (!isCountrySet()) {
    setCountry(getCountry())
  }

  const location = useLocation()
  useEffect(() => {
    document.title = getPageTitle(location.pathname)
  }, [location])

  if (isRussia()) {
    return (
      <div>
        <Routes>
          <Route path="*" element={<Imperialists />} />
        </Routes>
      </div>
    )
  }

  return (
    <div>
      <Routes>
        <Route path="/" element={<GameRoute path="/"/>}/>
        <Route path="/english" element={<GameRoute path="/english"/>}/>
        <Route path="/portuguese" element={<GameRoute path="/portuguese"/>}/>
        <Route path="/portugues" element={<GameRoute path="/portugues"/>}/>
        <Route path="/french" element={<GameRoute path="/french"/>}/>
        <Route path="/francais" element={<GameRoute path="/francais"/>}/>
        <Route path="/spanish" element={<GameRoute path="/spanish"/>}/>
        <Route path="/espanol" element={<GameRoute path="/espanol"/>}/>
        <Route path="/german" element={<GameRoute path="/german"/>}/>
        <Route path="/deutsch" element={<GameRoute path="/deutsch"/>}/>
        <Route path="/six" element={<GameRoute path="/six"/>}/>
        <Route path="/six/english" element={<GameRoute path="/six/english"/>}/>

        <Route path="/unlimited" element={<GameRoute path="/unlimited"/>}/>
        <Route path="/english/unlimited" element={<GameRoute path="/english/unlimited"/>}/>
        <Route path="/portuguese/unlimited" element={<GameRoute path="/portuguese/unlimited"/>}/>
        <Route path="/portugues/unlimited" element={<GameRoute path="/portugues/unlimited"/>}/>
        <Route path="/french/unlimited" element={<GameRoute path="/french/unlimited"/>}/>
        <Route path="/francais/unlimited" element={<GameRoute path="/francais/unlimited"/>}/>
        <Route path="/spanish/unlimited" element={<GameRoute path="/spanish/unlimited"/>}/>
        <Route path="/espanol/unlimited" element={<GameRoute path="/espanol/unlimited"/>}/>
        <Route path="/german/unlimited" element={<GameRoute path="/german/unlimited"/>}/>
        <Route path="/deutsch/unlimited" element={<GameRoute path="/deutsch/unlimited"/>}/>
        <Route path="/six/unlimited" element={<GameRoute path="/six/unlimited"/>}/>
        <Route path="/six/english/unlimited" element={<GameRoute path="/six/english/unlimited"/>}/>

        <Route path="/staircase" element={<GameRoute path="/staircase"/>}/>
        <Route path="/english/staircase" element={<GameRoute path="/english/staircase"/>}/>
        <Route path="/portuguese/staircase" element={<GameRoute path="/portuguese/staircase"/>}/>
        <Route path="/portugues/staircase" element={<GameRoute path="/portugues/staircase"/>}/>
        <Route path="/french/staircase" element={<GameRoute path="/french/staircase"/>}/>
        <Route path="/francais/staircase" element={<GameRoute path="/francais/staircase"/>}/>
        <Route path="/spanish/staircase" element={<GameRoute path="/spanish/staircase"/>}/>
        <Route path="/espanol/staircase" element={<GameRoute path="/espanol/staircase"/>}/>
        <Route path="/german/staircase" element={<GameRoute path="/german/staircase"/>}/>
        <Route path="/deutsch/staircase" element={<GameRoute path="/deutsch/staircase"/>}/>
        <Route path="/six/staircase" element={<GameRoute path="/six/staircase"/>}/>
        <Route path="/six/english/staircase" element={<GameRoute path="/six/english/staircase"/>}/>

        <Route path="/lazy" element={<GameRoute path="/lazy"/>}/>
        <Route path="/english/lazy" element={<GameRoute path="/english/lazy"/>}/>
        <Route path="/portuguese/lazy" element={<GameRoute path="/portuguese/lazy"/>}/>
        <Route path="/portugues/lazy" element={<GameRoute path="/portugues/lazy"/>}/>
        <Route path="/french/lazy" element={<GameRoute path="/french/lazy"/>}/>
        <Route path="/francais/lazy" element={<GameRoute path="/francais/lazy"/>}/>
        <Route path="/spanish/lazy" element={<GameRoute path="/spanish/lazy"/>}/>
        <Route path="/espanol/lazy" element={<GameRoute path="/espanol/lazy"/>}/>
        <Route path="/german/lazy" element={<GameRoute path="/german/lazy"/>}/>
        <Route path="/deutsch/lazy" element={<GameRoute path="/deutsch/lazy"/>}/>
        <Route path="/six/lazy" element={<GameRoute path="/six/lazy"/>}/>
        <Route path="/six/english/lazy" element={<GameRoute path="/six/english/lazy"/>}/>

        <Route path="/lazy/unlimited" element={<GameRoute path="/lazy/unlimited"/>}/>
        <Route path="/english/lazy/unlimited" element={<GameRoute path="/english/lazy/unlimited"/>}/>
        <Route path="/portuguese/lazy/unlimited" element={<GameRoute path="/portuguese/lazy/unlimited"/>}/>
        <Route path="/portugues/lazy/unlimited" element={<GameRoute path="/portugues/lazy/unlimited"/>}/>
        <Route path="/french/lazy/unlimited" element={<GameRoute path="/french/lazy/unlimited"/>}/>
        <Route path="/francais/lazy/unlimited" element={<GameRoute path="/francais/lazy/unlimited"/>}/>
        <Route path="/spanish/lazy/unlimited" element={<GameRoute path="/spanish/lazy/unlimited"/>}/>
        <Route path="/espanol/lazy/unlimited" element={<GameRoute path="/espanol/lazy/unlimited"/>}/>
        <Route path="/german/lazy/unlimited" element={<GameRoute path="/german/lazy/unlimited"/>}/>
        <Route path="/deutsch/lazy/unlimited" element={<GameRoute path="/deutsch/lazy/unlimited"/>}/>
        <Route path="/six/lazy/unlimited" element={<GameRoute path="/six/lazy/unlimited"/>}/>
        <Route path="/six/english/lazy/unlimited" element={<GameRoute path="/six/english/lazy/unlimited"/>}/>

        <Route path="/speedy" element={<GameRoute path="/speedy"/>}/>
        <Route path="/english/speedy" element={<GameRoute path="/english/speedy"/>}/>
        <Route path="/portuguese/speedy" element={<GameRoute path="/portuguese/speedy"/>}/>
        <Route path="/portugues/speedy" element={<GameRoute path="/portugues/speedy"/>}/>
        <Route path="/french/speedy" element={<GameRoute path="/french/speedy"/>}/>
        <Route path="/francais/speedy" element={<GameRoute path="/francais/speedy"/>}/>
        <Route path="/spanish/speedy" element={<GameRoute path="/spanish/speedy"/>}/>
        <Route path="/espanol/speedy" element={<GameRoute path="/espanol/speedy"/>}/>
        <Route path="/german/speedy" element={<GameRoute path="/german/speedy"/>}/>
        <Route path="/deutsch/speedy" element={<GameRoute path="/deutsch/speedy"/>}/>
        <Route path="/six/speedy" element={<GameRoute path="/six/speedy"/>}/>
        <Route path="/six/english/speedy" element={<GameRoute path="/six/english/speedy"/>}/>

        <Route path="/speedy/unlimited" element={<GameRoute path="/speedy/unlimited"/>}/>
        <Route path="/english/speedy/unlimited" element={<GameRoute path="/english/speedy/unlimited"/>}/>
        <Route path="/portuguese/speedy/unlimited" element={<GameRoute path="/portuguese/speedy/unlimited"/>}/>
        <Route path="/portugues/speedy/unlimited" element={<GameRoute path="/portugues/speedy/unlimited"/>}/>
        <Route path="/french/speedy/unlimited" element={<GameRoute path="/french/speedy/unlimited"/>}/>
        <Route path="/francais/speedy/unlimited" element={<GameRoute path="/francais/speedy/unlimited"/>}/>
        <Route path="/spanish/speedy/unlimited" element={<GameRoute path="/spanish/speedy/unlimited"/>}/>
        <Route path="/espanol/speedy/unlimited" element={<GameRoute path="/espanol/speedy/unlimited"/>}/>
        <Route path="/german/speedy/unlimited" element={<GameRoute path="/german/speedy/unlimited"/>}/>
        <Route path="/deutsch/speedy/unlimited" element={<GameRoute path="/deutsch/speedy/unlimited"/>}/>
        <Route path="/six/speedy/unlimited" element={<GameRoute path="/six/speedy/unlimited"/>}/>
        <Route path="/six/english/speedy/unlimited" element={<GameRoute path="/six/english/speedy/unlimited"/>}/>

        <Route path="/speedy/lazy" element={<GameRoute path="/speedy/lazy"/>}/>
        <Route path="/english/speedy/lazy" element={<GameRoute path="/english/speedy/lazy"/>}/>
        <Route path="/portuguese/speedy/lazy" element={<GameRoute path="/portuguese/speedy/lazy"/>}/>
        <Route path="/portugues/speedy/lazy" element={<GameRoute path="/portugues/speedy/lazy"/>}/>
        <Route path="/french/speedy/lazy" element={<GameRoute path="/french/speedy/lazy"/>}/>
        <Route path="/francais/speedy/lazy" element={<GameRoute path="/francais/speedy/lazy"/>}/>
        <Route path="/spanish/speedy/lazy" element={<GameRoute path="/spanish/speedy/lazy"/>}/>
        <Route path="/espanol/speedy/lazy" element={<GameRoute path="/espanol/speedy/lazy"/>}/>
        <Route path="/german/speedy/lazy" element={<GameRoute path="/german/speedy/lazy"/>}/>
        <Route path="/deutsch/speedy/lazy" element={<GameRoute path="/deutsch/speedy/lazy"/>}/>
        <Route path="/six/speedy/lazy" element={<GameRoute path="/six/speedy/lazy"/>}/>
        <Route path="/six/english/speedy/lazy" element={<GameRoute path="/six/english/speedy/lazy"/>}/>

        <Route path="/speedy/lazy/unlimited" element={<GameRoute path="/speedy/lazy/unlimited"/>}/>
        <Route path="/english/speedy/lazy/unlimited" element={<GameRoute path="/english/speedy/lazy/unlimited"/>}/>
        <Route path="/portuguese/speedy/lazy/unlimited" element={<GameRoute path="/portuguese/speedy/lazy/unlimited"/>}/>
        <Route path="/portugues/speedy/lazy/unlimited" element={<GameRoute path="/portugues/speedy/lazy/unlimited"/>}/>
        <Route path="/french/speedy/lazy/unlimited" element={<GameRoute path="/french/speedy/lazy/unlimited"/>}/>
        <Route path="/francais/speedy/lazy/unlimited" element={<GameRoute path="/francais/speedy/lazy/unlimited"/>}/>
        <Route path="/spanish/speedy/lazy/unlimited" element={<GameRoute path="/spanish/speedy/lazy/unlimited"/>}/>
        <Route path="/espanol/speedy/lazy/unlimited" element={<GameRoute path="/espanol/speedy/lazy/unlimited"/>}/>
        <Route path="/german/speedy/lazy/unlimited" element={<GameRoute path="/german/speedy/lazy/unlimited"/>}/>
        <Route path="/deutsch/speedy/lazy/unlimited" element={<GameRoute path="/deutsch/speedy/lazy/unlimited"/>}/>
        <Route path="/six/speedy/lazy/unlimited" element={<GameRoute path="/six/speedy/lazy/unlimited"/>}/>
        <Route path="/six/english/speedy/lazy/unlimited" element={<GameRoute path="/six/english/speedy/lazy/unlimited"/>}/>

        <Route path="/dummies" element={<GameRoute path="/dummies"/>}/>

        <Route path="/swift" element={<GameRoute path="/swift"/>}/>
        <Route path="/swift/unlimited" element={<GameRoute path="/swift/unlimited"/>}/>

        <Route path="/scalizzi" element={<GameRoute path="/scalizzi"/>}/>

        <Route path="/ginjanner" element={<GameRoute path="/ginjanner"/>}/>
        <Route path="/ginjanner/unlimited" element={<GameRoute path="/ginjanner/unlimited"/>}/>
        <Route path="/six/ginjanner" element={<GameRoute path="/six/ginjanner"/>}/>
        <Route path="/six/ginjanner/unlimited" element={<GameRoute path="/six/ginjanner/unlimited"/>}/>

        <Route path="/impossible" element={<GameRoute path="/impossible"/>}/>
        <Route path="/english/impossible" element={<GameRoute path="/english/impossible"/>}/>
        <Route path="/portuguese/impossible" element={<GameRoute path="/portuguese/impossible"/>}/>
        <Route path="/portugues/impossible" element={<GameRoute path="/portugues/impossible"/>}/>
        <Route path="/french/impossible" element={<GameRoute path="/french/impossible"/>}/>
        <Route path="/francais/impossible" element={<GameRoute path="/francais/impossible"/>}/>
        <Route path="/spanish/impossible" element={<GameRoute path="/spanish/impossible"/>}/>
        <Route path="/espanol/impossible" element={<GameRoute path="/espanol/impossible"/>}/>
        <Route path="/german/impossible" element={<GameRoute path="/german/impossible"/>}/>
        <Route path="/deutsch/impossible" element={<GameRoute path="/deutsch/impossible"/>}/>
        <Route path="/six/impossible" element={<GameRoute path="/six/impossible"/>}/>
        <Route path="/six/english/impossible" element={<GameRoute path="/six/english/impossible"/>}/>

        <Route path="/cospotle/*" element={<GameRoute path="/cospotle/*"/>}/>
        <Route path="/english/cospotle/*" element={<GameRoute path="/english/cospotle/*"/>}/>
        <Route path="/portuguese/cospotle/*" element={<GameRoute path="/portuguese/cospotle/*"/>}/>
        <Route path="/portugues/cospotle/*" element={<GameRoute path="/portugues/cospotle/*"/>}/>
        <Route path="/french/cospotle/*" element={<GameRoute path="/french/cospotle/*"/>}/>
        <Route path="/francais/cospotle/*" element={<GameRoute path="/francais/cospotle/*"/>}/>
        <Route path="/spanish/cospotle/*" element={<GameRoute path="/spanish/cospotle/*"/>}/>
        <Route path="/espanol/cospotle/*" element={<GameRoute path="/espanol/cospotle/*"/>}/>
        <Route path="/german/cospotle/*" element={<GameRoute path="/german/cospotle/*"/>}/>
        <Route path="/deutsch/cospotle/*" element={<GameRoute path="/deutsch/cospotle/*"/>}/>
        <Route path="/six/cospotle/*" element={<GameRoute path="/six/cospotle/*"/>}/>
        <Route path="/six/english/cospotle/*" element={<GameRoute path="/six/english/cospotle/*"/>}/>

        <Route path="/cospotle/team/*" element={<GameRoute path="/cospotle/team/*"/>}/>
        <Route path="/english/cospotle/team/*" element={<GameRoute path="/english/cospotle/team/*"/>}/>
        <Route path="/portuguese/cospotle/team/*" element={<GameRoute path="/portuguese/cospotle/team/*"/>}/>
        <Route path="/portugues/cospotle/team/*" element={<GameRoute path="/portugues/cospotle/team/*"/>}/>
        <Route path="/french/cospotle/team/*" element={<GameRoute path="/french/cospotle/team/*"/>}/>
        <Route path="/francais/cospotle/team/*" element={<GameRoute path="/francais/cospotle/team/*"/>}/>
        <Route path="/spanish/cospotle/team/*" element={<GameRoute path="/spanish/cospotle/team/*"/>}/>
        <Route path="/espanol/cospotle/team/*" element={<GameRoute path="/espanol/cospotle/team/*"/>}/>
        <Route path="/german/cospotle/team/*" element={<GameRoute path="/german/cospotle/team/*"/>}/>
        <Route path="/deutsch/cospotle/team/*" element={<GameRoute path="/deutsch/cospotle/team/*"/>}/>
        <Route path="/six/cospotle/team/*" element={<GameRoute path="/six/cospotle/team/*"/>}/>
        <Route path="/six/english/cospotle/team/*" element={<GameRoute path="/six/english/cospotle/team/*"/>}/>

        <Route path="/cospotle/parallel/*" element={<GameRoute path="/cospotle/parallel/*"/>}/>
        <Route path="/english/cospotle/parallel/*" element={<GameRoute path="/english/cospotle/parallel/*"/>}/>
        <Route path="/portuguese/cospotle/parallel/*" element={<GameRoute path="/portuguese/cospotle/parallel/*"/>}/>
        <Route path="/portugues/cospotle/parallel/*" element={<GameRoute path="/portugues/cospotle/parallel/*"/>}/>
        <Route path="/french/cospotle/parallel/*" element={<GameRoute path="/french/cospotle/parallel/*"/>}/>
        <Route path="/francais/cospotle/parallel/*" element={<GameRoute path="/francais/cospotle/parallel/*"/>}/>
        <Route path="/spanish/cospotle/parallel/*" element={<GameRoute path="/spanish/cospotle/parallel/*"/>}/>
        <Route path="/espanol/cospotle/parallel/*" element={<GameRoute path="/espanol/cospotle/parallel/*"/>}/>
        <Route path="/german/cospotle/parallel/*" element={<GameRoute path="/german/cospotle/parallel/*"/>}/>
        <Route path="/deutsch/cospotle/parallel/*" element={<GameRoute path="/deutsch/cospotle/parallel/*"/>}/>
        <Route path="/six/cospotle/parallel/*" element={<GameRoute path="/six/cospotle/parallel/*"/>}/>
        <Route path="/six/english/cospotle/parallel/*" element={<GameRoute path="/six/english/cospotle/parallel/*"/>}/>

        <Route path="/cospotle/bot" element={<GameRoute path="/cospotle/bot"/>}/>
        <Route path="/english/cospotle/bot" element={<GameRoute path="/english/cospotle/bot"/>}/>
        <Route path="/portuguese/cospotle/bot" element={<GameRoute path="/portuguese/cospotle/bot"/>}/>
        <Route path="/portugues/cospotle/bot" element={<GameRoute path="/portugues/cospotle/bot"/>}/>
        <Route path="/french/cospotle/bot" element={<GameRoute path="/french/cospotle/bot"/>}/>
        <Route path="/francais/cospotle/bot" element={<GameRoute path="/francais/cospotle/bot"/>}/>
        <Route path="/spanish/cospotle/bot" element={<GameRoute path="/spanish/cospotle/bot"/>}/>
        <Route path="/espanol/cospotle/bot" element={<GameRoute path="/espanol/cospotle/bot"/>}/>
        <Route path="/german/cospotle/bot" element={<GameRoute path="/german/cospotle/bot"/>}/>
        <Route path="/deutsch/cospotle/bot" element={<GameRoute path="/deutsch/cospotle/bot"/>}/>
        <Route path="/six/cospotle/bot" element={<GameRoute path="/six/cospotle/bot"/>}/>
        <Route path="/six/english/cospotle/bot" element={<GameRoute path="/six/english/cospotle/bot"/>}/>

        <Route path="/cospotle/bot/challenge" element={<GameRoute path="/cospotle/bot/challenge"/>}/>
        <Route path="/english/cospotle/bot/challenge" element={<GameRoute path="/english/cospotle/bot/challenge"/>}/>
        <Route path="/portuguese/cospotle/bot/challenge" element={<GameRoute path="/portuguese/cospotle/bot/challenge"/>}/>
        <Route path="/portugues/cospotle/bot/challenge" element={<GameRoute path="/portugues/cospotle/bot/challenge"/>}/>
        <Route path="/french/cospotle/bot/challenge" element={<GameRoute path="/french/cospotle/bot/challenge"/>}/>
        <Route path="/francais/cospotle/bot/challenge" element={<GameRoute path="/francais/cospotle/bot/challenge"/>}/>
        <Route path="/spanish/cospotle/bot/challenge" element={<GameRoute path="/spanish/cospotle/bot/challenge"/>}/>
        <Route path="/espanol/cospotle/bot/challenge" element={<GameRoute path="/espanol/cospotle/bot/challenge"/>}/>
        <Route path="/german/cospotle/bot/challenge" element={<GameRoute path="/german/cospotle/bot/challenge"/>}/>
        <Route path="/deutsch/cospotle/bot/challenge" element={<GameRoute path="/deutsch/cospotle/bot/challenge"/>}/>
        <Route path="/six/cospotle/bot/challenge" element={<GameRoute path="/six/cospotle/bot/challenge"/>}/>
        <Route path="/six/english/cospotle/bot/challenge" element={<GameRoute path="/six/english/cospotle/bot/challenge"/>}/>

        <Route path="/cospotle/bot/parallel/challenge" element={<GameRoute path="/cospotle/bot/parallel/challenge"/>}/>
        <Route path="/english/cospotle/bot/parallel/challenge" element={<GameRoute path="/english/cospotle/bot/parallel/challenge"/>}/>
        <Route path="/portuguese/cospotle/bot/parallel/challenge" element={<GameRoute path="/portuguese/cospotle/bot/parallel/challenge"/>}/>
        <Route path="/portugues/cospotle/bot/parallel/challenge" element={<GameRoute path="/portugues/cospotle/bot/parallel/challenge"/>}/>
        <Route path="/french/cospotle/bot/parallel/challenge" element={<GameRoute path="/french/cospotle/bot/parallel/challenge"/>}/>
        <Route path="/francais/cospotle/bot/parallel/challenge" element={<GameRoute path="/francais/cospotle/bot/parallel/challenge"/>}/>
        <Route path="/spanish/cospotle/bot/parallel/challenge" element={<GameRoute path="/spanish/cospotle/bot/parallel/challenge"/>}/>
        <Route path="/espanol/cospotle/bot/parallel/challenge" element={<GameRoute path="/espanol/cospotle/bot/parallel/challenge"/>}/>
        <Route path="/german/cospotle/bot/parallel/challenge" element={<GameRoute path="/german/cospotle/bot/parallel/challenge"/>}/>
        <Route path="/deutsch/cospotle/bot/parallel/challenge" element={<GameRoute path="/deutsch/cospotle/bot/parallel/challenge"/>}/>   
        <Route path="/six/cospotle/bot/parallel/challenge" element={<GameRoute path="/six/cospotle/bot/parallel/challenge"/>}/>
        <Route path="/six/english/cospotle/bot/parallel/challenge" element={<GameRoute path="/six/english/cospotle/bot/parallel/challenge"/>}/>     
        
        <Route path="/cospotle/bot/parallel/" element={<GameRoute path="/cospotle/bot/parallel/"/>}/>
        <Route path="/english/cospotle/bot/parallel/" element={<GameRoute path="/english/cospotle/bot/parallel/"/>}/>
        <Route path="/portuguese/cospotle/bot/parallel/" element={<GameRoute path="/portuguese/cospotle/bot/parallel/"/>}/>
        <Route path="/portugues/cospotle/bot/parallel/" element={<GameRoute path="/portugues/cospotle/bot/parallel/"/>}/>
        <Route path="/french/cospotle/bot/parallel/" element={<GameRoute path="/french/cospotle/bot/parallel/"/>}/>
        <Route path="/francais/cospotle/bot/parallel/" element={<GameRoute path="/francais/cospotle/bot/parallel/"/>}/>
        <Route path="/spanish/cospotle/bot/parallel/" element={<GameRoute path="/spanish/cospotle/bot/parallel/"/>}/>
        <Route path="/espanol/cospotle/bot/parallel/" element={<GameRoute path="/espanol/cospotle/bot/parallel/"/>}/>
        <Route path="/german/cospotle/bot/parallel/" element={<GameRoute path="/german/cospotle/bot/parallel/"/>}/>
        <Route path="/deutsch/cospotle/bot/parallel/" element={<GameRoute path="/deutsch/cospotle/bot/parallel/"/>}/>  
        <Route path="/six/cospotle/bot/parallel/" element={<GameRoute path="/six/cospotle/bot/parallel/"/>}/>
        <Route path="/six/english/cospotle/bot/parallel/" element={<GameRoute path="/six/english/cospotle/bot/parallel/"/>}/>     
        
        <Route path="/custom/*" element={<GameRoute path="/custom/*"/>}/>
        <Route path="/english/custom/*" element={<GameRoute path="/english/custom/*"/>}/>
        <Route path="/portuguese/custom/*" element={<GameRoute path="/portuguese/custom/*"/>}/>
        <Route path="/portugues/custom/*" element={<GameRoute path="/portugues/custom/*"/>}/>
        <Route path="/french/custom/*" element={<GameRoute path="/french/custom/*"/>}/>
        <Route path="/francais/custom/*" element={<GameRoute path="/francais/custom/*"/>}/>
        <Route path="/spanish/custom/*" element={<GameRoute path="/spanish/custom/*"/>}/>
        <Route path="/espanol/custom/*" element={<GameRoute path="/espanol/custom/*"/>}/>
        <Route path="/german/custom/*" element={<GameRoute path="/german/custom/*"/>}/>
        <Route path="/deutsch/custom/*" element={<GameRoute path="/deutsch/custom/*"/>}/>
        <Route path="/six/custom/*" element={<GameRoute path="/six/custom/*"/>}/>
        <Route path="/six/english/custom/*" element={<GameRoute path="/six/english/custom/*"/>}/>

        <Route path="/tos"
          element={
            <Policy title={TranslationKey.TERMS_OF_SERVICE} policy={tosPolicy}/>
          }
        />
        <Route path="/r*ssia" element={<Imperialists />} />
        <Route path="/gamer" element={<NaughtyApp />} />
        <Route path="/privacypolicy"
          element={
            <Policy title={TranslationKey.PRIVACY_POLICY} policy={privacyPolicy}/>
          }
        />
        <Route path="/cookiespolicy"
          element={
            <Policy title={TranslationKey.COOKIES_POLICY} policy={cookiesPolicy}/>
          }
        />
        <Route path="/sventime" element={<SvenTime />} />
        <Route path="*" element={<NaughtyApp />} />
      </Routes>
    </div>
  )
}

export default RoutedApp
