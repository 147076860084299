import { recordSpeedyResultGaEvent } from "../components/alerts/Analytics"
import { getIsSpeedy } from "../App"
import { getCounter, getGameStreak, getStoredNumberOfGames, getResults, getSpeedyEndTime, getSpeedyStartTime, setCounter, setGameStreak, setStoredNumberOfGames, setResults, setSpeedyEndTime, setSpeedyStartTime } from "./localStorage"

export function isSpeedyGameDone(): boolean {
  return getIsSpeedy() && getCounter() >= getStoredNumberOfGames()
}

export function getSpeedyGameCounter(): string{
  var count = getCounter()
  if(!isSpeedyGameDone()){
    count++
  }
  
  return count + '/' + getStoredNumberOfGames().toString()
}
  
export function resetSpeedyGame(): void {
  setCounter(0)
  setSpeedyStartTime(0)
  setSpeedyEndTime(0)
  setGameStreak([])
}

export function hasSpeedyGameStarted(): boolean{
  return getIsSpeedy() && getSpeedyStartTime() !== 0
}
  
export const addSpeedyTimePenalty = () => {
  setSpeedyStartTime(getSpeedyStartTime() - 30)
}
  
function adjustFieldSize(field: number): string{
  var strField = ''
  if(field < 10){
    strField = '0'
  }
  
  return strField + field.toString()
}
  
export function calculateCountdownTitle(time?: number): string{
  var diff = getSpeedyEndTime() - getSpeedyStartTime()
  if(typeof time !== 'undefined'){
    diff = time
  }

  const midnight = new Date(1996,1,12,0,0,0,0).getTime()
  const diffDate = new Date(diff * 1000 + midnight)
 
  return adjustFieldSize(diffDate.getHours()) + ':' 
    + adjustFieldSize(diffDate.getMinutes()) + ':' 
    + adjustFieldSize(diffDate.getSeconds())
}

export function getResultsTimestamps(): string[]{
  const results = []

  for(const time of getResults()){
    results.push(calculateCountdownTitle(time))
  }

  return results
}

export function addSpeedyResult(){
  setSpeedyEndTime(Math.floor(Date.now() / 1000))

  const gameResult = getSpeedyEndTime() - getSpeedyStartTime()

  recordSpeedyResultGaEvent(calculateCountdownTitle(gameResult))

  const results = getResults()
  if(results.length === 0){
    return setResults([gameResult])
  }

  var added = false
  for(var i = 0; i < results.length; i++){
    if(gameResult < results[i]){
      results.splice(i, 0, gameResult)
      added = true
      break
    }
  }

  if(!added){
    results.push(gameResult)
  }

  while(results.length > 5){
    results.pop()
  }

  return setResults(results)
}

export function incSpeedyNumberOfGames(){
  const numberOfGames = getStoredNumberOfGames()
  if(numberOfGames >= 60){
    return setStoredNumberOfGames(5)
  }

  if(numberOfGames >= 30){
    return setStoredNumberOfGames(numberOfGames + 10)
  }

  setStoredNumberOfGames(numberOfGames + 5)
}

export function decSpeedyNumberOfGames(){
  const numberOfGames = getStoredNumberOfGames()
  if(numberOfGames <= 5){
    return setStoredNumberOfGames(60)
  }

  if(numberOfGames <= 30){
    return setStoredNumberOfGames(numberOfGames - 5)
  }

  setStoredNumberOfGames(numberOfGames - 10)
}

export function getTotalTimePenalty(){
  var total = 0
  for(const length of getGameStreak()){
    if(length === -1){
      total = total + 30
    }
  }

  return total + 's'
}