import classnames from 'classnames'

import { CharStatus } from '../../../lib/statuses'
import { useState } from 'react'

type Props = {
  position: number
  rowIndex: number
  statuses: Map<number, CharStatus[]> 
}

export const CustomCell = ({
  position,
  rowIndex,
  statuses
}: Props) => {
  const [cellStatus, setCellStatus] = useState('empty')

  if(cellStatus !== statuses.get(rowIndex)![position]){
    setCellStatus(statuses.get(rowIndex)![position])
  }

  const classes = classnames(
    'xxshort:w-11 xxshort:h-11 short:text-xl short:w-12 short:h-12 h-14 w-14 border-solid border-2 flex items-center justify-center mx-0.5 text-4xl font-bold rounded dark:text-white' ,
    {
      'bg-white dark:bg-slate-900 border-slate-200 dark:border-slate-600':
        !cellStatus,
      'border-black dark:border-slate-100': !cellStatus,
      'absent shadowed bg-black dark:bg-black text-white border-black dark:border-black':
      cellStatus === 'hidden',
    }
  )

  return (
    <div className={classes} onClick={() => 
      {
        if(cellStatus === 'empty'){
          statuses.get(rowIndex)![position] = 'hidden'
          setCellStatus('hidden')
        }else{
          statuses.get(rowIndex)![position] = 'empty'
          setCellStatus('empty')
        }
      }
    }/>
  )
}
