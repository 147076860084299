import { getIsScalizzi } from "../../App";
import { Language } from "../../constants/language";
import { getDefinition, getLanguage, setDefinition } from "../../lib/localStorage";

export interface Dictionary {
  word:       string;
  phonetics:  Phonetic[];
  meanings:   Meaning[];
  license:    License;
  sourceUrls: string[];
}

export interface License {
  name: string;
  url:  string;
}

export interface Meaning {
  partOfSpeech: string;
  definitions:  Definition[];
  synonyms:     string[];
  antonyms:     string[];
}

export interface Definition {
  definition: string;
  synonyms:   any[];
  antonyms:   any[];
  example?:   string;
}

export interface Phonetic {
  audio:      string;
  sourceUrl?: string;
  license?:   License;
  text?:      string;
}

export const getDefinitionText = (solution: string) => {
  var result = getDefinition(solution)
  if(result === null
      || result === ""
      || typeof result === "undefined"){
    return '';
  }

  return solution.charAt(0).toUpperCase() + solution.slice(1).toLowerCase() + ': ' + result;
}

export const getShortnedDefinitionText = (solution: string) => {
  var result = getDefinition(solution)
  if(result === null
      || result === ""
      || typeof result === "undefined"){
    return '';
  }

  var size = 50
  if(result.length < 50){
    size  = result.length
  }

  return solution.charAt(0).toUpperCase() + solution.slice(1).toLowerCase() + ': ' + result.slice(0,size) + '...';
}

export const recalcDefinitionAPI = (solution: string) => {
  if(getLanguage() === Language.ENGLISH){
    callDefinitionAPI(solution);
  }
}

const callDefinitionAPI = async (solution: string) => {
  if(getCustomDefinition(solution) !== ''){
    setDefinition(getCustomDefinition(solution))
    return
  }

  setDefinition('')

  let data: string = '';
  return await fetch('https://api.dictionaryapi.dev/api/v2/entries/en/' + solution)
    .then(function(response) {
      return response.json();
  })
    .then(function(myJson) {

    data = JSON.stringify(myJson)
    const dict:Dictionary[] = JSON.parse(data)
    
    var savedMeaning: Meaning = dict[0].meanings[0]
    for(const meaning of dict[0].meanings){
      if(meaning.definitions.length > savedMeaning.definitions.length){
        savedMeaning = meaning
      }
    }
    
    data = savedMeaning.definitions[0].definition

    setDefinition(data)
    return data;
  })
    .catch((error) => {
      setDefinition(getCustomDefinition(solution))
      console.log(error)
  });
}

const getCustomDefinition = (solution: string) => {
  var definitions: { [letter: string]: string } = {}
  definitions['GAYLY'] = 'Cheerfully; in a gay manner'
  definitions['DUMMY'] = 'A silent person; a person who does not talk.'
  definitions['MASSE'] = '(Massé) Denoting a stroke made with an inclined cue, imparting swerve to the ball.'
  definitions['THEIR'] = 'Belonging to or associated with the people or things previously mentioned or easily identified.'
  definitions['FRISK'] = '(of a police officer or other official) pass the hands over (someone) in a search for hidden weapons, drugs, or other items.'
  definitions['ICILY'] = 'in an unfriendly way that shows no emotion'
  definitions['GRAIL'] = 'a thing that is eagerly pursued or sought after'
  definitions['BICEP'] = 'any of several muscles having two points of attachment at one end'
  definitions['PITCH'] = 'the quality of a sound governed by the rate of vibrations producing it; the degree of highness or lowness of a tone.'
  definitions['GAMER'] = 'A person who plays any kind of game; Kenny Haller'
  if(getIsScalizzi()){
    definitions['DITCH'] = 'A trench; a long, shallow indentation, as for irrigation or drainage where you might find Brittany\'s car'
  }

  if(solution in definitions){
    return definitions[solution]
  }

  return ''
}