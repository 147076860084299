import { PlayIcon } from "@heroicons/react/outline"
import { getPageTitle, getTranslation } from "../../../context/messages"
import { getLanguagePageUrl } from "../../../route/GameRoute"
import { TranslationKey } from "@/constants/strings"

type Props = {
  index: number
  pageNumber: number
  location?: string
  title?: TranslationKey
  description?: TranslationKey
  handler?: Function 
  disabled? : boolean
}

export const GameModeSwitchModal = ({ index, pageNumber, location, title, description, handler, disabled }: Props) => {
  const lowerBound = pageNumber * 4
  const upperBound = lowerBound + 3
  //0 1 2 3 4
  //5 6 7 8 9

  if(index < lowerBound || index > upperBound){
    return (<></>)
  }
  
  var classNameStyle = 'inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-base'
  if(disabled === true){
    classNameStyle = 'inline-flex w-full items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:text-base'
  }

  return (
    <div className="flex justify-between gap-20 py-3">
      <div className="mt-3 text-left text-gray-500 dark:text-gray-300">
        {title === undefined && (
          <p className="leading-none">{getPageTitle(location!, true)}</p>
        )}
        {title !== undefined && (
          <p className="leading-none">{getTranslation(title)}</p>
        )}
        {description && (
          <p className="mt-1 text-xs text-gray-500 dark:text-gray-300">
            {getTranslation(description)}
          </p>
        )}
      </div>
      <div key={index} className="mt-2 flex flex-col divide-y">
      <button
        key = {index}
        type="button"
        disabled={disabled === true}
        className={classNameStyle}
        onClick={event => {
          if(handler !== undefined){
            handler(true)
          }else{
            window.location.href=getLanguagePageUrl(location!)
          }         
        }}        
      >
        <PlayIcon className="h-6 w-6 cursor-pointer dark:stroke-white" />
      </button>
      </div>
    </div>
  )
}
