import { useEffect, useState } from "react";

export const SvenTime = () => {
  //my bday
  const targetTime = 824079600000
  const [currentTime, setCurrentTime] = useState(Date.now() - targetTime);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Date.now() - targetTime);
    }, 432);

    return () => clearInterval(interval);
  }, []);

  const secMs = 432
  const minMs = secMs * 100
  const hourMs = minMs * 100
  const dayMs = hourMs * 20
  const monthMs = dayMs * 28
  const yearMs = monthMs * 13

  const year = Math.floor(currentTime / yearMs)
  const month = Math.floor((currentTime - (year * yearMs)) / monthMs)
  const day = Math.floor((currentTime - (year * 13 * monthMs) - (month * monthMs)) / dayMs)
  const hours = Math.floor((currentTime - (year * 13 * 28 * dayMs) - (month * monthMs) - (day * dayMs)) / hourMs)
  const minutes = Math.floor((currentTime - (year * 13 * 28 * 20 * hourMs) - (month * monthMs) - (day * dayMs) - (hours * hourMs)) / minMs)
  const seconds = Math.floor((currentTime % minMs) / secMs)

  return (
    <div
      className="flex items-center justify-center text-center"
      style={{
        fontSize: '20px',
        color: 'green',
      }}
    >
      <p className="counter">
        <span>{year}yr</span>
        <span>{month}m </span>
        <span>{day}d </span>
        <span>{hours}h </span>
        <span>{minutes}min </span>
        <span>{seconds}s </span>
      </p>
    </div>
  );
};