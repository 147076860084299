import { TranslationKey } from '../../../constants/strings'
import { BaseModal } from '../BaseModal'
import { SettingsToggle } from './SettingsToggle'


type Props = {
  isOpen: boolean
  handleClose: () => void
  isDarkMode: boolean
  handleDarkMode: Function
  isHighContrastMode: boolean
  handleHighContrastMode: Function
}

export const ThemeSettingsModal = ({
  isOpen,
  handleClose,
  isDarkMode,
  handleDarkMode,
  isHighContrastMode,
  handleHighContrastMode
}: Props) => {

  return (
    <BaseModal title={TranslationKey.SETTINGS} isOpen={isOpen} handleClose={handleClose}>
      <div className="mt-2 flex flex-col divide-y">
        <SettingsToggle
          settingName={TranslationKey.DARK_MODE}
          flag={isDarkMode}
          handleFlag={handleDarkMode}
          description={TranslationKey.DARK_MODE_DESCRIPTION}
        />
        <SettingsToggle
          settingName={TranslationKey.HIGH_CONTRAST_MODE}
          flag={isHighContrastMode}
          handleFlag={handleHighContrastMode}
          description={TranslationKey.HIGH_CONTRAST_MODE_DESCRIPTION}
        />
      </div>
    </BaseModal>
  )
}
