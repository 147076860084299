import classnames from 'classnames'

import { REVEAL_TIME_MS } from '../../constants/settings'
import { getStoredIsHighContrastMode, getStoredRemainingSolutions, setStoredCurrentRemainingSolutions, setStoredOutsideBot } from '../../lib/localStorage'
import { CharStatus } from '../../lib/statuses'
import { getIsShowSpotleBot, isCustom, isSixLetter } from '../../App'

type Props = {
  index?: number
  value?: string
  status?: CharStatus
  isRevealing?: boolean
  isCompleted?: boolean
  position?: number
  maskedStatus?: CharStatus
  onOpenSolutionsPage?: Function
  botGame?: boolean
}

export const Cell = ({
  index, 
  value,
  status,
  isRevealing,
  isCompleted,
  position = 0,
  maskedStatus,
  onOpenSolutionsPage, 
  botGame
}: Props) => {
  const isFilled = value && !isCompleted
  //the maskedStatus will only be populated if the game was NOT started in grid hard mode
  const shouldReveal = isRevealing && isCompleted && maskedStatus !== 'hidden'
  const letterAnimation = isRevealing && isCompleted
  const animationDelay = `${position * REVEAL_TIME_MS}ms`
  const isHighContrast = getStoredIsHighContrastMode()

  var maxPosition = 4
  if(isSixLetter()){
    maxPosition = 5
  }

  var defaultClassName = 'xxshort:w-11 xxshort:h-11 short:text-xl short:w-12 short:h-12 h-14 w-14 border-solid border-2 flex items-center justify-center mx-0.5 text-4xl font-bold rounded dark:text-white'
  if(position > maxPosition && (!isCustom() || getIsShowSpotleBot())){
    defaultClassName = 'xxshort:w-11 xxshort:h-11 short:text-base short:w-12 short:h-12 h-14 w-14 flex items-center justify-center mx-0.5 text-lg font-bold dark:text-white'
  }

  const classes = classnames(
    defaultClassName ,
    {
      'bg-white dark:bg-slate-900 border-slate-200 dark:border-slate-600':
        !status,
      'border-black dark:border-slate-100': value && !status,
      'absent shadowed bg-slate-400 dark:bg-slate-700 text-white border-slate-400 dark:border-slate-700':
        status === 'absent',
      'absent shadowed bg-black dark:bg-black text-white border-black dark:border-black':
        status === 'hidden',
      'correct shadowed bg-orange-500 text-white border-orange-500':
        status === 'correct' && isHighContrast,
      'present shadowed bg-cyan-500 text-white border-cyan-500':
        status === 'present' && isHighContrast,
      'correct shadowed bg-green-500 text-white border-green-500':
        status === 'correct' && !isHighContrast,
      'present shadowed bg-yellow-500 text-white border-yellow-500':
        status === 'present' && !isHighContrast,
      'cell-fill-animation': isFilled,
      'cell-reveal': shouldReveal
    }
  )

  return (
    <div className={classes} style={{ animationDelay }} onClick={() => 
      {
        if(position === 6 && onOpenSolutionsPage !== undefined && index !== undefined){
          if (botGame) {
            setStoredOutsideBot(false)
          } else {
            setStoredOutsideBot(true)
          }

          setStoredCurrentRemainingSolutions(getStoredRemainingSolutions(index))
          onOpenSolutionsPage()
        }
      }
    }>
      <div className={classnames({'letter-container': letterAnimation})} style={{ animationDelay }}>
        {value}
      </div>
    </div>
  )
}
